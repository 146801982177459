import React from 'react';
import PaymentsInvestorsTable from '../../../partials/others/PaymentsInvestorsTable';
import store from "../../../store";
import { connect } from "react-redux";
import { IMaskInput } from 'react-imask';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CurrencyValue from '../../../partials/others/CurrencyValue';

UIkit.use(Icons);

class PaymentsInvestors extends React.Component {

    constructor(props) {
        super(props);

        const defaultData = {
            profile: this.props.profile,
            projects: [],
            projectIds: [],
            projectsProfileIds: [],
            profiles: [],
            currentProfile: null,
            profilesIds: [],
            projectDataTable: '',
            amountProject: '',
            comment: '',
            paymentProjectId: '',
            amountProjectCalc: 0,
            calcPaymentsActive: false,
            calcBorrowerBalance: 0,
            ignoreCalcBalance: true
        }

        this.state = {
            ...defaultData,
            defaultData: defaultData
        };

        this.clearData = this.clearData.bind(this);
        this.getComments = this.getComments.bind(this);
        this.handleChangeProjects = this.handleChangeProjects.bind(this);
        this.generateProjectPayments = this.generateProjectPayments.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    async componentDidMount() {
        const profileHash = localStorage.getItem("profileHash");

        if (profileHash) {
            await fetch("https://api.crowd-money.ru/profile", {
                method: 'POST',
                body: JSON.stringify({
                    data: {
                        profileHash: profileHash
                    }
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        if (!result) {
                            localStorage.removeItem('profileHash');
                            this.props.history.push('/');
                        }

                        this.setState({
                            profile: result
                        })
                    },
                    (error) => {
                        console.log(error)
                    }
                );
        }

        if (this.state.profile.access.authorized === false) {
            this.props.history.push('/');
        }
        else {
            store.dispatch({
                type: 'CABINET.INDEX',
                payload: {
                    profile: this.state.profile
                }
            });

            await fetch("https://api.crowd-money.ru/loans", {
                method: 'POST',
                body: JSON.stringify({
                    data: {
                        type: "cabinet",
                        profileId: this.state.profile.access.id
                    }
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.projectsUpdate(result)
                    },
                    (error) => {
                        console.log(error)
                    }
                );

            fetch("https://api.crowd-money.ru/profile", {
                method: 'POST',
                body: JSON.stringify({
                    action: 'getProfiles',
                    data: {
                        profileId: this.state.profile.access.id
                    }
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            ...this.state,
                            profiles: this.profilesUpdate(result)
                        })
                    },
                    (error) => {
                        console.log(error)
                    }
                );
        }
    }

    getComments(projectID) {
        return [
            "Пополнение баланса лицевого счета",
            "Возврат инвестированных в заявку средств",
            `Выплата ежемесячных процентов по договору инвестирования №${projectID}`,
            `Выплата пени за просрочку по договору инвестирования №${projectID}`,
            `Возврат суммы основного долга по договору инвестирования №${projectID}`,
            `Возврат части суммы основного долга по договору инвестирования №${projectID}`,
            `Досрочное погашение займа по договору инвестирования №${projectID}`
        ]
    }

    commentIgnore = [
        "Пополнение баланса лицевого счета",
        "Возврат инвестированных в заявку средств"
    ];

    clearData = () => {
        this.setState({
            ...this.state.defaultData,
            projects: this.state.projects,
            profiles: this.state.profiles
        })
    }

    handleChange = (e, newValue) => {
        let name = e.target.name;
        let value = e.target.value;
        let amountProject = this.state.amountProject;
        let calcPaymentsActive = this.state.calcPaymentsActive;
        let calcBorrowerBalance = this.state.calcBorrowerBalance;
        let ignoreCalcBalance = this.state.ignoreCalcBalance;
        
        if (name == "comment"){
            ignoreCalcBalance = this.commentIgnore.includes(value);
            amountProject = '';
        }

        if (name == "amountProject") {
            if (value == "" || value == "0" || ignoreCalcBalance)  {
                calcPaymentsActive = true
                calcBorrowerBalance = this.state.currentProfile.balance
            }
            else {
                value = parseFloat(value.replace(',', '.'));
                calcPaymentsActive = value > this.state.currentProfile.balance;
                calcBorrowerBalance = this.state.currentProfile.balance - value;  
    
                if (calcBorrowerBalance < 0) {
                    calcPaymentsActive = true
                }
                
                calcPaymentsActive = !calcPaymentsActive;
            }

            amountProject = value;
        }

        this.setState({
            ...this.state,
            [name]: value,
            calcPaymentsActive: calcPaymentsActive,
            calcBorrowerBalance: calcBorrowerBalance,
            ignoreCalcBalance: ignoreCalcBalance,
            amountProject: amountProject
        })
    };

    handleChangeProjects = (id, newValue) => {
        if (newValue != null) {
            let projectIds = this.state.projects.filter(project => project.profileId == newValue.id).map(p => p.id);

            this.setState({
                ...this.state,
                projectIds: projectIds,
                currentProfile: newValue,
                paymentProjectId: projectIds[0],
                comment: this.state.comment == '' ? this.getComments(projectIds[0])[0] : this.state.comment,
                calcBorrowerBalance: newValue.balance
            })
        }
    };

    calculateAmountPayment = (project, amountProject) => {
        project.investmentsProject.forEach(investment => {
            investment['amountPayment'] = (amountProject * ((parseFloat(investment.amount / project.collected)))).toFixed(2)
        })

        return project;
    }

    projectsUpdate = (projects) => {
        let projectsRep = projects.filter(project => [8, 7, 4, 5].includes(project.status))
        let projectsProfileIds = projectsRep.map(project => project.profileId)

        this.setState({
            ...this.state,
            projects: projectsRep,
            projectsProfileIds: projectsProfileIds
        })
    }

    profilesUpdate = (profiles) => {
        let projectsProfileIds = this.state.projectsProfileIds;
        let profilesRep = profiles.filter(profile => projectsProfileIds.includes(profile.id))
            .map((row) => {
                return {
                    ...row,
                    fullName: (row.entrepreneurData.type === 1 && row.lastName + " " + row.firstName + " " + row.middleName) ||
                        (row.entrepreneurData.type === 2 && "ИП " + row.lastName + " " + row.firstName + " " + row.middleName) ||
                        (row.entrepreneurData.type === 3 && row.entrepreneurData.companyName),
                }
            }
            )
        return profilesRep;
    }

    generateProjectPayments = () => {
        let generateActive = this.state.paymentProjectId != '' && this.state.comment != '' &&
            (this.state.amountProject != '')


        if (!generateActive) {
            store.dispatch({
                type: "SHOW.SNACKBAR",
                payload: {
                    status: "error",
                    message: "Заполнены не все поля"
                }
            });
        }
        else {
            fetch("https://api.crowd-money.ru/loans/get/" + this.state.paymentProjectId, {
                method: 'POST'
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        let calculateAmountProject = this.calculateAmountPayment(result, this.state.amountProject)

                        this.setState({
                            ...this.state,
                            projectDataTable: calculateAmountProject,
                            amountProjectCalc: calculateAmountProject.investmentsProject.reduce((sum, investment) => sum + parseFloat(investment.amountPayment), 0)
                        });
                        store.dispatch({
                            type: "SHOW.SNACKBAR",
                            payload: {
                                status: "success",
                                message: "Платёж сформирован"
                            }
                        });

                    },
                    (error) => {
                        console.log(error)
                    }
                );
        }
    }

    render() {
        const { profile, profiles, projectIds, projectDataTable, amountProject, comment, paymentProjectId, currentProfile, amountProjectCalc, calcPaymentsActive, calcBorrowerBalance, ignoreCalcBalance } = this.state;

        return (
            <div className="tm-body">
                <div className="tm-section-documents">
                    <div className="uk-container uk-container-expand uk-section-small uk-padding-remove-top">
                        <h3 className="uk-h3 tm-font-weight-5">Платежи инвесторам</h3>
                        <div className="uk-overflow-auto">
                            {
                                profiles.length != 0 ? (
                                    <div className="uk-grid-row-small" data-uk-grid>
                                        <div className="uk-width-1-3@s">
                                            <label className="uk-form-label">
                                                Выбрать заёмщика
                                            </label>
                                            <Autocomplete
                                                key="autocomplete"
                                                name="projectsList"
                                                options={profiles}
                                                disableClearable
                                                getOptionLabel={(option) => `${option.fullName}`}
                                                renderOption={(props, option) => (
                                                    <li {...props}>
                                                        {
                                                            `${option.fullName}`
                                                        }
                                                    </li>
                                                )}
                                                value={currentProfile}
                                                onChange={this.handleChangeProjects}
                                                renderInput={(params) => (
                                                    <TextField {...params} placeholder="Заёмщики" />
                                                )}
                                            />
                                        </div>
                                        <div className="uk-width-1-6@s">
                                            <label className="uk-form-label">
                                                Номер договора
                                            </label>
                                            <select
                                                className="uk-select"
                                                name="paymentProjectId"
                                                onChange={this.handleChange}
                                                disabled={currentProfile == null}
                                            >
                                                {
                                                    paymentProjectId == '' && (
                                                        <option value={0}>Выберите договор...</option>
                                                    )
                                                }
                                                {
                                                    projectIds.map(id => (
                                                        <option value={id}>{id}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="uk-width-1-2@s">
                                            <label className="uk-form-label">
                                                Назначение платежа
                                            </label>
                                            <select
                                                className="uk-select"
                                                defaultValue={comment}
                                                name="comment"
                                                onChange={this.handleChange}
                                                disabled={currentProfile == null}
                                            >
                                                <>
                                                    {
                                                        comment == '' && (
                                                            <option value={0}>Выберите назначение...</option>
                                                        )
                                                    }
                                                    {
                                                        comment != '' && (
                                                            this.getComments(paymentProjectId).map(comment => (
                                                                <option value={comment}>{comment}</option>
                                                            ))
                                                        )
                                                    }
                                                </>
                                            </select>
                                        </div>                                       
                                        <div className="uk-width-1-3@s">
                                            <label className="uk-form-label">
                                                Сумма платежа, руб.
                                            </label>
                                            <IMaskInput
                                                mask={Number}
                                                radix=","
                                                name="amountProject"
                                                unmask={true}
                                                inputRef={(input) => { this.amountProject = input }}
                                                className="uk-input"
                                                onChange={this.handleChange}
                                                placeholder='0'
                                                value={`${amountProject}`}                                              
                                                disabled={currentProfile == null}
                                            />
                                        </div>                                        
                                        <div className="uk-margin-medium-top">
                                            <button
                                                className="uk-button uk-button-large uk-button-primary"
                                                onClick={() => this.generateProjectPayments()}
                                                disabled={!calcPaymentsActive}
                                            >
                                                Сформировать платеж
                                            </button>
                                        </div>
                                    </div>
                                )
                                    :
                                    (
                                        <div className="tm-spinner">
                                            <span data-uk-spinner="ratio: 3" />
                                        </div>
                                    )
                            }
                        </div>
                        {
                            currentProfile != null && (
                                <div className="uk-width-expand@s uk-margin-medium-top">
                                    <h3 className={`uk-h3 ${ignoreCalcBalance ? "uk-text-muted" : ""}`}>Баланс заёмщика: {<CurrencyValue value={calcBorrowerBalance} currencyType="₽" />}</h3>
                                </div>
                            )
                        }
                        <div className="uk-margin-medium-top">
                            {
                                projectDataTable && (
                                    <PaymentsInvestorsTable
                                        data={projectDataTable}
                                        amountProject={amountProject}
                                        comment={comment}
                                        defaultProfile={profile}
                                        clear={this.clearData}
                                        amountProjectCalc={amountProjectCalc}
                                        borrowerProfileId={this.state.currentProfile.id}
                                    />
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(PaymentsInvestors);