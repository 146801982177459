import React, { useState } from 'react';
import CurrencyValue from '../CurrencyValue';
import store from "../../../store";
import UIkit from 'uikit'

function ChangeBalanceModal(props) {
    const defaultData = {
        noActivate: true,
        purposePaymentSelected: "",
        purposePaymentCustom: "",
        projectId: ""
    }

    const runChangeBalance = () => {
        handleChangeBalance(props.action, props.profile, props.newBalance, getPurposeText())
    }

    const [data, setData] = useState(defaultData);       
     
    const handleChangeBalance = (action, profile, newBalance, purposePayment) => {       

        profile.balance = action === 1 ? parseFloat(newBalance) + parseFloat(profile.balance) : parseFloat(profile.balance) - parseFloat(newBalance);     
        
        store.dispatch({
            type: "HIDE.SNACKBAR"
        });

        fetch("https://api.crowd-money.ru/profile/balance", {
            method: 'POST',
            body: JSON.stringify({
                action: 'changeBalance',
                data: {
                    inquirer: {
                        profileHash: props.defaultProfile.access.hash
                    },
                    profileId: profile.id,
                    balance: {
                        type: action,
                        amount: newBalance
                    },
                    purposePayment: purposePayment,
                    projectId: data.projectId
                }
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.message) {
                        store.dispatch({
                            type: "SHOW.SNACKBAR",
                            payload: {
                                status: result.status,
                                message: result.message
                            }
                        });
                    }

                    if (result.status === "success") {
                        //  item.newBalance = "";
                        //  items[section][index] = item;                      
                    }

                    UIkit.modal(`#changeBalanceModal${profile.id}`).hide();
                    setData(defaultData)

                },
                (error) => {
                    console.log(error)
                }
            );
        
    };

    const getPurposeText = () => { 
        let purposePaymentText = data.purposePaymentSelected ? data.purposePaymentSelected : data.purposePaymentCustom  
        setData({noActivate: true, purposePaymentSelected: "", purposePaymentCustom:"", projectId: ""}) 
        purposePaymentText += data.projectId ? data.projectId : ""
        return purposePaymentText        
    }

    const activateContinue = () => {
        return (data.purposePaymentSelected == "" || data.purposePaymentSelected.includes("№")) &&              
               (data.projectId == "") &&
               (data.purposePaymentCustom == "")               
    }

    return (
        <div id={`changeBalanceModal${props.profile.id}`} className="uk-flex-top" data-uk-modal>           
            <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-width-1-3@s">
                <button className="uk-modal-close-default" type="button" data-uk-close></button>
                <h3 
                    className="uk-h3 tm-font-weight-5"> 
                        {props.action === 1 ? ("Пополнение") : ("Списание")} на сумму {<CurrencyValue value={props.newBalance} currencyType={"₽"} />}
                </h3>
                <p>Для изменения баланса необходимо заполнить назначение операции.</p>
                <div className="uk-margin">
                    <select
                        className="uk-select"
                        defaultValue="Выберите назначение..."                                   
                        onChange={(e) => {
                            if (e.target.value == "Другое") setData({...defaultData, noActivate: false})                                                     
                            else setData({...defaultData, purposePaymentSelected: e.target.value})                                                                                    
                        }}>
                        <option value="Выберите назначение..." disabled hidden>Выберите назначение...</option>
                        {
                            props.action === 1 ? (
                                <>
                                    <option value="Пополнение баланса лицевого счета">Пополнение баланса лицевого счета</option>
                                    <option value="Корректировка баланса лицевого счета">Корректировка баланса лицевого счета</option>
                                    <option value="Возврат инвестированных в заявку средств">Возврат инвестированных в заявку средств</option>                                    
                                    <option value="Выплата ежемесячных процентов по договору инвестирования №">Выплата ежемесячных процентов по договору инвестирования №</option>
                                    <option value="Выплата пени за просрочку по договору инвестирования №">Выплата пени за просрочку по договору инвестирования №</option>                                       
                                    <option value="Возврат суммы основного долга по договору инвестирования №">Возврат суммы основного долга по договору инвестирования №</option>
                                    <option value="Возврат части суммы основного долга по договору инвестирования №">Возврат части суммы основного долга по договору инвестирования №</option>                                
                                    <option value="Досрочное погашение займа по договору инвестирования №">Досрочное погашение займа по договору инвестирования №</option>
                                </>) : (
                                <>
                                    <option value="Корректировка баланса лицевого счета">Корректировка баланса лицевого счета</option> 
                                    <option value="Вывод средств с лицевого счета">Вывод средств с лицевого счета</option>                                       
                                </>
                            )
                        }                                  
                        <option value="Другое">Другое</option>                        
                    </select>
                    {
                        data.purposePaymentSelected.includes("№") && (
                            <div className="uk-margin">
                                <select className="uk-select"
                                        defaultValue="Выберите номер договора"
                                        onChange={(e) => {setData({...data, projectId: e.target.value})}}
                                >
                                    <option value="Выберите номер договора" disabled hidden>Выберите номер договора</option>
                                    {
                                        props.projectsId.map(element => 
                                            <option value={element}>{element}</option>
                                        )
                                    }
                                </select>
                            </div>
                        )
                    }
                </div>
                <div className="uk-margin">
                    <textarea
                        type="text"                       
                        placeholder="Назначение операции"
                        className="uk-textarea"
                        rows="3"
                        value={data.purposePaymentCustom}
                        maxlength="128"
                        name="purposeAmount"
                        disabled={data.noActivate}
                        onChange={(e)=>{setData({...defaultData, noActivate:false, purposePaymentCustom:e.target.value})}}
                    />
                </div>                
                <div className="uk-margin">
                    <button 
                        className="uk-button uk-button-large uk-button-primary uk-width" 
                        disabled={activateContinue()} 
                        onClick={runChangeBalance.bind(this)}>
                            Продолжить
                    </button>                   
                </div>
            </div>
        </div>
    )
}
export default ChangeBalanceModal;