import React, { useState } from 'react';
import fileDownload from 'js-file-download';
import store from "../../../store";
import "react-datepicker/dist/react-datepicker.css";
import CurrencyFormat from 'react-currency-format';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import ScheduleModal from '../../../partials/others/ScheduleModal';
import { Link } from "react-router-dom";
import { getDateDiffDelay } from "../../main/Utils";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useRef } from 'react';

function OperationHistory(props) {
    const getOwners = (investments) => {
        let owners = {};

        investments.forEach((investment) => {
            owners[investment.project.owner.profileId] = investment.project.owner
        })

        return Object.values(owners);
    }

    const owners = getOwners(props.investments);

    const defaultData = {
        investments: props.investments,
        investmentsFilterable: props.investments,
        projectIds: props.investments.map(i => i.projectId),
        owners: owners,
        innList: owners.map(owner => owner.entrepreneurData.inn),
        currentProjectId: '',
        currentOwner: null,
        currentInn: '',
    }

    const [data, setData] = useState(defaultData);

    const handleChange = (id, value, reason, name) => {
        let investmentsFilterable = [...data.investmentsFilterable];
        let fieldName = "";

        if (name.includes("projectIds")) {
            investmentsFilterable = filter(value, data.currentOwner, data.currentInn);
            fieldName = "currentProjectId";
        }

        if (name.includes("owners")) {
            investmentsFilterable = filter(data.currentProjectId, value, data.currentInn);
            fieldName = "currentOwner";
        }

        if (name.includes("innList")) {
            investmentsFilterable = filter(data.currentProjectId, data.currentOwner, value);
            fieldName = "currentInn";
        }

        let ownersFilter = getOwners(investmentsFilterable);

        setData({
            ...data,
            investmentsFilterable: [...investmentsFilterable],
            projectIds: investmentsFilterable.map(i => i.projectId),
            owners: ownersFilter,
            innList: ownersFilter.map(owner => owner.entrepreneurData.inn),
            [fieldName]: value,
        })
    };

    const filter = (projectId, owner, inn) => {
        projectId = projectId ? projectId : "";
        let profileId = owner ? owner.profileId : "";
        inn = inn ? inn : "";

        let investmentsFilterable = [...data.investments];
        investmentsFilterable = investmentsFilterable.filter(investment => investment.projectId.includes(projectId));
        investmentsFilterable = investmentsFilterable.filter(investment => investment.project.owner.profileId.includes(profileId));
        investmentsFilterable = investmentsFilterable.filter(investment => investment.project.owner.entrepreneurData.inn.includes(inn))

        return investmentsFilterable;
    };

    const clearFilter = () => {
        setData({ ...defaultData })
    };

    const projectIdsRef = useRef();
    const ownersRef = useRef();
    const innListRef = useRef();

    const generateStatementFromRegister = (investmentId) => {
        fetch("https://api.crowd-money.ru/loans/statement", {
            method: 'POST',
            body: JSON.stringify({
                action: "getStatementFromRegister",
                data: {
                    investmentId: investmentId
                }
            })
        })
            .then(res => res.blob())
            .then((blob) => {
                store.dispatch({
                    type: "SHOW.SNACKBAR",
                    payload: {
                        status: "success",
                        message: "Выписка сформирована"
                    }
                });
                fileDownload(blob, `Выписка из реестра договоров.pdf`)
            },
                (error) => {
                    console.log(error)
                    store.dispatch({
                        type: "SHOW.SNACKBAR",
                        payload: {
                            status: "error",
                            message: "Ошибка формирования"
                        }
                    });
                }
            );
    }

    return (
        <>
            <h3 className="uk-h3 tm-font-weight-5">История операций</h3>
            <div className="uk-grid-row-small uk-margin-bottom" data-uk-grid>
                <div className="uk-width-1-4@s">
                    <Autocomplete
                        name="projectIds"
                        ref={projectIdsRef}
                        options={data.projectIds}
                        getOptionLabel={(option) => `${option}`}
                        renderOption={(props, option) => (
                            <li {...props}>
                                {
                                    `${option}`
                                }
                            </li>
                        )}
                        value={data.currentProjectId}
                        onChange={(e, v, r) => {
                            handleChange(e, v, r, projectIdsRef.current.getAttribute("name"))
                        }
                        }
                        renderInput={(params) => (
                            <TextField {...params} placeholder="Номер договора" />
                        )}
                    />
                </div>
                <div className="uk-width-1-3@s">
                    <Autocomplete
                        name="owners"
                        ref={ownersRef}
                        options={data.owners}
                        getOptionLabel={(option) => `${option.fullName}`}
                        renderOption={(props, option) => (
                            <li {...props}>
                                {
                                    `${option.fullName}`
                                }
                            </li>
                        )}
                        value={data.currentOwner}
                        onChange={(e, v, r) => {
                            handleChange(e, v, r, ownersRef.current.getAttribute("name"))
                        }
                        }
                        renderInput={(params) => (
                            <TextField {...params} placeholder="Заёмщик" />
                        )}
                    />
                </div>
                <div className="uk-width-1-4@s">
                    <Autocomplete
                        name="innList"
                        ref={innListRef}
                        options={data.innList}
                        getOptionLabel={(option) => `${option}`}
                        renderOption={(props, option) => (
                            <li {...props}>
                                {
                                    `${option}`
                                }
                            </li>
                        )}
                        value={data.currentInn}
                        onChange={(e, v, r) => {
                            handleChange(e, v, r, innListRef.current.getAttribute("name"))
                        }
                        }
                        renderInput={(params) => (
                            <TextField {...params} placeholder="ИНН" />
                        )}
                    />
                </div>
                <div>
                    <button
                        className="uk-button uk-button-default uk-button-large"
                        disabled={props.disabled}
                        onClick={clearFilter}>
                        Сброс
                    </button>
                </div>
            </div>
            <div className="uk-overflow-auto">
                <table className="tm-table-balance-operations uk-table uk-text-center uk-table-divider uk-table-hover uk-table-middle">
                    <thead>
                        <tr>
                            <th className="uk-text-center uk-table-middle">Номер договора</th>
                            <th className="uk-text-center uk-table-middle">Заёмщик</th>
                            <th className="uk-text-center uk-table-middle">ИНН</th>
                            <th className="uk-text-center uk-table-middle">Статус</th>
                            <th className="uk-text-center uk-table-middle">Акцепт</th>
                            <th className="uk-text-center uk-table-middle">Выписка</th>
                            <th className="uk-text-center uk-table-middle">Дата операции</th>
                            <th className="uk-text-center uk-table-middle">Сумма</th>
                            {
                                props.expired && (
                                    <th className="uk-text-center uk-table-middle">Просрочка, дн.</th>
                                )
                            }
                            <th className="uk-text-center uk-width-small uk-table-middle">График</th>
                            <th className="uk-width-small uk-table-middle" ></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.investmentsFilterable.length > 0 ? (
                                data.investmentsFilterable.map((investment) => (
                                    <>
                                        <tr>
                                            <td>{investment.projectId}</td>
                                            <td>
                                                {investment.project.owner.fullName}
                                            </td>
                                            <td>{investment.project.owner.entrepreneurData.inn}</td>
                                            <td>
                                                {
                                                    investment.project.status === 0 && (
                                                        <span className="uk-text-warning">{investment.project.statusName}</span>
                                                    )
                                                }
                                                {
                                                    investment.project.status === 1 && (
                                                        <span className="uk-text-success">{investment.project.statusName}</span>
                                                    )
                                                }
                                                {
                                                    investment.project.status === 2 && (
                                                        <span className="uk-text-warning">{investment.project.statusName}</span>
                                                    )
                                                }
                                                {
                                                    investment.project.status === 3 && (
                                                        <span className="uk-text-danger">{investment.project.statusName}</span>
                                                    )
                                                }
                                                {
                                                    investment.project.status === 4 && (
                                                        <span className="uk-text-muted">{investment.project.statusName}</span>
                                                    )
                                                }
                                                {
                                                    investment.project.status === 5 && (
                                                        <span className="uk-text-success">{investment.project.statusName}</span>
                                                    )
                                                }
                                                {
                                                    investment.project.status === 6 && (
                                                        <span className="uk-text-success">{investment.project.statusName}</span>
                                                    )
                                                }
                                                {
                                                    investment.project.status === 7 && (
                                                        <span className="uk-text-danger">{investment.project.statusName}</span>
                                                    )
                                                }
                                                {
                                                    investment.project.status === 8 && (
                                                        <span className="uk-text-success">{investment.project.statusName}</span>
                                                    )
                                                }
                                                {
                                                    investment.project.status === 9 && (
                                                        <span className="uk-text-warning">{investment.project.statusName}</span>
                                                    )
                                                }
                                            </td>
                                            <td>
                                                {
                                                    investment.paymentInvestmentAccept ? (
                                                        <a href={`/private/paymentInvestmentAccepts/${investment.paymentInvestmentAccept}`} download>Скачать</a>
                                                    ) : '-'
                                                }
                                            </td>
                                            <td>
                                                <a
                                                    download
                                                    onClick={() => generateStatementFromRegister(investment.id)}>Скачать</a>
                                            </td>
                                            <td>{investment.date}</td>
                                            <td>
                                                <CurrencyFormat value={investment.amount} displayType={'text'} thousandSeparator={" "} /> ₽
                                            </td>
                                            {
                                                props.expired && (
                                                    <td>
                                                        {
                                                            getDateDiffDelay(investment.project.projectDateDelay)
                                                        }
                                                    </td>
                                                )
                                            }
                                            <td>
                                                {
                                                    investment.investmentScheduleDocument ? (
                                                        <>
                                                            <div className={window.innerWidth > 600 && ("uk-flex uk-flex-between")}>
                                                                <div className="uk-button uk-button-small"
                                                                    data-uk-toggle={`target: #scheduleModal${investment.id};`}
                                                                >
                                                                    <VisibilityIcon />
                                                                </div>
                                                                <a className="uk-button uk-button-small"
                                                                    href={`/private/schedules/${investment.investmentScheduleDocument}`} download>
                                                                    <DownloadIcon />
                                                                </a>
                                                            </div>
                                                            <ScheduleModal
                                                                investment={investment}
                                                                scheduleData={investment.investmentScheduleData}
                                                            />
                                                        </>
                                                    )
                                                        :
                                                        '-'
                                                }
                                            </td>
                                            <td>
                                                {
                                                    ([4, 5, 6, 7, 8].includes(investment.project.status) && (investment.project.msgCount > 0)) ? (
                                                        <Link
                                                            to={`/project/${investment.projectId}/?msgRead=true`}
                                                        >
                                                            <div className="uk-button uk-button-small uk-button-primary">
                                                                <div data-uk-icon="icon: mail; ratio: 1" />
                                                                <span>&nbsp;{investment.project.msgCount}</span>
                                                            </div>
                                                        </Link>
                                                    ) : (
                                                        <Link
                                                            to={`project/${investment.projectId}`}
                                                            className="uk-button uk-button-small uk-button-primary"
                                                        >
                                                            Проект
                                                        </Link>
                                                    )
                                                }
                                            </td>
                                        </tr>
                                    </>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={7}>Операций нет</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default OperationHistory;