import React from 'react';
import CurrencyValue from '../../../partials/others/CurrencyValue';
import store from "../../../store";
import { connect } from "react-redux";
import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'
import { DataGrid, ruRU } from '@material-ui/data-grid';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import {getDateDiffDelay} from "../../main/Utils"

UIkit.use(Icons);

const theme = createTheme(
    {
        typography: {
            fontFamily: [
                'Montserrat',
                'sans-serif'
            ].join(',')
        }
    },
    ruRU
);

class ProjectsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: this.props.profile,
            projects: []
        }

        this.getDaysInMonth = this.getDaysInMonth.bind(this);
    }

    async componentDidMount() {

        const profileHash = localStorage.getItem("profileHash");

        if (profileHash) {
            await fetch("https://api.crowd-money.ru/profile", {
                method: 'POST',
                body: JSON.stringify({
                    data: {
                        profileHash: profileHash
                    }
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        if (!result) {
                            localStorage.removeItem('profileHash');
                            this.props.history.push('/');
                        }

                        this.setState({
                            profile: result
                        })
                    },
                    (error) => {
                        console.log(error)
                    }
                );
        }

        if (this.state.profile.access.authorized === false) {
            this.props.history.push('/');
        }
        else {
            store.dispatch({
                type: 'CABINET.PROJECTS_TABLE',
                payload: {
                    profile: this.state.profile
                }
            });

            await fetch("https://api.crowd-money.ru/loans", {
                method: 'POST',
                body: JSON.stringify({
                    data: {
                        type: "cabinet",
                        profileId: this.state.profile.access.id
                    }
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            projects: this.projectsUpdate(result)
                        })
                    },
                    (error) => {
                        console.log(error)
                    }
                );
        }
    }

    getDaysInMonth = (monthsCount) => {
        var days = monthsCount * 30;

        if (monthsCount == 12) {
            days += 5;
        }

        return days;
    }

    // getDateDiffDelay = (dateDelay) => {
    //     var dateAttr = dateDelay.split('.');
    //     dateDelay = new Date(dateAttr[2], dateAttr[1] - 1, (dateAttr[0]));
    //     var today = new Date();
    //     var daysDiff = Math.ceil((today.getTime() - dateDelay.getTime()) / (1000 * 3600 * 24));
    //     return daysDiff;
    // }

    projectsUpdate = (projects) => {
        let projectsRep = projects.flatMap((row) => {
            return {
                profileId: row.profileId,
                id: row.id,
                loanType: (row.data.loanType === 1 && "Исполнение государственного контракта") ||
                    (row.data.loanType === 2 && "Развитие бизнеса") ||
                    (row.data.loanType === 3 && "Пополнение оборотных средств"),
                entrepreneurData: (row.entrepreneurData.type === 1 && row.mainData.lastName + " " + row.mainData.firstName + " " + row.mainData.middleName) ||
                    (row.entrepreneurData.type === 2 && "ИП " + row.mainData.lastName + " " + row.mainData.firstName + " " + row.mainData.middleName) ||
                    (row.entrepreneurData.type === 3 && row.entrepreneurData.companyName),
                investmentOfferDocument: row.investmentOfferDocument,
                status: row.status,
                statusName: (row.status === 0 && "На рассмотрении") ||
                    (row.status === 1 && row.investmentOfferVerifyCode != 0 && "Сбор средств") ||
                    (row.status === 1 && row.investmentOfferVerifyCode == 0 && "Рассмотрена") ||
                    (row.status === 2 && "Требуются уточнения") ||
                    (row.status === 3 && "Отказано") ||
                    (row.status === 4 && "Займ погашен") ||
                    (row.status === 5 && "Сбор средств завершён") ||
                    (row.status === 6 && "Погашен досрочно") ||
                    (row.status === 7 && "Просрочка платежей") ||
                    (row.status === 8 && "Активный") ||
                    (row.status === 9 && "Не состоялась"),
                date: row.date.create,
                okved: row.okved,
                closeInvestors: row.closeInvestors,
                collected: row.collected,
                rate: row.rate,
                durationDays: row.data.durationDays ? row.data.durationDays : this.getDaysInMonth(row.data.duration),
                note: row.note,
                dateDelay: row.date.delay
            }
        }
        )
        return projectsRep;
    }

    render() {
        const { projects } = this.state;

        return (
            <div className="tm-body">
                <div className="uk-container uk-section-small uk-padding-remove-top tm-container-cabinet">
                    <h3 className="uk-h3 tm-font-weight-5">Витрина заявок</h3>
                    <div className="uk-overflow-auto">
                        {/* <ThemeProvider theme={theme}>
                                <DataGrid
                                    columns={
                                        [
                                            {
                                                field: 'projectId',
                                                headerName: '№',
                                                width: 100
                                            },
                                            {
                                                field: 'projectType',
                                                headerName: 'Тип',
                                                width: 200,
                                                align: 'center',
                                            },
                                            {
                                                field: 'loanType',
                                                headerName: 'Цель займа',
                                                width: 330,
                                                align: 'center',
                                            },
                                            {
                                                field: 'borrower',
                                                headerName: 'Заёмщик',
                                                width: 300                                           
                                            },
                                            {
                                                field: 'sum',
                                                headerName: 'Собранная сумма',
                                                filterable: false,
                                                disableColumnMenu: true,
                                                width: 210
                                            },
                                            {
                                                field: 'rate',
                                                headerName: 'Ставка',
                                                filterable: false,
                                                disableColumnMenu: true,
                                                width: 120
                                            },
                                            {
                                                field: 'duration',
                                                headerName: 'Срок, дн.',
                                                filterable: false,
                                                disableColumnMenu: true,
                                                width: 140
                                            },
                                            {
                                                field: 'status',
                                                headerName: 'Текущий статус',
                                                width: 220,
                                                valueGetter: params => `${params.value.statusName}`,
                                                renderCell: (params) => (
                                                    <>                                                   
                                                        {
                                                            params.row.status.status === 0 && (
                                                                <span className="uk-text-warning">{params.value}</span>
                                                            )
                                                        }
                                                        {
                                                            params.row.status.status === 1 && (
                                                                <span className="uk-text-success">{params.value}</span>
                                                            )
                                                        }
                                                        {
                                                            params.row.status.status === 2 && (
                                                                <span className="uk-text-warning">{params.value}</span>
                                                            )
                                                        }
                                                        {
                                                            params.row.status.status === 3 && (
                                                                <span className="uk-text-danger">{params.value}</span>
                                                            )
                                                        }
                                                        {
                                                            params.row.status.status === 4 && (
                                                                <span className="uk-text-muted">{params.value}</span>
                                                            )
                                                        }
                                                        {
                                                            params.row.status.status === 5 && (
                                                                <span className="uk-text-success">{params.value}</span>
                                                            )
                                                        }
                                                        {
                                                            params.row.status.status === 6 && (
                                                                <span className="uk-text-success">{params.value}</span>
                                                            )
                                                        }
                                                        {
                                                            params.row.status.status === 7 && (
                                                                <span className="uk-text-danger">{params.value}</span>
                                                            )
                                                        }
                                                    </>
                                                )
                                            },
                                            {
                                                field: 'dateDelay',
                                                headerName: 'Просрочка, дн.',
                                                filterable: false,
                                                disableColumnMenu: true,
                                                width: 220
                                            },
                                            {
                                                field: 'note',
                                                headerName: 'Примечание',
                                                filterable: false,
                                                disableColumnMenu: true,
                                                width: 220
                                            },
                                        ]
                                    }
                                    rows={
                                        projects.map((project) => (
                                            // project.status != 3 && project.status != 2 && project.status != 0 &&
                                            {
                                                id: project.id,
                                                projectId: project.id,
                                                projectType: "Тип заявки",
                                                loanType: project.loanType,
                                                borrower: project.entrepreneurData,
                                                sum: project.collected,
                                                rate: project.rate,
                                                duration: project.durationDays,
                                                // status: project,
                                                dateDelay: project.dateDelay,
                                                note: project.note
                                            }
                                        ))
                                    }
                                    //rowsPerPageOptions={[5]}
                                    rowsPerPageOptions={[25, 50, 100]}
                                    disableSelectionOnClick
                                    rowHeight={50}
                                    disableColumnSelector={true}
                                    sortingOrder={['desc', 'asc']}
                                />
                            </ThemeProvider> */}
                        <table className="tm-table-balance-operations uk-table uk-text-center uk-table-divider uk-table-hover uk-table-middle">
                            <thead>
                                <tr>
                                    <th className="uk-text-center uk-table-middle">ID заявки</th>
                                    <th className="uk-text-center uk-table-middle">Тип заявки</th>
                                    <th className="uk-text-center uk-table-middle">Цель займа</th>
                                    <th className="uk-text-center uk-table-middle">Заёмщик</th>
                                    <th className="uk-text-center uk-table-middle">Собранная сумма, руб.</th>
                                    <th className="uk-text-center uk-table-middle">Ставка</th>
                                    <th className="uk-text-center uk-table-middle">Срок, дн.</th>
                                    <th className="uk-text-center uk-table-middle">Текущий статус</th>
                                    <th className="uk-text-center uk-table-middle">Просрочка, дн.</th>
                                    <th className="uk-text-center uk-table-middle">Примечание</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    projects.length > 0 ? (
                                        projects.map((project) => (
                                            project.status != 3 && project.status != 2 && project.status != 0 && (
                                                //Игнорируем заявки
                                                <tr>
                                                    <td>
                                                        {
                                                            project.closeInvestors != false && (
                                                                <span>{project.id}</span>
                                                            )
                                                        }
                                                        {
                                                            project.closeInvestors == false && (
                                                                <a class="uk-link-text" href={`/project/${project.id}`}>{project.id}</a>
                                                            )
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            project.closeInvestors != false && (
                                                                <span className="uk-text">Закрытое инвестиционное предложение</span>
                                                            )
                                                        }
                                                        {
                                                            project.closeInvestors == false && (
                                                                <span className="uk-text-success">Открытое инвестиционное предложение</span>
                                                            )
                                                        }
                                                    </td>
                                                    <td>
                                                        {project.loanType}
                                                    </td>
                                                    <td>
                                                        {project.entrepreneurData}
                                                    </td>
                                                    <td>
                                                        <CurrencyValue value={project.collected} currencyType={"₽"} />
                                                    </td>
                                                    <td>
                                                        {project.rate}%
                                                    </td>
                                                    <td>
                                                        {project.durationDays}
                                                    </td>
                                                    <td>
                                                        {
                                                            project.status === 0 && (
                                                                <span className="uk-text-warning">{project.statusName}</span>
                                                            )
                                                        }
                                                        {
                                                            project.status === 1 && (
                                                                <span className="uk-text-success">{project.statusName}</span>
                                                            )
                                                        }
                                                        {
                                                            project.status === 2 && (
                                                                <span className="uk-text-warning">{project.statusName}</span>
                                                            )
                                                        }
                                                        {
                                                            project.status === 3 && (
                                                                <span className="uk-text-danger">{project.statusName}</span>
                                                            )
                                                        }
                                                        {
                                                            project.status === 4 && (
                                                                <span className="uk-text-muted">{project.statusName}</span>
                                                            )
                                                        }
                                                        {
                                                            project.status === 5 && (
                                                                <span className="uk-text-success">{project.statusName}</span>
                                                            )
                                                        }
                                                        {
                                                            project.status === 6 && (
                                                                <span className="uk-text-success">{project.statusName}</span>
                                                            )
                                                        }
                                                        {
                                                            project.status === 7 && (
                                                                <span className="uk-text-danger">{project.statusName}</span>
                                                            )
                                                        }
                                                        {
                                                            project.status === 8 && (
                                                                <span className="uk-text-success">{project.statusName}</span>
                                                            )
                                                        }
                                                        {
                                                            project.status === 9 && (
                                                                <span className="uk-text-warning">{project.statusName}</span>
                                                            )
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            project.dateDelay != "" && project.status === 7 && (
                                                                getDateDiffDelay(project.dateDelay)
                                                            )
                                                        }
                                                        {
                                                            project.dateDelay == "" && (
                                                                "-"
                                                            )
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            project.note != "" && (
                                                                project.note
                                                            )
                                                        }
                                                        {
                                                            project.note == "" && (
                                                                "-"
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={5}>Заявки отсутствуют</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(ProjectsTable);