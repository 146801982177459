import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import store from "../../../store";
import "react-datepicker/dist/react-datepicker.css";

function DelayWork(props) {

    const [data, setData] = useState({
        delayWorkData: props.project.delayWorkData ? props.project.delayWorkData : [],
        date: Math.floor(Date.now() / 1000),
        eventText: ""
    });

    const addEvent = () => {
        let newEventData = [...data.delayWorkData];
        newEventData.unshift({
            date: data.date,
            eventText: data.eventText
        });

        setData({
            ...data,
            delayWorkData: [...newEventData]
        })
    }

    const deleteEvent = (index) => {
        data.delayWorkData.splice(index, 1);

        setData({
            ...data
        })
    }

    const setDelayWork = () => {
        fetch("https://api.crowd-money.ru/loans/updateWorkDelay", {
            method: 'POST',
            body: JSON.stringify({
                action: 'setDelayWorkData',
                data: {
                    projectId: props.project.id,
                    dataWorkDelay: data.delayWorkData
                }
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    store.dispatch({
                        type: "SHOW.SNACKBAR",
                        payload: {
                            status: result.status,
                            message: result.message
                        }
                    });
                },
                (error) => {
                    console.log(error)
                }
            );
    }

    return (
        <>
            <div className="uk-overflow-auto">
                <div className=" uk-flex uk-flex-middle">
                    <div className="uk-width-1-4@s uk-margin-right">
                        <label className="uk-form-label">
                            Дата
                        </label>
                        <DatePicker
                            selected={new Date(data.date * 1000)}
                            className="uk-input"
                            locale="ru"
                            dateFormat="dd.MM.yyyy"
                            onChange={(value) => setData({
                                ...data,
                                date: Math.floor(value.valueOf() / 1000)
                            })
                            }
                        />
                    </div>
                    <div className="uk-width-3-4@s uk-margin-right">
                        <label className="uk-form-label">
                            Содержание события
                        </label>
                        <textarea
                            value={data.eventText}
                            className="uk-textarea"
                            rows={2}
                            Style="resize: none;"
                            name="eventText"
                            onChange={(value) => setData({
                                ...data,
                                eventText: value.target.value
                            })
                            }
                        />
                    </div>
                    <button className="uk-button uk-button-medium uk-button-primary uk-margin-top"
                        onClick={addEvent}
                    >
                        Добавить
                    </button>
                </div>
                <table className="tm-table-balance-operations uk-text-center uk-table uk-table-divider uk-table-hover uk-table-middle">
                    <thead>
                        <tr>
                            <th className="uk-text-center uk-width-1-4@s">Дата</th>
                            <th className="uk-text-center uk-width-auto">Событие</th>
                            <th className="uk-width-1-6@s"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.delayWorkData.length > 0 ? (
                                data.delayWorkData.map((eventData, index) => (
                                    <tr>
                                        <td>
                                            {new Date(eventData.date * 1000).toLocaleDateString("ru-RU")}
                                        </td>
                                        <td>
                                            {eventData.eventText}
                                        </td>
                                        <td>
                                            <div className="uk-margin-large-left uk-button uk-button-small uk-button-primary"
                                                onClick={() => deleteEvent(index)}
                                            >
                                                Удалить
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={5}>События отсутствуют</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
            <div className="uk-margin-medium-top">
                <div className="uk-button uk-button-large uk-button-primary"
                    onClick={setDelayWork}
                >
                    Сохранить изменения
                </div>
            </div>
        </>
    )
}

export default DelayWork;