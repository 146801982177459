import React from 'react';
import CurrencyFormat from 'react-currency-format';
import NumberFormat from 'react-number-format';
import store from "../../../store";
import { connect } from "react-redux";
import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'
import { Link } from "react-router-dom";
import InputMask from "react-input-mask";
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CalculateSchedule from '../../../partials/others/CalculateSchedule';
import CurrencyValue from '../../../partials/others/CurrencyValue';
import ScheduleModal from '../../../partials/others/ScheduleModal';
import AgreementRequestBKI from '../../../partials/others/AgreementRequestBKI';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import fileDownload from 'js-file-download';
import { IMaskInput } from 'react-imask';
import DelayWork from "../../../partials/others/DelayWork";
import { getDateDiffDelay } from "../../main/Utils";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

UIkit.use(Icons);

class Project extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            profiles: [],
            sProfiles: [],
            partners: [],
            viewSchedule: false,
            viewDelayWork: false,
            messages: [],
            messageText: ""
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeCloseInvestors = this.handleChangeCloseInvestors.bind(this);
        this.update = this.update.bind(this);

        this.uploadFile = this.uploadFile.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.handleDownloadDocuments = this.handleDownloadDocuments.bind(this);
        this.handleViewSchedule = this.handleViewSchedule.bind(this);
        this.handleViewDelayWork = this.handleViewDelayWork.bind(this);
        this.handleMessage = this.handleMessage.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.deleteMessage = this.deleteMessage.bind(this);
        this.readMessages = this.readMessages.bind(this);
    }

    async componentDidMount() {
        const profileHash = localStorage.getItem("profileHash");

        if (profileHash) {
            await fetch("https://api.crowd-money.ru/profile", {
                method: 'POST',
                body: JSON.stringify({
                    data: {
                        profileHash: profileHash
                    }
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        if (!result) {
                            localStorage.removeItem('profileHash');
                            this.props.history.push('/');
                        }

                        this.setState({
                            profile: result
                        })
                    },
                    (error) => {
                        console.log(error)
                    }
                );
        }

        if (this.state.profile.access.authorized === false) {
            this.props.history.push('/');
        }
        else {
            await fetch("https://api.crowd-money.ru/loans/get/" + window.location.pathname.split('/')[2], {
                method: 'POST'
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log(result)
                        this.setState({
                            isLoaded: true,
                            project: {
                                ...result,
                                closeProject: result.closeInvestors ? 1 : 0,
                                closeInvestors: result.closeInvestors != "" ? result.closeInvestors : []
                            }
                        });

                    },
                    (error) => {
                        console.log(error)
                    }
                );

            store.dispatch({
                type: 'CABINET.PROJECT',
                payload: this.state
            });
        }

        fetch("https://api.crowd-money.ru/profile", {
            method: 'POST',
            body: JSON.stringify({
                action: 'getProfiles',
                data: {
                    profileId: this.state.profile.access.id
                }
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    var profiles = result.filter((profile) => { return (profile.group === 1 && profile.verification.length !== 0) });

                    this.setState({
                        ...this.state,
                        profiles: [...profiles],
                        sProfiles: [...profiles]
                    })
                },
                (error) => {
                    console.log(error)
                }
            );

        fetch("https://api.crowd-money.ru/partners", {
            method: 'POST',
            body: JSON.stringify({
                action: 'getPartners'
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        ...this.state,
                        partners: result.map(e => { return { id: e.partnerId, name: e.partnerName } })
                    })
                },
                (error) => {
                    console.log(error)
                }
            )

        fetch("https://api.crowd-money.ru/loans/message", {
            method: 'POST',
            body: JSON.stringify({
                action: 'getMessages',
                projectId: this.state.project.id 
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result) {
                        this.setState({
                            ...this.state,
                            messages: [...result],
                            messageText: ""
                        })
                        
                        const queryParams = new URLSearchParams(window.location.search);
                        if (queryParams.get('msgRead')){
                            this.readMessages();
                        }
                    }

                }, (error) => {
                    console.log(error)
                }
            )
    }

    readMessages = () => {
        fetch("https://api.crowd-money.ru/loans/message", {
            method: 'POST',
            body: JSON.stringify({
                action: "readMessages",
                data: {
                    projectId: this.state.project.id,
                    profileId: this.state.profile.access.id
                }
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result) {
                        store.dispatch({
                            type: "SHOW.SNACKBAR",
                            payload: {
                                status: result.status,
                                message: result.message
                            }
                        });

                        UIkit.scroll("#target").scrollTo("#target");
                    }
                }, (error) => {
                    console.log(error)
                }
            )
    }

    generateStatementFromRegister = (investmentId) => {
        fetch("https://api.crowd-money.ru/loans/statement", {
            method: 'POST',
            body: JSON.stringify({
                action: "getStatementFromRegister",
                data: {
                    investmentId: investmentId
                }
            })
        })
            .then(res => res.blob())
            .then((blob) => {
                store.dispatch({
                    type: "SHOW.SNACKBAR",
                    payload: {
                        status: "success",
                        message: "Выписка сформирована"
                    }
                });
                fileDownload(blob, `Выписка из реестра договоров.pdf`)
            },
                (error) => {
                    console.log(error)
                    store.dispatch({
                        type: "SHOW.SNACKBAR",
                        payload: {
                            status: "error",
                            message: "Ошибка формирования"
                        }
                    });
                }
            );
    }

    handleChange = (e, newValue) => {
        this.setState({
            project: {
                ...this.state.project,
                [e.target.name]: (
                    e.target.name !== "indicators" &&
                    e.target.name !== "offerValidity" &&
                    e.target.name !== "platformCommission" &&
                    e.target.name !== "rate" &&
                    e.target.name !== "closeInvestors" &&
                    e.target.name !== "personalStruct" &&
                    e.target.name !== "typeActivity" &&
                    e.target.name !== "expPlatform" &&
                    e.target.name !== "guarantors" &&
                    e.target.name !== "note" &&
                    e.target.name !== "points" &&
                    e.target.name !== "okved"
                )
                    ? parseFloat(e.target.value) : e.target.value
            }
        })
    };

    handleMessage = (e) => {
        this.setState({
            'messageText': e.target.value
        })
    };

    handleChangeCloseInvestors = (e, newValue) => {
        this.setState({
            project: {
                ...this.state.project,
                closeInvestors: newValue
            }
        })
    };

    handleViewDelayWork = () => {
        this.setState({
            ...this.state,
            viewDelayWork: !this.state.viewDelayWork
        })
    };

    handleViewSchedule = () => {
        this.setState({
            ...this.state,
            viewSchedule: !this.state.viewSchedule
        })
    };

    handleChangePartners = (e, newValue) => {
        let partnersId = newValue.map(p => p.id);
        if (newValue.length == 0) {
            this.setState({
                ...this.state,
                profiles: [...this.state.sProfiles]
            })
        } else {
            this.setState({
                ...this.state,
                profiles: this.state.profiles.filter((profile) => partnersId.includes(profile.partnerId))
            })
        }
    };

    deleteMessage = (messageId) => {
        fetch("https://api.crowd-money.ru/loans/message", {
            method: 'POST',
            body: JSON.stringify({
                action: 'deleteMessage',
                data: {
                    messageId: messageId,
                    projectId: this.state.project.id
                }
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    store.dispatch({
                        type: "SHOW.SNACKBAR",
                        payload: {
                            status: result.status,
                            message: result.message
                        }
                    });

                    if (result.status == 'success') {
                        this.setState({
                            ...this.state,
                            messages: [...result.messagesList],
                            messageText: ""
                        })
                    }

                }, (error) => {
                    console.log(error)
                }
            )
    }

    sendMessage = () => {
        if (this.state.messageText != "") {
            fetch("https://api.crowd-money.ru/loans/message", {
                method: 'POST',
                body: JSON.stringify({
                    action: 'saveMessage',
                    data: {
                        profileId: this.state.profile.access.id,
                        profileGroup: this.state.profile.access.group,
                        projectId: this.state.project.id,
                        messageText: this.state.messageText
                    }
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        store.dispatch({
                            type: "SHOW.SNACKBAR",
                            payload: {
                                status: result.status,
                                message: result.message
                            }
                        });

                        if (result.status == 'success') {
                            this.setState({
                                ...this.state,
                                messages: [...result.messagesList],
                                messageText: ""
                            })
                        }

                    }, (error) => {
                        console.log(error)
                    }
                )
        }
    };

    uploadFile = (e) => {
        let documents = this.state.project.data.documents;

        documents.push({
            file: e.target.files[0],
            name: e.target.files[0].name
        });

        this.setState({
            project: {
                ...this.state.project,
                data: {
                    ...this.state.project.data,
                    documents: documents
                }
            }
        })
    };

    removeFile = (fileIndex) => {
        this.setState({
            project: {
                ...this.state.project,
                data: {
                    ...this.state.project.data,
                    documents: this.state.project.data.documents.filter((document, index) => index !== fileIndex)
                }
            }
        })
    };

    handleDownloadDocuments = (projectId) => {
        fetch("https://api.crowd-money.ru/loans/documents", {
            method: 'POST',
            body: JSON.stringify({
                action: "projectDownloadDocuments",
                data: {
                    projectId: projectId
                }
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    window.location.assign(`https://crowd-money.ru/${result.downloadPath}`);
                },
                (error) => {
                    console.log(error)
                }
            );
    };

    update = () => {
        store.dispatch({
            type: "HIDE.SNACKBAR"
        });

        const formData = new FormData();

        formData.append('action', 'projectUpdate');
        formData.append('data[inquirer][profileHash]', this.state.profile.access.hash);
        formData.append('data[project][profileId]', this.state.project.profileId);
        formData.append('data[project][indicators]', this.state.project.indicators);
        formData.append('data[project][id]', this.state.project.id);
        formData.append('data[project][status]', this.state.project.status);
        formData.append('data[project][rating]', this.state.project.rating);
        formData.append('data[project][points]', this.state.project.points);
        formData.append('data[project][rate]', this.state.project.rate);
        formData.append('data[project][offerValidity]', this.state.project.offerValidity);
        formData.append('data[project][repaymentType]', this.state.project.repaymentType ? this.state.project.repaymentType : this.state.project.data.repaymentType);
        formData.append('data[project][platformCommission]', this.state.project.platformCommission);
        formData.append('data[project][documents]', this.state.project.data.documents);
        formData.append('data[project][personalStruct]', this.state.project.personalStruct);
        formData.append('data[project][typeActivity]', this.state.project.typeActivity);
        formData.append('data[project][expPlatform]', this.state.project.expPlatform);
        formData.append('data[project][guarantors]', this.state.project.guarantors);
        formData.append('data[project][note]', this.state.project.note);
        formData.append('data[project][okved]', this.state.project.okved);

        if (this.state.project.closeProject) {
            this.state.project.closeInvestors.map((e, index) => {
                formData.append('data[project][closeInvestors][' + index + '][id]', e.id);
                formData.append('data[project][closeInvestors][' + index + '][name]', e.name);
            });
        } else {
            formData.append('data[project][closeInvestors]', "");
        }

        if (this.state.project.data.documents.length > 0) {
            this.state.project.data.documents.map((document, index) => {
                formData.append('data[project][documents][' + index + '][file]', document.file);
                formData.append('data[project][documents][' + index + '][name]', document.name);
            })
        }

        fetch("https://api.crowd-money.ru/loans/update", {
            method: 'POST',
            body: formData
        })
            .then(res => res.json())
            .then(
                (result) => {
                    store.dispatch({
                        type: "SHOW.SNACKBAR",
                        payload: {
                            status: result.status,
                            message: result.message
                        }
                    });

                    if (result.field) {
                        this[result.field].focus();

                        return false;
                    }
                }, (error) => {
                    console.log(error)
                }
            )
    };

    render() {
        const { isLoaded, profile, project, partners, viewSchedule, viewDelayWork, messages, messageText } = this.state;
        const profiles = this.state.profiles.map(e => {
            return { id: e.id, name: `${e.lastName} ${e.firstName} ${e.middleName}` }
        })

        return (
            <div className="tm-body">
                <div className={`uk-container ${this.props.profile.access.group === 0 ? 'uk-container-expand' : ''} uk-section-small uk-position-relative`}>
                    {
                        isLoaded ? (
                            <>
                                <h3 className="uk-h3 uk-margin-small-bottom tm-font-weight-5">
                                    «{project.data.projectName}»
                                </h3>
                                <div className="uk-text-small uk-text-muted uk-margin-medium-bottom">
                                    Рабочая область проекта
                                </div>
                                <div className="uk-child-width-expand@s uk-grid-match uk-margin-medium-bottom" data-uk-grid>
                                    <div>
                                        <div className="uk-card uk-card-default uk-card-small uk-card-body" data-type="header">
                                            <div className="tm-text-xsmall uk-text-muted uk-text-uppercase">
                                                Сумма
                                            </div>
                                            <h4 className="uk-h4 uk-margin-remove-top uk-margin-small-bottom">
                                                <CurrencyFormat value={project.data.amountStart} displayType={'text'} thousandSeparator={" "} /> — <CurrencyFormat value={project.data.amountEnd} displayType={'text'} thousandSeparator={" "} /> ₽
                                            </h4>
                                            <div className="tm-text-xsmall uk-text-muted" Style="margin-bottom: 13px;">
                                                Собрано  <CurrencyFormat value={project.collected} displayType={'text'} thousandSeparator={" "} />&nbsp;₽
                                            </div>
                                            <div className="tm-investment-rating">
                                                <span style={{ width: ((project.collected / project.data.amountEnd) * 100).toFixed(2) + '%' }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="uk-card uk-card-default uk-card-small uk-card-body" data-type="header">
                                            <div className="tm-text-xsmall uk-text-muted uk-text-uppercase">
                                                Рейтинг проекта
                                            </div>
                                            <h4 className="uk-h4 uk-margin-remove-top uk-margin-small-bottom">
                                                {
                                                    project.rating === 0 && "Не указан"
                                                }
                                                {
                                                    project.rating === 1 && "Высокий"
                                                }
                                                {
                                                    project.rating === 2 && "Средний"
                                                }
                                                {
                                                    project.rating === 3 && "Низкий"
                                                }
                                            </h4>
                                            <div className="tm-text-xsmall uk-text-muted uk-text-uppercase">
                                                Индекс благонадёжности
                                            </div>
                                            <h4 className="uk-h4 uk-margin-remove-top">
                                                {
                                                    project.points <= 30 && "Высокий"
                                                }
                                                {
                                                    (project.points > 30 && project.points < 50) && "Средний"
                                                }
                                                {
                                                    project.points >= 50 && "Низкий"
                                                }
                                            </h4>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="uk-card uk-card-default uk-card-small uk-card-body" data-type="header">
                                            <div data-uk-grid>
                                                <div className="uk-width-expand">
                                                    <div className="tm-text-xsmall uk-text-muted uk-text-uppercase">Ставка</div>
                                                    <h4 className="tm-font-weight-5 uk-h2 uk-margin-remove-top uk-margin-small-bottom">{project.rate}%</h4>
                                                </div>
                                                <div className="uk-width-auto">
                                                    <div className="tm-text-xsmall uk-text-muted uk-text-uppercase">Срок займа</div>
                                                    <h4 className="tm-font-weight-5 uk-h2 uk-margin-remove-top uk-margin-small-bottom">{project.data.duration} мес.</h4>
                                                </div>
                                            </div>
                                            <div className="tm-investment-rating uk-margin-top">
                                                <span Style="width: 0%;" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    profile.access.group === 0 && (
                                        <ul data-uk-tab="connect: .uk-switcher;">
                                            <li className="uk-visible@s"><a href="#">Информация по заявке</a></li>
                                            <li><a href="#">Администрирование</a></li>
                                            {
                                                project.status === 5 && project.scheduleDocument == '' && (
                                                    <li><a href="#">График погашения</a></li>
                                                )
                                            }
                                            <li><a href="#">Инвесторы</a></li>
                                            {
                                                project.status === 7 && (
                                                    <li><a href="#">Работа с просроченной задолженностью</a></li>
                                                )
                                            }
                                        </ul>
                                    )
                                }
                                <ul className="uk-switcher uk-margin-medium-top uk-margin-bottom">
                                    <li className="uk-active">
                                        <div className="uk-card uk-card-default uk-card-small uk-card-body uk-margin-medium">
                                            <div data-uk-grid className="uk-flex uk-flex-middle">
                                                <div className="uk-width-auto@s">
                                                    <h4 className="uk-h4 tm-font-weight-6">Заявка №{project.id}</h4>
                                                    <div className="uk-text-small uk-text-muted">
                                                        Создана {project.date.create}
                                                    </div>
                                                </div>
                                                <div className="uk-width-expand@s">
                                                    <div className="uk-align-right@s">
                                                        <div className="uk-text-small uk-text-muted">Статус заявки</div>
                                                        <h4 className="uk-h4 uk-margin-top">
                                                            {
                                                                project.status === 0 && "На рассмотрении"
                                                            }
                                                            {
                                                                project.status === 1 && project.investmentOfferVerifyCode == 0 && "Рассмотрена"
                                                            }
                                                            {
                                                                project.status === 1 && project.investmentOfferVerifyCode != 0 && "Опубликована"
                                                            }
                                                            {
                                                                project.status === 2 && "Требуются уточнения"
                                                            }
                                                            {
                                                                project.status === 3 && "Отказано"
                                                            }
                                                            {
                                                                project.status === 4 && "Займ погашен"
                                                            }
                                                            {
                                                                project.status === 5 && "Сбор средств завершён"
                                                            }
                                                            {
                                                                project.status === 6 && "Погашен досрочно"
                                                            }
                                                            {
                                                                project.status === 7 && "Просрочка платежей"
                                                            }
                                                            {
                                                                project.status === 8 && "Активный"
                                                            }
                                                            {
                                                                project.status === 9 && "Не состоялась"
                                                            }
                                                        </h4>
                                                        {
                                                            (project.status === 7) && (
                                                                <div className="uk-text-small uk-text-muted">{getDateDiffDelay(project.date.delay)} дн.</div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            project.scheduleData && (
                                                <>
                                                    <h4 className="uk-h4 uk-margin-bottom tm-font-weight-6">График погашения</h4>
                                                    <div className="uk-card uk-card-default uk-card-small uk-card-body uk-margin-medium-bottom">
                                                        <div className="uk-margin-top">
                                                            <div data-uk-grid>
                                                                <div className="uk-width-expand@s">
                                                                    <h4 className="uk-h4 uk-margin-small-bottom">
                                                                        Следующий платеж
                                                                    </h4>
                                                                    <div className="uk-text-small uk-text-muted uk-text-center" data-uk-grid>
                                                                        <CurrencyValue value={project.scheduleData.rows[project.scheduleData.indexPaymentSchedule].paymentAmount} />&nbsp;₽
                                                                    </div>
                                                                </div>
                                                                <div className="uk-width-expand@s">
                                                                    <h4 className="uk-h4 uk-margin-small-bottom">
                                                                        Остаток долга
                                                                    </h4>
                                                                    <div className="uk-text-small uk-text-muted" data-uk-grid>
                                                                        <CurrencyValue value={project.scheduleData.rows[project.scheduleData.indexPaymentSchedule].debtAmount} />&nbsp;₽
                                                                    </div>
                                                                </div>
                                                                <div className="uk-width-expand@s">
                                                                    <h4 className="uk-h4 uk-margin-small-bottom">
                                                                        Выплачено
                                                                    </h4>
                                                                    <div className="uk-text-small uk-text-muted" data-uk-grid>
                                                                        {/* <CurrencyValue value={project.scheduleData.indexPaymentSchedule == 0 ? 0 : project.scheduleData.total - project.scheduleData.rows[project.scheduleData.indexPaymentSchedule].debtAmount} />&nbsp;₽ */}
                                                                        <CurrencyValue value={project.totalReturned} />&nbsp;₽
                                                                    </div>
                                                                </div>
                                                                <div className="uk-width-expand@s">
                                                                    <h4 className="uk-h4 uk-margin-small-bottom">
                                                                        Общая сумма долга
                                                                    </h4>
                                                                    <div className="uk-text-small uk-text-muted" data-uk-grid>
                                                                        <CurrencyValue value={project.scheduleData.total} />&nbsp;₽
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <button href="#toggle-animation" class="uk-button uk-button-default uk-margin-top" type="button" onClick={() => this.handleViewSchedule()}>Просмотреть график</button>
                                                        {
                                                            viewSchedule && (
                                                                <div className="uk-overflow-auto uk-margin">
                                                                    <table className="tm-table-balance-operations uk-table uk-text-center uk-table-divider uk-table-hover uk-table-middle">
                                                                        <thead>
                                                                            <tr>
                                                                                <th className="uk-text-center">Номер платежа (месяц)</th>
                                                                                <th className="uk-text-center">Задолженность на начало периода</th>
                                                                                <th className="uk-text-center">Погашение основного долга по займу</th>
                                                                                <th className="uk-text-center">Ежемесячные проценты за пользование займом</th>
                                                                                <th className="uk-text-center">Количество дней пользования средствами займа</th>
                                                                                <th className="uk-text-center">Выплаты Займодавцу</th>
                                                                                <th className="uk-text-center">Дата платежа (не позднее)</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                project.scheduleData.rows.map((row, index) => (
                                                                                    <tr className={`${index == project.scheduleData.indexPaymentSchedule ? "uk-text-bold" : ""}`}>
                                                                                        <td>
                                                                                            {row.number}
                                                                                        </td>
                                                                                        <td>
                                                                                            <CurrencyValue value={row.debtAmount} />
                                                                                        </td>
                                                                                        <td>
                                                                                            <CurrencyValue value={row.repaymentAmount} />
                                                                                        </td>
                                                                                        <td>
                                                                                            <CurrencyValue value={row.rateMonthly} />
                                                                                        </td>
                                                                                        <td>
                                                                                            {row.daysCount}
                                                                                        </td>
                                                                                        <td>
                                                                                            <CurrencyValue value={row.paymentAmount} />
                                                                                        </td>
                                                                                        <td>
                                                                                            {row.paymentDate}
                                                                                        </td>
                                                                                    </tr>
                                                                                ))
                                                                            }
                                                                            <tr className="uk-text-bold">
                                                                                <td colSpan={5}>
                                                                                    Общие выплаты за весь период:
                                                                                </td>
                                                                                <td>
                                                                                    <CurrencyValue value={project.scheduleData.total} />
                                                                                </td>
                                                                                <td />
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </>
                                            )
                                        }
                                        {
                                            project.delayWorkData.length > 0 && (
                                                <>
                                                    <h4 className="uk-h4 uk-margin-bottom tm-font-weight-6">Работа с просроченной задолженностью</h4>
                                                    <div className="uk-card uk-card-default uk-card-small uk-card-body uk-margin-medium-bottom">
                                                        <div className="uk-margin-top">
                                                            <div className="uk-width-expand@s">
                                                                <div className="uk-text-small uk-text-muted">
                                                                    В данном разделе представлена информация относительно работы Платформы по взысканию просроченной задолженности
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <button href="#toggle-animation" class="uk-button uk-button-default uk-margin-top" type="button" onClick={() => this.handleViewDelayWork()}>Просмотреть события</button>
                                                        {
                                                            viewDelayWork && (
                                                                <div className="uk-overflow-auto uk-margin">
                                                                    <table className="tm-table-balance-operations uk-table uk-text-center uk-table-divider uk-table-hover uk-table-middle">
                                                                        <thead>
                                                                            <tr>
                                                                                <th className="uk-text-center">Дата</th>
                                                                                <th className="uk-text-center">Содержание события</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                project.delayWorkData.map((eventData) => (
                                                                                    <tr>
                                                                                        <td>
                                                                                            {new Date(eventData.date * 1000).toLocaleDateString("ru-RU")}
                                                                                        </td>
                                                                                        <td>
                                                                                            {eventData.eventText}
                                                                                        </td>
                                                                                    </tr>
                                                                                ))
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </>
                                            )
                                        }
                                        <h4 className="uk-h4 uk-margin-bottom tm-font-weight-6">Данные о заёмщике</h4>
                                        <div className="uk-card uk-card-default uk-card-small uk-card-body uk-margin-medium-bottom">
                                            <div className="uk-margin-top">
                                                <div data-uk-grid>
                                                    <div className="uk-width-expand@s">
                                                        <h4 className="uk-h4 uk-margin-small-bottom">
                                                            {
                                                                project.entrepreneurData.type === 2 && "ИП " + project.mainData.lastName + " " + project.mainData.firstName + " " + project.mainData.middleName
                                                            }
                                                            {
                                                                project.entrepreneurData.type === 3 && project.entrepreneurData.companyName
                                                            }
                                                        </h4>
                                                        <div className="uk-text-small uk-text-muted" data-uk-grid>
                                                            {
                                                                project.entrepreneurData.type === 2 && (
                                                                    <>
                                                                        <div>
                                                                            ИНН {project.entrepreneurData.inn}
                                                                        </div>
                                                                        <div>
                                                                            ОГРНИП {project.entrepreneurData.ogrnip}
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                            {
                                                                project.entrepreneurData.type === 3 && (
                                                                    <>
                                                                        <div>
                                                                            ИНН {project.entrepreneurData.inn}
                                                                        </div>
                                                                        <div>
                                                                            КПП {project.entrepreneurData.kpp}
                                                                        </div>
                                                                        <div>
                                                                            ОГРН {project.entrepreneurData.ogrn}
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="uk-width-auto@s">
                                                        <div className="uk-card uk-card-default uk-card-small uk-card-body" Style="padding: 15px; position: relative; top: -8px;">
                                                            <div className="tm-text-xsmall uk-text-muted uk-text-uppercase">
                                                                Индекс благонадёжности
                                                            </div>
                                                            <h4 className="uk-h4 uk-margin-remove-top">
                                                                {
                                                                    project.points <= 30 && "Высокий"
                                                                }
                                                                {
                                                                    (project.points > 30 && project.points < 50) && "Средний"
                                                                }
                                                                {
                                                                    project.points >= 50 && "Низкий"
                                                                }
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="uk-margin-top">
                                                    <h4 className="uk-h4 uk-margin-small-bottom">
                                                        Документы
                                                    </h4>
                                                    <div data-uk-grid>
                                                        {
                                                            project.data.documents.length > 0 && (
                                                                <div className="uk-width-auto@s">
                                                                    <div className="tm-text-xsmall uk-text-muted uk-margin-small-bottom">
                                                                        Архив .zip
                                                                    </div>

                                                                    <div className="tm-button-download-file" onClick={() => (this.handleDownloadDocuments(project.id))}>
                                                                        <i data-uk-icon="icon: download; ratio: 5;" />
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        <div className="uk-width-auto@s">
                                                            <div className="tm-text-xsmall uk-text-muted uk-margin-small-bottom">
                                                                Инвестиционное предложение
                                                            </div>
                                                            <a href={`/private/investmentOffers/${project.investmentOfferDocument}`} className="tm-button-download-file" download>
                                                                <i data-uk-icon="icon: download; ratio: 5;" />
                                                            </a>
                                                        </div>
                                                        {
                                                            project.scheduleDocument && (
                                                                <div className="uk-width-auto@s">
                                                                    <div className="tm-text-xsmall uk-text-muted uk-margin-small-bottom">
                                                                        График погашения
                                                                    </div>
                                                                    <a href={`/private/schedules/${project.scheduleDocument}`} className="tm-button-download-file" download>
                                                                        <i data-uk-icon="icon: download; ratio: 5;" />
                                                                    </a>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                    {
                                                        project.indicators && (
                                                            <>
                                                                <h4 className="uk-h4 uk-margin-small-bottom">
                                                                    Показатели заёмщика
                                                                </h4>
                                                                <div Style="white-space: pre-line;">
                                                                    {project.indicators}
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        profile.access.group === 0 && (
                                                            <>
                                                                <h4 className="uk-h4 uk-margin-small-bottom">
                                                                    Реквизиты
                                                                </h4>
                                                                <div data-uk-grid>
                                                                    <div className="uk-width-auto@s">
                                                                        <label className="uk-form-label">Расчётный счёт</label>
                                                                        <NumberFormat value={project.data.checkingAccount} displayType={'text'} format="####-####-####-####-####" />
                                                                    </div>
                                                                    <div className="uk-width-auto@s">
                                                                        <label className="uk-form-label">БИК Банка</label>
                                                                        <NumberFormat value={project.data.bik} displayType={'text'} format="###-###-###" />
                                                                    </div>
                                                                    <div className="uk-width-auto@s">
                                                                        <label className="uk-form-label">Корреспондентский счет банка</label>
                                                                        <NumberFormat value={project.data.correspondentAccount} displayType={'text'} format="####-####-####-####-####" />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <h4 className="uk-h4 tm-font-weight-6">Данные по заявке</h4>
                                        <div className="uk-card uk-card-default uk-card-small uk-card-body">
                                            <div className="uk-margin-top">
                                                <div className="uk-grid-row-small uk-child-width-auto@s" data-uk-grid>
                                                    <div>
                                                        <div className="tm-text-xsmall uk-text-muted uk-text-uppercase">
                                                            Тип займа
                                                        </div>
                                                        <div className="uk-margin-small-top">
                                                            {
                                                                project.data.loanType === 1 && "Исполнение государственного контракта"
                                                            }
                                                            {
                                                                project.data.loanType === 2 && "Развитие бизнеса"
                                                            }
                                                            {
                                                                project.data.loanType === 3 && "Пополнение оборотных средств"
                                                            }
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="tm-text-xsmall uk-text-muted uk-text-uppercase">
                                                            Требуемая сумма
                                                        </div>
                                                        <div className="uk-margin-small-top">
                                                            <CurrencyFormat value={project.data.amountStart} displayType={'text'} thousandSeparator={" "} /> — <CurrencyFormat value={project.data.amountEnd} displayType={'text'} thousandSeparator={" "} /> ₽
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="tm-text-xsmall uk-text-muted uk-text-uppercase">
                                                            Срок займа
                                                        </div>
                                                        <div className="uk-margin-small-top">
                                                            {
                                                                `${project.data.duration} мес. ( ${project.data.durationDays ? project.data.durationDays : ""} д.)`
                                                            }
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="tm-text-xsmall uk-text-muted uk-text-uppercase">
                                                            Тип погашения основного долга
                                                        </div>
                                                        <div className="uk-margin-small-top">
                                                            {
                                                                (project.data.repaymentType === 1 || project.data.repaymentType === "1") && "В конце срока"
                                                            }
                                                            {
                                                                (project.data.repaymentType === 2 || project.data.repaymentType === "2") && "Ежеквартально"
                                                            }
                                                            {
                                                                (project.data.repaymentType === 3 || project.data.repaymentType === "3") && "Ежемесячно"
                                                            }
                                                            {
                                                                (project.data.repaymentType === 4 || project.data.repaymentType === "4") && "Ежемесячно (аннуитет)"
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        project.data.loanType === 1 && (
                                                            <>
                                                                <div>
                                                                    <div className="tm-text-xsmall uk-text-muted uk-text-uppercase">
                                                                        Заказчик
                                                                    </div>
                                                                    <div className="uk-margin-small-top">
                                                                        {project.data.companyName}
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="tm-text-xsmall uk-text-muted uk-text-uppercase">
                                                                        Реестровый номер контракта
                                                                    </div>
                                                                    <div className="uk-margin-small-top">
                                                                        <a href={`https://zakupki.gov.ru/epz/order/notice/ea44/view/common-info.html?regNumber=${project.data.contractNumber}`} target="_blank">{project.data.contractNumber}</a>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="tm-text-xsmall uk-text-muted uk-text-uppercase">
                                                                        Объект закупки
                                                                    </div>
                                                                    <div className="uk-margin-small-top">
                                                                        {project.data.purchaseObject}
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="tm-text-xsmall uk-text-muted uk-text-uppercase">
                                                                        Сумма
                                                                    </div>
                                                                    <div className="uk-margin-small-top">
                                                                        <CurrencyFormat value={project.data.contractAmount} displayType={'text'} thousandSeparator={" "} /> ₽
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="tm-text-xsmall uk-text-muted uk-text-uppercase">
                                                                        Дата заключения
                                                                    </div>
                                                                    <div className="uk-margin-small-top">
                                                                        <NumberFormat value={project.data.contractDateStart} displayType={'text'} format="##.##.####" />
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="tm-text-xsmall uk-text-muted uk-text-uppercase">
                                                                        Дата исполнения
                                                                    </div>
                                                                    <div className="uk-margin-small-top">
                                                                        <NumberFormat value={project.data.contractDateEnd} displayType={'text'} format="##.##.####" />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                                <div className="uk-margin-top">
                                                    <div className="tm-text-xsmall uk-text-muted uk-text-uppercase">
                                                        О бизнесе
                                                    </div>
                                                    <div className="uk-margin-small-top uk-text-justify" dangerouslySetInnerHTML={{ __html: project.data.companyAbout }} />
                                                </div>
                                                <div className="uk-margin-top">
                                                    <div className="uk-child-width-auto@s" data-uk-grid>
                                                        <div>
                                                            <div className="tm-text-xsmall uk-text-muted uk-text-uppercase">
                                                                Название проекта
                                                            </div>
                                                            <div className="uk-margin-small-top">
                                                                {project.data.projectName}
                                                            </div>
                                                        </div>
                                                        {
                                                            project.data.companyWebsite && (
                                                                <div>
                                                                    <div className="tm-text-xsmall uk-text-muted uk-text-uppercase">
                                                                        Веб-сайт заёмщика
                                                                    </div>
                                                                    <div className="uk-margin-small-top">
                                                                        {project.data.companyWebsite}
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                              [4, 5, 6, 7, 8].includes(project.status) && (
                                                <>
                                                    <h4 className="uk-h4 tm-font-weight-6" id="target" uk-scroll>Комментарии</h4>
                                                    <div uk-grid>
                                                        {
                                                            messages.length > 0 ? (
                                                                messages.map((message) => (
                                                                    <div className="uk-flex uk-margin-bottom">
                                                                        <div className={`uk-card uk-card-default uk-card-body ${message.profileId == profile.access.id ? 'uk-margin-auto-left' : ''} uk-width-xlarge`}>
                                                                            <div className="uk-flex uk-text-small uk-text-muted uk-flex-between">
                                                                                <div>
                                                                                    {
                                                                                        message.profileGroup == 0 ? 'Администратор' : `${message.lastName} ${message.firstName}`
                                                                                    }
                                                                                </div>
                                                                                <div>
                                                                                    {message.createDate}
                                                                                </div>
                                                                            </div>
                                                                            <div className="uk-margin-top uk-text-break">
                                                                                {message.messageText}
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            (window.innerWidth > 600) && (profile.access.group === 0) && (
                                                                                <div className="uk-flex uk-flex-middle">
                                                                                    <button className="uk-button uk-button-small uk-button-primary uk-margin-left"
                                                                                        data-uk-icon="icon: trash;"
                                                                                        onClick={() => this.deleteMessage(message.id)}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                )
                                                                )
                                                            ) :
                                                                (
                                                                    <div className="uk-text-center uk-text-small uk-text-muted">
                                                                        Сообщения отсутствуют
                                                                    </div>
                                                                )
                                                        }
                                                    </div>
                                                    {
                                                        [5, 7, 8].includes(project.status) && (
                                                            <div>
                                                            <div className="uk-margin-top">
                                                                <div className="uk-flex uk-flex-between">
                                                                    <input
                                                                        placeholder="Сообщение"
                                                                        className="uk-input"
                                                                        name="messageText"
                                                                        onChange={this.handleMessage}
                                                                        maxlength="256"
                                                                        value={messageText}
                                                                        onKeyDown={(e) => {
                                                                            if (e.key === "Enter"){
                                                                                this.sendMessage()
                                                                            }
                                                                        }}
                                                                    >
                                                                    </input>
                                                                    <button
                                                                        className="uk-button uk-button-primary uk-margin-left"
                                                                        onClick={() => this.sendMessage()}
                                                                    >
                                                                        {
                                                                            window.innerWidth < 600 ?
                                                                                <div data-uk-icon="icon: arrow-right; ratio: 1.7" /> :
                                                                                'Отправить'
                                                                        }
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        ) 
                                                    }                                                   
                                                </>
                                            )
                                        }
                                    </li>
                                    {
                                        profile.access.group === 0 && (
                                            <>
                                                <li>
                                                    <div className="uk-grid-row-small" data-uk-grid>
                                                        <div className="uk-width-1-1@s">
                                                            <label className="uk-form-label">
                                                                Показатели заёмщика
                                                            </label>
                                                            <textarea
                                                                className="uk-textarea"
                                                                rows={15}
                                                                Style="resize: none;"
                                                                name="indicators"
                                                                onChange={this.handleChange}
                                                            >
                                                                {project.indicators}
                                                            </textarea>
                                                        </div>
                                                        <div className="uk-width-1-4@s">
                                                            <label className="uk-form-label">
                                                                Статус заявки
                                                            </label>
                                                            <select className="uk-select" name="status" onChange={this.handleChange}>
                                                                <option value="0" selected={project.status === 0 ? true : false}>На рассмотрении</option>
                                                                <option value="2" selected={project.status === 2 ? true : false}>Требуются уточнения</option>
                                                                <option value="3" selected={project.status === 3 ? true : false}>Отказано</option>
                                                                <option value="1" selected={project.status === 1 ? true : false}>Рассмотрена</option>
                                                                <option value="4" selected={project.status === 4 ? true : false}>Займ погашен</option>
                                                                <option value="5" selected={project.status === 5 ? true : false}>Сбор средств завершён</option>
                                                                <option value="6" selected={project.status === 6 ? true : false}>Погашен досрочно</option>
                                                                <option value="7" selected={project.status === 7 ? true : false}>Просрочка платежей</option>
                                                                <option value="8" selected={project.status === 8 ? true : false}>Активный</option>
                                                                <option value="9" selected={project.status === 9 ? true : false}>Не состоялась</option>
                                                            </select>
                                                        </div>
                                                        <div className="uk-width-1-4@s">
                                                            <label className="uk-form-label">
                                                                Рейтинг проекта
                                                            </label>
                                                            <select className="uk-select" name="rating" onChange={this.handleChange}>
                                                                <option value="0" selected={project.rating === 0 ? true : false}>Не указан</option>
                                                                <option value="1" selected={project.rating === 1 ? true : false}>Высокий</option>
                                                                <option value="2" selected={project.rating === 2 ? true : false}>Средний</option>
                                                                <option value="3" selected={project.rating === 3 ? true : false}>Низкий</option>
                                                            </select>
                                                        </div>
                                                        <div className="uk-width-1-4@s">
                                                            <label className="uk-form-label">
                                                                Индекс благонадёжности
                                                            </label>
                                                            <select className="uk-select" name="points" onChange={this.handleChange}>
                                                                <option value="0" selected={project.points <= 30 ? true : false}>Высокий</option>
                                                                <option value="40" selected={(project.points > 30 && project.points < 50) ? true : false}>Средний</option>
                                                                <option value="50" selected={project.points >= 50 ? true : false}>Низкий</option>
                                                            </select>
                                                        </div>
                                                        <div className="uk-width-1-4@s">
                                                            <label className="uk-form-label">
                                                                Ставка %
                                                            </label>
                                                            <IMaskInput
                                                                mask={Number}
                                                                scale={2}
                                                                unmask={true}
                                                                className="uk-input"
                                                                value={project.rate}
                                                                name="rate"
                                                                ref={(input) => { this.rate = input }}
                                                                onAccept={
                                                                    (value) => {
                                                                        this.setState({
                                                                            ...this.state,
                                                                            project: {
                                                                                ...this.state.project,
                                                                                rate: value
                                                                            }
                                                                        })
                                                                    }
                                                                }
                                                            />
                                                        </div>                                                        
                                                        <div className="uk-width-1-4@s">
                                                            <label className="uk-form-label">
                                                                Срок действия предложения
                                                            </label>
                                                            <InputMask mask="99.99.9999" onChange={this.handleChange}>
                                                                <input
                                                                    type="text"
                                                                    className="uk-input"
                                                                    name="offerValidity"
                                                                    defaultValue={project.offerValidity}
                                                                />
                                                            </InputMask>
                                                        </div>
                                                        <div className="uk-width-1-4@s">
                                                            <label className="uk-form-label">
                                                                Тип погашения основного долга
                                                            </label>
                                                            <select className="uk-select" name="repaymentType" onChange={this.handleChange}>
                                                                <option value="0" selected={project.data.repaymentType == 0 ? true : false}>Не указан</option>
                                                                <option value="1" selected={project.data.repaymentType == 1 ? true : false}>В конце срока</option>
                                                                <option value="2" selected={project.data.repaymentType == 2 ? true : false}>Ежеквартально</option>
                                                                <option value="3" selected={project.data.repaymentType == 3 ? true : false}>Ежемесячно</option>
                                                                <option value="4" selected={project.data.repaymentType == 4 ? true : false}>Ежемесячно (аннуитет)</option>
                                                            </select>
                                                        </div>
                                                        <div className="uk-width-1-4@s">
                                                            <label className="uk-form-label">
                                                                Комиссия платформы
                                                            </label>
                                                            <IMaskInput
                                                                mask={Number}
                                                                scale={2}
                                                                unmask={true}
                                                                className="uk-input"
                                                                value={project.platformCommission}
                                                                name="platformCommission"
                                                                ref={(input) => { this.platformCommission = input }}
                                                                onAccept={
                                                                    (value) => {
                                                                        this.setState({
                                                                            ...this.state,
                                                                            project: {
                                                                                ...this.state.project,
                                                                                platformCommission: value
                                                                            }
                                                                        })
                                                                    }
                                                                }
                                                            />
                                                        </div>
                                                        <div className="uk-width-1-4@s">
                                                            <label className="uk-form-label">
                                                                Закрытое инвест. предложение
                                                            </label>
                                                            <select className="uk-select" name="closeProject" onChange={this.handleChange} >
                                                                <option value={0} selected={project.closeProject == 0 ? true : false}>Нет</option>
                                                                <option value={1} selected={project.closeProject == 1 ? true : false}>Да</option>
                                                            </select>
                                                        </div>
                                                        {
                                                            project.closeProject == 1 && (
                                                                <>
                                                                    <div className="uk-width-1-3@s" >
                                                                        <label className="uk-form-label">
                                                                            Выбрать партнера
                                                                        </label>
                                                                        <Autocomplete key="autocomplete"
                                                                            multiple
                                                                            id="checkboxes-tags-partners"
                                                                            name="partners"
                                                                            options={partners}
                                                                            disableCloseOnSelect
                                                                            //defaultValue={project.closeInvestors} сделать сохранение партнеров
                                                                            getOptionLabel={(option) => option.name}
                                                                            renderOption={(props, option, { selected }) => (
                                                                                <li {...props}>
                                                                                    <Checkbox
                                                                                        icon={icon}
                                                                                        checkedIcon={checkedIcon}
                                                                                        style={{ marginRight: 8 }}
                                                                                        checked={selected}
                                                                                    />
                                                                                    {option.name}
                                                                                </li>
                                                                            )}
                                                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                                                            onChange={this.handleChangePartners}
                                                                            renderInput={(params) => (
                                                                                <TextField {...params} placeholder="Партнеры" />
                                                                            )}
                                                                        />
                                                                    </div>
                                                                    <div className="uk-width-1-3@s" >
                                                                        <label className="uk-form-label">
                                                                            Выбрать инвестора
                                                                        </label>
                                                                        <Autocomplete key="autocomplete"
                                                                            multiple
                                                                            id="checkboxes-tags"
                                                                            options={profiles}
                                                                            disableCloseOnSelect
                                                                            defaultValue={project.closeInvestors}
                                                                            getOptionLabel={(option) => option.name}
                                                                            renderOption={(props, option, { selected }) => (
                                                                                <li {...props}>
                                                                                    <Checkbox
                                                                                        icon={icon}
                                                                                        checkedIcon={checkedIcon}
                                                                                        style={{ marginRight: 8 }}
                                                                                        checked={selected}
                                                                                    />
                                                                                    {option.name}
                                                                                </li>
                                                                            )}
                                                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                                                            onChange={this.handleChangeCloseInvestors}
                                                                            renderInput={(params) => (
                                                                                <TextField {...params} placeholder="Инвесторы" />
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                        <div className="uk-width-1-2@s">
                                                            <label className="uk-form-label">Персональный состав</label>
                                                            <textarea
                                                                className="uk-textarea"
                                                                rows={3}
                                                                Style="resize: none;"
                                                                name="personalStruct"
                                                                onChange={this.handleChange}
                                                            >
                                                                {project.personalStruct}
                                                            </textarea>
                                                        </div>
                                                        <div className="uk-width-1-2@s">
                                                            <label className="uk-form-label">Виды деятельности</label>
                                                            <textarea
                                                                className="uk-textarea"
                                                                rows={3}
                                                                Style="resize: none;"
                                                                name="typeActivity"
                                                                onChange={this.handleChange}
                                                            >
                                                                {project.typeActivity}
                                                            </textarea>
                                                        </div>
                                                        <div className="uk-width-1-2@s">
                                                            <label className="uk-form-label">Опыт на платформе</label>
                                                            <textarea
                                                                className="uk-textarea"
                                                                rows={3}
                                                                Style="resize: none;"
                                                                name="expPlatform"
                                                                onChange={this.handleChange}
                                                            >
                                                                {project.expPlatform}
                                                            </textarea>
                                                        </div>
                                                        <div className="uk-width-1-2@s">
                                                            <label className="uk-form-label">Поручители</label>
                                                            <textarea
                                                                className="uk-textarea"
                                                                rows={3}
                                                                Style="resize: none;"
                                                                name="guarantors"
                                                                onChange={this.handleChange}
                                                            >
                                                                {project.guarantors}
                                                            </textarea>
                                                        </div>
                                                        <div className="uk-width-3-4@s">
                                                            <label className="uk-form-label">
                                                                Примечание
                                                            </label>
                                                            <textarea
                                                                className="uk-textarea"
                                                                rows={5}
                                                                Style="resize: none;"
                                                                name="note"
                                                                onChange={this.handleChange}
                                                            >
                                                                {project.note}
                                                            </textarea>
                                                        </div>
                                                        <div className="uk-width-1-4@s">
                                                            <label className="uk-form-label">ОКВЭД</label>
                                                            <input type="text" className="uk-input" name="okved" defaultValue={project.okved} onChange={this.handleChange} />
                                                        </div>                                                    
                                                    </div>
                                                    <div className="uk-margin-top">
                                                        <AgreementRequestBKI
                                                            profileId={project.profileId}
                                                            element={<a class="uk-link uk-text-primary">Скачать файл согласия на запрос в БКИ (нажмите на ссылку)</a>}
                                                        />
                                                    </div>
                                                    <div className="uk-margin-top">
                                                        <label className="uk-form-label">
                                                            Фотографии и документы к проекту
                                                        </label>
                                                        <div className="tm-upload-documents" data-uk-grid>
                                                            <div className="uk-width-auto">
                                                                <div data-uk-form-custom className="tm-button-upload-file">
                                                                    <i data-uk-icon="icon: link; ratio: 5;" />
                                                                    <input type="file" onChange={this.uploadFile} />
                                                                </div>
                                                            </div>
                                                            {
                                                                project.data.documents.length > 0 && (
                                                                    project.data.documents.map((document, index) => (
                                                                        <div className="uk-width-auto">
                                                                            <div className="tm-file">
                                                                                <i data-uk-icon="icon: close; ratio: 5;" className="tm-button-remove-file" onClick={() => this.removeFile(index)} />
                                                                                <span className="tm-filename">{document.name}</span>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="uk-margin-medium-top">
                                                        <div className="uk-button uk-button-large uk-button-primary" onClick={this.update}>
                                                            Сохранить изменения
                                                        </div>
                                                    </div>
                                                </li>
                                                {
                                                    project.status === 5 && project.scheduleDocument == '' && (
                                                        <li>
                                                            <CalculateSchedule
                                                                profile={profile}
                                                                customVisible={false}
                                                                repaymentScheduleParams={
                                                                    {
                                                                        projectId: project.id,
                                                                        amount: project.collected,
                                                                        duration: project.data.duration,
                                                                        rate: project.rate,
                                                                        ratePlatform: project.platformCommission,
                                                                        repaymentType: project.data.repaymentType
                                                                    }} />
                                                        </li>
                                                    )
                                                }
                                                <li>
                                                    <div className="uk-overflow-auto uk-margin">
                                                        <table className="tm-table-balance-operations uk-table uk-text-center uk-table-divider uk-table-hover uk-table-middle">
                                                            <thead>
                                                                <tr>
                                                                    <th className="uk-text-center">Дата</th>
                                                                    <th className="uk-text-center">ID инвестора</th>
                                                                    <th className="uk-text-center">ФИО</th>
                                                                    <th className="uk-text-center">Сумма инвестиций, руб.</th>
                                                                    <th className="uk-text-center">Доля инвестора в займе</th>
                                                                    <th className="uk-text-center">Выписка</th>
                                                                    <th className="uk-text-center">График</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    project.investmentsProject.map(investment => (
                                                                        <tr>
                                                                            <td>
                                                                                {investment.date}
                                                                            </td>
                                                                            <td>
                                                                                {investment.profile.profileId}
                                                                            </td>
                                                                            <td>
                                                                                <>
                                                                                    <Link to={`/profile/edit/${investment.profile.profileId}`}>{investment.profile.lastName} {investment.profile.firstName} {investment.profile.middleName}</Link>
                                                                                </>
                                                                            </td>
                                                                            <td>
                                                                                <CurrencyValue value={investment.amount} />&nbsp;₽
                                                                            </td>
                                                                            <td>
                                                                                {parseFloat(((investment.amount / project.collected) * 100)).toFixed(2)} %
                                                                            </td>
                                                                            <td>
                                                                                <a
                                                                                    download
                                                                                    onClick={() => this.generateStatementFromRegister(investment.id)}>Скачать</a>
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    investment.investmentScheduleDocument ? (
                                                                                        <>
                                                                                            <div className={window.innerWidth > 600 && ("uk-flex uk-flex-between")}>
                                                                                                <div className="uk-button uk-button-small"
                                                                                                    data-uk-toggle={`target: #scheduleModal${investment.id};`}
                                                                                                >
                                                                                                    <VisibilityIcon color='info' />
                                                                                                </div>
                                                                                                <a
                                                                                                    className="uk-button uk-button-small"
                                                                                                    href={`/private/schedules/${investment.investmentScheduleDocument}`} download>
                                                                                                    <DownloadIcon color='info' />
                                                                                                </a>
                                                                                            </div>
                                                                                            <ScheduleModal
                                                                                                investment={investment}
                                                                                                scheduleData={investment.investmentScheduleData}
                                                                                            />
                                                                                        </>
                                                                                    )
                                                                                        :
                                                                                        '-'
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                                <tr className="uk-text-bold">
                                                                    <td colSpan={2}>

                                                                    </td>
                                                                    <td >
                                                                        Итого:
                                                                    </td>
                                                                    <td>
                                                                        <CurrencyValue value={project.collected} />&nbsp;₽
                                                                    </td>
                                                                    <td>
                                                                        100 %
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </li>
                                                <li>
                                                    <DelayWork project={project} />
                                                </li>
                                            </>
                                        )
                                    }
                                </ul>
                            </>
                        ) : (
                            <div className="tm-spinner">
                                <span data-uk-spinner="ratio: 3" />
                            </div>
                        )
                    }
                </div>
            </div >
        )
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(Project);