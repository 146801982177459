import React from 'react';
import CurrencyValue from '../../../partials/others/CurrencyValue';
import StatementQualifiedDescription from '../../../partials/others/StatementQualifiedDescription';
import { Link } from "react-router-dom";
import { PieChart } from 'react-minimal-pie-chart';
import store from "../../../store";
import { connect } from "react-redux";
import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'
import OperationHistory from '../OperationHistory';

UIkit.use(Icons);

class Investments extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            profile: this.props.profile,
            investments: [],
            investmentsFilterable: [],
            payments: []
        }
    }

    async componentDidMount() {
        const profileHash = localStorage.getItem("profileHash");

        if (profileHash) {
            await fetch("https://api.crowd-money.ru/profile", {
                method: 'POST',
                body: JSON.stringify({
                    data: {
                        profileHash: profileHash
                    }
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        if (!result) {
                            localStorage.removeItem('profileHash');
                            this.props.history.push('/');
                        }

                        this.setState({
                            profile: result
                        })
                    },
                    (error) => {
                        console.log(error)
                    }
                );
        }

        if (this.state.profile.access.authorized === false) {
            this.props.history.push('/');
        }
        else {
            fetch("https://api.crowd-money.ru/investments", {
                method: 'POST',
                body: JSON.stringify({
                    action: "getInvestments",
                    data: {
                        profileHash: this.state.profile.access.hash,
                        profileId: this.state.profile.access.id
                    }
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            investments: result,
                            investmentsFilterable: result.filter(v => [5, 8].includes(v.project.status))
                        })
                    },
                    (error) => {
                        console.log(error)
                    }
                );

            fetch("https://api.crowd-money.ru/payments", {
                method: 'POST',
                body: JSON.stringify({
                    action: "getPayments",
                    data: {
                        profileHash: this.state.profile.access.hash
                    }
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            payments: result
                        })
                    },
                    (error) => {
                        console.log(error)
                    }
                );

            store.dispatch({
                type: 'CABINET.INVESTMENTS',
                payload: {
                    profile: this.state.profile
                }
            });
        }
    }

    render() {
        const { investments, investmentsFilterable, payments, profile } = this.state;
        let investmentAmount = 0;
        let withdrawAmount = 0;

        const investmentsChartPieOne = () => {
            let paymentsTotalAmount = 0;
            let investmentsTotalAmount = 0;

            if (payments.length > 0) {
                payments.map((payment) => {
                    if (payment.type === 1 && payment.status === 3) {
                        paymentsTotalAmount += parseFloat(payment.amount);
                    }

                    if (payment.type === 2) {
                        investmentsTotalAmount += parseFloat(payment.amount);
                    }
                });

                return parseFloat(((investmentsTotalAmount / paymentsTotalAmount) * 100).toFixed(2));
            }
            else {
                return 0;
            }
        };

        const investmentsChartPieTwo = () => {
            let getExpectedIncome = expectedIncome();
            let totalInvestment = 0;

            if (payments.length > 0) {
                payments.filter((payment) => {
                    if (payment.type === 2) {
                        totalInvestment += parseFloat(payment.amount)
                    }
                })

                return parseInt(((getExpectedIncome / totalInvestment) * 100));
            }
            else {
                return 0;
            }
        };

        const balanceChartPieThree = () => {
            if (profile.access.group === 1) {
                // let withdrawAmount = 0;
                // let investmentsTotalAmount = 0;

                // if (payments.length > 0) {
                //     payments.map((payment) => {
                //         if (payment.type === 4 && payment.status === 3) {
                //             withdrawAmount += parseFloat(payment.amount);
                //         }

                //         if (payment.type === 2) {
                //             investmentsTotalAmount += parseFloat(payment.amount);
                //         }
                //     });

                //     return parseFloat((withdrawAmount / investmentsTotalAmount * 100).toFixed(2));
                // }
                // else {
                //     return 0;
                // }

                if (getSuccessInvestmentsAmount() == 0 && getInvestmentsAmount() == 0) {
                    return 0;
                }

                return parseInt((getSuccessInvestmentsAmount() / getInvestmentsAmount()) * 100);
            };
        };



        const getSuccessInvestmentsAmount = () => {
            if (profile.access.group === 1) {
                let investPrjSuccessTotalAmount = 0;

                if (investments.length > 0) {
                    investments.map((investment) => {
                        if (investment.project.status === 4) {
                            investPrjSuccessTotalAmount += parseFloat(investment.amount);
                        }

                    });

                    return parseFloat(investPrjSuccessTotalAmount).toFixed(2)
                }
                else {
                    return 0;
                }
            }
        }

        const getInvestmentsAmount = () => {
            let investmetsAmount = 0;
            payments.length > 0 && (
                payments.filter((payment) => {
                    if (payment.type === 2) {
                        investmetsAmount += parseFloat(payment.amount)
                    }
                })
            )

            return investmetsAmount;
        }

        const expectedIncome = () => {
            let income = 0;

            if (payments.length > 0) {
                payments.map((payment) => {
                    if (payment.type === 1 && payment.purposePayment.includes("Выплата ежемесячных процентов")) {
                        income += parseFloat(payment.amount);
                    }
                });

                return income;
            }
            else {
                return 0;
            }

            // if (investments.length > 0) {
            //     investments.map((investment) => {
            //         if (investment.project.status === 1 || investment.project.status === 5) {
            //             expectedIncome += investment.amount + parseFloat(((investment.amount * (investment.project.rate / 100) / 12) * investment.project.data.duration).toFixed(2));
            //         }
            //     });
            // }
        };

        console.log(this.state)

        return (
            <div className="tm-body">
                <div className="tm-section-documents">
                    <div className="uk-container uk-section-small uk-padding-remove-top">
                        <h3 className="uk-h3 tm-font-weight-5">Активные</h3>
                        <div>
                            <ul className="uk-tab">
                                <li><Link to="/projects">Для инвестиций</Link></li>
                                <li className="uk-active"><a href="#">Активные</a></li>
                                <li><Link to="/Expired">Просроченные</Link></li>
                                <li><Link to="/archive">Архив</Link></li>
                            </ul>
                            <div className="tm-charts uk-width-auto uk-margin-medium-bottom">
                                <div className="uk-position-relative uk-visible-toggle" tabIndex="-1" data-uk-slider>
                                    <ul className="uk-flex uk-flex-middle uk-slider-items uk-child-width">
                                        <li className="uk-text-center uk-width-expand@m">
                                            <div className="tm-chart">
                                                <PieChart
                                                    rounded
                                                    lineWidth={15}
                                                    data={[
                                                        { title: '', value: 100, color: '#1890FF' }
                                                    ]}
                                                />
                                                <div className="tm-label">100%</div>
                                            </div>
                                            <div className="uk-text-center">
                                                <div className="uk-margin-top uk-text-muted">
                                                    Инвестировано
                                                </div>
                                                <div>
                                                    <span className="uk-h3">
                                                        <CurrencyValue value={getInvestmentsAmount()} currencyType={"₽"} />
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="uk-text-center uk-width-expand@m">
                                            <div className="tm-chart">
                                                <PieChart
                                                    rounded
                                                    lineWidth={15}
                                                    data={[
                                                        { title: '', value: 100 - investmentsChartPieTwo(), color: '#f0f2f5' },
                                                        { title: '', value: investmentsChartPieTwo(), color: '#5ddecf' }
                                                    ]}
                                                />
                                                <div className="tm-label">{investmentsChartPieTwo()}%</div>
                                            </div>
                                            <div className="uk-text-center">
                                                <div className="uk-margin-top uk-text-muted">
                                                    Доход с инвестиций
                                                </div>
                                                <div>
                                                    <span className="uk-h3">
                                                        <CurrencyValue value={expectedIncome()} currencyType={"₽"} />
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="uk-text-center uk-width-expand@m">
                                            <div className="tm-chart">
                                                <PieChart
                                                    rounded
                                                    lineWidth={15}
                                                    data={[
                                                        { title: '', value: 100 - balanceChartPieThree(), color: '#f0f2f5' },
                                                        { title: '', value: 100, color: '#f221f3' }
                                                    ]}
                                                />
                                                <div className="tm-label">{balanceChartPieThree() > 0 ? balanceChartPieThree() : 0}%</div>
                                            </div>
                                            <div className="uk-text-center">
                                                <div className="uk-margin-top uk-text-muted">Возврат инвестиций</div>
                                                <div>
                                                    <span className="uk-h3">
                                                        <CurrencyValue value={getSuccessInvestmentsAmount()} currencyType={"₽"} />
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <a className="uk-position-center-left uk-position-small" href="#"
                                        data-uk-slidenav-previous data-uk-slider-item="previous"></a>
                                    <a className="uk-position-center-right uk-position-small" href="#"
                                        data-uk-slidenav-next data-uk-slider-item="next"></a>
                                </div>
                            </div>
                            {
                                profile.approve === 1 &&
                                profile.entrepreneurData.category === 1 &&
                                profile.entrepreneurData.type === 1 &&
                                profile.qualified !== 1 && (
                                    <StatementQualifiedDescription profileId={profile.access.id} />
                                )
                            }
                            <hr className="uk-margin-medium" />
                            {
                                investmentsFilterable.length > 0 ? (
                                    <OperationHistory investments={investmentsFilterable} />
                                ) : (
                                    <div className="uk-alert uk-text-center uk-alert-primary" Style="border-radius: 4px;">
                                        Информация отсутствует
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(Investments);