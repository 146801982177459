import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box'
import { styled } from '@material-ui/core/styles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const AntTabs = styled(Tabs)({    
    '& .MuiTabs-indicator': {
        display: "none"     
    },
    'margin-bottom':"20px"
  });
  
  const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    padding: '10px 15px',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontWeight: 500,
    marginRight: theme.spacing(4),
    color: '#FFFFFF',
    fontFamily: [
      'Montserrat,sans-serif',
    ].join(','),
    '&:hover': {
      color: '#FAD167',
      opacity: 1,
    },
    '&.Mui-selected': {
      color: '#07011B',
      backgroundColor: '#FAD167',
      'border-radius': '6px'
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }));

export default function BasicTabs(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <AntTabs value={value} onChange={handleChange}> 
                    {
                        props.tabs.map((e, i) => (<AntTab label={e} />))
                    }
                </AntTabs>
            </Box>
            {
                props.content.map((e, i) => (<TabPanel value={value} index={i}>{e}</TabPanel>))
            }
        </Box>
    );
}