import React from 'react';
import {connect} from "react-redux";
import InputMask from "react-input-mask";
import store from "../../../../../../store";

class SectionEntrepreneur extends React.Component {
    constructor(props) {
        super(props);

        this.onCopy = this.onCopy.bind(this);
    }

    onCopy = (value) => {
        navigator.clipboard.writeText(value);

        store.dispatch({
            type: "SHOW.SNACKBAR",
            payload: {
                status: "success",
                message: `Значение скопировано ${value}`
            }
        });        
    }

    render() {
        const { parentThis, data, onChange, disableSection, profileGroup } = this.props;

        return (
            <>
                <h3 className="uk-h3">Другие данные</h3>
                <div className="uk-grid-row-small" data-uk-grid>
                    <div className="uk-width-1-1@s">
                        <label className="tm-radio uk-margin-medium-right">
                            <input
                                className="uk-radio"
                                type="radio"
                                name="type"
                                value={1}
                                checked={data.type === 1 ? true : false}
                                onChange={onChange}
                                disabled={disableSection}
                            />
                            <span>Физическое лицо</span>
                        </label>
                        <label className="tm-radio uk-margin-medium-right">
                            <input
                                className="uk-radio"
                                type="radio"
                                name="type"
                                value={2}
                                checked={data.type === 2 ? true : false}
                                onChange={onChange}
                                disabled={disableSection}
                            />
                            <span>ИП</span>
                        </label>
                        <label className="tm-radio uk-margin-medium-right">
                            <input
                                className="uk-radio"
                                type="radio"
                                name="type"
                                value={3}
                                checked={data.type === 3 ? true : false}
                                onChange={onChange}
                                disabled={disableSection}
                            />
                            <span>Юридическое лицо</span>
                        </label>
                    </div>
                    {
                        data.type === 1 && (
                            <div className="uk-width-1-1@s">
                                <div className="uk-alert uk-alert-primary uk-text-small">
                                    Может выступать только в роли Инвестора.
                                </div>
                            </div>
                        )
                    }
                    {
                        data.type === 2 && (
                            <>
                                <div className="uk-width-1-1@s">
                                    <div className="uk-alert uk-alert-primary uk-text-small">
                                        Может выступать в роли Инвестора или Заёмщика.
                                    </div>
                                </div>
                                <div className="uk-width-1-2@s">
                                    <label className="uk-form-label">ИНН</label>
                                    <div className=" uk-flex uk-flex-middle">
                                    <InputMask
                                        mask="999999999999"
                                        value={data.inn}
                                        onChange={onChange}
                                        disabled={true}                                       
                                    >
                                        <input
                                             type="text"
                                             className="uk-input"
                                             name="inn"
                                             ref={(input) => {parentThis.inn = input}}
                                        />
                                    </InputMask>
                                        {
                                            profileGroup == 0 && (
                                                <button className="uk-button uk-button-small uk-button-primary uk-margin-left"
                                                    data-uk-icon="icon: copy;"
                                                    onClick={() => this.onCopy(data['inn'])}
                                                />
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="uk-width-1-2@s">
                                    <label className="uk-form-label">ОГРНИП</label>
                                    <div className=" uk-flex uk-flex-middle">
                                        <InputMask
                                            mask="999999999999999"
                                            value={data.ogrnip}
                                            onChange={onChange}
                                            disabled={disableSection}
                                        >
                                            <input
                                                type="text"
                                                className="uk-input"
                                                name="ogrnip"
                                                ref={(input) => { parentThis.ogrnip = input }}
                                            />
                                        </InputMask>
                                        {
                                            profileGroup == 0 && (
                                                <button className="uk-button uk-button-small uk-button-primary uk-margin-left"
                                                    data-uk-icon="icon: copy;"
                                                    onClick={() => this.onCopy(data['ogrnip'])}
                                                />
                                            )
                                        }
                                    </div>
                                </div>
                            </>
                        )
                    }
                    {
                        data.type === 3 && (
                            <>
                                <div className="uk-width-1-1@s">
                                    <div className="uk-alert uk-alert-primary uk-text-small">
                                        Может выступать в роли Инвестора или Заёмщика.
                                    </div>
                                </div>
                                <div className="uk-width-1-2@s">
                                    <label className="uk-form-label">Краткое наименование организации</label>
                                    <input
                                        type="text"
                                        className="uk-input"
                                        name="companyName"
                                        ref={(input) => {parentThis.companyName = input}}
                                        defaultValue={data.companyName}
                                        onChange={onChange}
                                        disabled={disableSection}
                                    />
                                </div>
                                <div className="uk-width-1-2@s ">
                                    <label className="uk-form-label">ИНН</label>
                                    <div className=" uk-flex uk-flex-middle">
                                        <InputMask
                                            mask="9999999999"
                                            value={data.inn}
                                            onChange={onChange}
                                            disabled={disableSection}
                                        >
                                            <input
                                                type="text"
                                                className="uk-input"
                                                name="inn"
                                                ref={(input) => { parentThis.inn = input }}
                                            />
                                        </InputMask>
                                        {
                                            profileGroup == 0 && (
                                                <button className="uk-button uk-button-small uk-button-primary uk-margin-left"
                                                    data-uk-icon="icon: copy;"
                                                    onClick={() => this.onCopy(data['inn'])}
                                                />
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="uk-width-1-2@s">
                                    <label className="uk-form-label">Юридический адрес</label>
                                    <input
                                        type="text"
                                        className="uk-input"
                                        name="address"
                                        ref={(input) => {parentThis.address = input}}
                                        defaultValue={data.address}
                                        onChange={onChange}
                                        disabled={disableSection}
                                    />
                                </div>
                                <div className="uk-width-1-2@s">
                                    <label className="uk-form-label">КПП</label>
                                    <div className=" uk-flex uk-flex-middle">
                                        <InputMask
                                            mask="999999999"
                                            value={data.kpp}
                                            onChange={onChange}
                                            disabled={disableSection}
                                        >
                                            <input
                                                type="text"
                                                className="uk-input"
                                                name="kpp"
                                                ref={(input) => { parentThis.kpp = input }}
                                            />
                                        </InputMask>
                                        {
                                            profileGroup == 0 && (
                                                <button className="uk-button uk-button-small uk-button-primary uk-margin-left"
                                                    data-uk-icon="icon: copy;"
                                                    onClick={() => this.onCopy(data['kpp'])}
                                                />
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="uk-width-1-2@s">
                                    <label className="uk-form-label">Руководитель (ФИО)</label>
                                    <input
                                        type="text"
                                        className="uk-input"
                                        name="fullName"
                                        ref={(input) => {parentThis.fullName = input}}
                                        defaultValue={data.fullName}
                                        onChange={onChange}
                                        disabled={disableSection}
                                    />
                                </div>
                                <div className="uk-width-1-2@s">
                                    <label className="uk-form-label">ОГРН</label>
                                    <div className=" uk-flex uk-flex-middle">
                                    <InputMask
                                        mask="9999999999999"
                                        value={data.ogrn}
                                        onChange={onChange}
                                        disabled={disableSection}
                                    >
                                        <input
                                            type="text"
                                            className="uk-input"
                                            name="ogrn"
                                            ref={(input) => {parentThis.ogrn = input}}
                                        />
                                    </InputMask>
                                        {
                                            profileGroup == 0 && (
                                                <button className="uk-button uk-button-small uk-button-primary uk-margin-left"
                                                    data-uk-icon="icon: copy;"
                                                    onClick={() => this.onCopy(data['ogrn'])}
                                                />
                                            )
                                        }
                                    </div>
                                </div>                               
                            </>
                        )
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(SectionEntrepreneur);