import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { Link } from "react-router-dom";
import { PieChart } from 'react-minimal-pie-chart';
import InputMask from "react-input-mask";
import store from "../../../store";
import { connect } from "react-redux";
import Countdown from 'react-countdown';

import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'
import NumberFormat from 'react-number-format';

UIkit.use(Icons)

class Projects extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            profile: this.props.profile,
            documents: [],
            currentStep: 0,
            projects: [],
            investment: [],
            payments: [],
            modalId: Math.random().toString(16).slice(2)
        };

        this.handleChange = this.handleChange.bind(this);
        this.showModalInvestment = this.showModalInvestment.bind(this);
        this.handleCreateInvestment = this.handleCreateInvestment.bind(this);
        this.handleResetCurrentStep = this.handleResetCurrentStep.bind(this);
        this.calcInvestmentAmountOfYear = this.calcInvestmentAmountOfYear.bind(this);
        this.getDiffDaysDates = this.getDiffDaysDates.bind(this);
        this.getDateProjectOfferValidity = this.getDateProjectOfferValidity.bind(this);
    }

    async componentDidMount() {
        const profileHash = localStorage.getItem("profileHash");

        fetch("https://api.crowd-money.ru/documents", {
            method: 'POST'
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        ...this.state,
                        isLoaded: true,
                        documents: result
                    })
                },
                (error) => {
                    console.log(error)
                }
            );

        if (profileHash) {
            await fetch("https://api.crowd-money.ru/profile", {
                method: 'POST',
                body: JSON.stringify({
                    data: {
                        profileHash: profileHash
                    }
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        if (!result) {
                            localStorage.removeItem('profileHash');
                            this.props.history.push('/');
                        }

                        this.setState({
                            ...this.state,
                            profile: result
                        })
                    },
                    (error) => {
                        console.log(error)
                    }
                );
        }

        if (this.state.profile.access.authorized === false) {
            this.props.history.push('/');
        }
        else {
            fetch("https://api.crowd-money.ru/loans", {
                method: 'POST',
                body: JSON.stringify({
                    data: {
                        type: "projectsList"
                    }
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            ...this.state,
                            projects: result
                        })
                    },
                    (error) => {
                        console.log(error)
                    }
                );

            fetch("https://api.crowd-money.ru/payments", {
                method: 'POST',
                body: JSON.stringify({
                    action: "getPayments",
                    data: {
                        profileHash: this.state.profile.access.hash
                    }
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            ...this.state,
                            payments: result
                        })
                    },
                    (error) => {
                        console.log(error)
                    }
                );

            store.dispatch({
                type: 'CABINET.PROJECTS',
                payload: {
                    profile: this.state.profile
                }
            });
        }
    }

    handleChange = (e) => {
        this.setState({
            ...this.state,
            investment: {
                ...this.state.investment,
                [e.target.name]: e.target.type === "checkbox" ? e.target.checked : parseFloat(e.target.value)
            }
        })
    };

    handleResetCurrentStep = () => {
        this.setState({
            ...this.state,
            currentStep: 0
        })
    };

    calcInvestmentAmountOfYear = () => {
        let amountOfYear = 0;

        this.state.payments.filter((payment) => {
            if (payment.type === 2 && payment.date.includes((new Date()).getFullYear())) {
                amountOfYear += parseFloat(payment.amount)
            }
        })

        return amountOfYear
    };

    getDiffDaysDates = (date) => {
        let endDate = this.getDateProjectOfferValidity(date);
        let diff = Math.floor((Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) - Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())) / (1000 * 60 * 60 * 24));
        return diff;
    };

    getDateProjectOfferValidity = (strDate) => {
        let date = strDate.split('.').reverse().join("-");
        date = new Date(date);
        date.setHours(date.getHours() + 7);
        return date;
    };

    showModalInvestment = (projectIndex, projectId, projectName) => {
        const profile = this.state.profile;

        if (profile.balance === 0) {
            store.dispatch({
                type: "SHOW.SNACKBAR",
                payload: {
                    status: "error",
                    message: "Пожалуйста, пополните баланс личного профиля"
                }
            });
        }
        else if (profile.qualified === 0 && profile.entrepreneurData.type === 1 && profile.verification.length != 0 && this.calcInvestmentAmountOfYear() > 600000) {
            store.dispatch({
                type: "SHOW.SNACKBAR",
                payload: {
                    status: "error",
                    message: "Лимит инвестирования превышен"
                }
            });
        }
        else {
            this.setState({
                ...this.state,
                investment: {
                    ...this.state.investment,
                    projectIndex: projectIndex,
                    projectId: projectId,
                    projectName: projectName
                }
            });

            UIkit.modal(`#tm-modal-investment-${this.state.modalId}`).show();
        }
    };

    handleCreateInvestment = () => {
        let projects = this.state.projects;
        let projectIndex = this.state.investment.projectIndex;

        let project = { ...projects[projectIndex] };

        store.dispatch({
            type: "HIDE.SNACKBAR"
        });

        fetch("https://api.crowd-money.ru/loans/create", {
            method: 'POST',
            body: JSON.stringify({
                action: "createInvestment",
                data: {
                    step: this.state.currentStep,
                    profileHash: this.state.profile.access.hash,
                    projectId: this.state.investment.projectId,
                    profilePhone: this.state.profile.mainData.phone,
                    amount: this.state.investment.amount,
                    agreeContract: this.state.investment.agreeContract,
                    agreeAcceptance: this.state.investment.agreeAcceptance,
                    verifyCode: this.state.investment.verifyCode,
                    sentCode: this.state.investment.sentCode
                }
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.message) {
                        store.dispatch({
                            type: "SHOW.SNACKBAR",
                            payload: {
                                status: result.status,
                                message: result.message
                            }
                        });
                    }

                    if (result.status === "continue") {
                        if (this.state.currentStep === 0) {
                            this.setState({
                                ...this.state,
                                currentStep: 1
                            });

                            UIkit.modal(`#tm-modal-investment-${this.state.modalId}`).hide();

                            return false;
                        }

                        if (this.state.currentStep === 1) {
                            this.setState({
                                ...this.state,
                                currentStep: 2,
                                investment: {
                                    ...this.state.investment,
                                    sentCode: result.sentCode
                                }
                            });

                            return false;
                        }
                    }

                    if (result.status === "success") {
                        project.collected = parseFloat(project.collected) + this.state.investment.amount;
                        projects[projectIndex] = project;

                        this.setState({
                            ...this.state,
                            projects: projects,
                            investment: {
                                acceptPath: result.acceptPath
                            },
                            currentStep: 3,

                        });
                    }
                },
                (error) => {
                    console.log(error)
                }
            );
    };

    render() {
        const { isLoaded, profile, currentStep, documents, modalId } = this.state;
        const projects = this.state.projects.filter(e => {
            if (e.closeInvestors) {
                return e.closeInvestors.map(v => v.id).includes(this.state.profile.access.id);
            }
            return e;
        })

        return (
            isLoaded && (
                <div className="tm-body">
                    <div className="tm-section-documents">
                        <div className="uk-container uk-section-small uk-padding-remove-top">
                            {
                                currentStep === 0 && (
                                    <>
                                        <h3 className="uk-h3 tm-font-weight-5">Заявки для финансирования</h3>
                                        <div>
                                            <ul className="uk-tab">
                                                <li className="uk-active"><a href="#">Для инвестиций</a></li>
                                                <li><Link to="/investments">Активные</Link></li>
                                                <li><Link to="/Expired">Просроченные</Link></li>
                                                <li><Link to="/archive">Архив</Link></li>
                                            </ul>
                                            {
                                                projects.length > 0 ? (
                                                    <div className="uk-child-width-1-3@s" data-uk-grid>
                                                        {
                                                            projects.map((project, index) => (
                                                                <div>
                                                                    <div className="uk-card uk-card-default uk-card-small tm-card-project">
                                                                        <div className="uk-card-body">
                                                                            <div className="uk-flex uk-flex-middle uk-grid-small" data-uk-grid>
                                                                                <div className="uk-width-auto">
                                                                                    <div className="tm-project-picture">
                                                                                        {
                                                                                            project.mainData.avatar ? (
                                                                                                <img src={`/public/uploads/avatars/${project.mainData.avatar}`} alt="" />
                                                                                            ) : (
                                                                                                <i data-uk-icon="icon: user;" />
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="uk-width-expand">
                                                                                    <label className="uk-margin-remove uk-text-bolder uk-form-label">
                                                                                        {
                                                                                            project.entrepreneurData.type === 1 && project.mainData.lastName + " " + project.mainData.firstName + " " + project.mainData.middleName
                                                                                        }
                                                                                        {
                                                                                            project.entrepreneurData.type === 2 && "ИП " + project.mainData.lastName + " " + project.mainData.firstName + " " + project.mainData.middleName
                                                                                        }
                                                                                        {
                                                                                            project.entrepreneurData.type === 3 && project.entrepreneurData.companyName
                                                                                        }
                                                                                    </label>
                                                                                </div>
                                                                                {
                                                                                    project.closeInvestors && (
                                                                                        <div className="uk-width-auto">
                                                                                            <div className="tm-close-project-picture" title="Закрытое инвест. предложение">
                                                                                                <i data-uk-icon="icon: lock;" />
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                            <hr />
                                                                            <div className="uk-margin uk-text-small" style={{ minHeight: 42 }}>
                                                                                {project.data.projectName}
                                                                            </div>
                                                                            <div className="uk-margin-small-bottom">
                                                                                <div className="uk-text-small uk-text-muted">
                                                                                    Тип займа
                                                                                </div>
                                                                                <div>
                                                                                    {
                                                                                        project.data.loanType === 1 && "Исполнение гос. контракта"
                                                                                    }
                                                                                    {
                                                                                        project.data.loanType === 2 && "Развитие бизнеса"
                                                                                    }
                                                                                    {
                                                                                        project.data.loanType === 3 && "Пополнение оборотных средств"
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <hr />
                                                                            <div className="uk-text-small uk-text-muted">
                                                                                Текущий статус
                                                                            </div>
                                                                            <div className="uk-text-small uk-margin-small-bottom">
                                                                                {
                                                                                    project.status === 1 && "Идёт сбор средств"
                                                                                }
                                                                                {
                                                                                    project.status === 4 && "Займ погашен"
                                                                                }
                                                                                {
                                                                                    project.status === 5 && "Сбор средств завершён"
                                                                                }
                                                                                {
                                                                                    project.status === 6 && "Погашен досрочно"
                                                                                }
                                                                                {
                                                                                    project.status === 7 && "Просрочка платежей"
                                                                                }
                                                                                {
                                                                                    project.status === 8 && "Активный"
                                                                                }
                                                                                {
                                                                                    project.status === 9 && "Не состоялась"
                                                                                }
                                                                            </div>
                                                                            <div className="uk-text-small uk-text-muted">
                                                                                Собрано
                                                                            </div>
                                                                            <div className="uk-text-small uk-margin-small-bottom">
                                                                                <CurrencyFormat value={project.collected} displayType={'text'} thousandSeparator={" "} /> из&nbsp;
                                                                                <CurrencyFormat value={project.data.amountStart} displayType={'text'} thousandSeparator={" "} />
                                                                                &nbsp;/&nbsp;
                                                                                <CurrencyFormat value={project.data.amountEnd} displayType={'text'} thousandSeparator={" "} />
                                                                                &nbsp;₽
                                                                            </div>
                                                                            <div className={`uk-text-medium uk-text-muted uk-text-bolder ${this.getDiffDaysDates(project.date.projectOfferValidity) <= 3 ? "uk-text-danger" : "uk-text-warning"}`}>
                                                                                До закрытия заявки осталось:
                                                                                <Countdown
                                                                                    date={this.getDateProjectOfferValidity(project.date.projectOfferValidity).getTime()}
                                                                                    renderer={(props) => <div>{`${props.days}д  ${props.hours}ч ${props.minutes}м`}</div>}
                                                                                />
                                                                            </div>
                                                                            <div className="tm-investment-rating uk-margin">
                                                                                <span style={{ width: ((project.collected / project.data.amountEnd) * 100).toFixed(2) + '%' }} />
                                                                            </div>
                                                                            <div data-uk-grid className="uk-grid-small uk-child-width-expand uk-margin">
                                                                                <div>
                                                                                    <div className="uk-text-small uk-text-muted">
                                                                                        Ставка
                                                                                    </div>
                                                                                    <h4 className="uk-h4 uk-margin-remove tm-font-weight-5"
                                                                                        Style="margin-top: 5px;">
                                                                                        {project.rate}%
                                                                                    </h4>
                                                                                </div>
                                                                                <div>
                                                                                    <div className="uk-text-small uk-text-muted">
                                                                                        Срок
                                                                                    </div>
                                                                                    <h4 className="uk-h4 uk-margin-remove tm-font-weight-5"
                                                                                        Style="margin-top: 5px;">{project.data.duration} мес.</h4>
                                                                                </div>
                                                                                <div>
                                                                                    <div className="uk-text-small uk-text-muted">
                                                                                        Рейтинг
                                                                                    </div>
                                                                                    <h4 className="uk-h4 uk-margin-remove tm-font-weight-5"
                                                                                        Style="margin-top: 5px;">
                                                                                        {
                                                                                            project.rating === 0 && "Не указан"
                                                                                        }
                                                                                        {
                                                                                            project.rating === 1 && "Высокий"
                                                                                        }
                                                                                        {
                                                                                            project.rating === 2 && "Средний"
                                                                                        }
                                                                                        {
                                                                                            project.rating === 3 && "Низкий"
                                                                                        }
                                                                                    </h4>
                                                                                </div>
                                                                            </div>
                                                                            <div className="uk-text-center">
                                                                                {
                                                                                    <div
                                                                                        className="uk-button uk-button-primary"
                                                                                        onClick={() => (this.showModalInvestment)(index, project.id, project.data.projectName)}
                                                                                    >Инвестировать</div>
                                                                                }
                                                                                {
                                                                                    <div className="uk-margin">
                                                                                        <Link to={`/project/${project.id}`} className="uk-button uk-button-primary">Перейти к проекту</Link>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                ) : (
                                                    <div className="uk-alert uk-text-center uk-alert-primary" Style="border-radius: 4px;">
                                                        Информация отсутствует
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </>
                                )
                            }
                            {
                                currentStep === 1 && (
                                    <>
                                        <h3 className="uk-h3 uk-margin-medium-bottom tm-font-weight-5">Договор инвестирования</h3>
                                        <div className="uk-card uk-card-default">
                                            <div className="uk-card-header">
                                                Присоединение к инвестиционному предложению и заключению договора займа
                                            </div>
                                            <div className="uk-card-body">
                                                Подтверждая присоединение к инвестиционному предложению, Вы соглашаетесь с условиями оферты, а также с условиями договора инвестирования. Также, Вы подтверждаете электронное подписание договора путем отправки на номер телефона SMS-кода, прописанное в Правилах инвестиционной платформы.
                                                <div className="uk-margin-top">
                                                    <label className="tm-checkbox">
                                                        <input
                                                            className="uk-checkbox"
                                                            type="checkbox"
                                                            name="agreeAcceptance"
                                                            onChange={this.handleChange}
                                                        />
                                                        <span>Я направляю <a href="/documents/Форма акцепта инвестиционного предложения.pdf" download>акцепт инвестиционного предложения (оферты)</a></span>
                                                    </label>
                                                </div>
                                                <div className="uk-margin">
                                                    <label className="tm-checkbox">
                                                        <input
                                                            className="uk-checkbox"
                                                            type="checkbox"
                                                            name="agreeContract"
                                                            onChange={this.handleChange}
                                                        />
                                                        <span>Я согласен(на) заключить <a href={`/private/investmentOffers/${projects[this.state.investment.projectIndex].investmentOfferDocument}`} download>Договор инвестирования</a> и поручаю «‎Crowd Money»‎ перечислить Заёмщику сумму займа</span>
                                                    </label>
                                                </div>
                                                <div className="uk-margin-medium-top">
                                                    <div className="uk-button uk-button-primary" onClick={this.handleCreateInvestment}>Подписать с SMS</div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                            {
                                currentStep === 2 && (
                                    <>
                                        <h3 className="uk-h3 uk-margin-medium-bottom tm-font-weight-5">Договор инвестирования</h3>
                                        <div className="uk-card uk-card-default">
                                            <div className="uk-card-header">
                                                Акцепт к инвестиционному предложению и заключение договора инвестирования
                                            </div>
                                            <div className="uk-card-body">
                                                <div className="uk-width-2-5@s">
                                                    <h4 className="uk-h4 uk-text-light">
                                                        Мы отправили код подтверждения
                                                        на<br /> номер <NumberFormat value={profile.mainData.phone} displayType={'text'} format="+#(###)###-##-##" />
                                                    </h4>
                                                    <div className="uk-margin">
                                                        <InputMask mask="9999" onChange={this.handleChange}>
                                                            <input
                                                                type="text"
                                                                placeholder="Код подтверждения"
                                                                className="uk-input"
                                                                name="verifyCode"
                                                                ref={(input) => {
                                                                    this.verifyCode = input
                                                                }}
                                                            />
                                                        </InputMask>
                                                    </div>
                                                    <div className="uk-margin-medium-top">
                                                        <div className="uk-button uk-button-primary" onClick={this.handleCreateInvestment}>Инвестировать</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                            {
                                currentStep === 3 && (
                                    <>
                                        <h3 className="uk-h3 uk-margin-medium-bottom tm-font-weight-5">Договор инвестирования</h3>
                                        <div className="uk-card uk-card-default">
                                            <div className="uk-card-header">
                                                Акцепт к инвестиционному предложению и заключение договора инвестирования
                                            </div>
                                            <div className="uk-card-body">
                                                <p>Ваш акцепт успешно подтверждён инвестиционной платформой «Crowd Money»!</p>
                                                <p>Сумма займа будет перечислена Заёмщику в случае успешного сбора необходимой суммы денежных средств.</p>
                                                <p>Спасибо за выбор платформы «Crowd Money».</p>
                                                <div className="uk-margin">
                                                    <a href={this.state.investment.acceptPath} className="uk-button uk-button-large uk-button-default uk-margin-medium-right" download>Скачать акцепт</a>
                                                    <div className="uk-button uk-button-large uk-button-primary" onClick={this.handleResetCurrentStep}>Перейти к проектам</div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </div>

                    <div id={`tm-modal-investment-${modalId}`} className="uk-flex-top" data-uk-modal>
                        <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-width-1-3@s">
                            <button className="uk-modal-close-default" type="button" data-uk-close></button>
                            <h3 className="uk-h3 uk-margin-remove tm-font-weight-5">Инвестиция в проект</h3>
                            <h4 className="uk-h4 uk-margin-remove-top uk-text-muted">
                                «{this.state.investment.projectName}»
                            </h4>
                            <div className="uk-margin">
                                <input type="text" className="uk-input" placeholder="Сумма" name="amount"
                                    ref={(input) => { this.amount = input }} onChange={this.handleChange} />
                            </div>
                            <div className="uk-margin">
                                <div className="uk-button uk-button-large uk-button-primary uk-width" onClick={this.handleCreateInvestment}>Продолжить</div>
                            </div>
                        </div>
                    </div>
                </div>)
        )
    }
}


const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(Projects);