import React from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import store from "../../store";
import UIkit from 'uikit'

const navLinks = [
    {
        title: 'Баланс',
        path: '/cabinet/balance',
        icon: '/assets/images/wallet.png'
    },
    {
        title: ['Заявки', 'Заявки'],
        path: ['/projects', '/cabinet/requests'],
        icon: '/assets/images/box.png'
    },
    {
        title: ['Мои инвестиции'],
        path: ['/investments'],
        icon: '/assets/images/person.png'
    },
    {
        title: 'Мой профиль',
        path: '/profile/edit/',
        icon: '/assets/images/gear.png'
    },
    {
        title: 'Документы',
        path: '/cabinet/documents',
        icon: '/assets/images/files.png'
    },
    {
        title: 'Выход',
        path: '/',
        icon: false
    }
];

class Sidebar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentItem: this.props.sidebar.currentItem
        };

        this.handleChangeActiveNav = this.handleChangeActiveNav.bind(this);
    }

    handleChangeActiveNav = (indexNav) => {
        this.setState({
            currentItem: indexNav
        });

        if (indexNav === 6) {
            store.dispatch({ type: 'HIDE.SIDEBAR' });                       
        }

        UIkit.offcanvas("#tm-sidebar").hide();
        UIkit.offcanvas("#tm-header-sidebar").hide();
    };

    render() {
        const group = this.props.profile.access.group === 0 ? parseInt(0) : this.props.profile.access.group;
        const {currentItem} = this.state;
        const currentYear = new Date().getFullYear();
        const verification = this.props.profile.verification.id ? true : false;
        const approve = this.props.profile.approve;

        return (
            <div id="tm-sidebar" data-uk-offcanvas="mode: push; overlay: true">
                <div className="tm-sidebar uk-offcanvas-bar uk-offcanvas-bar-animation uk-offcanvas-push">
                    <div className="tm-sidebar-header ">
                        <Link to="/" className="uk-display-block tm-logotype" />
                    </div>
                    <ul className="uk-nav tm-navigation"> 
                        {
                            group === 0 && (
                                <>
                                    <li onClick={() => (this.handleChangeActiveNav(0))}>
                                        <Link to="/cabinet">
                                            Dashboard
                                        </Link>
                                    </li>
                                    <li onClick={() => (this.handleChangeActiveNav(3))}>
                                        <Link to="/paymentsInvestors">
                                            Платежи инвесторам
                                        </Link>
                                    </li>
                                    <li onClick={() => (this.handleChangeActiveNav(1))}>
                                        <Link to="/partners">
                                            Партнеры
                                        </Link>
                                    </li>
                                    <li onClick={() => (this.handleChangeActiveNav(2))}>
                                        <Link to="/schedule">
                                            График погашения
                                        </Link>
                                    </li>
                                    <li onClick={() => (this.handleChangeActiveNav(4))}>
                                        <Link to="/claims">
                                            Досудебная работа
                                        </Link>
                                    </li>
                                </>
                            )
                        }
                        {                           
                            group === 1 && (
                                <>
                                    {(!verification && approve == 1) && (
                                        <li className={`tm-scoring tm-pulse-animation`}
                                            onClick={() => (this.handleChangeActiveNav(0))}
                                        >
                                            <Link to="/cabinet">
                                                <span className="tm-icon" />Скоринг оценка
                                            </Link>
                                        </li> )
                                    }  
                                    <li className="tm-logotype-small" onClick={() => (this.handleChangeActiveNav(0))}>
                                        <Link to="/main">
                                            <span className="tm-icon" />Главная
                                        </Link>
                                    </li> 
                                    <li className="tm-projects-table" onClick={() => (this.handleChangeActiveNav(0))}>
                                        <Link to="/projectsTable">
                                            <span className="tm-icon" />Витрина заявок
                                        </Link>
                                    </li>                                
                                    <li className="tm-wallet" onClick={() => (this.handleChangeActiveNav(1))}>
                                        <Link to="/cabinet/balance">
                                            <span className="tm-icon" />Баланс
                                        </Link>
                                    </li>
                                    <li className="tm-box" onClick={() => (this.handleChangeActiveNav(2))}>
                                        <Link to="/projects">
                                            <span className="tm-icon" />Заявки
                                        </Link>
                                    </li>
                                    <li className="tm-invest" onClick={() => (this.handleChangeActiveNav(3))}>
                                        <Link to="/investments">
                                            <span className="tm-icon" />Инвестиции
                                        </Link>
                                    </li>
                                    <li className="tm-person" onClick={() => (this.handleChangeActiveNav(4))}>
                                        <Link to={`/profile/edit/${this.props.profile.access.id}`}>
                                            <span className="tm-icon" />Мой профиль
                                        </Link>
                                    </li>
                                    <li className="tm-files" onClick={() => (this.handleChangeActiveNav(5))}>
                                        <Link to="/cabinet/documents">
                                            <span className="tm-icon" />Документы
                                        </Link>
                                    </li>                                   
                                </>
                            )
                        }
                        {
                            group === 2 && (
                                <>          
                                    {(!verification && approve == 1) && (
                                        <li className={`tm-scoring tm-pulse-animation`}
                                            onClick={() => (this.handleChangeActiveNav(0))}
                                        >
                                            <Link to="/cabinet">
                                                <span className="tm-icon" />Скоринг оценка
                                            </Link>
                                        </li> )
                                    } 
                                    <li className="tm-logotype-small" onClick={() => (this.handleChangeActiveNav(0))}>
                                        <Link to="/main">
                                            <span className="tm-icon" />Главная
                                        </Link>
                                    </li> 
                                    <li className="tm-wallet" onClick={() => (this.handleChangeActiveNav(1))}>
                                        <Link to="/cabinet/balance">
                                            <span className="tm-icon" />Баланс
                                        </Link>
                                    </li>
                                    <li className="tm-box" onClick={() => (this.handleChangeActiveNav(2))}>
                                        <Link to="/cabinet/requests">
                                            <span className="tm-icon" />Заявки
                                        </Link>
                                    </li>
                                    <li className="tm-person" onClick={() => (this.handleChangeActiveNav(4))}>
                                        <Link to={`/profile/edit/${this.props.profile.access.id}`}>
                                            <span className="tm-icon" />Мой профиль
                                        </Link>
                                    </li>
                                    <li className="tm-files" onClick={() => (this.handleChangeActiveNav(5))}>
                                        <Link to="/cabinet/documents">
                                            <span className="tm-icon" />Документы
                                        </Link>
                                    </li>                                   
                                </>
                            )
                        }
                        {
                            group === 3 && (
                                <>
                                    {(!verification && approve == 1) && (
                                        <li className={`tm-scoring tm-pulse-animation`}
                                            onClick={() => (this.handleChangeActiveNav(0))}
                                        >
                                            <Link to="/cabinet">
                                                <span className="tm-icon" />Скоринг оценка
                                            </Link>
                                        </li> )
                                    } 
                                    <li className="tm-logotype-small" onClick={() => (this.handleChangeActiveNav(0))}>
                                        <Link to="/main">
                                            <span className="tm-icon" />Главная
                                        </Link>
                                    </li> 
                                    <li className="tm-wallet" onClick={() => (this.handleChangeActiveNav(0))}>
                                        <Link to="/cabinet/balance">
                                            <span className="tm-icon" />Баланс
                                        </Link>
                                    </li>
                                    <li className="tm-person" onClick={() => (this.handleChangeActiveNav(4))}>
                                        <Link to={`/profile/edit/${this.props.profile.access.id}`}>
                                            <span className="tm-icon" />Мой профиль
                                        </Link>
                                    </li>
                                    <li className="tm-files" onClick={() => (this.handleChangeActiveNav(5))}>
                                        <Link to="/cabinet/documents">
                                            <span className="tm-icon" />Документы
                                        </Link>
                                    </li>                                    
                                </>
                            )
                        }                        
                        <li className="tm-exit" onClick={() => (this.handleChangeActiveNav(6))}>
                            <Link to="/">
                                <span className="tm-icon" />Назад
                            </Link>
                        </li>
                    </ul>                   
                    <div className="tm-sidebar-footer uk-position-bottom">
                        <div className="uk-margin-top">
                            <a href="mailto:info@crowd-money.ru">info@crowd-money.ru</a>
                        </div>
                        <div className="tm-social-links uk-margin-small">
                            {/* <a href="https://www.facebook.com/Crowd-money-109931980866275" className="tm-social-link tm-social-link-fb" target="_blank" />
                            <a href="https://www.instagram.com/crowdmoneyru" className="tm-social-link tm-social-link-ig" target="_blank" /> */}
                            <a href="https://t.me/crowdmru" className="tm-social-link tm-social-link-tg" target="_blank" />
                            <a href="https://zen.yandex.ru/id/5f5b6372763c003416f29f26?lang=ru" className="tm-social-link tm-social-link-dz" target="_blank" />
                        </div>
                        <div className="tm-copyright uk-margin-medium-bottom">
                            &copy; Copyright Crowd Money {currentYear}.
                        </div>                       
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(Sidebar);