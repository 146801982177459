import React, { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function ShowAndHidePassword(props){
    const [passwordType, setPasswordType] = useState("password");    
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    return (       
        <div class="uk-inline uk-width-expand">
            <a class="uk-form-icon uk-form-icon-flip" onClick={togglePassword}>
                {passwordType === "password" ? <VisibilityIcon/> : <VisibilityOffIcon/> }
            </a>
            <input
                type={passwordType}
                placeholder={props.placeholder}
                class="uk-input"
                maxLength={16}
                name={props.name}              
                onKeyDown={props.onKeyDown}            
                onChange={props.onChange}
                ref={(input) => {props.context[props.name] = input}}
            />           
        </div>      
    )
}
export default ShowAndHidePassword;