import React from 'react';
import {Link} from "react-router-dom";
import CurrencyFormat from 'react-currency-format';

class ShortCard extends React.Component {
    render() {
        const project = this.props.dataProject;

        return (
            <div className="uk-width-1-3@s">
                <div>
                    <div className="uk-card uk-card-default uk-card-small tm-card-project">
                        <Link className="uk-card-body" to={`/project/${project.status === 2 ? `edit/${project.id}`: project.id}`}>
                            <div className="uk-flex uk-flex-middle uk-grid-small" data-uk-grid>
                                <div className="uk-width-auto@s">
                                    <div className="tm-project-picture">
                                        {
                                            project.mainData.avatar ? (
                                                <img
                                                    src={`/public/uploads/avatars/${project.mainData.avatar}`}
                                                    alt=""/>
                                            ) : (
                                                <i data-uk-icon="icon: user;"/>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="uk-width-expand@s">
                                    <h5 className="uk-h5 uk-margin-remove">
                                        {
                                            project.entrepreneurData.type === 1 && project.mainData.lastName + " " + project.mainData.firstName + " " + project.mainData.middleName
                                        }
                                        {
                                            project.entrepreneurData.type === 2 && "ИП " + project.mainData.lastName + " " + project.mainData.firstName + " " + project.mainData.middleName
                                        }
                                        {
                                            project.entrepreneurData.type === 3 && project.entrepreneurData.companyName
                                        }
                                    </h5>
                                    <div className="uk-text-small uk-text-muted">
                                        {project.data.projectName}
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div className="uk-margin-small-bottom">
                                <div className="uk-text-small uk-text-muted">
                                    Тип займа
                                </div>
                                <div>
                                    {
                                        project.data.loanType === 1 && "Исполнение гос. контракта"
                                    }
                                    {
                                        project.data.loanType === 2 && "Развитие бизнеса"
                                    }
                                    {
                                        project.data.loanType === 3 && "Пополнение оборотных средств"
                                    }
                                </div>
                            </div>
                            <hr/>
                            <div className="uk-text-small uk-text-muted">
                                Текущий статус
                            </div>
                            <div className="uk-text-small uk-margin-small-bottom">
                                {
                                    project.status === 1 && "Идёт сбор средств"
                                }
                                {
                                    project.status === 4 && "Займ погашен"
                                }
                                {
                                    project.status === 5 && "Сбор средств завершён"
                                }
                                {
                                    project.status === 6 && "Погашен досрочно"
                                }
                                {
                                    project.status === 7 && "Просрочка платежей"
                                }
                                {
                                    project.status === 8 && "Активный"
                                }
                                {
                                    project.status === 9 && "Не состоялась"
                                }
                            </div>
                            <div className="uk-text-small">
                                Собрано <CurrencyFormat value={project.collected}
                                                        displayType={'text'}
                                                        thousandSeparator={" "}/> из&nbsp;
                                {/* {
                                    project.data.amountType === 1 && (
                                        <CurrencyFormat value={project.data.amountTotal}
                                                        displayType={'text'}
                                                        thousandSeparator={" "}/>
                                    )
                                }
                                {
                                    project.data.amountType === 2 && (
                                        <CurrencyFormat value={project.data.amountEnd}
                                                        displayType={'text'}
                                                        thousandSeparator={" "}/>
                                    )
                                } */}
                                (<CurrencyFormat value={project.data.amountStart} displayType={'text'} thousandSeparator={" "} />
                                 &nbsp;/&nbsp;
                                 <CurrencyFormat value={project.data.amountEnd} displayType={'text'} thousandSeparator={" "} />
                                )
                                &nbsp;₽
                            </div>
                            <div className="tm-investment-rating uk-margin">
                                {
                                    project.data.amountType === 1 && (
                                        <span style={{width: 100 - ((project.data.amountTotal / project.collected - 1) * 100).toFixed(2) + '%'}} />
                                    )
                                }
                                {
                                    project.data.amountType === 2 && (
                                        <span style={{width: 100 - ((project.data.amountEnd / project.collected - 1) * 100).toFixed(2) + '%'}} />
                                    )
                                }
                            </div>
                            <div data-uk-grid
                                 className="uk-grid-small uk-child-width-expand uk-margin">
                                <div>
                                    <div className="uk-text-small uk-text-muted">
                                        Ставка
                                    </div>
                                    <h4 className="uk-h4 uk-margin-remove tm-font-weight-5"
                                        style={{marginTop: '5px'}}>
                                        {project.rate}%
                                    </h4>
                                </div>
                                <div>
                                    <div className="uk-text-small uk-text-muted">
                                        Срок
                                    </div>
                                    <h4 className="uk-h4 uk-margin-remove tm-font-weight-5"
                                        style={{marginTop: '5px'}}>{project.data.duration} мес.</h4>
                                </div>
                                <div>
                                    <div className="uk-text-small uk-text-muted">
                                        Рейтинг
                                    </div>
                                    <h4 className="uk-h4 uk-margin-remove tm-font-weight-5"
                                        style={{marginTop: '5px'}}>
                                        {
                                            project.rating === 0 && "Не указан"
                                        }
                                        {
                                            project.rating === 1 && "Высокий"
                                        }
                                        {
                                            project.rating === 2 && "Средний"
                                        }
                                        {
                                            project.rating === 3 && "Низкий"
                                        }                                        
                                    </h4>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default ShortCard;