import React from 'react';
import CurrencyFormat from 'react-currency-format';
import DocumentBox from '../DocumentBox';

const documentNames = [{
    id: 1,
    name: "Устав оператора инвестиционной платформы"
}, {
    id: 2,
    name: "Договор инвестирования"
}, {
    id: 3,
    name: "Заявление о присоединении"
}, {
    id: 4,
    name: "Политика отбора заявок"
}, {
    id: 5,
    name: "Пользовательское соглашение"
}, {
    id: 6,
    name: "Правила платформы"
}, {
    id: 7,
    name: "Согласие на обработку данных"
}, {
    id: 8,
    name: "Сведения о лицах, контролирующих оператора инвестиционной платформы"
}, {
    id: 9,
    name: "Сведения о лицах, имеющих право распоряжаться не менее чем 10 процентами голосов, приходящихся на голосующие акции (доли), составляющие уставный капитал оператора инвестиционной платформы"
}, {
    id: 10,
    name: "Сведения о структуре и персональном составе органов управления оператора инвестиционной платформы"
}, {
    id: 11,
    name: "Годовая бухгалтерская (финансовая) отчетность за последний завершенный отчетный год"
}, {
    id: 12,
    name: "Сведения о видах, размерах и сроках взимания платы за услуги оператора инвестиционной платформы, оказываемые участникам инвестиционной платформы"
}, {
    id: 13,
    name: "Справка об изменениях"
}, {
    id: 14,
    name: "Порядок действий, необходимых для присоединения к договору об оказании услуг по привлечению инвестиций, к договору об оказании услуг по содействию в инвестировании, и порядок действий, необходимых для инвестирования с использованием инвестиционной платформы"
}, {
    id: 15,
    name: "Порядок действий, которые могут быть предприняты инвестором в случае неисполнения обязательств лицом, привлекающим инвестиции"
}, {
    id: 16,
    name: "Срок восстановления функционирования инвестиционной платформы в случае нарушения ее функционирования"
}, {
    id: 17,
    name: "Информация о том, является ли признание оператором инвестиционной платформы гражданина квалифицированным инвестором необходимым условием для оказания ему услуг по содействию в инвестировании"
}, {
    id: 18,
    name: "Годовой отчет оператора инвестиционной платформы о результатах деятельности по организации привлечения инвестиций"
}, {
    id: 19,
    name: "Положение о конфликте интересов ООО «Крауд Инвест»"
}, {
    id: 20,
    name: "Раскрытие информации о выявленных конфликтах интересов и принятых мерах"
}];

class DocumentsList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            documents: [],
            applications: []
        }

        this.setDate = this.setDate.bind(this);
    }

    componentDidMount() {
        fetch("https://api.crowd-money.ru/documents", {
            method: 'POST'
        })
            .then(res => res.json())
            .then(
                (result) => {

                    this.setState({
                        isLoaded: true,
                        documents: result
                    })
                },
                (error) => {
                    console.log(error)
                }
            );

        fetch("https://api.crowd-money.ru/loans", {
            method: 'POST',
            body: JSON.stringify({
                data: {
                    type: "fundraisingCompleted"
                }
            })
        })
            .then(res => res.json())
            .then(
                (result) => {

                    this.setState({
                        isLoaded: true,
                        applications: result
                    })
                },
                (error) => {
                    console.log(error)
                }
            );
    }

    setDate = (date, addDays) => {
        const parseDate = date.split('.')
        let setOldDate = new Date(parseDate[2] + '/' + parseDate[1] + '/' + parseDate[0]);
        let newDate = new Date(setOldDate);

        newDate.setDate(newDate.getDate() + addDays);

        let day = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();

        if (day < 10) {
            day = '0' + day;
        }

        if (month < 10) {
            month = '0' + month;
        }

        return (day + '.' + month + '.' + year)
    };

    render() {
        const { isLoaded, documents, applications } = this.state;
        let olderVersions = ['от 29.01.2022 до 20.02.2022', 'от 04.12.2021 до 29.01.2022', 'от 10.07.2021 до 04.12.2021', 'от 16.05.2021 до 10.07.2021', 'от 16.03.2021 до 16.05.2021', 'от 10.03.2021 до 16.03.2021', 'от 29.02.2021 до 10.03.2021', 'от 28.12.2020 до 29.02.2021', 'от 24.11.2020 до 28.12.2020'].reverse();

        return (
            isLoaded && (
                <div className="tm-section-faq" id="tm-section-faq">
                    <div className="uk-width-1-1@s uk-margin-auto">
                        <ul data-uk-accordion>
                            <li>
                                <a className="uk-accordion-title" href="#">Политика отбора заявок</a>
                                <div className="uk-accordion-content uk-text-justify">
                                    <p className="uk-text-justify">
                                        Получить заемные средства на инвестиционной платформе «Crowd money» может только действующий бизнес, поэтому каждая заявка проходит через специальную систему отбора (скоринг-модель), в которой учитывается ряд показателей. Основной целью платформы является формирование объективной оценки заявки и установление процентной ставки для займа, с учетом возможных рисков неплатежеспособности заемщика. Аналитику платформы необходимо провести данный процесс, с учетом предоставленной информации заемщиком, а также учесть уровень его благонадежности и финансовой устойчивости. Организационно-правовая форма заемщика: общество с ограниченной ответственностью и индивидуальный предприниматель. Физические лица не могут выступать заемщиками.
                                    </p>
                                    <p className="uk-text-justify">
                                        Расчет скоринг-оценки производится для двух показателей заемщика:<br />
                                        1. Индекс благонадежности, который является совокупной оценкой заемщика (высокий, средний, низкий).<br />
                                        2. Индекс финансовой устойчивости - на основе бухгалтерского баланса и финансовых результатов заемщика.
                                    </p>
                                    <p className="uk-text-justify">
                                        Показатели отражаются в карточке заемщика и доступны для просмотра инвесторами платформы.<br />
                                        Балльное значение скоринг-оценки заемщика влияет на диапазон процентной ставки по займу.<br />
                                        После расчета балльного значения и подачи заявки заемщиком система рассчитывает диапазон процентной ставки по займу. Точное значение процентной ставки устанавливается после анализа заявки (проекта) аналитиком. Заемщик получает уведомление о проверке заявки и значении процентной ставки. После подписания инвестиционного предложения на привлечения средств по установленной ставке и условиям займа - платформа публикует заявку и осуществляет привлечение средств.
                                    </p>
                                    <a href={`/documents/${documents[3]['data'][0]}`} download className="uk-button uk-button-primary uk-margin-top">Скачать документ</a>
                                </div>
                            </li>
                            <li>
                                <a className="uk-accordion-title" href="#">Участнику платформы</a>
                                <div className="uk-accordion-content">
                                    <p className="uk-text-justify">
                                        Комиссия инвестиционной платформы «Crowd money» является одной из самых низких на рынке краудлендинговых услуг. Размер комиссии составляет от 3% до 4% от суммы займа по договору инвестирования. Удержание денежных средств в пользу платформы происходит при сборе всей необходимой для заемщика суммы. Плательщиком комиссии является заемщик, на расчетный счет которого средства по договору инвестирования поступают за вычетом комиссии инвестиционной платформы.
                                    </p>
                                    <p className="uk-text-justify">
                                        Для инвесторов - физических лиц, в т.ч. квалифицированных, при выводе денежных средств c лицевого счета инвестиционной платформы удерживается комиссия в размере 1% от суммы вывода, согласно тарифам кредитного учреждения (банка), в котором открыт номинальный счет.
                                    </p>
                                    <p className="uk-text-justify">
                                        Более подробно с Тарифами платформы Вы можете ознакомиться в документе ниже.
                                    </p>
                                    <div>
                                        <DocumentBox
                                            href={`/documents/${documents[11]['data'][0]}`}
                                            text="Сведения о видах, размерах и сроках взимания платы за услуги оператора инвестиционной платформы, оказываемые участникам инвестиционной платформы"
                                        />
                                        <DocumentBox
                                            href={`/documents/${documents[13]['data'][0]}`}
                                            text="Порядок действий, необходимых для присоединения к договору об оказании услуг по привлечению инвестиций, к договору об оказании услуг по содействию в инвестировании, и порядок действий, необходимых для инвестирования с использованием инвестиционной платформы"
                                        />
                                        <DocumentBox
                                            href={`/documents/${documents[14]['data'][0]}`}
                                            text="Порядок действий, которые могут быть предприняты инвестором в случае неисполнения обязательств лицом, привлекающим инвестиции"
                                        />
                                        <DocumentBox
                                            href={`/documents/${documents[15]['data'][0]}`}
                                            text="Срок восстановления функционирования инвестиционной платформы в случае нарушения ее функционирования"
                                        />
                                        <DocumentBox
                                            href={`/documents/${documents[16]['data'][0]}`}
                                            text="Информация о том, является ли признание оператором инвестиционной платформы гражданина квалифицированным инвестором необходимым условием для оказания ему услуг по содействию в инвестировании"
                                        />
                                        <DocumentBox
                                            href={`/documents/${documents[17]['data'][0]}`}
                                            text="Годовой отчет оператора инвестиционной платформы о результатах деятельности по организации привлечения инвестиций"
                                        />
                                        <DocumentBox
                                            href={`/documents/${documents[18]['data'][0]}`}
                                            text="Положение о конфликте интересов ООО «Крауд Инвест»"
                                        />
                                        <DocumentBox
                                            href={`/documents/${documents[19]['data'][0]}`}
                                            text="Раскрытие информации о выявленных конфликтах интересов и принятых мерах"
                                        />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <a className="uk-accordion-title" href="#">Правила платформы</a>
                                <div className="uk-accordion-content uk-text-justify">
                                    <p className="uk-text-justify">
                                        Правила осуществления деятельности на инвестиционной платформе «Crowd money» устанавливают основы информационно-технологического взаимодействия между Оператором Платформы и Пользователями в процессе использования представленного функционала Платформы «Crowd money», порядок присоединения Пользователей к Правилам и порядок заключения договоров, предусмотренных Правилами.
                                    </p>
                                    <p className="uk-text-justify">
                                        Правила инвестиционной платформы «Crowd money» разработаны в соответствии с Федеральным законом от 02.08.2019 № 259- ФЗ «О привлечении инвестиций с использованием инвестиционных платформ и о внесении изменений в отдельные законодательные акты Российской Федерации» и определяют:<br />
                                        - требования к участникам инвестиционной платформы;<br />
                                        - сроки передачи денежных средств участникам инвестиционной платформы с номинального счета, открытого оператору инвестиционной платформы;<br />
                                        - условия договора об оказании услуг по привлечению инвестиций и договора об оказании услуг по содействию в инвестировании;<br />
                                        - порядок присоединения к договору об оказании услуг по содействию в инвестировании.
                                    </p>
                                    <div data-uk-grid>
                                        <div>
                                            <div>Дата публикации:</div>
                                            <span>{documents[12]['date']}</span>
                                        </div>
                                        <div>
                                            <div>Дата вступления в силу:</div>
                                            <span>{this.setDate(documents[12]['date'], 5)}</span>
                                        </div>
                                        <div>
                                            <div>Справка об изменениях:</div>
                                            <a href={`/documents/${documents[12]['data'][0]}`} download>Скачать документ</a>
                                        </div>
                                    </div>
                                    <a href={`/documents/${documents[5]['data'][0]}`} download className="uk-button uk-button-primary uk-margin-top">Скачать документ</a>
                                </div>
                            </li>
                            <li>
                                <a className="uk-accordion-title" href="#">Прекращенные заявки</a>
                                <div className="uk-accordion-content">
                                    <p className="uk-text-justify">
                                        Перечень прекращенных инвестиционных предложений (заявок) в связи с достижением указанного максимального объема денежных средств.
                                    </p>
                                    <div class="uk-overflow-auto">
                                        <table className="uk-table uk-text-center">
                                            <thead>
                                                <tr>
                                                    <th className="uk-text-center uk-table-middle">Номер заявки</th>
                                                    <th className="uk-text-center uk-table-middle">Дата создания</th>
                                                    <th className="uk-text-center uk-table-middle">Дата прекращения</th>
                                                    <th className="uk-text-center uk-table-middle">Сумма займа</th>
                                                    <th className="uk-text-center uk-table-middle">ОКВЭД</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    applications.length > 0 && (
                                                        applications.reverse().map((item, key) => (
                                                            <tr key={key}>
                                                                <td>{item.id}</td>
                                                                <td>{item.date.create}</td>
                                                                <td>{item.date.close}</td>
                                                                <td>
                                                                    <CurrencyFormat value={item.collected} displayType={'text'} thousandSeparator={" "} /> ₽
                                                                </td>
                                                                <td>{item.okved}</td>
                                                            </tr>
                                                        ))
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </li>
                            <li>
                                <a className="uk-accordion-title" href="#">Оферта на заключение договора инвестирования</a>
                                <div className="uk-accordion-content uk-text-justify">
                                    <p className="uk-text-justify">
                                        Оферта (инвестиционное предложение) – предложение заемщика, адресованное определенному кругу лиц инвесторов.<br />
                                        Инвестиционное предложение содержит информацию о сроке действия такого предложения, минимальном и максимальном размере суммы займа, процентной ставке за пользование денежными средствами и иные условия.<br />
                                        Инвестиционное предложение является первоначальным документом при заключении соответствующего договора инвестирования между Заемщиком и Инвестором.
                                    </p>
                                    <p className="uk-text-justify">
                                        Заемщик признает, что инвесторы заключают договор инвестирования и предоставляют заемщику сумму займа, полностью полагаясь на заверения заемщика как переданные платформе и указанные в инвестиционном предложении (оферте), а также на заверения и гарантии, предоставленные лицами, заключившими договоры в обеспечение исполнения обязательств заемщика по договору инвестирования. Недостоверность указанных заверений является существенным нарушением условий договора инвестирования. В случае несоответствия действительности таких заверений и гарантий Заемщик до выражения воли на выставление Инвестиционного предложения (оферты) сообщить Оператору сделки о том, какие из заверений и гарантий не соответствуют действительности и по какой причине.
                                    </p>
                                    <p className="uk-text-justify">
                                        Договор инвестирования считается заключенным и полностью юридически обязательным к исполнению заемщиком и инвестором с момента акцепта сторонами заявления на присоединение к инвестиционном предложению. Каждая из сторон по договору инвестирования обязуется сохранять конфиденциальность финансовой, коммерческой и прочей информации, полученной в ходе исполнения договора инвестирования.
                                    </p>
                                    <a href={`/documents/${documents[1]['data'][0]}`} download className="uk-button uk-button-primary uk-margin-top">Скачать документ</a>
                                </div>
                            </li>
                            <li>
                                <a className="uk-accordion-title" href="#">Обработка данных</a>
                                <div className="uk-accordion-content uk-text-justify">
                                    <p className="uk-text-justify">
                                        Оператор инвестиционной платформы ООО «Крауд Инвест» в соответствии с законодательством Российской Федерации вправе совершить любое действие (операцию) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
                                    </p>
                                    <div>
                                        <DocumentBox
                                            href={`/documents/${documents[6]['data'][0]}`}
                                            text="Пользовательское соглашение"
                                        />
                                        <DocumentBox
                                            href={`/documents/${documents[20]['data'][0]}`}
                                            text="Политика обработки и защиты персональных данных"
                                        />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <a className="uk-accordion-title" href="#">Информация об операторе</a>
                                <div className="uk-accordion-content">
                                    <div className="uk-card uk-card-default">
                                        <div className="uk-card-header">
                                            <h3>Реквизиты номинального счета компании</h3>
                                        </div>
                                        <div className="uk-card-body">
                                            <div className="uk-grid-column-large uk-child-width-1-2@s uk-margin-medium-bottom" data-uk-grid>
                                                <div>
                                                    <div className="uk-margin">
                                                        <label className="uk-form-label">Наименование</label>
                                                        <input type="text" className="uk-input uk-disabled" value="ООО «Крауд Инвест»" disabled />
                                                    </div>
                                                    <div className="uk-margin">
                                                        <label className="uk-form-label">Счёт</label>
                                                        <input type="text" className="uk-input uk-disabled" value="40702810720020100003" disabled />
                                                    </div>
                                                    <div className="uk-margin">
                                                        <label className="uk-form-label">Банк</label>
                                                        <input type="text" className="uk-input uk-disabled" value="ТКБ БАНК ПАО, г.Москва" disabled />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="uk-margin">
                                                        <label className="uk-form-label">ИНН</label>
                                                        <input type="text" className="uk-input uk-disabled" value="7713475803" disabled />
                                                    </div>
                                                    <div className="uk-margin">
                                                        <label className="uk-form-label">БИК</label>
                                                        <input type="text" className="uk-input uk-disabled" value="044525388" disabled />
                                                    </div>
                                                    <div className="uk-margin">
                                                        <label className="uk-form-label">К/С
                                                        </label>
                                                        <input type="text" className="uk-input uk-disabled" value="30101810800000000388" disabled />
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <p>
                                                    С 1 января 2020 года все инвестиционные платформы обязаны использовать номинальный счёт. Это необходимо для защиты средств пользователей. Новые правила предусмотрены законом № 259-ФЗ «О привлечении инвестиций с помощью инвестиционных платформ и о внесении изменений в отдельные законодательные акты Российской Федерации».
                                                </p>
                                                <p>
                                                    Номинальный счёт — это банковский счёт, на котором хранятся деньги инвесторов и только с их распоряжения перечисляются платформой на счет заемщика. Никто не может присвоить средства инвестора с номинального счёта.
                                                </p>
                                                <p>
                                                    Номинальный счет призван защитить максимально средства инвесторов.
                                                </p>
                                                <p>
                                                    Платформа контролирует движение денежных средств между лицевыми счетами пользователей, учет и движение денежных средств инвесторов и заемщиков, распределение денежных средств, пополнение номинального счета.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="uk-card uk-card-default">
                                        <div className="uk-card-header">
                                            <h3>
                                                Карточка и устав компании
                                            </h3>
                                        </div>
                                        <div className="uk-card-body">
                                            <div className="uk-grid-column-large uk-child-width-1-2@s uk-margin-medium-bottom" data-uk-grid>
                                                <div className="uk-width-expand@m">
                                                    <div className="uk-margin">
                                                        <label className="uk-form-label">Наименование</label>
                                                        <input type="text" className="uk-input uk-disabled" value="ООО «Крауд Инвест»" disabled />
                                                    </div>
                                                    <div className="uk-margin">
                                                        <label className="uk-form-label">Юридичекий адрес</label>
                                                        <textarea type="text" className="uk-textarea uk-disabled" value="127254, г. Москва, вн. тер. г. муниципальный округ Бутырский, ул. Добролюбова, д. 18, помещ. 3/1" disabled/>
                                                    </div>
                                                    <div className="uk-margin">
                                                        <label className="uk-form-label">Фактический адрес (место нахождения)</label>
                                                        <textarea type="text" className="uk-textarea uk-disabled" value="127254, г. Москва, вн. тер. г. муниципальный округ Бутырский, ул. Добролюбова, д. 18, помещ. 3/1" disabled/>
                                                    </div>
                                                </div>
                                                <div className="uk-width-1-3@s">
                                                    <div className="uk-margin">
                                                        <label className="uk-form-label">ИНН</label>
                                                        <input type="text" className="uk-input uk-disabled" value="7713475803" disabled />
                                                    </div>
                                                    <div className="uk-margin">
                                                        <label className="uk-form-label">КПП</label>
                                                        <textarea type="text" className="uk-textarea uk-disabled" value="771501001" disabled />
                                                    </div>
                                                    <div className="uk-margin">
                                                        <label className="uk-form-label">ОГРН</label>
                                                        <textarea type="text" className="uk-textarea uk-disabled" value="1207700187793" disabled />
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <p>
                                                    Цель нашей компании - создать благоприятную деловую среду для надежных и выгодных инвестиций в малый и средний бизнес.
                                                </p>
                                                <p>
                                                    Основные виды деятельности компании:<br />
                                                    - деятельность по предоставлению консультационных услуг по вопросам финансового посредничества;<br />
                                                    - деятельность консультативная и работы в области компьютерных технологий;<br />
                                                    -  деятельность по обработке данных, предоставление услуг по размещению информации и связанная с этим деятельность;<br />
                                                    - капиталовложения в уставные капиталы, венчурное инвестирование, в том числе посредством инвестиционных компаний.
                                                </p>
                                                <div>
                                                    <DocumentBox
                                                        href={`/documents/${documents[0]['data'][0]}`}
                                                        text="Устав оператора инвестиционной платформы"
                                                    />
                                                    <DocumentBox
                                                        href={`/documents/${documents[7]['data'][0]}`}
                                                        text="Сведения о лицах, контролирующих оператора инвестиционной платформы"
                                                    />
                                                    <DocumentBox
                                                        href={`/documents/${documents[8]['data'][0]}`}
                                                        text="Сведения о лицах, имеющих право распоряжаться не менее чем 10 процентами голосов, приходящихся на голосующие акции (доли), составляющие уставный капитал оператора инвестиционной платформы"
                                                    />
                                                    <DocumentBox
                                                        href={`/documents/${documents[9]['data'][0]}`}
                                                        text="Сведения о структуре и персональном составе органов управления оператора инвестиционной платформы"
                                                    />
                                                    <DocumentBox
                                                        href={`/documents/${documents[10]['data'][0]}`}
                                                        text="Годовая бухгалтерская (финансовая) отчетность за последний завершенный отчетный год"
                                                    />
                                                    <DocumentBox
                                                        href={`/documents/${documents[11]['data'][0]}`}
                                                        text="Сведения о видах, размерах и сроках взимания платы за услуги оператора инвестиционной платформы, оказываемые участникам инвестиционной платформы"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <a className="uk-accordion-title" href="#">Архив</a>
                                <div className="uk-accordion-content">
                                    {
                                        documentNames.map((item, key) => (
                                            <div className="uk-card uk-card-default uk-card-border uk-margin">
                                                <div className="uk-card-header">
                                                    <h4>
                                                        {item.name}
                                                    </h4>
                                                </div>
                                                <div className="uk-card-body uk-text-small">
                                                    {
                                                        documents.map((document, documentKey) => (
                                                            document.id === item.id && (
                                                                document.data.length > 1 ?
                                                                    document.data.map((dataItem, key) => (
                                                                        key > 0 && (
                                                                            // <div className="uk-margin-small">
                                                                            //     <div className="uk-text-muted">Прежняя версия</div>
                                                                            //     {documentKey === 5 && olderVersions[key - 1]}
                                                                            //     <div className="uk-margin-small-top">
                                                                            //         <a href={`/documents/${dataItem}`} download>Скачать документ</a>
                                                                            //     </div>
                                                                            // </div>

                                                                            <DocumentBox
                                                                                className="tm-document-pdf-box tm-document-pdf-boxes"
                                                                                href={`/documents/${dataItem}`}
                                                                                text={`Прежняя версия ${documentKey === 5 ? olderVersions[key - 1] : ""}`}
                                                                            />
                                                                        ))
                                                                    ) : 'Информация отсутствует'
                                                            )
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </li>
                        </ul>
                    </div>
                </div >
            )
        )
    }
}
export default DocumentsList;