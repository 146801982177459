import React from 'react';
import InputMask from "react-input-mask";
import NumberFormat from 'react-number-format';
import store from "../../../../../store";
import RequestVerification from "../Verification";
import AgreementRequestBKI from '../../../../../partials/others/AgreementRequestBKI';

const loanTypes = {
    1: {title: "Займ на исполнение государственного контракта"},
    2: {title: "Займ на развитие бизнеса"},
    3: {title: "Займ на пополнение оборотных средств"}
};

class FormApplicationLoan extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            documents: [],
            activeSpinner: false,
            profile: this.props.profile,
            formType: this.props.type,
            currentStep: 1,
            application: {
                loanType: 1,
                companyName: null,
                projectName: null, 
                repaymentType: 1,
                documents: [],
                ...props.project.data
            }
        };


        this.handleChange = this.handleChange.bind(this);
        this.changeLoanType = this.changeLoanType.bind(this);
        this.changeRepaymentType = this.changeRepaymentType.bind(this);

        this.uploadFile = this.uploadFile.bind(this);
        this.removeFile = this.removeFile.bind(this);

        this.save = this.save.bind(this);
    }

    componentDidMount() {
        fetch("https://api.crowd-money.ru/documents", {
            method: 'POST'
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({                   
                    isLoaded: true,
                    documents: result
                })
            },
            (error) => {
                console.log(error)
            }
        );
    }

    handleChange = (e) => {
        this.setState({
            application: {
                ...this.state.application,
                [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value
            }
        })
    };

    changeLoanType = (loanType) => {
        this.setState({
            application: {
                ...this.state.application,
                loanType: loanType
            }
        })
    };

    changeRepaymentType = (repaymentType) => {
        this.setState({
            application: {
                ...this.state.application,
                repaymentType: repaymentType
            }
        })
    };

    uploadFile = (e) => {
        let documents = this.state.application.documents;

        documents.push({
            file: e.target.files[0],
            name: e.target.files[0].name
        });

        this.setState({
            application: {
                ...this.state.application,
                documents: documents
            }
        })
    };

    removeFile = (fileIndex) => {
        this.setState({
            application: {
                ...this.state.application,
                documents: this.state.application.documents.filter((document, index) => index !== fileIndex)
            }
        })
    };

    save = () => {
        const $this = this;
        const formType = this.state.formType;
        const formData = new FormData();
        let url;
        let action;
        let projectId;

        this.setState({
            activeSpinner: true
        });

        if (formType === "create") {
            url = "https://api.crowd-money.ru/loans/request";
            action = "request";
            projectId = null;
        }
        else if (formType === "edit") {
            url = "https://api.crowd-money.ru/loans/update";
            action = "projectUpdate";
            projectId = this.props.project.id
        }

        store.dispatch({
            type: "HIDE.SNACKBAR"
        });

        formData.append('action', action);
        formData.append('step', this.state.currentStep);
        formData.append('data[projectId]', projectId);
        formData.append('data[profileId]', this.state.profile.access.id);
        formData.append('data[application][loanType]', this.state.application.loanType);

        if (this.state.application.companyName) {
            formData.append('data[application][companyName]', this.state.application.companyName);
        }

        if (this.state.application.contractNumber) {
            formData.append('data[application][contractNumber]', this.state.application.contractNumber);
        }

        if (this.state.application.purchaseObject) {
            formData.append('data[application][purchaseObject]', this.state.application.purchaseObject);
        }

        if (this.state.application.urlObject) {
            formData.append('data[application][urlObject]', this.state.application.urlObject);
        }

        if (this.state.application.contractAmount) {
            formData.append('data[application][contractAmount]', this.state.application.contractAmount);
        }

        if (this.state.application.contractDateStart) {
            formData.append('data[application][contractDateStart]', this.state.application.contractDateStart);
        }

        if (this.state.application.contractDateEnd) {
            formData.append('data[application][contractDateEnd]', this.state.application.contractDateEnd);
        }

        if (this.state.application.amountTotal) {
            formData.append('data[application][amountTotal]', this.state.application.amountTotal);
        }

        if (this.state.application.amountStart) {
            formData.append('data[application][amountStart]', this.state.application.amountStart);
        }

        if (this.state.application.amountEnd) {
            formData.append('data[application][amountEnd]', this.state.application.amountEnd);
        }

        formData.append('data[application][duration]', this.state.application.duration);        
        formData.append('data[application][repaymentType]', this.state.application.repaymentType);
        formData.append('data[application][companyAbout]', this.state.application.companyAbout);

        if (this.state.application.companyWebsite) {
            formData.append('data[application][companyWebsite]', this.state.application.companyWebsite);
        }

        formData.append('data[application][checkingAccount]', this.state.application.checkingAccount);
        formData.append('data[application][bik]', this.state.application.bik);
        formData.append('data[application][correspondentAccount]', this.state.application.correspondentAccount);
        formData.append('data[application][projectName]', this.state.application.projectName);

        if (this.state.application.documents.length > 0) {
            this.state.application.documents.map((document, index) => {
                formData.append('data[application][documents]['+index+'][file]', document.file);
                formData.append('data[application][documents]['+index+'][name]', document.name);
            })
        }

        if (this.state.application.agreementInvestmentProposal) {
            formData.append('data[application][agreementInvestmentProposal]', this.state.application.agreementInvestmentProposal);
        }

        if (this.state.application.agreementConclusion) {
            formData.append('data[application][agreementConclusion]', this.state.application.agreementConclusion);
        }

        if (this.state.application.sentCode) {
            formData.append('data[application][sentCode]', this.state.application.sentCode);
        }

        if (this.state.application.verifyCode) {
            formData.append('data[application][verifyCode]', this.state.application.verifyCode);
        }

        fetch(url, {
            method: 'POST',
            body: formData
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    activeSpinner: false
                });

                if (result.message) {
                    store.dispatch({
                        type: "SHOW.SNACKBAR",
                        payload: {
                            status: result.status,
                            message: result.message
                        }
                    });
                }

                if (result.field) {
                    $this[result.field].focus();
                    return false;
                }

                if (result.status === "success") {
                    if (formType === "edit") {
                        this.setState({
                            currentStep: 4
                        });
                        return false;
                    }

                    if (this.state.currentStep === 1) {
                        this.setState({
                            currentStep: 2
                        })
                    }
                    else if (this.state.currentStep === 2) {
                        this.setState({
                            currentStep: 3,
                            application: {
                                ...this.state.application,
                                sentCode: result.sentCode
                            }
                        });
                    }
                    else if (this.state.currentStep === 3) {
                        this.setState({
                            currentStep: 4
                        })
                    }
                }
            },
            (error) => {              
                console.log(error)
            }
        );
    };

    render() {
        const {isLoaded, documents, activeSpinner} = this.state;
        const {profile, formType, application, currentStep} = this.state;

        return (
            isLoaded && (
                <>
                    {
                        currentStep === 1 && (
                            <>
                                <h3 className="uk-h3 tm-font-weight-5">{loanTypes[application.loanType].title}</h3>
                                <div className="uk-text-small uk-text-muted uk-margin-medium-bottom">
                                    {
                                        formType === "create" ? "Новая заявка" : "Редактирование заявки"
                                    }
                                </div>
                                <div className="uk-width-2-3@s">
                                    <h4 className="uk-h4">Шаг 1: тип займа</h4>
                                    <div>
                                        <label className="tm-radio">
                                            <input
                                                className="uk-radio"
                                                type="radio"
                                                name="loanType"
                                                checked={application.loanType === 1 && true}
                                                onChange={() => (this.changeLoanType(1))}
                                            />
                                            <span>Исполнение государственного контракта</span>
                                        </label>
                                    </div>
                                    <div className="uk-margin">
                                        <label className="tm-radio">
                                            <input
                                                className="uk-radio"
                                                type="radio"
                                                name="loanType"
                                                checked={application.loanType === 2 && true}
                                                onChange={() => (this.changeLoanType(2))}
                                            />
                                            <span>Развитие бизнеса</span>
                                        </label>
                                    </div>
                                    <div>
                                        <label className="tm-radio">
                                            <input
                                                className="uk-radio"
                                                type="radio"
                                                name="loanType"
                                                checked={application.loanType === 3 && true}
                                                onChange={() => (this.changeLoanType(3))}
                                            />
                                            <span>Пополнение оборотных средств</span>
                                        </label>
                                    </div>
                                    {
                                        application.loanType === 1 && (
                                            <>
                                                <h4 className="uk-h4">Шаг 2: данные о госконтракте</h4>
                                                <div className="uk-margin">
                                                    <label className="uk-form-label">Заказчик</label>
                                                    <input
                                                        type="text"
                                                        maxLength={48}
                                                        placeholder="ООО «Ромашка»"
                                                        className="uk-input"
                                                        name="companyName"
                                                        defaultValue={application.companyName}
                                                        ref={(input) => {this.companyName = input}}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                                <div className="uk-margin">
                                                    <label className="uk-form-label">Реестровый номер контракта</label>
                                                    <InputMask mask="9999999999999999999" onChange={this.handleChange}>
                                                        <input
                                                            type="text"
                                                            className="uk-input"
                                                            name="contractNumber"
                                                            defaultValue={application.contractNumber}
                                                            ref={(input) => {this.contractNumber = input}}
                                                        />
                                                    </InputMask>
                                                </div>
                                                <div className="uk-margin">
                                                    <label className="uk-form-label">Объект закупки</label>
                                                    <input
                                                        type="text"
                                                        maxLength={256}
                                                        className="uk-input"
                                                        name="purchaseObject"
                                                        defaultValue={application.purchaseObject}
                                                        ref={(input) => {this.purchaseObject = input}}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>                                                
                                                <div data-uk-grid className="uk-margin uk-child-width-1-3@s">
                                                    <div>
                                                        <label className="uk-form-label">Сумма</label>
                                                        <input
                                                            type="text"
                                                            className="uk-input"
                                                            name="contractAmount"
                                                            defaultValue={application.contractAmount}
                                                            ref={(input) => {this.contractAmount = input}}
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                    <div>
                                                        <label className="uk-form-label">Дата заключения</label>
                                                        <InputMask mask="99.99.9999" onChange={this.handleChange}>
                                                            <input
                                                                type="text"
                                                                className="uk-input"
                                                                name="contractDateStart"
                                                                defaultValue={application.contractDateStart}
                                                                ref={(input) => {this.contractDateStart = input}}
                                                            />
                                                        </InputMask>
                                                    </div>
                                                    <div>
                                                        <label className="uk-form-label">Дата исполнения</label>
                                                        <InputMask mask="99.99.9999" onChange={this.handleChange}>
                                                            <input
                                                                type="text"
                                                                className="uk-input"
                                                                name="contractDateEnd"
                                                                defaultValue={application.contractDateEnd}
                                                                ref={(input) => {this.contractDateEnd = input}}
                                                            />
                                                        </InputMask>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                    <h4 className="uk-h4">Шаг {application.loanType === 1 ? 3 : 2}: требуемая сумма</h4>
                                    <div data-uk-grid className="uk-margin uk-child-width-1-2@s">
                                        <div>
                                            <input
                                                type="text"
                                                placeholder="Минимальная сумма займа"
                                                className="uk-input"
                                                name="amountStart"
                                                defaultValue={application.amountStart}
                                                ref={(input) => {this.amountStart = input}}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div>
                                            <input
                                                type="text"
                                                placeholder="Максимальная сумма займа"
                                                className="uk-input"
                                                name="amountEnd"
                                                defaultValue={application.amountEnd}
                                                ref={(input) => {this.amountEnd = input}}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <h4 className="uk-h4">Шаг {application.loanType === 1 ? 4 : 3}: срок займа в месяцах</h4>
                                    <div className="uk-margin uk-width-1-6@s">
                                        <select
                                            className="uk-select"
                                            name="duration"
                                            defaultValue={application.duration}
                                            ref={(input) => {this.duration = input}}
                                            onChange={this.handleChange}
                                        >
                                            <option value="0">Не выбран</option>
                                            <option value="1">1</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                        </select>
                                    </div>
                                    <h4 className="uk-h4">Шаг {application.loanType === 1 ? 5 : 4}: тип погашения основного долга</h4>
                                    <div className="uk-margin">
                                        <label className="tm-radio">
                                            <input
                                                className="uk-radio"
                                                type="radio"
                                                name="repaymentType"
                                                checked={application.repaymentType === 1 && true}
                                                onChange={() => (this.changeRepaymentType(1))}
                                            />
                                            <span>В конце срока (если срок до 3 месяцев)</span>
                                        </label>
                                    </div>
                                    <div className="uk-margin">
                                        <label className="tm-radio">
                                            <input
                                                className="uk-radio"
                                                type="radio"
                                                name="repaymentType"
                                                checked={application.repaymentType === 2 && true}
                                                onChange={() => (this.changeRepaymentType(2))}
                                            />
                                            <span>Ежеквартально (если срок 3 месяца и более)</span>
                                        </label>
                                    </div>
                                    <div className="uk-margin">
                                        <label className="tm-radio">
                                            <input
                                                className="uk-radio"
                                                type="radio"
                                                name="repaymentType"
                                                checked={application.repaymentType === 3 && true}
                                                onChange={() => (this.changeRepaymentType(3))}
                                            />
                                            <span>Ежемесячно</span>
                                        </label>
                                    </div>
                                    <div className="uk-margin">
                                        <label className="tm-radio">
                                            <input
                                                className="uk-radio"
                                                type="radio"
                                                name="repaymentType"
                                                checked={application.repaymentType === 4 && true}
                                                onChange={() => (this.changeRepaymentType(4))}
                                            />
                                            <span>Ежемесячно (аннуитет)</span>
                                        </label>
                                    </div>
                                    <h4 className="uk-h4">Шаг {application.loanType === 1 ? 6 : 5}: о бизнесе</h4>                                   
                                    <div className="uk-margin">
                                        <textarea
                                            className="uk-textarea uk-margin-small-bottom"
                                            placeholder="Расскажите нам немного о вашем бизнесе. Это даст нам больше представления :)"
                                            maxLength={1500}
                                            rows={8}
                                            name="companyAbout"
                                            defaultValue={application.companyAbout}
                                            ref={(input) => {this.companyAbout = input}}
                                            onChange={this.handleChange}
                                        />
                                        <div className="uk-text-small uk-text-muted uk-text-right">
                                            Не более 1500 символов
                                        </div>
                                    </div>
                                    <div className="uk-margin">
                                        <label className="uk-form-label">Если у вас есть сайт, вы можете его указать</label>
                                        <input
                                            type="text"
                                            maxLength={48}
                                            className="uk-input"
                                            name="companyWebsite"
                                            defaultValue={application.companyWebsite}
                                            ref={(input) => {this.companyWebsite = input}}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <h4 className="uk-h4">Шаг {application.loanType === 1 ? 7 : 6}: реквизиты банковского счёта</h4>
                                    <div className="uk-margin">
                                        <label className="uk-form-label">Расчётный счёт</label>
                                        <InputMask mask="99999999999999999999" onChange={this.handleChange}>
                                            <input
                                                type="text"
                                                className="uk-input"
                                                name="checkingAccount"
                                                defaultValue={application.checkingAccount}
                                                ref={(input) => {this.checkingAccount = input}}
                                            />
                                        </InputMask>
                                    </div>
                                    <div className="uk-margin">
                                        <label className="uk-form-label">БИК Банка</label>
                                        <InputMask mask="999999999" onChange={this.handleChange}>
                                            <input
                                                type="text"
                                                className="uk-input"
                                                name="bik"
                                                defaultValue={application.bik}
                                                ref={(input) => {this.bik = input}}
                                            />
                                        </InputMask>
                                    </div>
                                    <div className="uk-margin">
                                        <label className="uk-form-label">Корреспондентский счет банка</label>
                                        <InputMask mask="99999999999999999999" onChange={this.handleChange}>
                                            <input
                                                type="text"
                                                className="uk-input"
                                                name="correspondentAccount"
                                                defaultValue={application.correspondentAccount}
                                                ref={(input) => {this.correspondentAccount = input}}
                                            />
                                        </InputMask>
                                    </div>
                                    <h4 className="uk-h4">Шаг {application.loanType === 1 ? 8 : 7}: название проекта</h4>
                                    <div className="uk-margin">
                                        <input
                                            type="text"
                                            maxLength={48}
                                            className="uk-input"
                                            name="projectName"
                                            defaultValue={application.projectName}
                                            ref={(input) => {this.projectName = input}}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <h4 className="uk-h4">Шаг {application.loanType === 1 ? 9 : 8}: согласие на запрос в бюро кредитных историй</h4>
                                    <div className="uk-text-small uk-text-muted">
                                        скачайте сформированный файл согласия на запрос в бюро кредитных историй, подпишите электронной цифровой подписью и загрузите вместе с файлом подписи (sig) к заявке ниже, либо отправьте на нашу электронную почту info@crowd-money.ru
                                    </div>
                                    <div className="uk-margin">
                                    <AgreementRequestBKI 
                                        profileId={profile.access.id}
                                        element={<a class="uk-link uk-text-primary">Скачать файл согласия на запрос в БКИ (нажмите на ссылку)</a>}
                                    />                                   
                                    </div>                                    
                                    <h4 className="uk-h4">Шаг {application.loanType === 1 ? 10 : 9}: загрузите фотографии и документы к проекту</h4>
                                    <div className="uk-text-small uk-text-muted">
                                    {
                                        profile.entrepreneurData.type === 2 && (<>Налоговая декларация с отметкой ФНС за последний завершенный год, <br/>выписки по расчетным счетам и/или карточка 51 счета с начала года по текущую дату (Excel)</>)
                                    }
                                    {   
                                        profile.entrepreneurData.type === 3 && (<>Устав, Решение о назначении ГД, БФО за последний квартал, карточка 51 счета с начала года по текущую дату (Excel)</>)
                                    }                                    
                                    </div>
                                    <div className="tm-upload-documents uk-margin" data-uk-grid>
                                        <div className="uk-width-auto">
                                            <div data-uk-form-custom className="tm-button-upload-file">
                                                <i data-uk-icon="icon: link; ratio: 5;" />
                                                <input
                                                    type="file"
                                                    accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"
                                                    onChange={this.uploadFile}
                                                />
                                            </div>
                                        </div>
                                        {
                                            application.documents.length > 0 && (
                                                application.documents.map((document, index) => (
                                                    <div className="uk-width-auto">
                                                        <div className="tm-file">
                                                            <i data-uk-icon="icon: close; ratio: 5;" className="tm-button-remove-file" onClick={() => this.removeFile(index)}/>
                                                            <span className="tm-filename">{document.name}</span>
                                                        </div>
                                                    </div>
                                                ))
                                            )
                                        }
                                    </div>
                                    <div className="uk-margin-medium-top">
                                        <div className="uk-button uk-button-primary" onClick={this.save}>
                                            {
                                                formType === "create" ? "Далее" : "Сохранить изменения"
                                            }
                                        </div>                                        
                                    </div>
                                </div>                               
                            </>
                        )
                    }
                    {
                        (currentStep === 2 || currentStep === 3) && (
                            <>
                                <h3 className="uk-h3 uk-margin-medium-bottom tm-font-weight-5">Договор займа</h3>
                                <div className="uk-card uk-card-default">
                                    <div className="uk-card-header">
                                        Публикация инвестиционного предложения (заявки) и заключение договора займа
                                    </div>
                                    <div className="uk-card-body">
                                        {
                                            currentStep === 2 && (
                                                <>
                                                    Согласившись с публикацией инвестиционного соглашения Вы подтверждаете, что все данные, которые указаны в инвестиционном предложении – верны. Также, Вы подтверждаете, что согласившись с публикацией инвестиционного предложения, все пользователи, зарегистрированные в качестве инвесторов, могут видеть Вашу заявку на привлечение денежных средств. При сборе необходимой суммы будет заключен договор займа, а средства поступят на Ваш банковский счет, указанный в реквизитах.
                                                    <div className="uk-margin-top">
                                                        <label className="tm-checkbox">
                                                            <input
                                                                className="uk-checkbox"
                                                                type="checkbox"
                                                                name="agreementInvestmentProposal"
                                                                onChange={this.handleChange}
                                                            />
                                                            <span>Я согласен(на) с публикацией инвестиционного предложения на платформе</span>
                                                        </label>
                                                    </div>
                                                    <div className="uk-margin">
                                                        <label className="tm-checkbox">
                                                            <input
                                                                className="uk-checkbox"
                                                                type="checkbox"
                                                                name="agreementConclusion"
                                                                onChange={this.handleChange}
                                                            />
                                                            <span>Я согласен(на) с последующим заключением договора займа с инвесторами</span>
                                                        </label>
                                                    </div>
                                                    <div className="uk-margin-medium-top">
                                                        <a href={`/documents/${documents[1]['data'][0]}`} download className="uk-button uk-button-primary uk-margin-medium-right">Скачать договор</a>
                                                        <div className="uk-button uk-button-primary" onClick={this.save}>Подписать с SMS</div>
                                                    </div>                                                   
                                                </>
                                            )
                                        }
                                        {
                                            currentStep === 3 && (
                                                <div className="uk-width-2-5@s">
                                                    <h4 className="uk-h4 uk-text-light">
                                                        Мы отправили код подтверждения
                                                        на<br/> номер <NumberFormat value={profile.mainData.phone} displayType={'text'} format="+#(###)###-##-##" />
                                                    </h4>
                                                    <div className="uk-margin">
                                                        <InputMask mask="9999" onChange={this.handleChange}>
                                                            <input
                                                                type="text"
                                                                placeholder="Код подтверждения"
                                                                className="uk-input"
                                                                name="verifyCode"
                                                                ref={(input) => {
                                                                    this.verifyCode = input
                                                                }}
                                                            />
                                                        </InputMask>
                                                    </div>
                                                    <div className="uk-margin-medium-top">
                                                        <div className="uk-button uk-button-primary" onClick={this.save}>Далее</div>
                                                    </div>                                                   
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </>
                        )
                    }
                    {
                        currentStep === 4 && (
                            <RequestVerification />
                        )
                    }    
                    {
                        activeSpinner && (
                            <div className="tm-spinner">
                                <span data-uk-spinner="ratio: 3" />
                            </div>
                        )
                    }                                    
                </>
            )
        )
    }
}

export default FormApplicationLoan;