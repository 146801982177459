import React from 'react';
import CurrencyValue from '../../partials/others/CurrencyValue';
import ChangeBalancePanel from '../../partials/others/ChangeBalancePanel';
import { Link } from "react-router-dom";
import store from "../../store";
import UIkit from 'uikit'
import { connect } from "react-redux";
import InputMask from "react-input-mask";
import NumberFormat from 'react-number-format';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { DataGrid, ruRU, GridToolbarExport, GridToolbarContainer } from '@material-ui/data-grid';
import BasicTabs from '../../partials/others/TabPanel';

const theme = createTheme(
    {
        typography: {
            fontFamily: [
                'Montserrat',
                'sans-serif'
            ].join(',')
        }
    },
    ruRU
);

function CustomToolbar(tabFileName, exportFields) {
    let date = new Date();

    return (
        <GridToolbarContainer>
            <GridToolbarExport
                csvOptions={{
                    fileName: `${tabFileName} ${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`,
                    delimiter: ';',
                    utf8WithBom: true,
                    includeHeaders: true,
                    fields: exportFields
                }}
            />
        </GridToolbarContainer>
    );
}

const documentNames = [{
    id: 1,
    name: "Действующий устав оператора инвестиционной платформы"
}, {
    id: 2,
    name: "Договор инвестирования"
}, {
    id: 3,
    name: "Заявление о присоединении"
}, {
    id: 4,
    name: "Политика отбора заявок"
}, {
    id: 5,
    name: "Пользовательское соглашение"
}, {
    id: 6,
    name: "Правила платформы"
}, {
    id: 7,
    name: "Согласие на обработку данных"
}, {
    id: 8,
    name: "Сведения о лицах, контролирующих оператора инвестиционной платформы"
}, {
    id: 9,
    name: "Сведения о лицах, имеющих право распоряжаться не менее чем 10 процентами голосов, приходящихся на голосующие акции (доли), составляющие уставный капитал оператора инвестиционной платформы"
}, {
    id: 10,
    name: "Сведения о структуре и персональном составе органов управления оператора инвестиционной платформы"
}, {
    id: 11,
    name: "Годовая бухгалтерская (финансовая) отчетность за последний завершенный отчетный год"
}, {
    id: 12,
    name: "Сведения о видах, размерах и сроках взимания платы за услуги оператора инвестиционной платформы, оказываемые участникам инвестиционной платформы"
}, {
    id: 13,
    name: "Справка об изменениях"
}, {
    id: 14,
    name: "Порядок действий, необходимых для присоединения к договору об оказании услуг по привлечению инвестиций, к договору об оказании услуг по содействию в инвестировании, и порядок действий, необходимых для инвестирования с использованием инвестиционной платформы"
}, {
    id: 15,
    name: "Порядок действий, которые могут быть предприняты инвестором в случае неисполнения обязательств лицом, привлекающим инвестиции"
}, {
    id: 16,
    name: "Срок восстановления функционирования инвестиционной платформы в случае нарушения ее функционирования"
}, {
    id: 17,
    name: "Информация о том, является ли признание оператором инвестиционной платформы гражданина квалифицированным инвестором необходимым условием для оказания ему услуг по содействию в инвестировании"
}, {
    id: 18,
    name: "Годовой отчет оператора инвестиционной платформы о результатах деятельности по организации привлечения инвестиций"
}, {
    id: 19,
    name: "Положение о конфликте интересов ООО «Крауд Инвест»"
}, {
    id: 20,
    name: "Раскрытие информации о выявленных конфликтах интересов и принятых мерах"
}, {
    id: 21,
    name: "Политика обработки и защиты персональных данных"
}];

class Balance extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            documents: [],
            currentStep: 1,
            activeSpinner: false,
            profile: this.props.profile,
            projects: [],
            profiles: {
                1: [],
                2: [],
                3: []
            },
            partners: [],
            payments: [],
            fundraisingCompletedApplications: [],
            fca: {
                id: false,
                okved: false
            },
            balanceTotal: 0,
            changeBalanceModal: {
                func: null,
                newBalance: 0
            },
            fields: {
                sentCode: ""
            },
            countVerificationProfiles: 0,
            countVerificationProjects: 0,
            countVerificationPayments: 0,
        };

        this.handleVerificationSave = this.handleVerificationSave.bind(this);
        this.createAgreementDocument = this.createAgreementDocument.bind(this);
        this.changeEntrepreneurCategory = this.changeEntrepreneurCategory.bind(this);
        this.changeAgreement = this.changeAgreement.bind(this);
        this.changeBalance = this.changeBalance.bind(this);
        this.handleChangeBalance = this.handleChangeBalance.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.changePaymentStatus = this.changePaymentStatus.bind(this);
        this.changeDocument = this.changeDocument.bind(this);
        this.changeFca = this.changeFca.bind(this);
        this.fca = this.fca.bind(this);
    }

    async componentDidMount() {
        const profileHash = localStorage.getItem("profileHash");

        fetch("https://api.crowd-money.ru/documents", {
            method: 'POST'
        })
            .then(res => res.json())
            .then(
                (result) => {

                    this.setState({
                        isLoaded: true,
                        documents: result
                    })
                },
                (error) => {
                    console.log(error)
                }
            );

        if (profileHash) {
            await fetch("https://api.crowd-money.ru/profile", {
                method: 'POST',
                body: JSON.stringify({
                    data: {
                        profileHash: profileHash
                    }
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        if (!result) {
                            localStorage.removeItem('profileHash');
                            this.props.history.push('/');
                        }

                        this.setState({
                            profile: result
                        })
                    },
                    (error) => {
                        console.log(error)
                    }
                );
        }

        if (this.state.profile.access.authorized === false) {
            this.props.history.push('/');
        }
        else {
            if (this.state.profile.access.group != 0 && this.state.profile.verification.length != 0) {
                this.props.history.push('/main');
            }

            store.dispatch({
                type: 'CABINET.INDEX',
                payload: {
                    profile: this.state.profile
                }
            });

            if (this.state.profile.access.group === 0) {
                fetch("https://api.crowd-money.ru/loans/message", {
                    method: 'POST',
                    body: JSON.stringify({
                        action: "getCountNoReadMsg",
                        data: {
                            profileId: this.state.profile.access.id
                        }
                    })
                })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result) {
                                this.setState({
                                    ...this.state,
                                    msgCountData: result
                                })
                            }
                        },
                        (error) => {
                            console.log(error)
                        }
                    );

                fetch("https://api.crowd-money.ru/loans", {
                    method: 'POST',
                    body: JSON.stringify({
                        data: {
                            type: "cabinet",
                            profileId: this.state.profile.access.id
                        }
                    })
                })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            this.setState({
                                ...this.state,
                                projects: this.projectsUpdate(result),
                                countVerificationProjects: result.filter((project) => project.status === 0).length
                            })
                        },
                        (error) => {
                            console.log(error)
                        }
                    );
                fetch("https://api.crowd-money.ru/payments", {
                    method: 'POST',
                    body: JSON.stringify({
                        action: "getPayments",
                        data: {
                            inquirer: {
                                profileHash: this.state.profile.access.id
                            }
                        }
                    })
                })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            this.setState({
                                ...this.state,
                                payments: result,
                                countVerificationPayments: result.filter((payment) => payment.type === 3 && payment.status == 3).length
                            })
                        },
                        (error) => {
                            console.log(error)
                        }
                    );

                fetch("https://api.crowd-money.ru/loans", {
                    method: 'POST',
                    body: JSON.stringify({
                        data: {
                            type: "fundraisingCompleted"
                        }
                    })
                })
                    .then(res => res.json())
                    .then(
                        (result) => {

                            this.setState({
                                fundraisingCompletedApplications: result
                            })
                        },
                        (error) => {
                            console.log(error)
                        }
                    );

                fetch("https://api.crowd-money.ru/partners", {
                    method: 'POST',
                    body: JSON.stringify({
                        action: 'getPartners'
                    })
                })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            this.setState({
                                partners: result
                            })
                        },
                        (error) => {
                            console.log(error)
                        }
                    );
            }
        }

        fetch("https://api.crowd-money.ru/profile", {
            method: 'POST',
            body: JSON.stringify({
                action: 'getProfiles',
                data: {
                    profileId: this.state.profile.access.id
                }
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        ...this.state,
                        balanceTotal: 0,
                        profiles: {
                            1: this.profilesUpdate(result.filter((profile) => { return (profile.group === 1 && profile.verification.length !== 0) })),
                            2: this.profilesUpdate(result.filter((profile) => { return (profile.group === 2 && profile.verification.length !== 0) })),
                            3: this.profilesUpdate(result.filter((profile) => { return (profile.group === 3 || profile.verification.length === 0) }))
                        },
                        countVerificationProfiles: this.profilesUpdate(result.filter((profile) => { return (profile.approve === 0 && profile.agreementsData.personalData === 1) })).length
                    })
                },
                (error) => {
                    console.log(error)
                }
            );
    }

    profilesUpdate = (profiles) => {
        let profilesRep = profiles.map((profile, index) => {
            return {
                ...profile,
                needVerified: profile.approve === 0 && profile.agreementsData.personalData === 1,
                groupIndex: index
            }
        })

        return profilesRep;
    }

    projectsUpdate = (projects) => {
        let projectsRep = projects.map((row) => {
            return {
                profileId: row.profileId,
                id: row.id,
                loanType: (row.data.loanType === 1 && "Исполнение государственного контракта") ||
                    (row.data.loanType === 2 && "Развитие бизнеса") ||
                    (row.data.loanType === 3 && "Пополнение оборотных средств"),
                entrepreneurData: (row.entrepreneurData.type === 1 && row.mainData.lastName + " " + row.mainData.firstName + " " + row.mainData.middleName) ||
                    (row.entrepreneurData.type === 2 && "ИП " + row.mainData.lastName + " " + row.mainData.firstName + " " + row.mainData.middleName) ||
                    (row.entrepreneurData.type === 3 && row.entrepreneurData.companyName),
                investmentOfferDocument: row.investmentOfferDocument,
                status: row.status,
                statusName: (row.status === 0 && "На рассмотрении") ||
                    (row.status === 1 && row.investmentOfferVerifyCode != 0 && "Опубликована") ||
                    (row.status === 1 && row.investmentOfferVerifyCode == 0 && "Рассмотрена") ||
                    (row.status === 2 && "Требуются уточнения") ||
                    (row.status === 3 && "Отказано") ||
                    (row.status === 4 && "Займ погашен") ||
                    (row.status === 5 && "Сбор средств завершён") ||
                    (row.status === 6 && "Погашен досрочно") ||
                    (row.status === 7 && "Просрочка платежей") ||
                    (row.status === 8 && "Активный") ||
                    (row.status === 9 && "Не состоялась"),
                date: row.date.create,
                amountStart: row.data.amountStart,
                amountEnd: row.data.amountEnd,
                okved: row.okved,
                msgCount: this.state.msgCountData[row.id]
            }
        }
        )
        return projectsRep;
    }

    handleChange = (e) => {
        this.setState({
            fields: {
                ...this.state.fields,
                [e.target.name]: e.target.value
            }
        })
    };

    createAgreementDocument = (step, sign) => {
        const $this = this;

        store.dispatch({
            type: "HIDE.SNACKBAR"
        });

        this.setState({
            activeSpinner: true
        });

        if (sign) {
            this.setState({
                signStep: true
            });
        }

        fetch("https://api.crowd-money.ru/profile/agreement", {
            method: 'POST',
            body: JSON.stringify({
                action: 'createAgreementDocument',
                type: this.state.profile.entrepreneurData.category,
                data: {
                    profileId: this.state.profile.access.id,
                    sentCode: this.state.fields.sentCode
                },
                sign: sign,
                verifyCode: this.state.fields.verifyCode
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    $this.setState({
                        activeSpinner: false
                    });

                    if (result.message) {
                        store.dispatch({
                            type: "SHOW.SNACKBAR",
                            payload: {
                                status: result.status,
                                message: result.message
                            }
                        });
                    }

                    if (result.status == 'sentCode') {
                        this.setState({
                            fields: {
                                ...this.state.fields,
                                sentCode: result.sentCode
                            }
                        })
                    }

                    if (result.status == 'success') {
                        this.setState({
                            currentStep: step,
                            profile: {
                                ...this.state.profile,
                                agreementsDocumentsData: result.data
                            }
                        })
                    }

                    if (result.status == 'success' && step === 3) {
                        this.handleVerificationSave(3)
                    }
                },
                (error) => {
                    console.log(error)
                }
            );
    };

    changeEntrepreneurCategory = (e) => {
        this.setState({
            profile: {
                ...this.state.profile,
                entrepreneurData: {
                    ...this.state.profile.entrepreneurData,
                    category: parseInt(e.target.value)
                }
            }
        })
    };

    changeDocument = (e, data) => {
        store.dispatch({
            type: "HIDE.SNACKBAR"
        });

        const formData = new FormData();

        formData.append('action', 'updateDocument');
        formData.append('data[inquirer][profileHash]', this.state.profile.access.hash);
        formData.append('data[document][id]', data.id);
        formData.append('data[document][name]', data.name);
        formData.append('data[document][file]', e.target.files[0]);

        fetch("https://api.crowd-money.ru/documents/update", {
            method: 'POST',
            body: formData
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.message) {
                        store.dispatch({
                            type: "SHOW.SNACKBAR",
                            payload: {
                                status: result.status,
                                message: result.message
                            }
                        });
                    }
                },
                (error) => {
                    console.log(error)
                }
            );
    };

    changeAgreement = (e) => {
        this.setState({
            profile: {
                ...this.state.profile,
                entrepreneurData: {
                    ...this.state.profile.entrepreneurData,
                    agreement: e.target.checked ? 1 : 0
                }
            }
        })
    };

    changeBalance = (section, index, e) => {
        let items = this.state.profiles;

        let item = { ...items[section][index] };

        item.newBalance = e.target.value;

        items[section][index] = item;

        this.setState(items);
    };

    handleChangeBalance = (action, section, index, profileId, purposePayment) => {
        let items = this.state.profiles;

        let item = { ...items[section][index] };

        item.balance = action === 1 ? parseFloat(item.newBalance) + parseFloat(item.balance) : parseFloat(item.balance) - parseFloat(item.newBalance);

        store.dispatch({
            type: "HIDE.SNACKBAR"
        });

        fetch("https://api.crowd-money.ru/profile/balance", {
            method: 'POST',
            body: JSON.stringify({
                action: 'changeBalance',
                data: {
                    inquirer: {
                        profileHash: this.state.profile.access.hash
                    },
                    profileId: profileId,
                    balance: {
                        type: action,
                        amount: item.newBalance
                    },
                    purposePayment: purposePayment
                }
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.message) {
                        store.dispatch({
                            type: "SHOW.SNACKBAR",
                            payload: {
                                status: result.status,
                                message: result.message
                            }
                        });
                    }

                    if (result.status === "success") {
                        item.newBalance = "";
                        items[section][index] = item;

                        this.setState(items);
                    }

                    UIkit.modal("#changeBalanceModal").hide();
                },
                (error) => {
                    console.log(error)
                }
            );
    };

    changePaymentStatus = (e, paymentId) => {
        store.dispatch({
            type: "HIDE.SNACKBAR"
        });

        fetch("https://api.crowd-money.ru/payments", {
            method: 'POST',
            body: JSON.stringify({
                action: 'updatePayment',
                data: {
                    inquirer: {
                        profileHash: this.state.profile.access.hash
                    },
                    paymentId: paymentId,
                    status: parseInt(e.target.value)
                }
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.message) {
                        store.dispatch({
                            type: "SHOW.SNACKBAR",
                            payload: {
                                status: result.status,
                                message: result.message
                            }
                        });
                    }
                },
                (error) => {
                    console.log(error)
                }
            );
    };

    handleVerificationSave = (nextStep) => {
        const $this = this;

        store.dispatch({
            type: "HIDE.SNACKBAR"
        });

        if (nextStep === 3) {
            this.setState({
                activeSpinner: true
            });
        }

        fetch("https://api.crowd-money.ru/verification", {
            method: 'POST',
            body: JSON.stringify({
                action: 'setVerification',
                data: {
                    profileId: this.state.profile.access.id,
                    currentStep: this.state.currentStep,
                    entrepreneurData: this.state.profile.entrepreneurData
                }
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result)

                    $this.setState({
                        activeSpinner: false
                    });

                    if (result.message) {
                        store.dispatch({
                            type: "SHOW.SNACKBAR",
                            payload: {
                                status: result.status,
                                message: result.message
                            }
                        });
                    }

                    if (result.field) {
                        $this[result.field].focus();
                    }

                    if (result.status === "success") {
                        $this.setState({
                            currentStep: nextStep
                        });

                        store.dispatch({
                            type: 'CABINET.CHANGE_CABINET_TYPE',
                            payload: {
                                group: this.state.profile.entrepreneurData.category
                            }
                        });
                    }

                    if (result.type) {
                        $this.setState({
                            verificationType: result.type,
                            currentStep: nextStep
                        });
                    }
                },
                (error) => {
                    console.log(error)
                }
            );
    };

    changeFca = (e) => {
        this.setState({
            fca: {
                ...this.state.fca,
                [e.target.name]: e.target.value
            }
        })
    };

    fca = () => {
        store.dispatch({
            type: "HIDE.SNACKBAR"
        });

        fetch("https://api.crowd-money.ru/loans/update", {
            method: 'POST',
            body: JSON.stringify({
                action: 'fca',
                data: {
                    inquirer: {
                        profileHash: this.state.profile.access.hash
                    },
                    fca: this.state.fca
                }
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.message) {
                        store.dispatch({
                            type: "SHOW.SNACKBAR",
                            payload: {
                                status: result.status,
                                message: result.message
                            }
                        });
                    }

                    if (result.field) {
                        this[result.field].focus();
                    }
                },
                (error) => {
                    console.log(error)
                }
            );
    };

    render() {
        const { isLoaded, documents, currentStep, signStep, payments, application, profile, projects, profiles, verificationType, activeSpinner, partners, countVerificationProjects, countVerificationProfiles, countVerificationPayments } = this.state;

        const balanceTotal = [...profiles[1], ...profiles[2], ...profiles[3]].reduce((sum, profile) => { return sum + profile.balance }, 0)

        const recipient = (payment) => {
            let $recipientFrom;
            let $recipientTo;

            if (payment.type === 1 || payment.type === 3 || payment.type === 4) {
                $recipientTo = {
                    mainData: payment.mainData,
                    entrepreneurData: payment.entrepreneurData,
                    fullName: null
                };
            }

            if (payment.type === 2) {
                $recipientFrom = {
                    mainData: payment.mainData,
                    entrepreneurData: payment.entrepreneurData,
                    fullName: null
                };

                $recipientTo = {
                    access: payment.project.owner.access,
                    mainData: payment.project.owner.mainData,
                    entrepreneurData: payment.project.owner.entrepreneurData,
                    fullName: null
                };
            }

            if (payment.type === 1 || payment.type === 3 || payment.type === 4) {
                if ($recipientTo.entrepreneurData.type === 1) {
                    $recipientTo.fullName = $recipientTo.mainData.lastName + ' ' + $recipientTo.mainData.firstName + ' ' + $recipientTo.mainData.middleName;
                }
                else if ($recipientTo.entrepreneurData.type === 2) {
                    $recipientTo.fullName = 'ИП ' + $recipientTo.mainData.lastName + ' ' + $recipientTo.mainData.firstName + ' ' + $recipientTo.mainData.middleName;
                }
                else if ($recipientTo.entrepreneurData.type === 3) {
                    $recipientTo.fullName = $recipientTo.entrepreneurData.companyName
                }

                let obj = {
                    linkElement: <Link className="uk-link" to={`/profile/edit/${payment.profileId}`}>{$recipientTo.fullName}</Link>,
                    recipientTofullName: $recipientTo.fullName
                }

                return obj
            }

            else if (payment.type === 2) {
                if ($recipientTo.entrepreneurData.type === 1) {
                    $recipientTo.fullName = $recipientTo.mainData.lastName + ' ' + $recipientTo.mainData.firstName + ' ' + $recipientTo.mainData.middleName;

                }
                else if ($recipientTo.entrepreneurData.type === 2) {
                    $recipientTo.fullName = 'ИП ' + $recipientTo.mainData.lastName + ' ' + $recipientTo.mainData.firstName + ' ' + $recipientTo.mainData.middleName;
                }
                else if ($recipientTo.entrepreneurData.type === 3) {
                    $recipientTo.fullName = $recipientTo.entrepreneurData.companyName
                }

                if ($recipientFrom.entrepreneurData.type === 1) {
                    $recipientFrom.fullName = $recipientFrom.mainData.lastName + ' ' + $recipientFrom.mainData.firstName + ' ' + $recipientFrom.mainData.middleName;

                }
                else if ($recipientFrom.entrepreneurData.type === 2) {
                    $recipientFrom.fullName = 'ИП ' + $recipientFrom.mainData.lastName + ' ' + $recipientFrom.mainData.firstName + ' ' + $recipientFrom.mainData.middleName;
                }
                else if ($recipientFrom.entrepreneurData.type === 3) {
                    $recipientFrom.fullName = $recipientFrom.entrepreneurData.companyName
                }

                let obj = {
                    profileId: payment.profileId,
                    recipientFrom: $recipientFrom,
                    recipientTo: $recipientTo
                }

                return obj
            }
        };

        const getProjectsId = (profileId) => {
            let projectsId = []
            payments.forEach(e => {
                if (e.profileId == profileId && e.projectId != '0')
                    projectsId.push(e.projectId)
            })
            return projectsId
        };

        const getDateTime = (value) => {
            let dateParts = value.split(/\.|:|\s\W\s/)
            return new Date(dateParts[2], dateParts[1] - 1, dateParts[0], dateParts[3], dateParts[4])
        }

        const getTabNumbers = (tabName, count) => {
            if (count != 0) {
                return `${tabName} (${count})`
            } else {
                return tabName
            }
        }

        return (
            isLoaded && (
                <div className="tm-body">
                    {
                        profile.access.group === 0 && (
                            <div className="uk-container uk-container-expand uk-section-medium uk-padding-remove-top">
                                <BasicTabs
                                    tabs={[getTabNumbers('Заявки', countVerificationProjects), 'Пользователи', 'Лицевые счета', 'Операции по ном. счёту', 'Документы', 'Прекращенные заявки']}
                                    content={[
                                        <div>
                                            <h4 className="uk-h4 uk-text-bolder">Заявки</h4>
                                            <div className="uk-overflow-auto" style={{ height: 720 }}>
                                                <ThemeProvider theme={theme}>
                                                    <DataGrid                                                       
                                                        columns={
                                                            [
                                                                {
                                                                    field: 'projectId',
                                                                    headerName: 'ID',
                                                                    width: 130,
                                                                    filterable: true,
                                                                    disableColumnMenu: false,
                                                                    type: 'number',
                                                                    align: 'center',
                                                                    headerAlign: 'center',
                                                                    renderCell: (params) => (
                                                                        <Link to={`/project/${params.value}`} className="uk-button uk-button-small uk-button-primary">{params.value}</Link>
                                                                    )
                                                                },
                                                                {
                                                                    field: 'loanType',
                                                                    headerName: 'Тип займа',
                                                                    width: 330
                                                                },
                                                                {
                                                                    field: 'borrower',
                                                                    headerName: 'Заёмщик',
                                                                    width: 300,
                                                                    valueGetter: params => `${params.value.entrepreneurData}`,
                                                                    renderCell: (params) => (
                                                                        <Link className="uk-link" to={`/profile/edit/${params.row.borrower.profileId}`}>
                                                                            {
                                                                                params.value
                                                                            }
                                                                        </Link>
                                                                    )
                                                                },
                                                                {
                                                                    field: 'investmentOffer',
                                                                    headerName: 'Инвест. предложение',
                                                                    filterable: false,
                                                                    disableColumnMenu: true,
                                                                    width: 220,
                                                                    renderCell: (params) => (
                                                                        <>
                                                                            {
                                                                                params.value ? <a href={`/private/investmentOffers/${params.value}`} className="uk-link" download>Скачать документ</a> : '-'
                                                                            }
                                                                        </>
                                                                    )
                                                                },
                                                                {
                                                                    field: 'status',
                                                                    headerName: 'Статус',
                                                                    width: 220,
                                                                    valueGetter: params => `${params.value.statusName}`,
                                                                    renderCell: (params) => (
                                                                        <>
                                                                            {
                                                                                params.row.status.status === 0 && (
                                                                                    <span className="uk-text-warning">{params.value}</span>
                                                                                )
                                                                            }
                                                                            {
                                                                                params.row.status.status === 1 && (
                                                                                    <span className="uk-text-success">{params.value}</span>
                                                                                )
                                                                            }
                                                                            {
                                                                                params.row.status.status === 2 && (
                                                                                    <span className="uk-text-warning">{params.value}</span>
                                                                                )
                                                                            }
                                                                            {
                                                                                params.row.status.status === 3 && (
                                                                                    <span className="uk-text-danger">{params.value}</span>
                                                                                )
                                                                            }
                                                                            {
                                                                                params.row.status.status === 4 && (
                                                                                    <span className="uk-text-muted">{params.value}</span>
                                                                                )
                                                                            }
                                                                            {
                                                                                params.row.status.status === 5 && (
                                                                                    <span className="uk-text-success">{params.value}</span>
                                                                                )
                                                                            }
                                                                            {
                                                                                params.row.status.status === 6 && (
                                                                                    <span className="uk-text-success">{params.value}</span>
                                                                                )
                                                                            }
                                                                            {
                                                                                params.row.status.status === 7 && (
                                                                                    <span className="uk-text-danger">{params.value}</span>
                                                                                )
                                                                            }
                                                                            {
                                                                                params.row.status.status === 8 && (
                                                                                    <span className="uk-text-success">{params.value}</span>
                                                                                )
                                                                            }
                                                                             {
                                                                                params.row.status.status === 9 && (
                                                                                    <span className="uk-text-warning">{params.value}</span>
                                                                                )
                                                                            }
                                                                        </>
                                                                    )
                                                                },
                                                                {
                                                                    field: 'amountStart',
                                                                    headerName: 'Мин. сумма',
                                                                    width: 190,
                                                                    type: 'number',
                                                                    hide: true
                                                                },
                                                                {
                                                                    field: 'amountEnd',
                                                                    headerName: 'Макс. сумма',
                                                                    width: 190,
                                                                    type: 'number',
                                                                    hide: true
                                                                },
                                                                {
                                                                    field: 'date',
                                                                    headerName: 'Дата операции',
                                                                    width: 190,
                                                                    type: 'dateTime',
                                                                    valueGetter: params => getDateTime(params.value),
                                                                    renderCell: (params) => (<>{params.row.date}</>)
                                                                },
                                                                {
                                                                    field: 'mail',
                                                                    headerName: 'Сообщения',
                                                                    width: 140,
                                                                    filterable: false,
                                                                    disableColumnMenu: true,
                                                                    // type: 'number',                                                                   
                                                                    headerAlign: 'center',
                                                                    renderCell: (params) => (
                                                                        [4, 5, 6, 7, 8].includes(params.value.status) && (
                                                                            params.value.msgCount > 0 && (<Link to={`/project/${params.value.id}/?msgRead=true`}><div className="uk-button uk-button-small uk-button-primary uk-flex uk-flex-between uk-flex-middle"><div data-uk-icon="icon: mail; ratio: 1" /><span>&nbsp;{params.value.msgCount}</span></div></Link>)
                                                                        )
                                                                    )
                                                                },
                                                            ]
                                                        }
                                                        rows={
                                                            projects.map((project) => (
                                                                {
                                                                    id: project.id,
                                                                    projectId: project.id,
                                                                    loanType: project.loanType,
                                                                    borrower: project,
                                                                    investmentOffer: project.investmentOfferDocument,
                                                                    status: project,
                                                                    amountStart: project.amountStart,
                                                                    amountEnd: project.amountEnd,
                                                                    date: project.date,
                                                                    mail: project
                                                                }
                                                            ))
                                                        }
                                                        components={{
                                                            Toolbar: () => CustomToolbar(
                                                                "Заявки",
                                                                ['projectId', 'loanType', 'borrower', 'investmentOffer', 'status', 'amountStart', 'amountEnd', 'date'])
                                                        }}

                                                        // rowsPerPageOptions={[5]}
                                                        rowsPerPageOptions={[25, 50, 100]}
                                                        disableSelectionOnClick
                                                        rowHeight={50}
                                                        disableColumnSelector={true}
                                                        sortingOrder={['desc', 'asc']}
                                                    />
                                                </ThemeProvider>
                                            </div>
                                        </div>,
                                        <div>
                                            <h4 className="uk-h4 uk-text-bolder">Пользователи</h4>
                                            <BasicTabs
                                                tabs={
                                                    [getTabNumbers('Заёмщики', profiles[2].length),
                                                    getTabNumbers('Инвесторы', profiles[1].length),
                                                    getTabNumbers('Новые пользователи', profiles[3].filter((profile) => profile.needVerified).length)]
                                                }
                                                content={[
                                                    <div>
                                                        <div className="uk-overflow-auto" style={{ height: 720 }}>
                                                            <ThemeProvider theme={theme}>
                                                                <DataGrid
                                                                    columns={
                                                                        [
                                                                            {
                                                                                field: 'profileId',
                                                                                headerName: 'ID',
                                                                                width: 90,
                                                                                filterable: false,
                                                                                disableColumnMenu: true,
                                                                                type: 'number',
                                                                                align: 'center',
                                                                                headerAlign: 'center'
                                                                            },
                                                                            {
                                                                                field: 'fio',
                                                                                headerName: 'Полное имя',
                                                                                width: 290,
                                                                                valueGetter: params => `${params.value.lastName} ${params.value.firstName} ${params.value.middleName}`,
                                                                                renderCell: (params) => (
                                                                                    <Link className="uk-link" to={`/profile/edit/${params.row.id}`}>{params.value}</Link>
                                                                                )
                                                                            },
                                                                            {
                                                                                field: 'info',
                                                                                headerName: 'Тип заёмщика',
                                                                                width: 150,
                                                                                filterable: false,
                                                                                sortable: false,
                                                                                disableColumnMenu: true,
                                                                                renderCell: (params) => (
                                                                                    params.value.type == 1 && ("Физ. лицо") ||
                                                                                    params.value.type == 2 && ("ИП") ||
                                                                                    params.value.type == 3 && ("Юр. лицо")
                                                                                ),
                                                                                valueFormatter: (params) => (
                                                                                    params.value.type == 1 && ("Физ. лицо") ||
                                                                                    params.value.type == 2 && ("ИП") ||
                                                                                    params.value.type == 3 && ("Юр. лицо")
                                                                                )
                                                                            },
                                                                            {
                                                                                field: 'companyName',
                                                                                headerName: 'Наименование юр. лица',
                                                                                width: 270,
                                                                                renderCell: (params) => (
                                                                                    params.value && (params.value) ||
                                                                                    !params.value && ("-")
                                                                                )
                                                                            },
                                                                            {
                                                                                field: 'inn',
                                                                                headerName: 'ИНН',
                                                                                width: 150
                                                                            },
                                                                            {
                                                                                field: 'phoneNumber',
                                                                                headerName: 'Телефон',
                                                                                width: 200
                                                                            },
                                                                            {
                                                                                field: 'gender',
                                                                                headerName: 'Пол',
                                                                                align: 'center',
                                                                                disableColumnMenu: true,
                                                                                filterable: false,
                                                                                headerAlign: 'center',
                                                                                width: 150,
                                                                                renderCell: params => (<>
                                                                                    {params.value === 1 ? 'Мужской' : 'Женский'}</>
                                                                                ),
                                                                                valueFormatter: params => params.value === 1 ? 'Мужской' : 'Женский'
                                                                            },
                                                                            {
                                                                                field: 'verified',
                                                                                headerName: 'Верифицирован',
                                                                                width: 200,
                                                                                disableColumnMenu: true,
                                                                                filterable: false,
                                                                                headerAlign: 'center',
                                                                                align: 'center',
                                                                                renderCell: params => (<>
                                                                                    {params.value === 1 ? <span className="uk-text-success">Да</span> : 'Нет'}</>
                                                                                ),
                                                                                valueFormatter: params => params.value === 1 ? "Да" : "Нет"

                                                                            },
                                                                            {
                                                                                field: 'partner',
                                                                                headerName: 'Партнер',
                                                                                align: 'center',
                                                                                headerAlign: 'center',
                                                                                width: 200
                                                                            },
                                                                            {
                                                                                field: 'date',
                                                                                headerName: 'Дата регистрации',
                                                                                minWidth: 240,
                                                                                flex: 0.5,
                                                                                type: 'dateTime',
                                                                                valueGetter: params => getDateTime(params.value),
                                                                                renderCell: params => (<>{params.row.date}</>),
                                                                                valueFormatter: params => params.row.date
                                                                            },
                                                                            {
                                                                                field: 'dateAccession',
                                                                                headerName: 'Дата присоединения',
                                                                                minWidth: 240,
                                                                                flex: 0.5,
                                                                                type: 'dateTime',
                                                                                valueGetter: params => getDateTime(params.value),
                                                                                renderCell: params => (<>{params.row.dateAccession}</>),
                                                                                valueFormatter: params => params.row.dateAccession
                                                                            }
                                                                        ]
                                                                    }
                                                                    rows={
                                                                        profiles[2].map((profile) => (
                                                                            {
                                                                                id: profile.id,
                                                                                profileId: profile.id,
                                                                                fio: profile,
                                                                                info: profile.entrepreneurData,
                                                                                companyName: profile.entrepreneurData.companyName,
                                                                                inn: profile.entrepreneurData.inn,
                                                                                phoneNumber: profile.phone,
                                                                                gender: profile.gender,
                                                                                verified: profile.approve,
                                                                                partner: partners.find(e => e.partnerId == profile.partnerId) ?
                                                                                    partners.find(e => e.partnerId == profile.partnerId).partnerName : "",
                                                                                date: profile.dateSignup,
                                                                                dateAccession: profile.dateAccession
                                                                            }
                                                                        ))
                                                                    }
                                                                    components={{ Toolbar: () => CustomToolbar("Заёмщики") }}
                                                                    rowsPerPageOptions={[5]}
                                                                    disableSelectionOnClick
                                                                    rowHeight={50}
                                                                    rowsPerPageOptions={[25, 50, 100]}
                                                                    disableColumnSelector={true}
                                                                    sortingOrder={['desc', 'asc']}
                                                                />
                                                            </ThemeProvider>
                                                        </div>
                                                    </div>,
                                                    <div>
                                                        <div className="uk-overflow-auto" style={{ height: 720 }}>
                                                            <ThemeProvider theme={theme}>
                                                                <DataGrid
                                                                    columns={
                                                                        [
                                                                            {
                                                                                field: 'profileId',
                                                                                headerName: 'ID',
                                                                                width: 90,
                                                                                filterable: false,
                                                                                disableColumnMenu: true,
                                                                                type: 'number',
                                                                                align: 'center',
                                                                                headerAlign: 'center',
                                                                            },
                                                                            {
                                                                                field: 'fio',
                                                                                headerName: 'Полное имя',
                                                                                width: 290,
                                                                                valueGetter: params => `${params.value.lastName} ${params.value.firstName} ${params.value.middleName}`,
                                                                                renderCell: (params) => (
                                                                                    <Link className="uk-link" to={`/profile/edit/${params.row.id}`}>{params.value}</Link>
                                                                                )
                                                                            },
                                                                            {
                                                                                field: 'info',
                                                                                headerName: 'Тип инвестора',
                                                                                width: 150,
                                                                                filterable: false,
                                                                                sortable: false,
                                                                                disableColumnMenu: true,
                                                                                renderCell: (params) => (
                                                                                    params.value.type == 1 && ("Физ. лицо") ||
                                                                                    params.value.type == 2 && ("ИП") ||
                                                                                    params.value.type == 3 && ("Юр. лицо")
                                                                                ),
                                                                                valueFormatter: (params) => (
                                                                                    params.value.type == 1 && ("Физ. лицо") ||
                                                                                    params.value.type == 2 && ("ИП") ||
                                                                                    params.value.type == 3 && ("Юр. лицо")
                                                                                )
                                                                            },
                                                                            {
                                                                                field: 'companyName',
                                                                                headerName: 'Наименование юр. лица',
                                                                                width: 270,
                                                                                renderCell: (params) => (
                                                                                    params.value && (params.value) ||
                                                                                    !params.value && ("-")
                                                                                )
                                                                            },
                                                                            {
                                                                                field: 'inn',
                                                                                headerName: 'ИНН',
                                                                                width: 150
                                                                            },
                                                                            {
                                                                                field: 'phoneNumber',
                                                                                headerName: 'Телефон',
                                                                                width: 200
                                                                            },
                                                                            {
                                                                                field: 'gender',
                                                                                headerName: 'Пол',
                                                                                align: 'center',
                                                                                headerAlign: 'center',
                                                                                disableColumnMenu: true,
                                                                                filterable: false,
                                                                                width: 150,
                                                                                renderCell: params => (<>
                                                                                    {params.value === 1 ? 'Мужской' : 'Женский'}</>
                                                                                ),
                                                                                valueFormatter: params => (
                                                                                    params.value === 1 ? 'Мужской' : 'Женский'
                                                                                )
                                                                            },
                                                                            {
                                                                                field: 'verified',
                                                                                headerName: 'Верифицирован',
                                                                                width: 200,
                                                                                headerAlign: 'center',
                                                                                disableColumnMenu: true,
                                                                                filterable: false,
                                                                                align: 'center',
                                                                                renderCell: params => (<>
                                                                                    {params.value === 1 ? <span className="uk-text-success">Да</span> : 'Нет'}</>
                                                                                ),
                                                                                valueFormatter: params => (
                                                                                    params.value === 1 ? "Да" : 'Нет'
                                                                                )
                                                                            },
                                                                            {
                                                                                field: 'qualified',
                                                                                headerName: 'Квалифицированный',
                                                                                width: 220,
                                                                                disableColumnMenu: true,
                                                                                filterable: false,
                                                                                headerAlign: 'center',
                                                                                align: 'center',
                                                                                renderCell: params => (<>
                                                                                    {params.value === 1 ? <span className="uk-text-success">Да</span> : 'Нет'}</>
                                                                                ),
                                                                                valueFormatter: params => (
                                                                                    params.value === 1 ? "Да" : 'Нет'
                                                                                )
                                                                            },
                                                                            {
                                                                                field: 'partner',
                                                                                headerName: 'Партнер',
                                                                                align: 'center',
                                                                                headerAlign: 'center',
                                                                                width: 200
                                                                            },
                                                                            {
                                                                                field: 'date',
                                                                                headerName: 'Дата регистрации',
                                                                                minWidth: 240,
                                                                                flex: 0.5,
                                                                                type: 'dateTime',
                                                                                valueGetter: params => getDateTime(params.value),
                                                                                renderCell: params => (<>{params.row.date}</>),
                                                                                valueFormatter: params => (params.row.date)
                                                                            },
                                                                            {
                                                                                field: 'dateAccession',
                                                                                headerName: 'Дата присоединения',
                                                                                minWidth: 240,
                                                                                flex: 0.5,
                                                                                type: 'dateTime',
                                                                                renderCell: params => (<>{params.row.dateAccession}</>)
                                                                            }
                                                                        ]
                                                                    }
                                                                    rows={
                                                                        profiles[1].map((profile) => (
                                                                            {
                                                                                id: profile.id,
                                                                                profileId: profile.id,
                                                                                fio: profile,
                                                                                info: profile.entrepreneurData,
                                                                                companyName: profile.entrepreneurData.companyName,
                                                                                inn: profile.entrepreneurData.inn,
                                                                                phoneNumber: profile.phone,
                                                                                gender: profile.gender,
                                                                                verified: profile.approve,
                                                                                qualified: profile.qualified,
                                                                                partner: partners.find(e => e.partnerId == profile.partnerId) ?
                                                                                    partners.find(e => e.partnerId == profile.partnerId).partnerName : "",
                                                                                date: profile.dateSignup,
                                                                                dateAccession: profile.dateAccession
                                                                            }
                                                                        ))
                                                                    }
                                                                    components={{ Toolbar: () => CustomToolbar("Инвесторы") }}
                                                                    rowsPerPageOptions={[5]}
                                                                    disableSelectionOnClick
                                                                    rowHeight={50}
                                                                    rowsPerPageOptions={[25, 50, 100]}
                                                                    disableColumnSelector={true}
                                                                    sortingOrder={['desc', 'asc']}
                                                                />
                                                            </ThemeProvider>
                                                        </div>
                                                    </div>,
                                                    <div>
                                                        <div className="uk-overflow-auto" style={{ height: 720 }}>
                                                            <ThemeProvider theme={theme}>
                                                                <DataGrid
                                                                    columns={
                                                                        [
                                                                            {
                                                                                field: 'profileId',
                                                                                headerName: 'ID',
                                                                                width: 90,
                                                                                filterable: false,
                                                                                disableColumnMenu: true,
                                                                                type: 'number',
                                                                                align: 'center',
                                                                                headerAlign: 'center',
                                                                            },
                                                                            {
                                                                                field: 'fio',
                                                                                headerName: 'Полное имя',
                                                                                width: 380,
                                                                                valueGetter: params => `${params.value.lastName} ${params.value.firstName} ${params.value.middleName}`,
                                                                                renderCell: (params) => (
                                                                                    <Link className="uk-link" to={`/profile/edit/${params.row.id}`}>{params.value}</Link>
                                                                                )
                                                                            },
                                                                            {
                                                                                field: 'phoneNumber',
                                                                                headerName: 'Телефон',
                                                                                width: 170
                                                                            },
                                                                            {
                                                                                field: 'gender',
                                                                                headerName: 'Пол',
                                                                                align: 'center',
                                                                                headerAlign: 'center',
                                                                                disableColumnMenu: true,
                                                                                filterable: false,
                                                                                width: 120,
                                                                                renderCell: params => (<>
                                                                                    {params.value === 1 ? 'Мужской' : 'Женский'}</>
                                                                                ),
                                                                                valueFormatter: params => (
                                                                                    params.value === 1 ? 'Мужской' : 'Женский'
                                                                                )
                                                                            },
                                                                            {
                                                                                field: 'verified',
                                                                                headerName: 'Верифицирован',
                                                                                width: 200,
                                                                                headerAlign: 'center',
                                                                                disableColumnMenu: true,
                                                                                filterable: false,
                                                                                align: 'center',
                                                                                renderCell: params => (<>
                                                                                    {params.value === 1 ? <span className="uk-text-success">Да</span> : 'Нет'}</>
                                                                                ),
                                                                                valueFormatter: params => (
                                                                                    params.value === 1 ? "Да" : "Нет"
                                                                                )
                                                                            },
                                                                            {
                                                                                field: 'needVerified',
                                                                                headerName: 'Запрос на модерацию',
                                                                                width: 240,
                                                                                headerAlign: 'center',
                                                                                disableColumnMenu: true,
                                                                                filterable: false,
                                                                                align: 'center',
                                                                                renderCell: params => (<>
                                                                                    {params.value === true ? <span className="uk-text-warning">Да</span> : 'Нет'}</>
                                                                                ),
                                                                                valueFormatter: params => (
                                                                                    params.value === true ? "Да" : "Нет"
                                                                                )
                                                                            },
                                                                            {
                                                                                field: 'partner',
                                                                                headerName: 'Партнер',
                                                                                align: 'center',
                                                                                headerAlign: 'center',
                                                                                width: 150
                                                                            },
                                                                            {
                                                                                field: 'date',
                                                                                headerName: 'Дата регистрации',
                                                                                minWidth: 240,
                                                                                flex: 0.5,
                                                                                type: 'dateTime',
                                                                                valueGetter: params => getDateTime(params.value),
                                                                                renderCell: params => (<>{params.row.date}</>),
                                                                                valueFormatter: params => (params.row.date)
                                                                            }
                                                                        ]
                                                                    }
                                                                    rows={
                                                                        profiles[3].map((profile) => (
                                                                            {
                                                                                id: profile.id,
                                                                                profileId: profile.id,
                                                                                fio: profile,
                                                                                phoneNumber: profile.phone,
                                                                                gender: profile.gender,
                                                                                verified: profile.approve,
                                                                                needVerified: profile.needVerified,
                                                                                partner: partners.find(e => e.partnerId == profile.partnerId) ?
                                                                                    partners.find(e => e.partnerId == profile.partnerId).partnerName : "",
                                                                                date: profile.dateSignup
                                                                            }
                                                                        ))
                                                                    }
                                                                    components={{ Toolbar: () => CustomToolbar("Новые пользователи") }}
                                                                    rowsPerPageOptions={[5]}
                                                                    disableSelectionOnClick
                                                                    rowHeight={50}
                                                                    rowsPerPageOptions={[25, 50, 100]}
                                                                    disableColumnSelector={true}
                                                                    sortingOrder={['desc', 'asc']}
                                                                />
                                                            </ThemeProvider>
                                                        </div>
                                                    </div>
                                                ]}
                                            />
                                        </div>,
                                        <div>
                                            <h4 className="uk-h4 uk-text-bolder">
                                                <div class="uk-flex">
                                                    <div>Лицевые счета пользователей</div>
                                                    <div className="uk-margin-auto-left">Баланс номинального счёта: {<CurrencyValue value={balanceTotal} currencyType={"₽"} />}</div>
                                                </div>
                                            </h4>
                                            <div className="uk-overflow-auto" style={{ height: 720 }}>
                                                <ThemeProvider theme={theme}>
                                                    <DataGrid
                                                        columns={
                                                            [
                                                                {
                                                                    field: 'fio',
                                                                    headerName: 'Полное имя',
                                                                    width: 290,
                                                                    valueGetter: params => `${params.value.lastName} ${params.value.firstName} ${params.value.middleName}`,
                                                                    renderCell: (params) => (
                                                                        <Link className="uk-link" to={`/profile/edit/${params.row.fio.id}`}>{params.value}</Link>
                                                                    )
                                                                },
                                                                {
                                                                    field: 'info',
                                                                    headerName: 'Тип пользователя',
                                                                    width: 150,
                                                                    filterable: false,
                                                                    sortable: false,
                                                                    disableColumnMenu: true,
                                                                    renderCell: (params) => (
                                                                        params.value.type == 1 && ("Физ. лицо") ||
                                                                        params.value.type == 2 && ("ИП") ||
                                                                        params.value.type == 3 && ("Юр. лицо")
                                                                    ),
                                                                    valueFormatter: (params) => (
                                                                        params.value.type == 1 && ("Физ. лицо") ||
                                                                        params.value.type == 2 && ("ИП") ||
                                                                        params.value.type == 3 && ("Юр. лицо")
                                                                    )
                                                                },
                                                                {
                                                                    field: 'companyName',
                                                                    headerName: 'Наименование юр. лица',
                                                                    width: 270,
                                                                    renderCell: (params) => (
                                                                        params.value && (params.value) ||
                                                                        !params.value && ("-")
                                                                    )
                                                                },
                                                                {
                                                                    field: 'inn',
                                                                    headerName: 'ИНН',
                                                                    width: 150
                                                                },
                                                                {
                                                                    field: 'accountNumber',
                                                                    headerName: 'Лицевой счёт',
                                                                    width: 130
                                                                },
                                                                {
                                                                    field: 'phoneNumber',
                                                                    headerName: 'Телефон',
                                                                    width: 150
                                                                },
                                                                {
                                                                    field: 'group',
                                                                    headerName: 'Группа',
                                                                    width: 130,
                                                                    align: 'center',
                                                                    headerAlign: 'center',
                                                                    disableColumnMenu: true,
                                                                    filterable: false,
                                                                    renderCell: params => (<>
                                                                        {params.value === 1 ? 'Инвестор' : 'Заёмщик'}</>
                                                                    ),
                                                                    valueFormatter: params => (
                                                                        params.value === 1 ? 'Инвестор' : 'Заёмщик'
                                                                    )
                                                                },
                                                                {
                                                                    field: 'balance',
                                                                    headerName: 'Баланс',
                                                                    width: 140,
                                                                    renderCell: params => (<CurrencyValue value={params.value} currencyType={"₽"} />),
                                                                    valueFormatter: params => (Number(params.value).toFixed(2))
                                                                },
                                                                {
                                                                    field: 'changeBalance',
                                                                    headerName: 'Изменить баланс',
                                                                    width: 350,
                                                                    filterable: false,
                                                                    disableColumnMenu: true,
                                                                    sortable: false,
                                                                    renderCell: params => (
                                                                        <>
                                                                            <ChangeBalancePanel
                                                                                profile={params.value}
                                                                                defaultProfile={this.state.profile}
                                                                                projectsId={getProjectsId(params.value.id)}
                                                                            />
                                                                        </>
                                                                    )
                                                                }
                                                            ]
                                                        }
                                                        rows={
                                                            [...profiles[1], ...profiles[2], ...profiles[3]].map((profile, index) => (
                                                                {
                                                                    id: index,
                                                                    fio: profile,
                                                                    info: profile.entrepreneurData,
                                                                    companyName: profile.entrepreneurData.companyName,
                                                                    inn: profile.entrepreneurData.inn,
                                                                    accountNumber: profile.accountNumber,
                                                                    phoneNumber: profile.phone,
                                                                    group: profile.group,
                                                                    balance: profile.balance,
                                                                    changeBalance: profile
                                                                }
                                                            ))
                                                        }
                                                        components={{ Toolbar: () => CustomToolbar("Лицевые счета") }}
                                                        rowsPerPageOptions={[5]}
                                                        disableSelectionOnClick
                                                        rowHeight={50}
                                                        disableColumnSelector={true}
                                                        rowsPerPageOptions={[25, 50, 100]}
                                                        sortingOrder={['desc', 'asc']}
                                                    />
                                                </ThemeProvider>
                                            </div>
                                        </div>,
                                        <div>
                                            <h4 className="uk-h4 uk-text-bolder">Операции по счетам участников</h4>
                                            <BasicTabs
                                                tabs={['Пополнение баланса', 'Инвестиция в проект', getTabNumbers('Заявки на вывод средств', countVerificationPayments), 'Списание средств']}
                                                content={[
                                                    <div>
                                                        <div className="uk-overflow-auto" style={{ height: 720 }}>
                                                            <ThemeProvider theme={theme}>
                                                                <DataGrid
                                                                    columns={
                                                                        [
                                                                            {
                                                                                field: 'information',
                                                                                headerName: 'Информация',
                                                                                width: 450,
                                                                                valueGetter: params => `${params.value.recipientTofullName}`,
                                                                                renderCell: params => (
                                                                                    <>
                                                                                        {params.row.information.linkElement}
                                                                                    </>
                                                                                )
                                                                            },
                                                                            {
                                                                                field: 'purposePayment',
                                                                                headerName: 'Назначение операции',
                                                                                width: 550,
                                                                                valueGetter: params => `${params.value.purposePayment}`,
                                                                                renderCell: params => (
                                                                                    <>
                                                                                        {params.value}
                                                                                    </>
                                                                                )
                                                                            },
                                                                            {
                                                                                field: 'accountNumber',
                                                                                headerName: 'Лицевой счёт',
                                                                                width: 210,
                                                                                align: 'center',
                                                                                headerAlign: 'center',
                                                                                valueGetter: params => `${params.value.profileAccountNumber}`,
                                                                                renderCell: (params) => (
                                                                                    <>
                                                                                        {
                                                                                            // params.row.accountNumber.interiorId > 0 ? (
                                                                                            params.row.accountNumber.profileAccountNumber
                                                                                            // ) : (
                                                                                            //     '-'
                                                                                            // )
                                                                                        }
                                                                                    </>
                                                                                )
                                                                            },
                                                                            {
                                                                                field: 'date',
                                                                                headerName: 'Дата операции',
                                                                                width: 200,
                                                                                type: 'dateTime',
                                                                                valueGetter: params => getDateTime(params.value),
                                                                                renderCell: (params) => (<>{params.row.date}</>)
                                                                            },
                                                                            {
                                                                                field: 'sum',
                                                                                headerName: 'Сумма',
                                                                                width: 140,
                                                                                valueGetter: params => params.value * 1,
                                                                                renderCell: (params) => (<CurrencyValue value={params.value} currencyType={"₽"} />)
                                                                            },
                                                                            // {
                                                                            //     field: 'status',
                                                                            //     headerName: 'Статус платежа',
                                                                            //     minWidth: 170,
                                                                            //     align: 'center',
                                                                            //     filterable: false,
                                                                            //     disableColumnMenu: true,
                                                                            //     flex: 1,
                                                                            //     valueGetter: params => `${params.value.status}`,
                                                                            //     renderCell: (params) => (
                                                                            //         <>
                                                                            //            {
                                                                            //                 (
                                                                            //                     params.value == 1 ? (
                                                                            //                         <span className="uk-text-success">Исполнен</span>
                                                                            //                     ) : (
                                                                            //                         <select className="uk-select" defaultValue={params.value} onChange={(e) => this.changePaymentStatus(e, params.row.accountNumber.id)}>
                                                                            //                             <option value={1}>Исполнен</option>
                                                                            //                             <option value={2}>Отменен</option>
                                                                            //                             <option value={3}>В обработке</option>                                                                                      
                                                                            //                         </select>
                                                                            //                     )
                                                                            //                 )
                                                                            //             }
                                                                            //         </>
                                                                            //     )
                                                                            // }
                                                                        ]
                                                                    }
                                                                    rows={
                                                                        payments.filter(payment => (payment.type === 1)).map((payment, index) => (
                                                                            {
                                                                                id: index,
                                                                                information: recipient(payment),
                                                                                purposePayment: payment,
                                                                                accountNumber: payment,
                                                                                date: payment.date,
                                                                                sum: payment.amount,
                                                                                status: payment
                                                                            }
                                                                        ))
                                                                    }
                                                                    components={{ Toolbar: () => CustomToolbar("Пополнение баланса") }}
                                                                    rowsPerPageOptions={[25, 50, 100]}
                                                                    disableSelectionOnClick
                                                                    rowHeight={50}
                                                                    disableColumnSelector={true}
                                                                    sortingOrder={['desc', 'asc']}
                                                                />
                                                            </ThemeProvider>
                                                        </div>
                                                    </div>,
                                                    <div>
                                                        <div className="uk-overflow-auto" style={{ height: 720 }}>
                                                            <ThemeProvider theme={theme}>
                                                                <DataGrid
                                                                    columns={
                                                                        [
                                                                            {
                                                                                field: 'from',
                                                                                headerName: 'От',
                                                                                width: 450,
                                                                                valueGetter: params => `${params.value.recipientFrom.fullName}`,
                                                                                renderCell: (params) => (
                                                                                    <>
                                                                                        <Link className="uk-link" to={`/profile/edit/${params.row.from.profileId}`}>{params.row.from.recipientFrom.fullName}</Link>
                                                                                    </>
                                                                                )
                                                                            },
                                                                            {
                                                                                field: 'to',
                                                                                headerName: 'Кому',
                                                                                width: 450,
                                                                                valueGetter: params => `${params.value.recipientTo.fullName}`,
                                                                                renderCell: (params) => (
                                                                                    <>
                                                                                        {
                                                                                            params.row.to.recipientTo.access ?
                                                                                                <Link className="uk-link" to={`/profile/edit/${params.row.to.recipientTo.access.id}`}>{params.row.to.recipientTo.fullName}</Link> :
                                                                                                (params.row.to.recipientTo.fullName)
                                                                                        }
                                                                                    </>
                                                                                    // <>                                                                                
                                                                                    //     <Link to={`/profile/edit/${params.row.to.recipientTo.access.id}`}>{params.row.to.recipientTo.fullName}</Link>                                                                                   
                                                                                    // </>
                                                                                )
                                                                            },
                                                                            {
                                                                                field: 'accept',
                                                                                headerName: 'Акцепт',
                                                                                width: 150,
                                                                                filterable: false,
                                                                                disableColumnMenu: true,
                                                                                renderCell: (params) => (
                                                                                    <>
                                                                                        {
                                                                                            params.value ? <a href={`/private/paymentInvestmentAccepts/${params.value}`} download>Скачать акцепт</a> : '-'
                                                                                        }
                                                                                    </>
                                                                                )
                                                                            },
                                                                            {
                                                                                field: 'accountNumber',
                                                                                headerName: 'Лицевой счёт',
                                                                                width: 210,
                                                                                align: 'center',
                                                                                headerAlign: 'center',
                                                                                valueGetter: params => `${params.value.interiorId}`,
                                                                                renderCell: (params) => (
                                                                                    <>
                                                                                        {
                                                                                            // params.row.accountNumber.interiorId > 0 ? (
                                                                                            params.row.accountNumber.profileAccountNumber
                                                                                            // ) : (
                                                                                            //     '-'
                                                                                            // )
                                                                                        }
                                                                                    </>
                                                                                ),
                                                                                valueFormatter: (params) => (params.row.accountNumber.profileAccountNumber)
                                                                            },
                                                                            {
                                                                                field: 'date',
                                                                                headerName: 'Дата операции',
                                                                                width: 200,
                                                                                type: 'dateTime',
                                                                                valueGetter: params => getDateTime(params.value),
                                                                                renderCell: (params) => (<>{params.row.date}</>)
                                                                            },
                                                                            {
                                                                                field: 'sum',
                                                                                headerName: 'Сумма',
                                                                                width: 140,
                                                                                valueGetter: params => params.value * 1,
                                                                                renderCell: (params) => (<CurrencyValue value={params.value} currencyType={"₽"} />)
                                                                            },
                                                                            {
                                                                                field: 'status',
                                                                                headerName: 'Статус платежа',
                                                                                minWidth: 170,
                                                                                align: 'center',
                                                                                filterable: false,
                                                                                disableColumnMenu: true,
                                                                                flex: 1,
                                                                                valueGetter: params => `${params.value.status}`,
                                                                                renderCell: (params) => (
                                                                                    <>
                                                                                        {
                                                                                            (
                                                                                                params.value == 1 ? (
                                                                                                    <span className="uk-text-success">Исполнен</span>
                                                                                                ) : (
                                                                                                    <select className="uk-select" defaultValue={params.value} onChange={(e) => this.changePaymentStatus(e, params.row.accountNumber.id)}>
                                                                                                        <option value={1}>Исполнен</option>
                                                                                                        <option value={2}>Отменен</option>
                                                                                                        <option value={3}>В обработке</option>
                                                                                                    </select>
                                                                                                )
                                                                                            )
                                                                                        }
                                                                                    </>
                                                                                ),
                                                                                valueFormatter: (params) => (
                                                                                    params.value == 1 ? ("Исполнен") : ("")
                                                                                )
                                                                            }
                                                                        ]
                                                                    }
                                                                    rows={
                                                                        payments.filter(payment => payment.type === 2).map((payment, index) => (
                                                                            {
                                                                                id: index,
                                                                                from: recipient(payment),
                                                                                to: recipient(payment),
                                                                                accept: payment.investmentAccept,
                                                                                accountNumber: payment,
                                                                                date: payment.date,
                                                                                sum: payment.amount,
                                                                                status: payment
                                                                            }
                                                                        ))
                                                                    }
                                                                    components={{ Toolbar: () => CustomToolbar("Инвестиция в проект") }}
                                                                    rowsPerPageOptions={[25, 50, 100]}
                                                                    disableSelectionOnClick
                                                                    rowHeight={50}
                                                                    disableColumnSelector={true}
                                                                    sortingOrder={['desc', 'asc']}
                                                                />
                                                            </ThemeProvider>
                                                        </div>
                                                    </div>,
                                                    <div>
                                                        <div className="uk-overflow-auto" style={{ height: 720 }}>
                                                            <ThemeProvider theme={theme}>
                                                                <DataGrid
                                                                    columns={
                                                                        [
                                                                            {
                                                                                field: 'information',
                                                                                headerName: 'Информация',
                                                                                width: 450,
                                                                                valueGetter: params => `${params.value.recipientTofullName}`,
                                                                                renderCell: params => (
                                                                                    <>
                                                                                        {params.row.information.linkElement}
                                                                                    </>
                                                                                )
                                                                            },
                                                                            // {
                                                                            //     field: 'kc',
                                                                            //     headerName: 'К/С',
                                                                            //     width: 200,
                                                                            //     renderCell: (params) => (
                                                                            //         <>
                                                                            //             <NumberFormat value={params.value.data.correspondentAccount} displayType={'text'} format="#####-###-#-####-#######" />
                                                                            //         </>
                                                                            //     )
                                                                            // },
                                                                            {
                                                                                field: 'pc',
                                                                                headerName: 'Р/С',
                                                                                width: 230,
                                                                                renderCell: (params) => (
                                                                                    <>
                                                                                        <NumberFormat value={params.value.data.currentAccount} displayType={'text'} format="#####-###-#-####-#######" />
                                                                                    </>
                                                                                ),
                                                                                valueFormatter: (params) => (String(params.value.data.currentAccount))
                                                                            },
                                                                            {
                                                                                field: 'bik',
                                                                                headerName: 'БИК',
                                                                                width: 150,
                                                                                renderCell: (params) => (
                                                                                    <>
                                                                                        <NumberFormat value={params.value.data.bik} displayType={'text'} format="###-###-###" />
                                                                                    </>
                                                                                ),
                                                                                valueFormatter: (params) => (String(params.value.data.bik))
                                                                            },
                                                                            // {
                                                                            //     field: 'accept',
                                                                            //     headerName: 'Акцепт',
                                                                            //     width: 150,
                                                                            //     renderCell: (params) => (
                                                                            //         <>
                                                                            //             {
                                                                            //                 params.value ? <a href={`/private/paymentInvestmentAccepts/${params.value}`} download>Скачать акцепт</a> : '-'
                                                                            //             }
                                                                            //         </>
                                                                            //     )
                                                                            // },
                                                                            {
                                                                                field: 'accountNumber',
                                                                                headerName: 'Лицевой счёт',
                                                                                width: 210,
                                                                                align: 'center',
                                                                                headerAlign: 'center',
                                                                                valueGetter: params => `${params.value.interiorId}`,
                                                                                renderCell: (params) => (
                                                                                    <>
                                                                                        {
                                                                                            // params.row.accountNumber.interiorId > 0 ? (
                                                                                            params.row.accountNumber.profileAccountNumber
                                                                                            // ) : (
                                                                                            //     '-'
                                                                                            // )
                                                                                        }
                                                                                    </>
                                                                                ),
                                                                                valueFormatter: (params) => (
                                                                                    params.row.accountNumber.profileAccountNumber
                                                                                )
                                                                            },
                                                                            {
                                                                                field: 'date',
                                                                                headerName: 'Дата операции',
                                                                                width: 200,
                                                                                type: 'dateTime',
                                                                                valueGetter: params => getDateTime(params.value),
                                                                                renderCell: (params) => (<>{params.row.date}</>)
                                                                            },
                                                                            {
                                                                                field: 'sum',
                                                                                headerName: 'Сумма',
                                                                                width: 160,
                                                                                valueGetter: params => params.value * 1,
                                                                                renderCell: (params) => (<CurrencyValue value={params.value} currencyType={"₽"} />)

                                                                                //renderCell: (params) => (<CurrencyValue value={params.value} currencyType={"₽"}/>)
                                                                                // renderCell: (params) => (
                                                                                //     <>
                                                                                //         {
                                                                                //             params.row.accountNumber.type !== 3 && (<CurrencyValue value={params.value} currencyType={"₽"}/>)
                                                                                //         }
                                                                                //         {
                                                                                //             params.row.accountNumber.type === 3 && ('—')
                                                                                //         }
                                                                                //     </>)
                                                                            },
                                                                            {
                                                                                field: 'sumCommission',
                                                                                headerName: 'Сумма с комиссией',
                                                                                width: 200,
                                                                                valueGetter: params => params.value,
                                                                                renderCell: params => params.value != 0 && <CurrencyValue value={params.value} currencyType={"₽"} />
                                                                            },
                                                                            {
                                                                                field: 'status',
                                                                                headerName: 'Статус платежа',
                                                                                minWidth: 170,
                                                                                align: 'center',
                                                                                filterable: false,
                                                                                disableColumnMenu: true,
                                                                                flex: 1,
                                                                                valueGetter: params => `${params.value.status}`,
                                                                                renderCell: (params) => (
                                                                                    <>
                                                                                        {
                                                                                            (
                                                                                                params.value == 1 ? (
                                                                                                    <span className="uk-text-success">Исполнен</span>
                                                                                                ) : (
                                                                                                    <select className="uk-select" defaultValue={params.value} onChange={(e) => this.changePaymentStatus(e, params.row.accountNumber.id)}>
                                                                                                        <option value={1}>Исполнен</option>
                                                                                                        <option value={2}>Отменен</option>
                                                                                                        <option value={3}>В обработке</option>
                                                                                                    </select>
                                                                                                )
                                                                                            )
                                                                                        }
                                                                                    </>
                                                                                ),
                                                                                valueFormatter: (params) => (
                                                                                    params.value == 1 ? ("Исполнен") : ("")
                                                                                )
                                                                            }
                                                                        ]
                                                                    }
                                                                    rows={
                                                                        payments.filter(payment => (payment.type === 3)).map((payment, index) => (
                                                                            {
                                                                                id: index,
                                                                                information: recipient(payment),
                                                                                kc: payment,
                                                                                pc: payment,
                                                                                bik: payment,
                                                                                //accept: payment.investmentAccept,
                                                                                accountNumber: payment,
                                                                                date: payment.date,
                                                                                sum: payment.amount,
                                                                                sumCommission: payment.entrepreneurData.type == 1 ? (payment.amount * 0.99) : 0,
                                                                                status: payment
                                                                            }
                                                                        ))
                                                                    }
                                                                    components={{ Toolbar: () => CustomToolbar("Заявки на вывод средств") }}
                                                                    rowsPerPageOptions={[25, 50, 100]}
                                                                    disableSelectionOnClick
                                                                    rowHeight={50}
                                                                    disableColumnSelector={true}
                                                                    sortingOrder={['desc', 'asc']}
                                                                />
                                                            </ThemeProvider>
                                                        </div>
                                                    </div>,
                                                    <div>
                                                        <div className="uk-overflow-auto" style={{ height: 720 }}>
                                                            <ThemeProvider theme={theme}>
                                                                <DataGrid
                                                                    columns={
                                                                        [
                                                                            {
                                                                                field: 'information',
                                                                                headerName: 'Информация',
                                                                                width: 450,
                                                                                valueGetter: params => `${params.value.recipientTofullName}`,
                                                                                renderCell: params => (
                                                                                    <>
                                                                                        {params.row.information.linkElement}
                                                                                    </>
                                                                                )
                                                                            },
                                                                            {
                                                                                field: 'purposePayment',
                                                                                headerName: 'Назначение операции',
                                                                                width: 550,
                                                                                valueGetter: params => `${params.value.purposePayment}`,
                                                                                renderCell: params => (
                                                                                    <>
                                                                                        {params.value}
                                                                                    </>
                                                                                )
                                                                            },
                                                                            {
                                                                                field: 'accountNumber',
                                                                                headerName: 'Лицевой счёт',
                                                                                width: 210,
                                                                                align: 'center',
                                                                                headerAlign: 'center',
                                                                                valueGetter: params => `${params.value.profileAccountNumber}`,
                                                                                renderCell: (params) => (
                                                                                    <>
                                                                                        {params.row.accountNumber.profileAccountNumber}
                                                                                    </>
                                                                                )
                                                                            },
                                                                            {
                                                                                field: 'date',
                                                                                headerName: 'Дата операции',
                                                                                width: 200,
                                                                                type: 'dateTime',
                                                                                valueGetter: params => getDateTime(params.value),
                                                                                renderCell: (params) => (<>{params.row.date}</>)
                                                                            },
                                                                            {
                                                                                field: 'sum',
                                                                                headerName: 'Сумма',
                                                                                width: 140,
                                                                                valueGetter: params => params.value * 1,
                                                                                renderCell: (params) => (<CurrencyValue value={params.value} currencyType={"₽"} />)
                                                                            },
                                                                            // {
                                                                            //     field: 'status',
                                                                            //     headerName: 'Статус платежа',
                                                                            //     minWidth: 170,
                                                                            //     align: 'center',
                                                                            //     filterable: false,
                                                                            //     disableColumnMenu: true,
                                                                            //     flex: 1,
                                                                            //     valueGetter: params => `${params.value.status}`,
                                                                            //     renderCell: (params) => (
                                                                            //         <>
                                                                            //             {
                                                                            //                 (
                                                                            //                     params.value == 1 ? (
                                                                            //                         <span className="uk-text-success">Исполнен</span>
                                                                            //                     ) : (
                                                                            //                         <select className="uk-select" defaultValue={params.value} onChange={(e) => this.changePaymentStatus(e, params.row.accountNumber.id)}>
                                                                            //                             <option value={1}>Исполнен</option>
                                                                            //                             <option value={2}>Отменен</option>
                                                                            //                             <option value={3}>В обработке</option>                                                                                      
                                                                            //                         </select>
                                                                            //                     )
                                                                            //                 )
                                                                            //             }
                                                                            //         </>
                                                                            //     )
                                                                            // }
                                                                        ]
                                                                    }
                                                                    rows={
                                                                        payments.filter(payment => (payment.type === 4)).map((payment, index) => (
                                                                            {
                                                                                id: index,
                                                                                information: recipient(payment),
                                                                                purposePayment: payment,
                                                                                accountNumber: payment,
                                                                                date: payment.date,
                                                                                sum: payment.amount,
                                                                                status: payment
                                                                            }
                                                                        ))
                                                                    }
                                                                    components={{ Toolbar: () => CustomToolbar("Списание средств") }}
                                                                    rowsPerPageOptions={[25, 50, 100]}
                                                                    disableSelectionOnClick
                                                                    rowHeight={50}
                                                                    disableColumnSelector={true}
                                                                    sortingOrder={['desc', 'asc']}
                                                                />
                                                            </ThemeProvider>
                                                        </div>
                                                    </div>
                                                ]}
                                            />
                                        </div>,
                                        <div>
                                            <h4 className="uk-h4 uk-text-bolder">Документы</h4>
                                            <div className="uk-alert uk-alert-primary uk-text-small">
                                                После выбора файла, загрузка и обновление документа произойдёт автоматически.
                                            </div>
                                            <div className="uk-overflow-auto">
                                                <table className="uk-table uk-table-middle uk-table-divider" style={{ tableLayout: "fixed" }}>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: 15 }}>№</th>
                                                            <th>Наименование документа</th>
                                                            <th style={{ width: 152 }} />
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            documentNames.map((item, key) => (
                                                                <tr key={key}>
                                                                    <td>{item.id}</td>
                                                                    <td>{item.name}</td>
                                                                    <td>
                                                                        <div data-uk-form-custom>
                                                                            <input type="file"
                                                                                onChange={(e) => this.changeDocument(e, item)}
                                                                                accept="application/pdf" />
                                                                            <button type="button"
                                                                                className="uk-button uk-button-small uk-button-default">Выбрать
                                                                                файл
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>,
                                        <div>
                                            <h4 className="uk-h4 uk-text-bolder">Прекращённые заявки</h4>
                                            <div className="uk-overflow-auto" style={{ height: 500 }}>
                                                <ThemeProvider theme={theme}>
                                                    <DataGrid
                                                        columns={
                                                            [
                                                                {
                                                                    field: 'reqNumber',
                                                                    headerName: 'Номер заявки',
                                                                    width: 240
                                                                },
                                                                {
                                                                    field: 'createDate',
                                                                    headerName: 'Дата создания',
                                                                    width: 260,
                                                                    type: 'dateTime',
                                                                    valueGetter: params => getDateTime(params.value),
                                                                    renderCell: params => (<>{params.row.createDate}</>)
                                                                },
                                                                {
                                                                    field: 'endDate',
                                                                    headerName: 'Дата прекращения',
                                                                    width: 300,
                                                                    type: 'dateTime',
                                                                    valueGetter: params => getDateTime(params.value),
                                                                    renderCell: params => (<>{params.row.endDate}</>)
                                                                },
                                                                {
                                                                    field: 'sum',
                                                                    headerName: 'Сумма займа',
                                                                    width: 240,
                                                                    renderCell: (params) => (<CurrencyValue value={params.value} currencyType={"₽"} />)
                                                                },
                                                                {
                                                                    field: 'okved',
                                                                    headerName: 'ОКВЭД',
                                                                    width: 150,
                                                                    valueFormatter: params => (params.value)
                                                                }
                                                            ]
                                                        }
                                                        rows={
                                                            this.state.fundraisingCompletedApplications.map((item, index) => (
                                                                {
                                                                    id: index,
                                                                    reqNumber: item.id,
                                                                    createDate: item.date.create,
                                                                    endDate: item.date.close,
                                                                    sum: item.collected,
                                                                    okved: item.okved
                                                                }
                                                            ))
                                                        }
                                                        components={{ Toolbar: () => CustomToolbar("Прекращенные заявки") }}
                                                        rowsPerPageOptions={[25, 50, 100]}
                                                        disableSelectionOnClick
                                                        rowHeight={50}
                                                        disableColumnSelector={true}
                                                        sortingOrder={['desc', 'asc']}
                                                    />
                                                </ThemeProvider>
                                            </div>
                                            <h3 className="uk-h3 uk-text-bolder">Добавить заявку</h3>
                                            <div data-uk-grid>
                                                <div className="uk-width-1-3@s">
                                                    <label className="uk-form-label">Номер заявки</label>
                                                    <select className="uk-select" name="id" onChange={this.changeFca}>
                                                        <option value={0}>Не указан</option>
                                                        {
                                                            projects.map((item, key) => (
                                                                item.okved === '' && (
                                                                    <option value={item.id}>Заявка №{item.id}</option>
                                                                )
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                                <div className="uk-width-2-3@s">
                                                    <label className="uk-form-label">ОКВЭД</label>
                                                    <input type="text" name="okved" className="uk-input" ref={(input) => { this.okved = input }} onChange={this.changeFca} />
                                                </div>
                                            </div>
                                            <div className="uk-margin">
                                                <div className="uk-button uk-button-primary" onClick={this.fca}>Добавить заявку</div>
                                            </div>
                                        </div>
                                    ]}
                                />
                            </div>
                        )
                    }
                    {
                        profile.access.group > 0 && (
                            <div className="uk-position-relative">
                                {
                                    activeSpinner && (
                                        <div className="tm-spinner">
                                            <span data-uk-spinner="ratio: 3" />
                                        </div>
                                    )
                                }
                                <div className="uk-container uk-section-small uk-padding-remove-top">
                                    <h3 className="uk-h3 tm-font-weight-5">
                                        {
                                            currentStep === 1 ? 'Процедура скоринг-оценки' : 'Заполните анкету'
                                        }
                                    </h3>
                                    <div>
                                        {
                                            profile.verification.length === 0 && (
                                                <div className="tm-steps uk-margin-medium-bottom">
                                                    <div className="tm-step uk-active">
                                                        <span className="tm-step-number">1</span>
                                                        <span className="tm-step-title">Начинаем</span>
                                                    </div>
                                                    <div className={`tm-step-separator ${currentStep > 1 && 'uk-active'}`} />
                                                    <div className={`tm-step ${currentStep > 1 && 'uk-active'}`}>
                                                        <span className="tm-step-number">2</span>
                                                        <span className="tm-step-title">
                                                            {
                                                                profile.entrepreneurData.category === 1 ? 'Об инвесторе' : ' О заёмщике'
                                                            }
                                                        </span>
                                                    </div>
                                                    <div className={`tm-step-separator ${currentStep > 2 && 'uk-active'}`} />
                                                    <div className={`tm-step ${currentStep > 2 && 'uk-active'}`}>
                                                        <span className="tm-step-number">3</span>
                                                        <span className="tm-step-title">Результат</span>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        <div className="tm-section-entrepreneur">
                                            {
                                                currentStep === 1 && (
                                                    <div>
                                                        {
                                                            profile.approve === 0 && (
                                                                <>
                                                                    <div className="uk-margin-medium-top">
                                                                        <h3 className="uk-h3 tm-font-weight-5">
                                                                            Автоматическая скоринг оценка
                                                                        </h3>
                                                                        <div className="tm-font-weight-5">
                                                                            Для прохождения скоринг оценки необходимо:
                                                                        </div>
                                                                        <ul className="uk-list uk-list-circle uk-margin-small-top uk-text-small">
                                                                            <li>
                                                                                Заполнить данные о себе и  бизнесе на странице настроек.
                                                                            </li>
                                                                            <li>
                                                                                Дождаться проверки указанных данных модераторами нашей платформы.
                                                                            </li>
                                                                        </ul>
                                                                        <div className="tm-font-weight-5">
                                                                            После прохождения скоринг оценки:
                                                                        </div>
                                                                        <div className="uk-margin-small-top uk-text-small">
                                                                            Вам будет доступен полный функционал платформы.
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            (profile.approve === 1 && profile.verification.length === 0) && (
                                                                <>
                                                                    <h4 className="uk-h4">Вы:</h4>
                                                                    <div>
                                                                        <div className="uk-margin-bottom">
                                                                            <label className="tm-radio">
                                                                                <input
                                                                                    className="uk-radio"
                                                                                    type="radio"
                                                                                    name="category"
                                                                                    value={1}
                                                                                    checked={profile.entrepreneurData.category === 1 && true}
                                                                                    onChange={this.changeEntrepreneurCategory}
                                                                                />
                                                                                <span>Инвестор</span>
                                                                            </label>
                                                                        </div>
                                                                        {
                                                                            profile.entrepreneurData.type > 1 && (
                                                                                <div>
                                                                                    <label className="tm-radio">
                                                                                        <input
                                                                                            className="uk-radio"
                                                                                            type="radio"
                                                                                            name="category"
                                                                                            value={2}
                                                                                            checked={profile.entrepreneurData.category === 2 && true}
                                                                                            onChange={this.changeEntrepreneurCategory}
                                                                                        />
                                                                                        <span>Заёмщик</span>
                                                                                    </label>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div className="uk-margin-medium-top">
                                                                        <div className="uk-button uk-button-primary" onClick={() => (
                                                                            this.createAgreementDocument(2)
                                                                        )}>Отправить</div>
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            }
                                            {
                                                (currentStep === 2 && !signStep) && (
                                                    <div className="uk-width">
                                                        {
                                                            profile.entrepreneurData.type === 1 && (
                                                                <div className="uk-width-2-3@s">
                                                                    <h4 className="uk-h4">Шаг 1: данные</h4>
                                                                    <div className="uk-margin uk-text-small uk-text-muted">
                                                                        Ограничение для физических лиц - 600 тыс. рублей в год.
                                                                    </div>
                                                                    <div className="uk-grid-row-small" data-uk-grid>
                                                                        <div className="uk-width-1-2@s">
                                                                            <label className="uk-form-label">Полное наименование (ФИО)</label>
                                                                            <input
                                                                                type="text"
                                                                                className="uk-input"
                                                                                defaultValue={profile.mainData.lastName + ' ' + profile.mainData.firstName + ' ' + profile.mainData.middleName}
                                                                                disabled={true}
                                                                            />
                                                                        </div>
                                                                        <div className="uk-width-1-2@s">
                                                                            <label className="uk-form-label">ИНН</label>
                                                                            <InputMask mask="999999999999" value={profile.entrepreneurData.inn} disabled={true}>
                                                                                <input
                                                                                    type="text"
                                                                                    className="uk-input"
                                                                                />
                                                                            </InputMask>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            profile.entrepreneurData.type > 1 && (
                                                                <div data-uk-grid>
                                                                    {
                                                                        profile.entrepreneurData.type === 2 && (
                                                                            <div className="uk-width-2-3@s">
                                                                                <h4 className="uk-h4">Шаг 1: данные</h4>
                                                                                <div className="uk-grid-row-small" data-uk-grid>
                                                                                    <div className="uk-width">
                                                                                        <label className="uk-form-label">Полное наименование (ФИО)</label>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="uk-input"
                                                                                            defaultValue={profile.mainData.lastName + ' ' + profile.mainData.firstName + ' ' + profile.mainData.middleName}
                                                                                            disabled={true}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uk-width-1-2@s">
                                                                                        <label className="uk-form-label">ИНН</label>
                                                                                        <InputMask mask="999999999999" value={profile.entrepreneurData.inn} disabled={true}>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="uk-input"
                                                                                            />
                                                                                        </InputMask>
                                                                                    </div>
                                                                                    <div className="uk-width-1-2@s">
                                                                                        <label className="uk-form-label">ОГРНИП</label>
                                                                                        <InputMask mask="999999999999999" value={profile.entrepreneurData.ogrnip} disabled={true}>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="uk-input"
                                                                                            />
                                                                                        </InputMask>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                    {
                                                                        profile.entrepreneurData.type === 3 && (
                                                                            <div className="uk-width-2-3@s">
                                                                                <h4 className="uk-h4">Шаг 1: данные</h4>
                                                                                <div className="uk-grid-row-small" data-uk-grid>
                                                                                    <div className="uk-width-1-2@s">
                                                                                        <label className="uk-form-label">Наименование организации</label>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="uk-input"
                                                                                            defaultValue={profile.entrepreneurData.companyName}
                                                                                            disabled={true}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uk-width-1-2@s">
                                                                                        <label className="uk-form-label">ИНН</label>
                                                                                        <InputMask mask="9999999999" value={profile.entrepreneurData.inn} disabled={true}>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="uk-input"
                                                                                            />
                                                                                        </InputMask>
                                                                                    </div>
                                                                                    <div className="uk-width-1-2@s">
                                                                                        <label className="uk-form-label">КПП</label>
                                                                                        <InputMask mask="999999999" value={profile.entrepreneurData.kpp} disabled={true}>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="uk-input"
                                                                                            />
                                                                                        </InputMask>
                                                                                    </div>
                                                                                    <div className="uk-width-1-2@s">
                                                                                        <label className="uk-form-label">ОГРН</label>
                                                                                        <InputMask mask="9999999999999" value={profile.entrepreneurData.ogrn} disabled={true}>
                                                                                            <input
                                                                                                type="text"
                                                                                                className="uk-input"
                                                                                            />
                                                                                        </InputMask>
                                                                                    </div>
                                                                                    <div className="uk-width-1-2@s">
                                                                                        <label className="uk-form-label">Адрес место нахождения</label>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="uk-input"
                                                                                            defaultValue={profile.entrepreneurData.address}
                                                                                            disabled={true}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uk-width-1-2@s">
                                                                                        <label className="uk-form-label">Руководитель (ФИО)</label>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="uk-input"
                                                                                            value={profile.entrepreneurData.fullName}
                                                                                            disabled={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                    {/* <div className="uk-width-1-3@s">
                                                                        <h4 className="uk-h4">Шаг 2: документы</h4>
                                                                        <label className="tm-text-xsmall uk-text-muted">
                                                                            {
                                                                                profile.entrepreneurData.type === 2 && (
                                                                                    'Выписка из ЕГРИП'
                                                                                )
                                                                            }
                                                                            {
                                                                                profile.entrepreneurData.type === 3 && (
                                                                                    'Свидетельство о регистрации'
                                                                                )
                                                                            }
                                                                        </label>
                                                                        <a href={`/public/uploads/documents/${profile.documentsData.registrationCertificate.main}`} className="tm-button-download-file" download>
                                                                            <i data-uk-icon="icon: download; ratio: 5;" />
                                                                        </a>
                                                                    </div> */}
                                                                </div>
                                                            )
                                                        }
                                                        <div className="uk-margin-large-top">
                                                            <label className="tm-checkbox uk-text-small">
                                                                <input
                                                                    className="uk-checkbox"
                                                                    type="checkbox"
                                                                    onChange={this.changeAgreement}
                                                                />
                                                                <span>
                                                                    {
                                                                        profile.entrepreneurData.category === 1 && (
                                                                            <>
                                                                                Я согласен(а) заключить <a href={`/private/investmentAssistance/` + this.state.profile.agreementsDocumentsData.investorDocumentfile} download>договор на оказание услуг по содействию в инвестировании</a>
                                                                            </>
                                                                        )
                                                                    }
                                                                    {
                                                                        profile.entrepreneurData.category === 2 && (
                                                                            <>
                                                                                Я согласен(а) заключить <a href={`/private/investmentAttracting/` + this.state.profile.agreementsDocumentsData.borrowerDocumentfile} download>договор на оказание услуг по привлечению инвестиций</a>
                                                                            </>
                                                                        )
                                                                    }
                                                                    .</span>
                                                            </label>
                                                        </div>
                                                        <div className="uk-margin-medium-top">
                                                            <button className="uk-button uk-button-primary" disabled={!this.state.profile.entrepreneurData.agreement} onClick={() => (this.createAgreementDocument(2, true))}>Подписать с SMS</button>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            {
                                                (currentStep === 2 && signStep) && (
                                                    <div className="uk-width-2-5@s">
                                                        <h4 className="uk-h4 uk-text-light">
                                                            Мы отправили код подтверждения
                                                            на<br /> номер <NumberFormat value={profile.mainData.phone} displayType={'text'} format="+#(###)###-##-##" />
                                                        </h4>
                                                        <div className="uk-margin">
                                                            <InputMask mask="9999" onChange={this.handleChange}>
                                                                <input
                                                                    type="text"
                                                                    placeholder="Код подтверждения"
                                                                    className="uk-input"
                                                                    name="verifyCode"
                                                                    ref={(input) => {
                                                                        this.verifyCode = input
                                                                    }}
                                                                />
                                                            </InputMask>
                                                        </div>
                                                        <div className="uk-margin-medium-top">
                                                            <div className="uk-button uk-button-primary" onClick={() => (this.createAgreementDocument(3, true))}>Далее</div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            {
                                                (currentStep === 3 && verificationType === 'approve') && (
                                                    <>
                                                        <div className="tm-verification-status uk-margin-medium-bottom">
                                                            <div data-uk-grid>
                                                                <div className="uk-width-2-3@s">
                                                                    <h3 className="uk-h3 uk-margin-small-bottom">Регистрация прошла успешно!</h3>
                                                                    <div className="uk-text-muted">
                                                                        {
                                                                            (profile.entrepreneurData.type === 1 || profile.entrepreneurData.type === 2) && profile.mainData.lastName + ' ' + profile.mainData.firstName + ' ' + profile.mainData.middleName
                                                                        }
                                                                        {
                                                                            profile.entrepreneurData.type === 3 && (
                                                                                profile.entrepreneurData.fullName
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="uk-width-1-3@s">
                                                                    <div className="uk-text-right">
                                                                        <div className="uk-text-small uk-text-muted" Style="padding-top: 6px;">
                                                                            {
                                                                                profile.entrepreneurData.type === 1 && 'Физическое лицо'
                                                                            }
                                                                            {
                                                                                profile.entrepreneurData.type === 2 && 'ИП'
                                                                            }
                                                                            {
                                                                                profile.entrepreneurData.type === 3 && 'ООО'
                                                                            }
                                                                        </div>
                                                                        <div className="uk-margin-small-top uk-text-bolder">
                                                                            {
                                                                                profile.entrepreneurData.category === 1 && 'Инвестор'
                                                                            }
                                                                            {
                                                                                profile.entrepreneurData.category === 2 && 'Заёмщик'
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <span>
                                                                {
                                                                    profile.entrepreneurData.category === 1 && (
                                                                        <>
                                                                            <a href={`/private/investmentAssistance/` + this.state.profile.agreementsDocumentsData.investorDocumentfile} download>Скачать подписанный договор на оказание услуг по содействию в инвестировании</a>
                                                                        </>
                                                                    )
                                                                }
                                                                {
                                                                    profile.entrepreneurData.category === 2 && (
                                                                        <>
                                                                            <a href={`/private/investmentAttracting/` + this.state.profile.agreementsDocumentsData.borrowerDocumentfile} download>Скачать подписанный договор на оказание услуг по привлечению инвестиций</a>
                                                                        </>
                                                                    )
                                                                }
                                                                .</span>
                                                        </div>
                                                    </>
                                                )
                                            }
                                            {
                                                (currentStep === 3 && verificationType === 'cancel') && (
                                                    <>
                                                        <h3 className="uk-h3">Отказ в регистрации</h3>
                                                        <div className="uk-text-muted uk-margin-medium-bottom">
                                                            Нам жаль, но вы состоите в рисковом реестре.<br />
                                                            Попробуйте позже снова.
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>)
                    }
                </div>
            )
        )
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(Balance);