import React from 'react';
import store from "../../../store";
import {connect} from "react-redux";
import InputMask from "react-input-mask";

import FormApplicationLoan from "./components/Form";
import RequestVerification from "./components/Verification";

import ProjectShortCard  from "../Projects/components/ShortCard";
import NumberFormat from 'react-number-format';

import CurrencyFormat from 'react-currency-format';

class Requests extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            profile: this.props.profile,
            openForm: false,
            activeSpinner: false,
            investmentOffer: {
                currentStep: 0
            }
        };

        this.openFormApplicationLoan = this.openFormApplicationLoan.bind(this);
        this.signInvestmentOffer = this.signInvestmentOffer.bind(this);
    }

    async componentDidMount() {
        const profileHash = localStorage.getItem("profileHash");

        if (profileHash) {
            await fetch("https://api.crowd-money.ru/profile", {
                method: 'POST',
                body: JSON.stringify({
                    data: {
                        profileHash: profileHash
                    }
                })
            })
            .then(res => res.json())
            .then(
                (result) => {
                    if (!result) {
                        localStorage.removeItem('profileHash');
                        this.props.history.push('/');
                    }

                    this.setState({
                        profile: result
                    })
                },
                (error) => {
                    console.log(error)
                }
            );
        }

        if (this.state.profile.access.authorized === false) {
            this.props.history.push('/');
        }
        else {
            store.dispatch({
                type: 'CABINET.REQUESTS',
                payload: {
                    profile: this.state.profile
                }
            });
        }
    }

    openFormApplicationLoan = () => {
        this.setState({
            openForm: true
        })
    };

    signInvestmentOffer = (projectId) => {
        const profileHash = localStorage.getItem("profileHash");

        this.setState({
            activeSpinner: true
        });

        fetch("https://api.crowd-money.ru/loans/offer", {
            method: 'POST',
            body: JSON.stringify({
                step: this.state.investmentOffer.currentStep,
                data: {
                    profileHash: profileHash,
                    projectId: projectId,
                    sentCode: this.state.investmentOffer.sentCode,
                    verifyCode: this.state.investmentOffer.verifyCode
                }
            })
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    activeSpinner: false
                });

                if (result.message) {
                    store.dispatch({
                        type: "SHOW.SNACKBAR",
                        payload: {
                            status: result.status,
                            message: result.message
                        }
                    });
                }

                if (result.field) {
                    this[result.field].focus();

                    return false;
                }

                if (result.status === "continue") {
                    this.setState({
                        investmentOffer: {
                            currentStep: 1,
                            sentCode: result.sentCode
                        }
                    });

                    return false;
                }

                if (result.status === "success") {
                    this.setState({
                        investmentOffer: {
                            currentStep: 2,
                            document: result.document
                        }
                    });
                }
            },
            (error) => {
                console.log(error)
            }
        );
    };

    handleChange = (e) => {
        this.setState({
            investmentOffer: {
                ...this.state.investmentOffer,
                [e.target.name]: e.target.value
            }
        })
    };

    render() {
        const {profile, openForm, investmentOffer, activeSpinner} = this.state;
        let hasProjectWaiting = false;
        let hasProjectNotSigned = false;
        let hasProjectActive = false;
        let hasProjectClarification = false;
        let hasProjectDenied = false;
        let hasProjectClosed = false;
        let hasProjectCollectCompleted = false;

        let projectsWaiting = [];
        let projectNotSigned = [];
        let projectActive = [];
        let projectsClarification = [];
        let projectsDenied = [];
        let projectsClosed = [];
        let projectsCollectCompleted = [];

        if (profile.projects.length > 0) {
            profile.projects.map((project) => {
                if (project.status === 0) {
                    hasProjectWaiting = true;
                    projectsWaiting.push(project)
                }
                else if (project.status === 1 && !project.investmentOfferVerifyCode) {
                    hasProjectNotSigned = true;
                    projectNotSigned.push(project);
                }
                else if ([1, 7, 8].includes(project.status) && project.investmentOfferVerifyCode) {
                    hasProjectActive = true;
                    projectActive.push(project);
                }
                else if (project.status === 2) {
                    hasProjectClarification = true;
                    projectsClarification.push(project);
                }
                else if (project.status === 3) {
                    hasProjectDenied = true;
                    projectsDenied.push(project);
                }
                else if ([4, 6].includes(project.status)) {
                    hasProjectClosed = true;
                    projectsClosed.push(project);
                }
                else if (project.status === 5) {
                    hasProjectCollectCompleted = true;
                    projectsCollectCompleted.push(project);
                }               
            });
        }

        return (
            <div className="tm-body">
                <div className="uk-container uk-section-small uk-padding-remove-top">
                    {
                        (!hasProjectNotSigned && !hasProjectWaiting && !hasProjectActive && !hasProjectClarification) && (
                            !openForm ? (
                                <>
                                    <h3 className="uk-h3 tm-font-weight-5">Заявки</h3>
                                    <h5 className="uk-h uk-text-muted uk-text-light">
                                        Для оформления займа необходимо подать заявку.<br />Нажмите на кнопку «получить займ» и заполните анкету.
                                    </h5>
                                    <div className="uk-button uk-button-primary" onClick={this.openFormApplicationLoan}>Получить займ</div>
                                </>
                            ) : <FormApplicationLoan profile={profile} project={{}} type="create" />
                        )
                    }
                    {
                        (hasProjectWaiting && !openForm && investmentOffer.currentStep === 0) && (
                            <RequestVerification />
                        )
                    }
                    {
                        investmentOffer.currentStep === 1 && (
                            <>
                                <h3 className="uk-h3 uk-margin-medium-bottom tm-font-weight-5">Инвестиционное предложение (оферта)</h3>
                                <div className="uk-card uk-card-default">
                                    <div className="uk-card-header">
                                        Подписание инвестиционного предложение (оферты) для публикации
                                    </div>
                                    <div className="uk-card-body">
                                        <div className="uk-width-2-5@s">
                                            <h4 className="uk-h4 uk-text-light">
                                                Мы отправили код подтверждения
                                                на<br/> номер <NumberFormat value={profile.mainData.phone} displayType={'text'} format="+#(###)###-##-##" />
                                            </h4>
                                            <div className="uk-margin">
                                                <InputMask mask="9999" onChange={this.handleChange}>
                                                    <input
                                                        type="text"
                                                        placeholder="Код подтверждения"
                                                        className="uk-input"
                                                        name="verifyCode"
                                                        ref={(input) => {
                                                            this.verifyCode = input
                                                        }}
                                                    />
                                                </InputMask>
                                            </div>
                                            <div className="uk-margin-medium-top">
                                                <div className="uk-button uk-button-primary" onClick={() => this.signInvestmentOffer(projectNotSigned[0]['id'])}>Далее</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    activeSpinner && (
                                        <div className="tm-spinner">
                                            <span data-uk-spinner="ratio: 3" />
                                        </div>
                                    )
                                } 
                            </>
                        )
                    }
                    {
                        investmentOffer.currentStep === 2 && (
                            <>
                                <h3 className="uk-h3 uk-margin-medium-bottom tm-font-weight-5">Инвестиционное предложение (оферта)</h3>
                                <div className="uk-card uk-card-default">
                                    <div className="uk-card-header">
                                        Публикация инвестиционного предложение (оферты) для публикации
                                    </div>
                                    <div className="uk-card-body">
                                        <div className="uk-text-small uk-text-muted">
                                            Инвестиционное предложение успешно подписано и опубликовано на платформе!<br/>
                                            В ближайшее время с офертой ознакомятся инвесторы.<br/><br/>
                                            Спасибо за выбор платформы «Crowd Money»!
                                        </div>
                                        <div className="uk-margin-medium-top">
                                            <a className="uk-button uk-button-default" href={`/private/investmentOffers/${investmentOffer.document}`} download>Скачать оферту</a>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                    {
                        (hasProjectNotSigned && !openForm && investmentOffer.currentStep === 0) && (
                            <>
                                <h3 className="uk-h3 tm-font-weight-5">Необходимо подписать оферту</h3>
                                {
                                    projectNotSigned.map((project) => (
                                        <div data-uk-grid>
                                            <ProjectShortCard dataProject={project} />
                                            <div className="uk-width-2-3@s">
                                                <div className="uk-margin">
                                                    <div className="tm-text-xsmall uk-text-muted uk-text-uppercase">
                                                        Инвестиционное предложение
                                                    </div>
                                                    <div className="uk-margin-small-top">
                                                        <a href={`/private/investmentOffers/${project.investmentOfferDocument}`} download>Скачать документ</a>
                                                    </div>
                                                    <div className="uk-margin-top">
                                                        <div className="uk-button uk-button-primary" onClick={() => this.signInvestmentOffer(projectNotSigned[0]['id'])}>Подписать с SMS</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </>
                        )
                    }
                    {
                        (hasProjectActive && !openForm && investmentOffer.currentStep === 0) && (
                            <>
                                <h3 className="uk-h3 tm-font-weight-5">Активная заявка</h3>
                                    {
                                        projectActive.map((project) => (
                                            <div data-uk-grid>
                                                <ProjectShortCard dataProject={project} />
                                                <div className="uk-width-2-3@s">
                                                    <div className="uk-margin">
                                                        <div className="tm-text-xsmall uk-text-muted uk-text-uppercase">
                                                            Тип погашения основного долга
                                                        </div>
                                                        <div className="uk-margin-small-top">
                                                            {
                                                                (project.data.repaymentType === '1' || project.data.repaymentType === 1) && "В конце срока"
                                                            }
                                                            {
                                                                (project.data.repaymentType === '2' || project.data.repaymentType === 2) && "Ежеквартально"
                                                            }
                                                            {
                                                                (project.data.repaymentType === '3' || project.data.repaymentType === 3) && "Ежемесячно"
                                                            }
                                                            {
                                                                (project.data.repaymentType === '4' || project.data.repaymentType === 4) && "Ежемесячно (аннуитет)"
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="uk-margin">
                                                        <div className="tm-text-xsmall uk-text-muted uk-text-uppercase">
                                                            {/* {
                                                                (project.data.repaymentType === '1' || project.data.repaymentType === 1) && "В конце срока"
                                                            }
                                                            {
                                                                (project.data.repaymentType === '2' || project.data.repaymentType === 2) && "Ежеквартально"
                                                            }
                                                            {
                                                                (project.data.repaymentType === '3' || project.data.repaymentType === 3) && "Ежемесячно"
                                                            } */}
                                                            Ежемесячно
                                                        </div>
                                                        <div className="uk-margin-small-top">
                                                            {
                                                                (project.data.repaymentType === '1' || project.data.repaymentType === 1) && <>0 ₽</>
                                                            }
                                                            {
                                                                (project.data.repaymentType === '2' || project.data.repaymentType === 2) && <><CurrencyFormat value={((project.collected * (project.rate / 100)) / 12).toFixed(2)} displayType={'text'} thousandSeparator={" "}/>&nbsp;₽</>
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        project.data.repaymentType === '2' && (
                                                            <div className="uk-margin">
                                                                <div className="tm-text-xsmall uk-text-muted uk-text-uppercase">
                                                                    Крайний платёж
                                                                </div>
                                                                <div className="uk-margin-small-top">
                                                                    {
                                                                        project.data.repaymentType === '2' && <><CurrencyFormat value={(parseFloat(project.collected) + (project.collected * (project.rate / 100)) / 12).toFixed(2)} displayType={'text'} thousandSeparator={" "}/>&nbsp;₽</>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    <div className="uk-margin">
                                                        <div className="tm-text-xsmall uk-text-muted uk-text-uppercase">
                                                            Сумма к погашению
                                                        </div>
                                                        <div className="uk-margin-small-top">
                                                            {
                                                                project.data.repaymentType === '2' && <><CurrencyFormat value={(parseFloat(project.collected) + (project.collected * (project.rate / 100)) / 12 * project.data.duration)} displayType={'text'} thousandSeparator={" "}/>&nbsp;₽</>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                            </>
                        )
                    }
                    {
                        (hasProjectClarification && !openForm && investmentOffer.currentStep === 0) && (
                            <>
                                <h3 className="uk-h3 tm-font-weight-5">Требуются уточнения</h3>
                                {
                                    projectsClarification.map((project) => (
                                        <div data-uk-grid>
                                            <ProjectShortCard dataProject={project} />
                                        </div>
                                    ))
                                }
                            </>
                        )
                    }
                    {
                        (hasProjectDenied && !openForm && investmentOffer.currentStep === 0) && (
                            <>
                                <h3 className="uk-h3 tm-font-weight-5">Отказано</h3>
                                {
                                    projectsDenied.map((project) => (
                                        <div data-uk-grid>
                                            <ProjectShortCard dataProject={project} />
                                        </div>
                                    ))
                                }
                            </>
                        )
                    }
                    {
                        (hasProjectClosed && !openForm && investmentOffer.currentStep === 0) && (
                            <>
                                <h3 className="uk-h3 tm-font-weight-5">Закрыт</h3>
                                {
                                    projectsClosed.map((project) => (
                                        <div data-uk-grid>
                                            <ProjectShortCard dataProject={project} />
                                        </div>
                                    ))
                                }
                            </>
                        )
                    }
                    {
                        (hasProjectCollectCompleted && !openForm && investmentOffer.currentStep === 0) && (
                            <>
                                <h3 className="uk-h3 tm-font-weight-5">Сбор средств завершён</h3>
                                {
                                    projectsCollectCompleted.map((project) => (
                                        <div data-uk-grid>
                                            <ProjectShortCard dataProject={project} />
                                        </div>
                                    ))
                                }
                            </>
                        )
                    }                    
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(Requests);