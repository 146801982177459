import React from 'react';
import { Link } from "react-router-dom";
import InputMask from "react-input-mask";
import Slider from '@material-ui/core/Slider';
import store from "../../../store";
import CurrencyFormat from 'react-currency-format';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import VideoModalButton from '../../../partials/others/VideoModalButton';

UIkit.use(Icons)

const advantages = [
    {
        icon: "wallet4.png",
        title: "Выгоднее вклада в банке",
        description: "Наша инвестиционная платформа позволяет зарабатывать на вложениях от 15% до 32% годовых в период от 1 до 12 месяцев. Для стабильного уровня доходности рекомендуем инвесторам диверсифицировать портфель инвестиций из нескольких проектов (займов)."
    },
    {
        icon: "icon.png",
        title: "Надежность платежей",
        description: "Все денежные средства по сделкам зачисляются на специальный номинальный счет, открытый в банке. Распоряжаться деньгами могут только инвесторы. Личные средства всегда можно вывести на личный банковский счёт. Банк защищает деньги инвесторов от любых претензий — блокировок и арестов."
    },
    {
        icon: "dollar.png",
        title: "Сколько можно заработать?",
        description: "Ставки по займам варьируются в зависимости от двух факторов: индекса благонадежности заемщика и рейтинга проекта (заявки на займ). Все прозрачно и просто: инвестируете деньги заемщику по оговоренной процентной ставке на указанные цели и получаете доход."
    },
    {
        icon: "workspace.png",
        title: "Почему инвестиционная платформа?",
        description: "В настоящее время существует большое количество заемщиков, представителей малого и среднего бизнеса, которые не могут получить кредит в банке. На инвестиционной платформе более гибкий подход, позволяющий привлечь средства инвестора с защитой вложений."
    }
];

const steps = [
    {
        image: "step_1.png",
        title: "Регистрация",
        description: "Прохождение процедуры регистрации и стандартной проверки на сайте"
    },
    {
        image: "step_2.png",
        title: "Выбор проекта",
        description: "Знакомство с информацией о проекте и со сведениями о заемщике, выбор суммы для инвестиций и заключение договора"
    },
    {
        image: "step_3.png",
        title: "Витрина проектов",
        description: "На витрине представлены проекты для инвестирования с присвоенным уровнем риска, проведенным анализом и оценкой заемщика"
    },
    {
        image: "step_4.png",
        title: "Инвестирование",
        description: "Перечисление на номинальный счет платформы средств, бенефициаром которых являетесь только Вы"
    }
];

const guarantees = [
    {
        icon: "star.png",
        title: "Отбор заемщиков",
        description: "Публикация заявки на привлечение финансирования происходит после прохождения экспертного анализа и скоринг-оценки"
    },
    {
        icon: "shield.png",
        title: "Поручительство",
        description: "Заключение договора поручительства с бенефициарными владельцами заемщика является обязательным условием выдачи займа на инвестиционной платформе"
    },
    {
        icon: "info.png",
        title: "Юридическая защита",
        description: "При выдаче займа заключается договор инвестирования, который имеет положительную судебную практику по взысканию средств инвесторов"
    }
];

const services = [
    {
        title: "Федеральная служба по финансовому мониторингу",
        icon: "service-1.png"
    },
    {
        title: "Федеральная налоговая служба России",
        icon: "service-2.png"
    },
    {
        title: "Федеральная служба государственной статистики",
        icon: "service-3.png"
    },
    {
        title: "Федеральное казначейство России",
        icon: "service-4.png"
    },
    {
        title: "Федеральная служба судебных приставов",
        icon: "service-5.png"
    },
    {
        title: "Генеральная прокуратура",
        icon: "service-6.png"
    },
    {
        title: "Верховный Суд Российской Федерации",
        icon: "service-7.png"
    },
    {
        title: "Министерство цифрового развития и связи",
        icon: "service-8.png"
    },
    {
        title: "Министерство внутренних дел России",
        icon: "service-9.png"
    }
];

const questions = [
    [
        {
            title: "Что такое платформа «Crowd Money»?",
            description: "«Crowd Money» - онлайн-платформа взаимного финансирования, на которой размещаются заявки на выдачу займа для реализации государственных контрактов и для развития бизнеса. Платформа действует по принципам краудлендинга: позволяет инвестировать средства с доходностью выше вклада в банке и привлекать денежные средства для развития бизнеса."
        },
        {
            title: "Как это работает в двух словах?",
            description: "Заключается договор инвестирования, который по смыслу является стандартным договором займа. Все платежи проходят через номинальный счет платформы, которая лишь управляет счетом и не может списать средства на свои нужды. По истечению срока заемщик перечисляет сумму инвестиций плюс доход по процентам на номинальный счет платформы, и пополнение средств отражается в личном кабинете инвестора."
        },
        {
            title: "Сколько можно заработать?",
            description: "Ставки по договорам инвестирования варьируются в зависимости от двух факторов: индекса благонадежности заемщика и рейтинга заявки. Все прозрачно и просто: инвестируете деньги заемщику по оговоренной процентной ставке на указанные цели и получаете доход."
        },
        {
            title: "Как быть с налогами?",
            description: "При заключении договора инвестирования (займа) есть две стороны процесса налогообложения: инвестор и заемщик. Налоговый кодекс возлагает на заемщика обязанность налогового агента. Платформа «Crowd Money» не удерживает с инвестора никаких налогов, эта задача возложена на заемщика. Если заемщик не удержал НДФЛ или не сообщил об этом, то никаких санкций к инвесторам со стороны налоговой не будет."
        }
    ],
    [
        {
            title: "Какие шаги для регистрации на платформе?",
            description: "Пять шагов для успешных инвестиций на платформе «Crowd Money»:<br /> 1. Регистрация пользователя на сайте; <br />2. Выбор статуса участника: инвестор либо заемщик; <br />3. Выбор проекта для инвестирования / подача заявки на займ; <br />4. Заключение договора инвестирования (займа); <br />5. Возврат инвестиций с процентным доходом."
        },
        {
            title: "Зачем заемщикам обращаться к инвестиционной платформе?",
            description: "При получении кредита в банке необходимо иметь твердое обеспечение, а без обеспечения - сроки проверочных мероприятий со стороны банка могут затянуть процесс на длительное время с высокой вероятностью отказа. При привлечении суммы выше 1 млн. рублей возникает «кредитный вакуум». На инвестиционной платформе более гибкий подход, позволяющий привлечь средства инвестора с гарантированной защитой вложений."
        },
        {
            title: "Почему стоит инвестировать на платформе?",
            description: "Ключевое слово - «простые» инвестиции. Все прозрачно и понятно: инвестируете средства заемщику по оговоренной процентной ставке, никаких скрытых платежей, никаких дополнительных налогов, только стандартная ставка по НДФЛ, удерживаемая налоговым агентом."
        },
        {
            title: "Какие гарантии?",
            description: "Мы регистрируем только надежных заемщиков, прошедших отбор скоринг- оценки для минимизации рисков инвестора. Анализ заявок на выдачу займа проводят квалифицированные финансовые эксперты. Платформа следит за соблюдением условий займа. При наступлении просрочки и невыплаты займа мы защищаем интересы инвестора в судебном порядке и проводим взыскание средств. В планах предоставить возможность застраховать договор инвестирования (займа) для компенсации возможных потерь."
        }
    ],
    [
        {
            title: "Как проходят платежи?",
            description: "Все платежи проходят через номинальный счет платформы, которая лишь удерживает свою комиссию, согласно Тарифам. Средства на номинальный счет зачисляют инвесторы, и по их распоряжению платформа перечисляет сумму займа на расчетный счет заемщика. По истечению срока заемщик перечисляет сумму инвестиций плюс доход по процентам на номинальный счет. Инвестор может вывести деньги на банковский счет в любой момент.\n"
        },
        {
            title: "Что такое номинальный счет?",
            description: "С 1 января 2020 года все инвестиционные платформы обязаны использовать номинальный счёт. Это необходимо для защиты средств пользователей. Правила предусмотрены законом No 259-ФЗ «О привлечении инвестиций с помощью инвестиционных платформ и о внесении изменений в отдельные законодательные акты Российской Федерации». Номинальный счёт — это банковский счёт, на котором хранятся деньги инвесторов, и только с их распоряжения перечисляются платформой на счет заемщика. Никто не может присвоить средства инвестора с номинального счёта."
        },
        {
            title: "Что делать если заемщик не возвращает средства займа?",
            description: "В большинстве случаев просрочка до 7 дней носит технический характер и связана с небольшой задержкой. Платформа мониторит сроки по займам и уведомляет заемщика заранее о наступлении даты уплаты платежей. В случае если заемщик не выплатил средства по договору инвестирования (займа) - инвестор может обратиться к платформе для организации судебного взыскания по платежам. Юристы займутся взысканием средств в пользу инвестора."
        },
        {
            title: "Не нашли ответа на Ваш вопрос?",
            description: "Напишите нам на почту info@crowd-money.ru или свяжитесь с нами по телефону +7 (985) 712-34-38, и мы оперативно Вам ответим."
        }
    ]
];

const partners = [
    { logotype: 'partners-image-2.png' },
    { logotype: 'partners-image-3.svg' },
    { logotype: 'partners-image-4.png' },
    { logotype: 'partners-image-6.svg' },
    { logotype: 'partners-image-7.svg' },
    { logotype: 'partners-image-8.svg' },
    { logotype: 'partners-image-9.png' },
    { logotype: 'partners-image-10.svg' },
    { logotype: 'partners-image-11.svg' }
];

const calculatePricesMarks = [
    {
        // value: 5,
        label: '100 000',
    },
    {
        // value: 95,
        label: '10 000 000',
    },
];

const calculateDurationsMarks = [
    {
        // value: 5,
        label: '3',
    },
    {
        // value: 95,
        label: '12',
    },
];

function valuetext(value) {
    return `${value}°C`;
}

class Investors extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            documents: [],
            calculatePrice: 100000,
            calculateDuration: 3,
            calculateTotal: 106250
        };

        UIkit.scroll('#tm-section-how-it-works');

        this.changeCalculatePrice = this.changeCalculatePrice.bind(this);
        this.changeCalculateDuration = this.changeCalculateDuration.bind(this);
        this.changeCalculateTotal = this.changeCalculateTotal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.signup = this.signup.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    componentDidMount() {
        store.dispatch({ type: 'MAIN.HOME' });

        fetch("https://api.crowd-money.ru/documents", {
            method: 'POST'
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        documents: result
                    })
                },
                (error) => {
                    console.log(error)
                }
            );
    }

    changeCalculatePrice = (g, value) => {
        this.setState({
            calculatePrice: value
        });

        this.changeCalculateTotal();
    };

    changeCalculateDuration = (g, value) => {
        this.setState({
            calculateDuration: value
        });

        this.changeCalculateTotal();
    };

    changeCalculateTotal = () => {
        const calculateTotal = this.state.calculatePrice * 0.25 / 12 * this.state.calculateDuration + this.state.calculatePrice

        this.setState({
            calculateTotal: calculateTotal.toFixed(0)
        })
    };

    handleChange = (e) => {
        this.setState({
            profile: {
                [e.target.name]: e.target.value
            }
        })
    };

    onKeyDown = (e) => {
        if (e.which === 13) {
            this.signup();
        }
    };

    signup = () => {
        store.dispatch({
            type: "HIDE.SNACKBAR"
        });

        fetch("https://api.crowd-money.ru/auth/signup", {
            method: 'POST',
            body: JSON.stringify({
                action: "signup",
                step: 1,
                data: this.state.profile
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.message) {
                        store.dispatch({
                            type: "SHOW.SNACKBAR",
                            payload: {
                                status: result.status,
                                message: result.message
                            }
                        });
                    }

                    if (result.field === 'phone') {
                        this.phone.focus();

                        return false;
                    }

                    store.dispatch({
                        type: 'MAIN.SIGNUP.STEP_TWO',
                        payload: {
                            signupData: {
                                activeStep: 2,
                                profile: {
                                    ...this.state.profile,
                                    sentCode: result.sentCode
                                }
                            }
                        }
                    });

                    this.props.history.push("signup");
                }, (error) => {
                    console.log(error)
                }
            )
    };

    render() {
        const { isLoaded, documents } = this.state;

        return (
            isLoaded && (
                <div>
                    <div className="uk-container uk-margin-large-bottom">
                        <div className="tm-section-hero">
                            <div className="uk-light uk-text-center">
                                <h1 className="uk-h1">
                                    <span>
                                        Зарабатывайте на инвестициях<br /> и приумножайте Ваш капитал
                                    </span>
                                </h1>
                                <p>
                                    Наша инвестиционная платформа позволяет зарабатывать на выдаче<br /> займа для реализации проектов и государственных контрактов<br /> с минимальным риском вложения Ваших средств.
                                </p>
                            </div>
                            <div className="tm-form-phone uk-text-center">
                                <InputMask mask="+7(999)999-99-99" onChange={this.handleChange} onKeyDown={this.onKeyDown}>
                                    <input
                                        type="text"
                                        placeholder="Номер мобильного телефона"
                                        className="uk-input"
                                        name="phone"
                                        ref={(input) => { this.phone = input }}
                                    />
                                </InputMask>
                                <div className="uk-button uk-button-primary" onClick={this.signup}>
                                    НАЧАТЬ
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="uk-container uk-margin-large-bottom">
                        <div className="tm-form-calculate">
                            <div className="tm-form-calculate-head uk-text-center">
                                Рассчитайте сумму инвестиции
                            </div>
                            <div className="tm-form-calculate-body">
                                <div className="tm-calculate-sliders">
                                    <div className="tm-calculate-category">
                                        <div className="uk-flex uk-flex-middle">
                                            <label className="uk-form-label">Сумма</label>&nbsp;
                                            <div className="uk-flex uk-flex-middle tm-box">
                                                <CurrencyFormat value={this.state.calculatePrice} displayType={'text'} thousandSeparator={" "} />&nbsp;
                                                <label className="uk-form-label">руб.</label>
                                            </div>
                                        </div>
                                        <Slider
                                            defaultValue={5000}
                                            marks={calculatePricesMarks}
                                            step={1000}
                                            min={100000}
                                            max={10000000}
                                            onChange={this.changeCalculatePrice}
                                        />
                                    </div>
                                    <div className="tm-calculate-category">
                                        <div className="uk-flex uk-flex-middle">
                                            <label className="uk-form-label">Срок</label>
                                            <div className="uk-flex uk-flex-middle tm-box">
                                                <div>{this.state.calculateDuration}</div>&nbsp;
                                                <label className="uk-form-label">мес.</label>
                                            </div>
                                            <label className="uk-form-label uk-margin-auto-left">Ставка 25%</label>
                                        </div>
                                        <Slider
                                            defaultValue={2}
                                            step={1}
                                            min={3}
                                            max={12}
                                            marks={calculateDurationsMarks}
                                            onChange={this.changeCalculateDuration}
                                        />
                                    </div>
                                </div>
                                <div className="tm-income">
                                    <label className="uk-margin-right">Ваш доход</label>&nbsp;
                                    <CurrencyFormat value={this.state.calculateTotal} displayType={'text'} thousandSeparator={" "} />&nbsp;
                                    <label>руб.</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="uk-container uk-margin-large-bottom">
                        <div className="tm-mineconom-info">                           
                            <img src={require('../../../assets/images/minecomom.png')} alt="" />
                            <div className="text-block">
                                <div className="uk-margin-bottom">
                                    <div className="uk-text-justify">
                                        Лицензия Центрального Банка Российской Федерации на осуществление деятельности оператора инвестиционной платформы №22 от 19.01.2021
                                    </div>
                                    <div className="uk-margin-small-top">
                                        <a href="https://www.cbr.ru/" target="_blank">Перейти на сайт Банка России</a>
                                    </div>
                                </div>
                                <div>
                                    <div className="uk-text-justify">
                                        Инвестиционная платформа прошла отбор по программе субсидирования доступа субъектов малого и среднего предпринимательства к альтернативным способам финансирования от Министерства экономического развития РФ.
                                    </div>
                                    <div className="uk-margin-small-top">
                                        <a href="https://www.economy.gov.ru/material/news/msp_smogut_poluchit_kompensaciyu_pri_privlechenii_investiciy_na_11_kraudfandingovyh_platformah.html" target="_blank">Перейти на сайт Минэкономразвития</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="uk-container uk-margin-large-bottom">
                        <div className="uk-light uk-text-center">
                            <h1 className="uk-h1">
                                <span>
                                    Почему инвесторы выбирают<br />Crowd Money
                                </span>
                            </h1>
                        </div>
                        <div className="tm-section-advantages">
                            {
                                advantages.map((advantage) => (
                                    <div className="tm-advantage">
                                        <img src={require(`../../../assets/images/icons/${advantage.icon}`)} />
                                        <h3 className="uk-h3">{advantage.title}</h3>
                                        <p className="uk-text-center">{advantage.description}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="uk-container uk-margin-xlarge-bottom">
                        <div className="tm-section-partners">
                            <div className="uk-light uk-text-center uk-margin-large-bottom">
                                <h1 className="uk-h1">
                                    <span>
                                        Наши партнёры
                                    </span>
                                </h1>
                            </div>
                            <div className="uk-position-relative uk-visible-toggle uk-light" tabIndex="-1" data-uk-slider>
                                <ul className="uk-flex uk-flex-middle uk-slider-items uk-child-width uk-child-width-auto@s">
                                    {
                                        partners.map((partner) => (
                                            <li className="uk-text-center">
                                                <div className="uk-panel uk-margin-left uk-margin-right">
                                                    <img src={require(`../../../assets/images/${partner.logotype}`)} style={{ height: 60 }} />
                                                </div>
                                            </li>
                                        ))
                                    }
                                </ul>
                                <a className="uk-position-center-left uk-position-small" href="#"
                                    data-uk-slidenav-previous data-uk-slider-item="previous"></a>
                                <a className="uk-position-center-right uk-position-small" href="#"
                                    data-uk-slidenav-next data-uk-slider-item="next"></a>
                            </div>
                        </div>
                    </div>
                    <div className="uk-container uk-margin-large-bottom">
                        <div className="tm-section-how-it-works" id="tm-section-how-it-works">
                            <div className="uk-light uk-text-center uk-margin-medium-bottom">
                                <h1 className="uk-h1">
                                    <span>
                                        Как работает инвестиционная<br />платформа
                                    </span>
                                </h1>
                            </div>
                            <div>
                                <div className="works-box">
                                    <div className="works-head">
                                        <div>
                                            <span>01</span>
                                            <h1>Анализ</h1>
                                            <VideoModalButton buttonName="Смотреть видео" url="/public/videos/crowdmoney.mp4" />
                                        </div>
                                    </div>
                                    <div className="works-box-body-step-one">
                                        <div className="step-one-info">
                                            <div>
                                                <div className="works-step-name-img">
                                                    <img src={require(`../../../assets/images/icons/analysis_01.png`)} />
                                                    <h3 className="uk-h3">Анализ заёмщиков</h3>
                                                </div>
                                                <p className="uk-text-left">
                                                    Передовая и полностью автоматизированная скоринг-модель нашей платформы позволяет оценивать заёмщиков по более 100 критериям и исключает доступ неблагонадежных компаний. Прошедшим отбор присваивается рейтинг и индекс благонадежности.
                                                </p>
                                            </div>
                                            <div>
                                                <div className="works-step-name-img">
                                                    <img src={require(`../../../assets/images/icons/analysis_02.png`)} />
                                                    <h3 className="uk-h3">Интеграция с ведомственными ресурсами</h3>
                                                </div>
                                                <p className="uk-text-left">
                                                    Наша автоматизированная система скоринг-оценки заемщиков интегрирована с государственными базами данных, что позволяет присвоить наиболее объективную оценку пользователям платформы и снизить риск наличия мошеннических действий и неблагонадежных участников.
                                                </p>
                                            </div>
                                            <div>
                                                <div className="works-step-name-img">
                                                    <img src={require(`../../../assets/images/icons/analysis_03.png`)} />
                                                    <h3 className="uk-h3">Особые критерии отбора</h3>
                                                </div>
                                                <p className="uk-text-left">
                                                    Наличие в рисковых реестрах, сведения о плановых и внеплановых проверках, действующие решения банка    о приостановлении операции (блокировки) по счетам, анализ кредитной истории.
                                                </p>
                                                <a href={`/documents/${documents[3]['data'][0]}`} Style="text-decoration: underline;" download>Политика отбора заявок</a>
                                            </div>

                                        </div>
                                        <div className="ved-logos">
                                            {
                                                services.map((service) => (
                                                    <div className="ved-logo-box">
                                                        <div className="ved-logo">
                                                            <img src={require(`../../../assets/images/services/${service.icon}`)} />
                                                        </div>
                                                        <span>{service.title}</span>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="works-box">
                                    <div className="works-head">
                                        <div>
                                            <span>02</span>
                                            <h1>Защита</h1>
                                            <VideoModalButton buttonName="Смотреть видео" url="/public/videos/crowdmoney.mp4" />
                                        </div>
                                    </div>
                                    <div className="uk-flex uk-grid uk-child-width-1-2@s">
                                        <div className="uk-margin-medium-bottom">
                                            <div className="works-step-name-img">
                                                <img src={require(`../../../assets/images/icons/security_01.png`)} />
                                                <h3 className="uk-h3">Защита интересов инвестора</h3>
                                            </div>
                                            <p className="uk-text-left">
                                                Платформа позволяет заключить инвестору договор инвестирования с заемщиком и в дальнейшем уведомляет заемщика о наступлении плановой даты погашения. При возникновении просрочки направляет досудебную претензию заемщику. Зачастую 90% случаев просрочки заемщики погашают до направления претензий. В редких случаях приходится подавать исковое заявление в суд, а после получения исполнительного листа Платформа собственными силами и с привлечением судебных приставов организует возврат средств в адрес инвестора.
                                            </p>
                                        </div>
                                        <div>
                                            <div className="works-step-name-img">
                                                <img src={require(`../../../assets/images/icons/security_02.png`)} />
                                                <h3 className="uk-h3">Платежи по номинальному счету</h3>
                                            </div>
                                            <p className="uk-text-left">
                                                Согласно <a href="http://www.consultant.ru/document/cons_doc_LAW_330652" target="_blank">Федеральному закону 259-ФЗ</a> все средства по сделкам краудлендинга должны зачисляться на номинальный счёт в банке. Распоряжаться деньгами могут только инвесторы, но при этом владелец счёта — платформа. Личные средства всегда можно вывести на личный банковский счёт. Банк защищает деньги инвесторов от любых претензий — блокировок и арестов.
                                            </p>
                                            <div className="uk-grid">
                                                <a href={`/documents/${documents[5]['data'][0]}`} className="uk-margin-right" Style="text-decoration: underline;" download>Правила работы платформы</a>
                                                <a href={`/documents/${documents[11]['data'][0]}`} Style="text-decoration: underline;" download>Тарифы платформы</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="uk-container uk-margin-large-bottom">
                        <div>
                            <div className="uk-light uk-text-center uk-margin-large-bottom">
                                <img src={require(`../../../assets/images/logotype 1.png`)} style={{ height: 60 }} />
                                <h1 className="uk-h1">
                                    <span>
                                        Как начать инвестировать?
                                    </span>
                                </h1>
                                <p>
                                    У нас вы можете стать инвестором просто и не выходя из дома.<br />
                                    Вам понадобится всего пару минут :)
                                </p>
                            </div>
                            <div className="tm-section-steps-box">
                            <div className="tm-section-steps-screens">
                                    <img className="screen-1" src={require(`../../../assets/images/screens/screen_1.png`)} />
                                    <img className="screen-2" src={require(`../../../assets/images/screens/screen_2.png`)} />
                                    <img className="screen-3" src={require(`../../../assets/images/screens/screen_3.png`)} />
                                    <img className="screen-4" src={require(`../../../assets/images/screens/screen_4.png`)} />
                                </div>
                                <div className="tm-section-steps-main">
                                    <div className="tm-section-steps">
                                        {
                                            steps.map((step, index) => (
                                                <>
                                                    <div className="tm-step">
                                                        <img src={require(`../../../assets/images/steps/${step.image}`)} />
                                                        <h3 className="uk-h3">{step.title}</h3>
                                                        <p>{step.description}</p>
                                                    </div>
                                                </>
                                            )
                                            )
                                        }
                                    </div>
                                    <div className="uk-text-left">
                                        <Link to="/signup" className="uk-button uk-button-primary">НАЧАТЬ</Link>
                                        <Link to="/documents" className="uk-button uk-button-default">ДОКУМЕНТЫ</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="uk-container uk-margin-large-bottom">
                        <div className="uk-light uk-text-center">
                            <h1 className="uk-h1">
                                <span>
                                    Гарантии
                                </span>
                            </h1>
                        </div>
                        <div className="tm-section-guarantees">
                            {
                                guarantees.map((guarantee) => (
                                    <div className="tm-guarantee">
                                        <img src={require(`../../../assets/images/icons/${guarantee.icon}`)} />
                                        <h3 className="uk-h3">{guarantee.title}</h3>
                                        <p className="uk-text-center">{guarantee.description}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="uk-container uk-margin-large-bottom">
                        <div className="tm-invest-start-box">
                            <div>
                                <div className="uk-light tm-invest-start-head">
                                    <h1 className="uk-h1 ">
                                        <span>
                                            Хотите начать<br />инвестировать?
                                        </span>
                                    </h1>
                                    <p>
                                        Зарегистрируйтесь на нашей платформе<br />и начните инвестировать уже сегодня!
                                    </p>
                                </div>
                                <div className="tm-invest-start">
                                    <Link to="/signup" className="uk-button uk-button-primary">НАЧАТЬ</Link>
                                </div>
                            </div>
                            <div className="tm-invest-start-images">
                                <img src={require(`../../../assets/images/Exclude.svg`)} />
                                <img src={require(`../../../assets/images/IpadPro.png`)} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-container uk-margin-large-bottom">
                        <div className="uk-light uk-text-center">
                            <h1 className="uk-h1 uk-margin-medium-bottom">
                                <span>
                                    Частые вопросы
                                </span>
                            </h1>
                        </div>
                        <div className="tm-section-faq" id="tm-section-faq">
                            <div className="uk-width-3-4@s uk-margin-auto">
                                <ul uk-switcher="connect: .uk-switcher;" className="tm-tabs-faq uk-subnav">
                                    <li><a href="#">Платформа</a></li>
                                    <li><a href="#">Регистрация</a></li>
                                    <li><a href="#">Вывод средств</a></li>
                                </ul>
                                <ul className="uk-switcher uk-margin-bottom">
                                    <li>
                                        <ul data-uk-accordion>
                                            {
                                                questions[0].map((question) => (
                                                    <li>
                                                        <a className="uk-accordion-title" href="#">{question.title}</a>
                                                        <div className="uk-accordion-content uk-text-justify">
                                                            <p dangerouslySetInnerHTML={{ __html: question.description }} />
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </li>
                                    <li>
                                        <ul data-uk-accordion>
                                            {
                                                questions[1].map((question) => (
                                                    <li>
                                                        <a className="uk-accordion-title" href="#">{question.title}</a>
                                                        <div className="uk-accordion-content uk-text-justify">
                                                            <p dangerouslySetInnerHTML={{ __html: question.description }} />
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </li>
                                    <li>
                                        <ul data-uk-accordion>
                                            {
                                                questions[2].map((question) => (
                                                    <li>
                                                        <a className="uk-accordion-title" href="#">{question.title}</a>
                                                        <div className="uk-accordion-content uk-text-justify">
                                                            <p dangerouslySetInnerHTML={{ __html: question.description }} />
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )
        )
    }
}

export default Investors;