import React, { useState } from 'react';
import fileDownload from 'js-file-download';
import store from "../../../store";

function AgreementRequestBKI(props) {
    const [data, setData] = useState({ url: "", activeSpinner: false });

    const createAgreementRequestBKI = () => {
        setData({
            ...data,
            activeSpinner: true,
        })

        fetch("https://api.crowd-money.ru/profile/AgreementRequestBKI", {
            method: 'POST',
            body: JSON.stringify({
                action: "getAgreementRequestBKI",
                data: {
                    profileId: props.profileId
                }
            })
        })
            .then(res => res.blob())
            .then((blob) => {
                store.dispatch({
                    type: "SHOW.SNACKBAR",
                    payload: {
                        status: "success",
                        message: "Согласие сформировано"
                    }
                });
                setData({ ...data, activeSpinner: false, })
                fileDownload(blob, "Согласие.pdf")
            },
                (error) => {
                    console.log(error)
                    setData({
                        ...data,
                        activeSpinner: false,
                    })
                }
            );
    }

    return (
        <>
            {data.activeSpinner == true && (<span data-uk-spinner="ratio: 1.5" />) ||
                <div onClick={createAgreementRequestBKI}>
                    {props.element}
                </div>                          
            }
        </>
    )
}
export default AgreementRequestBKI;