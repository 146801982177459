import React, { useState } from 'react';
import fileDownload from 'js-file-download';
import store from "../../../store";

function StatementQualifiedDescription(props) {     
    const [data, setData] = useState({url:"", activeSpinner: false});

    const createStatementQualified = () => {
        setData({
            ...data,            
            activeSpinner: true,
        })

        fetch("https://api.crowd-money.ru/investments", {
            method: 'POST',
            body: JSON.stringify({
                action: "getStatementQualified",
                data: {
                    profileId: props.profileId
                }
            })
        })
            .then(res => res.blob())
            .then((blob) => {
                store.dispatch({
                    type: "SHOW.SNACKBAR",
                    payload: {
                        status: "success",
                        message: "Заявление сформировано"
                    }
                });
                setData({...data, activeSpinner: false,})
                fileDownload(blob, "Заявление.pdf")
            },
                (error) => {
                    console.log(error)
                    setData({
                        ...data,            
                        activeSpinner: false,
                    })
                }
            );
    }

    return (
        <div className="uk-card uk-card-small uk-card-default">
            <div className="uk-card-body">
                <p className="uk-text-justify">В соответствии с&nbsp;
                    <a class="uk-link uk-text-primary" href={`http://www.consultant.ru/document/cons_doc_LAW_330652/`} target='_blank'>Федеральным законом 259-ФЗ</a>
                    , сумма инвестиций для неквалифицированного инвестора - физического лица ограничена в сумме не более 600 тыс. рублей в год.
                    Для получения статуса квалифицированного инвестора необходимо направить&nbsp;{data.activeSpinner == true && (<span data-uk-spinner="ratio: 1.5" />) ||
                        (<a class="uk-link uk-text-primary" onClick={createStatementQualified}>заявление</a>)} на e-mail: info@crowd-money.ru
                </p>
            </div>
        </div>
    )
}
export default StatementQualifiedDescription;