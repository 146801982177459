import React from 'react';
import { Link } from "react-router-dom";

const sections = [
    {
        title: "О ПЛАТФОРМЕ",
        links: [
            {
                title: "Кто мы",
                path: "about"
            },
            {
                title: "Раскрытие информации",
                path: "documents"
            },
            {
                title: "Создать заявку",
                path: "signin"
            }
        ]
    },
    {
        title: "ИНВЕСТОРАМ",
        links: [
            {
                title: "Платформа",
                path: "/#tm-section-how-it-works"
            },
            {
                title: "Гарантии",
                path: "/#tm-section-guarantees"
            },
            {
                title: "FAQ",
                path: "/#tm-section-faq"
            }
        ]
    },
    {
        title: "ЗАЕМЩИКАМ",
        links: [
            {
                title: "Платформа",
                path: "/borrowers/#tm-section-how-it-works"
            },
            {
                title: "Гарантии",
                path: "/borrowers/#tm-section-guarantees"
            },
            {
                title: "FAQ",
                path: "/borrowers/#tm-section-faq"
            }
        ]
    }
];

class Footer extends React.Component {
    render() {
        const currentPath = window.location.pathname.split('/')[1];
        const currentYear = new Date().getFullYear();

        return (
            <div className="tm-footer">
                <div className="uk-container uk-section-small">
                    <div className="uk-grid uk-grid-collapse uk-flex uk-flex-middle">
                        <div className="uk-width-3-5@s">
                            <div className="tm-sections-links">
                                <div className="uk-grid">
                                    {
                                        sections.map((section, sectionIndex) => (
                                            <div>
                                                <div className="tm-section">
                                                    <div className="tm-section-title">{section.title}</div>
                                                    <div className="tm-section-links">
                                                        {
                                                            section.links.map((link) => (
                                                                <>
                                                                    <div>
                                                                        <Link to={link.path}>
                                                                            {link.title}
                                                                        </Link>
                                                                    </div>
                                                                </>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    <div>
                                        <div className="tm-section">
                                            <div className="tm-section-title">Мы в соцсетях</div>
                                            <div className="tm-social-links">
                                                <a href="https://t.me/crowdmru" className="tm-social-link tm-social-link-tg" target="_blank" />
                                                <a href="https://zen.yandex.ru/id/5f5b6372763c003416f29f26?lang=ru" className="tm-social-link tm-social-link-dz" target="_blank" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-expand@s">
                            <div className="tm-contact-address">
                                ООО «Крауд Инвест», ИНН 7713475803<br />
                                ОГРН 1207700187793, КПП 771501001<br />
                                Юридический адрес: 127254, г. Москва<br />
                                вн. тер. г. муниципальный округ Бутырский<br />
                                ул. Добролюбова, д. 18, помещ. 3/1
                                <div className="uk-margin-small-top">
                                    <a href="mailto:info@crowd-money.ru">info@crowd-money.ru</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="uk-grid uk-flex uk-flex-middle">
                        <div className="uk-width-expand">
                            <hr className="footer-hr"/>
                            <div className="tm-copyright">
                                &copy; Copyright Crowd Money {currentYear}.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer;