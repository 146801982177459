import React, { useState } from 'react';
import fileDownload from 'js-file-download';
import store from "../../../store";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import "react-datepicker/dist/react-datepicker.css";

function BalanceHistoryGeneration(props) {
    registerLocale('ru', ru);

    let currentDate = new Date();
    let dateFrom = new Date();
    dateFrom.setMonth(currentDate.getMonth() - 2)

    const [data, setData] = useState({
        url: "",
        activeSpinner: false,
        dateFrom: dateFrom,
        dateTo: currentDate
    });

    const handleChange = (date, name, checking) => {
        if (checking()) {
            setData({
                ...data,
                [name]: date
            })
        }
    };

    const checkDateFrom = (date) => {
        return date <= data.dateTo;
    }

    const checkDateTo = (date) => {
        return date >= data.dateFrom;
    }

    const checkDateRange = (date1, date2) => {
        let monthDiff = date1.getMonth() - date2.getMonth();
        let yearDiff = date1.getYear() - date2.getYear();
        let diff = monthDiff + yearDiff * 12;

        if (Math.abs(diff) > 2) {
            store.dispatch({
                type: "SHOW.SNACKBAR",
                payload: {
                    status: "error",
                    message: "Диапазон дат превышает 3 месяца"
                }
            });

            return false;
        }

        return true;
    }

    const generateBalanceHistory = (fileType) => {
        if (checkDateRange(data.dateFrom, data.dateTo)) {
            setData({
                ...data,
                activeSpinner: true
            })
           
            data.dateFrom.setHours(0, 0, 0, 0);
            data.dateTo.setHours(23, 59, 59, 999);
          
            fetch("https://api.crowd-money.ru/payments/generateHistory", {
                method: 'POST',
                body: JSON.stringify({
                    action: "getBalanceHistory",
                    data: {
                        profileHash: props.profile.access.hash,
                        profileId: props.profileId,
                        type: fileType,
                        projectsIds: props.profile.projects.map(project => project.id).join(', '),
                        dateRange: {                            
                            dateFrom: Math.floor(data.dateFrom.valueOf() / 1000), 
                            dateTo:  Math.floor(data.dateTo.valueOf() / 1000)
                        }                        
                    }
                })
            })
                .then(res => res.blob())
                .then((blob) => {
                    store.dispatch({
                        type: "SHOW.SNACKBAR",
                        payload: {
                            status: "success",
                            message: "Отчет сформирован"
                        }
                    });
                    setData({ ...data, activeSpinner: false, })
                    fileDownload(blob, `История операций.${fileType}`)
                },
                    (error) => {
                        console.log(error)
                        setData({
                            ...data,
                            activeSpinner: false,
                        })
                    }
                );
        }

    }

    return (
        <>
            {
                data.activeSpinner == true &&
                (
                    <button
                        className="uk-button uk-button-primary uk-margin-auto-left"
                        disabled={true}>
                        <span data-uk-spinner="ratio: 1" />
                    </button>
                )
                ||
                (
                    <div className="uk-margin-auto-left uk-flex">
                        <div className="uk-margin-auto-left uk-flex uk-width-1-2@s">
                            <div className="uk-margin-right">
                                <DatePicker
                                    selected={data.dateFrom}
                                    className="uk-input"
                                    locale="ru"
                                    dateFormat="dd.MM.yyyy"                                                                                            
                                    onChange={(date) => handleChange(date, "dateFrom", () => checkDateFrom(date))}
                                />
                            </div>
                            <div className="uk-margin-right">
                                <DatePicker
                                    selected={data.dateTo}
                                    className="uk-input"
                                    locale="ru"
                                    dateFormat="dd.MM.yyyy"   
                                    onChange={(date) => handleChange(date, "dateTo", () => checkDateTo(date))}
                                />
                            </div>
                        </div>
                        <button
                            className="uk-button uk-button-primary"
                            disabled={props.disabled}
                            onClick={() => generateBalanceHistory("pdf")}>
                            {window.innerWidth < 600 ? 'Скачать' : 'Скачать отчет по операциям'}
                        </button>
                    </div>
                )
            }
        </>
    )
}

export default BalanceHistoryGeneration;