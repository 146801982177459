import React from 'react';
import {connect} from "react-redux";
import InputMask from "react-input-mask";

class SectionMain extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { parentThis, data, onChange, disableSection } = this.props;

        return (
            <>
                <h3 className="uk-h3">Основные данные</h3>
                <div className="uk-grid-row-small" data-uk-grid>
                    <div className="uk-width-1-2@s">
                        <label className="uk-form-label">Фамилия</label>
                        <input
                            type="text"
                            className="uk-input"
                            maxLength={16}
                            name="lastName"
                            ref={(input) => {parentThis.lastName = input}}
                            defaultValue={data.lastName}
                            disabled={true}
                            onChange={onChange}
                        />
                    </div>
                    <div className="uk-width-1-2@s">
                        <label className="uk-form-label">Имя</label>
                        <input
                            type="text"
                            className="uk-input"
                            maxLength={16}
                            name="firstName"
                            ref={(input) => {parentThis.firstName = input}}
                            defaultValue={data.firstName}
                            disabled={true}
                            onChange={onChange}
                        />
                    </div>
                    <div className="uk-width-1-2@s">
                        <label className="uk-form-label">Отчество</label>
                        <input
                            type="text"
                            className="uk-input"
                            maxLength={16}
                            name="middleName"
                            ref={(input) => {parentThis.middleName = input}}
                            defaultValue={data.middleName}
                            disabled={true}
                            onChange={onChange}
                        />
                    </div>
                    <div className="uk-width-1-2@s">
                        <label className="uk-form-label">Дата рождения</label>
                        <InputMask mask="99.99.9999" onChange={onChange} value={data.birthday} disabled={disableSection}>
                            <input
                                type="text"
                                className="uk-input"
                                name="birthday"
                                ref={(input) => {parentThis.birthday = input}}
                            />
                        </InputMask>
                    </div>
                    <div className="uk-width-1-2@s">
                        <label className="uk-form-label">Номер телефона</label>
                        <InputMask mask="+7(999)999-99-99" onChange={onChange} value={data.phone} disabled={disableSection}>
                            <input
                                type="text"
                                className="uk-input"
                                name="phone"
                                ref={(input) => {parentThis.phone = input}}
                            />
                        </InputMask>
                    </div>
                    <div className="uk-width-1-2@s">
                        <label className="uk-form-label">E-mail</label>
                        <input
                            type="text"
                            className="uk-input"
                            maxLength={32}
                            name="email"
                            ref={(input) => {parentThis.email = input}}
                            defaultValue={data.email}
                            onChange={onChange}
                            disabled={disableSection}
                        />
                    </div>
                    <div className="uk-width-1-2@s">
                        <label className="uk-form-label">СНИЛС</label>
                        <InputMask mask="999999999 99" onChange={onChange} value={data.snils} disabled={disableSection}>
                            <input
                                type="text"
                                className="uk-input"
                                name="snils"
                                ref={(input) => {parentThis.snils = input}}
                            />
                        </InputMask>
                    </div>
                    <div className="uk-width-1-2@s">
                        <label className="uk-form-label">ИНН</label>
                        <InputMask mask="999999999999" onChange={onChange} value={data.inn} disabled={disableSection}>
                            <input
                                type="text"
                                className="uk-input"
                                name="inn"
                                ref={(input) => {parentThis.inn = input}}
                            />
                        </InputMask>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(SectionMain);