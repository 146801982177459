import React from 'react';
import {connect} from 'react-redux';
import InputMask from "react-input-mask";
import store from "../../../store";
import {Link} from "react-router-dom";
import ShowAndHidePassword from "../../../partials/others/ShowAndHidePassword"

class SignUp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...this.props.signupData,
            profile: {
                ...this.props.signupData.profile,
                gender: 1
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSignup = this.handleSignup.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    handleChange = (e) => {
        this.setState({
            profile: {
                ...this.state.profile,
                [e.target.name]: e.target.value
            }
        })
    };

    onKeyDown = (e) => {
        if (e.which === 13) {
            this.handleSignup(this.state.activeStep);
        }
    };

    handleSignup = (step) => {
        const $this = this; 
        let partnerCode = "";

        if (step == 4){
            const queryParams = new URLSearchParams(window.location.search);
            partnerCode = queryParams.get('partnerCode') ? queryParams.get('partnerCode') : "";
        }

        store.dispatch({
            type: "HIDE.SNACKBAR"
        });

        fetch( "https://api.crowd-money.ru/auth/signup", {
            method: 'POST',
            body: JSON.stringify({
                action: "signup",
                step: this.state.activeStep,
                data: this.state.profile,
                partnerCode: partnerCode
            })
        })
        .then(res => res.json())
        .then(
            (result) => {
                if (result.status === "success") {
                    localStorage.setItem("profileHash", result.profileHash);

                    this.setState({
                        activeStep: 5
                    })
                }
                else {
                    if (result.message) {
                        store.dispatch({
                            type: "SHOW.SNACKBAR",
                            payload: {
                                status: result.status,
                                message: result.message
                            }
                        });
                    }

                    if (step === 1) {
                        if (result.field === 'phone') {
                            $this.phone.focus();

                            return false;
                        }

                        this.setState({
                            activeStep: 2,
                            profile: {
                                ...this.state.profile,
                                sentCode: result.sentCode
                            }
                        });
                    }
                    else if (step === 2) {
                        if (result.field === 'verifyCode') {
                            $this.verifyCode.focus();

                            return false;
                        }

                        this.setState({
                            activeStep: 3
                        });
                    }
                    else if (step === 3) {
                        if (result.field === 'lastName') {
                            $this.lastName.focus();

                            return false;
                        }
                        else if (result.field === 'firstName') {
                            $this.firstName.focus();

                            return false;
                        }
                        else if (result.field === 'middleName') {
                            $this.middleName.focus();

                            return false;
                        }

                        this.setState({
                            activeStep: 4
                        });

                    }
                    else if (step === 4) {
                        if (result.field === 'password') {
                            $this.password.focus();

                            return false;
                        }
                        else if (result.field === 'passwordConfirm') {
                            $this.passwordConfirm.focus();

                            return false;
                        }
                    }
                }
            },(error) => {
                console.log(error)
            }
        )
    };

    componentDidMount() {
        store.dispatch({ type: 'MAIN.SIGNUP' });
    }

    render() {
        const {activeStep} = this.state;

        return (
            <div className="tm-section-signin">
                <div className="uk-container uk-section">
                    {
                        activeStep === 1 && (
                            <>
                                <h3 className="uk-h3 uk-margin-small">Регистрация</h3>
                                <div className="uk-text-small uk-text-muted">Создание аккаунта на платформе</div>
                                <ul className="uk-tab uk-margin-medium-top uk-margin-medium-bottom">
                                    <li><Link to="/signin">Вход в аккаунт</Link></li>
                                    <li className="uk-active"><Link to="/signup">Регистрация</Link></li>
                                </ul>
                                <div className="uk-width-2-5@s">
                                    <h4 className="uk-h4 uk-text-light">Введите номер телефона и мы
                                        пришлем ответным сообщением СМС :)</h4>
                                    <div className="uk-margin">
                                        <InputMask mask="+7(999)999-99-99" onChange={this.handleChange} onKeyDown={this.onKeyDown}>
                                            <input
                                                type="text"
                                                placeholder="Номер телефона"
                                                className="uk-input"
                                                name="phone"
                                                ref={(input) => {this.phone = input}}
                                            />
                                        </InputMask>
                                    </div>
                                    <div className="uk-margin-medium-top">
                                        <div className="uk-button uk-button-large uk-button-primary uk-width" onClick={() => (this.handleSignup(1))}>Далее
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                    {
                        activeStep === 2 && (
                            <>
                                <h3 className="uk-h3 uk-margin-small">Регистрация</h3>
                                <div className="uk-text-small uk-text-muted">Создание аккаунта на платформе</div>
                                <ul className="uk-tab uk-margin-medium-top uk-margin-medium-bottom">
                                    <li><Link to="/signin">Вход в аккаунт</Link></li>
                                    <li className="uk-active"><Link to="/signup">Регистрация</Link></li>
                                </ul>
                                <div className="uk-width-2-5@s">
                                    <h4 className="uk-h4 uk-text-light">
                                        Мы отправили код подтверждения на<br /> номер {this.state.profile.phone}
                                    </h4>
                                    <div className="uk-margin">
                                        <InputMask mask="9999" onChange={this.handleChange} onKeyDown={this.onKeyDown}>
                                            <input
                                                type="text"
                                                placeholder="Код подтверждения"
                                                className="uk-input"
                                                name="verifyCode"
                                                ref={(input) => {this.verifyCode = input}}
                                            />
                                        </InputMask>
                                    </div>
                                    <div className="uk-margin-medium-top">
                                        <div className="uk-button uk-button-large uk-button-primary uk-width" onClick={() => (this.handleSignup(2))}>Далее
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                    {
                        activeStep === 3 && (
                            <>
                                <h3 className="uk-h3 uk-margin-small">Анкета пользователя</h3>
                                <div className="uk-text-small uk-text-muted uk-margin-bottom">Шаг 1</div>
                                <div className="uk-width-2-5@s">
                                    <div className="uk-margin">
                                        <label className="uk-form-label">Фамилия</label>
                                        <input
                                            type="text"
                                            placeholder="Например: Краснова"
                                            className="uk-input"
                                            maxLength={16}
                                            name="lastName"
                                            ref={(input) => {this.lastName = input}}
                                            onKeyDown={this.onKeyDown}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="uk-margin">
                                        <label className="uk-form-label">Имя</label>
                                        <input
                                            type="text"
                                            placeholder="Например: Татьяна"
                                            className="uk-input"
                                            maxLength={16}
                                            name="firstName"
                                            ref={(input) => {this.firstName = input}}
                                            onKeyDown={this.onKeyDown}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="uk-margin">
                                        <label className="uk-form-label">Отчество</label>
                                        <input
                                            type="text"
                                            placeholder="Например: Александровна"
                                            className="uk-input"
                                            maxLength={16}
                                            name="middleName"
                                            ref={(input) => {this.middleName = input}}
                                            onKeyDown={this.onKeyDown}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="uk-margin">
                                        <label className="uk-form-label">Ваш пол</label>
                                        <div>
                                            <label>
                                                <input
                                                    className="uk-radio"
                                                    type="radio"
                                                    name="gender"
                                                    value="1"
                                                    checked
                                                    onChange={this.handleChange}
                                                />
                                                <span>Мужской</span>
                                            </label>
                                        </div>
                                        <div className="uk-margin-small-top">
                                            <label>
                                                <input
                                                    className="uk-radio"
                                                    type="radio"
                                                    name="gender"
                                                    value="2"
                                                    onChange={this.handleChange}
                                                />
                                                <span>Женский</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="uk-margin-medium-top">
                                        <div className="uk-button uk-button-large uk-button-primary uk-width" onClick={() => (this.handleSignup(3))}>Далее
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                    {
                        activeStep === 4 && (
                            <>                           
                                <h3 className="uk-h3 uk-margin-small">Анкета пользователя</h3>
                                <div className="uk-text-small uk-text-muted uk-margin-bottom">Шаг 2</div>
                                <div className="uk-width-2-5@s">
                                    <div className="uk-margin">
                                        <label className="uk-form-label">Пароль</label>
                                        <ShowAndHidePassword 
                                            onKeyDown={this.onKeyDown}                              
                                            onChange={this.handleChange}
                                            placeholder="Введите пароль"
                                            name="password"
                                            context={this}
                                        />                                        
                                    </div>
                                    <div className="uk-margin">
                                        <ShowAndHidePassword 
                                            onKeyDown={this.onKeyDown}                              
                                            onChange={this.handleChange}
                                            placeholder="Повторите пароль"
                                            name="passwordConfirm"
                                            context={this}
                                        />                                        
                                    </div>
                                    <div className="uk-margin">
                                        <h5 className="uk-h5">
                                            Заполнить анкету для получения статуса участника платформы?
                                        </h5>
                                    </div>
                                    <div className="uk-margin">
                                        <label>
                                            <input
                                                className="uk-radio"
                                                type="radio"
                                                name="agreementFillForm"
                                                checked
                                                onChange={this.handleChange}
                                            />
                                            <span>Да, я хочу стать участником</span>
                                        </label>
                                    </div>
                                    <div className="uk-margin">
                                        <label>
                                            <input
                                                className="uk-radio"
                                                type="radio"
                                                name="agreementFillForm"
                                                onChange={this.handleChange}
                                            />
                                            <span>Нет, заполню позже</span>
                                        </label>
                                    </div>
                                    <div className="uk-margin-medium-top">
                                        <div className="uk-button uk-button-large uk-button-primary uk-width" onClick={() => (this.handleSignup(4))}>Зарегистрироваться
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                    {
                        activeStep === 5 && (
                            <>
                                <h3 className="uk-h3 uk-margin-small">Регистрация </h3>
                                <div className="uk-text-small uk-text-muted uk-margin-bottom">Создание аккаунта на платформе</div>
                                <ul className="uk-tab uk-margin-medium-top uk-margin-medium-bottom">
                                    <li><Link to="/signin">Вход в аккаунт</Link></li>
                                    <li className="uk-active"><Link to="/signup">Регистрация</Link></li>
                                </ul>
                                <div className="uk-width-2-5@s">
                                    <h4 className="uk-h4 uk-text-bolder">Поздравляем!</h4>
                                    <p>
                                        Спасибо, что выбрали нас.<br />
                                        Мы рады приветствовать Вас на нашей платформе!<br /><br />
                                        Желаем хорошей и легкой работы!
                                    </p>
                                    <hr />
                                    <p>
                                        Если есть вопросы, пишите на почту<br /><a href="mailto:info@crowd-money.ru">info@crowd-money.ru</a><br /><br />

                                        <span className="uk-text-bolder">C наилучшими пожеланиями,</span><br />
                                        Ваш Crowd Money.
                                    </p>
                                    <div className="uk-margin-medium-top">
                                        <Link to="/cabinet" className="uk-button uk-button-large uk-button-primary uk-width">В личный кабинет
                                        </Link>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(SignUp);