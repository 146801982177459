import React from 'react';
import CalculateSchedule from '../../../partials/others/CalculateSchedule';
import store from "../../../store";
import { connect } from "react-redux";
import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'

UIkit.use(Icons);

class Schedule extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            profile: {
                access: {
                    group: 0
                }
            }
        }
    }

    async componentDidMount() {
        const profileHash = localStorage.getItem("profileHash");

        if (profileHash) {
            await fetch("https://api.crowd-money.ru/profile", {
                method: 'POST',
                body: JSON.stringify({
                    data: {
                        profileHash: profileHash
                    }
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        if (!result) {
                            localStorage.removeItem('profileHash');
                            this.props.history.push('/');
                        }

                        this.setState({
                            profile: result
                        })
                    },
                    (error) => {
                        console.log(error)
                    }
                );
        }

        if (this.state.profile.access.authorized === false) {
            this.props.history.push('/');
        }
        else {
            store.dispatch({
                type: 'CABINET.INDEX',
                payload: {
                    profile: this.state.profile
                }
            });
        }
    }

    render() {
        const { profile } = this.state

        return (
            <div className="tm-body">
                <div className="tm-section-documents">
                    <div className="uk-container uk-container-expand uk-section-small uk-padding-remove-top">
                        <CalculateSchedule profile={profile} viewDocs={false} customVisible={true} adminCustomVisible={true} />
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(Schedule);