import React, { useState } from 'react';
import CurrencyValue from '../CurrencyValue';

function ScheduleModal(props) {

    return (
        <div id={`scheduleModal${props.investment.id}`} className="uk-flex-top" data-uk-modal>
            <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-width-2-3@s">
                <button className="uk-modal-close-default" type="button" data-uk-close></button>
                <h3 className="uk-h3 tm-font-weight-5">График погашения</h3>                
                <div className="uk-overflow-auto uk-margin">
                    <table className="tm-table-balance-operations uk-table uk-text-center uk-table-divider uk-table-hover uk-table-middle">
                        <thead>
                            <tr>
                                <th className="uk-text-center">Номер платежа (месяц)</th>
                                <th className="uk-text-center">Задолженность на начало периода</th>
                                <th className="uk-text-center">Погашение основного долга по займу</th>
                                <th className="uk-text-center">Ежемесячные проценты за пользование займом</th>
                                <th className="uk-text-center">Количество дней пользования средствами займа</th>
                                <th className="uk-text-center">Выплаты Займодавцу</th>
                                <th className="uk-text-center">Дата платежа (не позднее)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.scheduleData.rows.map(row => (
                                    <tr>
                                        <td>
                                            {row.number}
                                        </td>
                                        <td>
                                            <CurrencyValue value={row.debtAmount} />
                                        </td>
                                        <td>
                                            <CurrencyValue value={row.repaymentAmount} />
                                        </td>
                                        <td>
                                            <CurrencyValue value={row.rateMonthly} />
                                        </td>
                                        <td>
                                            {row.daysCount}
                                        </td>
                                        <td>
                                            <CurrencyValue value={row.paymentAmount} />
                                        </td>
                                        <td>
                                            {row.paymentDate}
                                        </td>
                                    </tr>
                                ))
                            }
                            <tr className="uk-text-bold">
                                <td colSpan={5}>
                                    Общие выплаты за весь период:
                                </td>
                                <td>
                                    <CurrencyValue value={props.scheduleData.total} />
                                </td>
                                <td />
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
export default ScheduleModal;