import React from 'react';
import InputMask from "react-input-mask";
import { Link } from "react-router-dom";
import store from "../../../store";
import ShowAndHidePassword from "../../../partials/others/ShowAndHidePassword"

class SignIn extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSignin = this.handleSignin.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);

        this.state = {
            profile: {}
        };
    }

    componentDidMount() {
        store.dispatch({ type: 'MAIN.SIGNIN' });
    }

    handleChange = (e) => {
        this.setState({
            profile: {
                ...this.state.profile,
                [e.target.name]: e.target.value
            }
        })
    };

    onKeyDown = (e) => {
        if (e.which === 13) {
            this.handleSignin();
        }
    };

    handleSignin = () => {
        const $this = this;

        store.dispatch({
            type: "HIDE.SNACKBAR"
        });

        fetch("https://api.crowd-money.ru/auth/signin", {
            method: 'POST',
            body: JSON.stringify({
                action: "signin",
                data: this.state.profile
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.status === "success") {
                        localStorage.setItem("profileHash", result.profileHash);

                        this.props.history.push('/cabinet');
                    }
                    else {
                        store.dispatch({
                            type: "SHOW.SNACKBAR",
                            payload: {
                                status: result.status,
                                message: result.message
                            }
                        });

                        if (result.field === 'phone') {
                            $this.phone.focus();

                            return false;
                        }
                        else if (result.field === 'password') {
                            $this.password.focus();

                            return false;
                        }

                    }
                }, (error) => {
                    console.log(error)
                }
            )
    };

    render() {
        return (
            <div className="tm-section-signin">
                <div className="uk-container uk-section">
                    <h3 className="uk-h3 uk-margin-small">Вход в аккаунт</h3>
                    <div className="uk-text-small uk-text-muted">Вход в существующий аккаунт</div>
                    <ul className="uk-tab uk-margin-medium-top uk-margin-medium-bottom">
                        <li className="uk-active"><Link to="/signin">Вход в аккаунт</Link></li>
                        <li><Link to="/signup">Регистрация</Link></li>
                    </ul>
                    <div className="uk-width-2-5@s uk-margin-medium-top">
                        <h4 className="uk-h4 uk-text-light">Вам нужно всего 30 секунд, чтобы заполнить анкету :)</h4>
                        <div className="uk-margin">
                            <InputMask mask="+7(999)999-99-99" onChange={this.handleChange} onKeyDown={this.onKeyDown}>
                                <input
                                    type="text"
                                    placeholder="Номер мобильного телефона"
                                    className="uk-input"
                                    name="phone"
                                    ref={(input) => { this.phone = input }}
                                />
                            </InputMask>
                        </div>
                        <div className="uk-margin">
                            <ShowAndHidePassword
                                onKeyDown={this.onKeyDown}
                                onChange={this.handleChange}
                                placeholder="Пароль"
                                name="password"
                                context={this}
                            />
                        </div>
                        <div className="uk-margin uk-text-center">
                            <Link to="/restore">Забыли пароль?</Link>
                        </div>
                        <div className="uk-margin-medium-top">
                            <div className="uk-button uk-button-large uk-button-primary uk-width" onClick={this.handleSignin}>Войти в аккаунт</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SignIn;