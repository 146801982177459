import React from 'react';
import CalculateSchedule from '../../../partials/others/CalculateSchedule';
import store from "../../../store";
import CurrencyValue from '../../../partials/others/CurrencyValue';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'

UIkit.use(Icons);

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: this.props.profile,
            profiles: {
                1: [],
                2: [],
                3: []
            },
            profilesCount: 0,
            projects: [],
            isLoaded: false,
            isLoadedDocs: false           
        }
    }

    async componentDidMount() {

        const profileHash = localStorage.getItem("profileHash");

        if (profileHash) {
            await fetch("https://api.crowd-money.ru/profile", {
                method: 'POST',
                body: JSON.stringify({
                    data: {
                        profileHash: profileHash
                    }
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        if (!result) {
                            localStorage.removeItem('profileHash');
                            this.props.history.push('/');
                        }

                        this.setState({
                            profile: result
                        })
                    },
                    (error) => {
                        console.log(error)
                    }
                );
        }

        if (this.state.profile.access.authorized === false) {
            this.props.history.push('/');
        }
        else {
            store.dispatch({
                type: 'CABINET.MAIN',
                payload: {
                    profile: this.state.profile
                }
            });

            await fetch("https://api.crowd-money.ru/loans", {
                method: 'POST',
                body: JSON.stringify({
                    data: {
                        type: "cabinet",
                        profileId: this.state.profile.access.id
                    }
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            ...this.state,
                            projects: result
                        })
                    },
                    (error) => {
                        console.log(error)
                    }
                );


            fetch("https://api.crowd-money.ru/profile", {
                method: 'POST',
                body: JSON.stringify({
                    action: 'getProfiles',
                    data: {
                        profileId: this.state.profile.access.id
                    }
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            ...this.state,
                            profiles: {
                                1: result.filter((profile) => { return (profile.group === 1 && profile.verification.length !== 0) }),
                                2: result.filter((profile) => { return (profile.group === 2 && profile.verification.length !== 0) }),
                                3: result.filter((profile) => { return (profile.group === 3 || profile.verification.length === 0) })
                            },
                            profilesCount: result.length,
                            isLoaded: true
                        })
                    },
                    (error) => {
                        console.log(error)
                    }
                );
        }
    }   
    
    getCollectProjects = () => {
        let allCollect = 0;
        if (this.state.projects.length != 0) {
            this.state.projects.forEach((project) => {
                // if (project.status == 5) {
                allCollect += parseFloat(project.collected)
                // }

            })
        }
        return allCollect;
    }

    render() {
        const { isLoaded, profiles, profile, profilesCount} = this.state;

        return (
            <div className="tm-body">
                <div className="tm-section-documents">
                    <div className="uk-container uk-section-small uk-padding-remove-top">
                        <h3 className="uk-h3 tm-font-weight-5 uk-margin-medium-bottom">Основная информация об инвестиционной платформе для {profile.access.group == 1 ? "инвестора" : "заемщика"}</h3>
                        <div className="uk-overflow-auto">
                            <div data-uk-grid>
                                <div className="uk-width-2-3@m">
                                    <div className="tm-crowdmoney-hello">
                                        <img src={require('../../../assets/images/hello.png')} alt="" />
                                        <div className="tm-description">
                                            <div className="tm-logotype" />
                                            <h3 className="uk-h3 uk-margin-small-top">
                                                Привет!<br />
                                                Я Crowd Money :)
                                            </h3>
                                            <p className="uk-text-small uk-text-muted">
                                                Наша платформа поможет тебе начать<br /> инвестировать в интересные проекты<br /> или получить займ под  новый проект.<br />
                                                Минимальная сумма инвестиций<br /> составляет 5000 рублей.
                                            </p>
                                            <div className="uk-margin-small-top">
                                                <Link to="/cabinet/documents">Узнать больше</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-width-1-3@m uk-inline">
                                    <div className="tm-crowdmoney-counts">
                                        {isLoaded &&
                                            (
                                                <>
                                                    <h3 className="uk-h3">Мы в цифрах...</h3>
                                                    <div>Пользователей:</div>
                                                    <h3 className="uk-h3 uk-margin-remove-top">
                                                        {profilesCount}
                                                    </h3>
                                                    <div>Заёмщиков:</div>
                                                    <h3 className="uk-h3 uk-margin-remove-top">
                                                        {profiles[2].length}
                                                    </h3>
                                                    <div>Выдано займов:</div>
                                                    <h3 className="uk-h3 uk-margin-remove-top">
                                                        {<CurrencyValue value={this.getCollectProjects()} currencyType={"₽"} />}
                                                    </h3>
                                                </>
                                            ) ||
                                            (
                                                <span className="uk-position-center" data-uk-spinner="ratio: 3" />
                                            )
                                        }
                                    </div>
                                </div>                                                                                  
                            </div>   
                            <CalculateSchedule profile={profile} viewDocs={true} customVisible={true}/>                          
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(Main);