import React from 'react';
import store from "../../../store";

class About extends React.Component {
    componentDidMount() {
        store.dispatch({ type: 'MAIN.ABOUT' });
    }

    render() {
        return (
            <div className="tm-section-about">
                <div className="uk-container uk-margin-bottom uk-padding">
                    <div className="uk-light uk-text-center">
                        <h1 className="uk-h1">
                            <span>
                                О платформе
                            </span>
                        </h1>
                    </div>
                </div>
                <div className="uk-container uk-margin-large-bottom tm-container-about">
                    <div className="tm-about-info-box">                
                        <div>
                            <h2 className="uk-h2">
                                Crowd Money
                            </h2>
                            <div className="uk-text-left uk-margin-bottom">
                                <p>
                                    Это передовая инвестиционная платформа взаимного финансирования по принципам краудлендинга. Платформа выступает в роли посредника при заключении договора инвестирования между сторонами. 19 января 2021 года оператор инвестиционной платформы ООО «Крауд Инвест» включен
                                    в соответствующий реестр Центрального банка Российской Федерации.
                                </p>
                            </div>
                            <a href="https://www.cbr.ru/finorg/foinfo/?ogrn=1207700187793" target="_blank">Перейти на сайт Банка России</a>
                        </div>
                        <img src={require('../../../assets/images/about_01.png')} alt="" />
                    </div>
                </div>
                <div className="uk-container uk-margin-large-bottom tm-container-about">
                    <div className="tm-mineconom-info">
                        <img src={require('../../../assets/images/mail.png')} alt="" />
                        <div className="text-block">
                            <div>
                                <div className="uk-text-justify">
                                    Инвестиционная платформа "Crowd money" прошла отбор по программе субсидирования доступа субъектов малого и среднего предпринимательства к альтернативным способам финансирования. Программа реализуется в рамках национального проекта «Малое и среднее предпринимательство и развитие индивидуальной предпринимательской инициативы». Субсидия предоставляется в целях возмещения части затрат на обеспечение доступа субъектов МСП к платформе для коллективного инвестирования.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-container uk-margin-large-bottom tm-container-about">
                    <div className="tm-about-info-box-crowd">
                        
                        <div>
                            <h2 className="uk-h2">
                                Краудлендинг
                            </h2>
                            <div className="uk-text-left uk-margin-bottom">
                                <p>
                                    Это процесс, при котором группа инвесторов дает займ индивидуальному предпринимателю или компании.
                                    В России принят Федеральный закон от 02.08.2019 N 259-ФЗ
                                    «О привлечении инвестиций с использованием инвестиционных платформ и о внесении изменений в отдельные законодательные акты Российской Федерации», который регулирует сферу краудлендинга.
                                </p>
                            </div>
                        </div>
                        <img src={require('../../../assets/images/about_02.png')} alt="" />
                    </div>
                </div>
                <div className="uk-container uk-margin-large-bottom tm-container-about">
                    <div className="tm-about-info-box-contact">
                        <div>
                            <h2 className="uk-h2">
                                Наши контакты
                            </h2>
                            <div className="uk-text-left uk-margin-bottom">
                                <div className="uk-margin">
                                    <p>
                                        Тел.: <span>+79857123438</span><br />
                                        Почта: <span>info@crowd-money.ru</span><br />
                                        {/* Техническая поддержка: <span>support@crowd-money.ru</span><br /> */}
                                        {/* Предложение о сотрудничестве: <span>agent@crowd-money.ru</span><br /> */}
                                    </p>
                                </div>
                            </div>
                            <div className="uk-margin">
                                <h4 className="uk-h4">Организация</h4>
                                <p>
                                    Общество с ограниченной ответственностью «Крауд Инвест»<br />ИНН 7713475803<br />ОГРН 1207700187793<br />КПП 771501001<br />
                                </p>
                                <h4 className="uk-h4">Юридический адрес</h4>
                                <p>
                                    127254, г. Москва<br /> вн. тер. г. муниципальный округ Бутырский<br /> ул. Добролюбова, д. 18, помещ. 3/1
                                </p>
                            </div>
                        </div>
                        <iframe className="tm-about-map" src="https://yandex.ru/map-widget/v1/?um=constructor%3A4b8cd65081c474eb5f267569e3b22100aa7cdcf7db911c071f0cf74be52388be&amp;source=constructor"></iframe>
                    </div>
                </div>
            </div >
        )
    }
}

export default About;