import React from 'react';
import store from '../../../store';
import {connect} from "react-redux";
import DocumentsList from "./components/List";

class Documents extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            profile: this.props.profile
        }
    }

    async componentDidMount() {
        const profileHash = localStorage.getItem("profileHash");

        if (profileHash) {
            await fetch("https://api.crowd-money.ru/profile", {
                method: 'POST',
                body: JSON.stringify({
                    data: {
                        profileHash: profileHash
                    }
                })
            })
            .then(res => res.json())
            .then(
                (result) => {
                    if (!result) {
                        localStorage.removeItem('profileHash');
                        this.props.history.push('/');
                    }

                    this.setState({
                        profile: result
                    })
                },
                (error) => {
                    console.log(error)
                }
            );
        }

        if (this.state.profile.access.authorized === false) {
            this.props.history.push('/');
        }
        else {
            store.dispatch({
                type: 'CABINET.DOCUMENTS',
                payload: {
                    profile: this.state.profile
                }
            });
        }
    }

    render() {
        return (
            <div className="tm-body">
                <div className="tm-section-documents">
                    <div className="uk-container uk-section-small uk-padding-remove-top">
                        <h3 className="uk-h3 tm-font-weight-5">Документы</h3>
                        <DocumentsList showRules />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(Documents);