import QRCode from 'qrcode'
import React from 'react';
import { useEffect, useState } from 'react';

 function PaymentCode(props){
    const [data, setData] = useState("");

        QRCode.toDataURL(
            `ST00012|Name=ООО «КРАУД ИНВЕСТ»|PersonalAcc=40702810720020100003|BankName=ТКБ БАНК ПАО, г.Москва|BIC=044525388|CorrespAcc=30101810800000000388|Purpose=Пополнение лицевого счёта №${props.accountNumber} на платформе "Crowd Money". Без НДС|PayeeINN=7713475803|KPP=771501001`
        ,{
            errorCorrectionLevel: 'L',           
            scale: 3                   
          })
            .then((data) => {
                setData(data)
            })

    return (
        <div>        
             <img src={data}/>            
        </div>
      );
}

 export default PaymentCode;