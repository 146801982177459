import React from 'react';
import {Provider, connect} from 'react-redux';

import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import UIkit from 'uikit';

import store from './store';

import Sidebar from './partials/Sidebar';
import Header from './partials/Header';
import Footer from './partials/Footer';

import About from './pages/main/About';
import Investors from './pages/main/Investors';
import Borrowers from './pages/main/Borrowers';

import SignUp from './pages/main/SignUp';
import SignIn from './pages/main/SignIn';
import Restore from './pages/main/Restore';

import Cabinet from './pages/cabinet';
import Settings from './pages/cabinet/Profiles';
import Balance from './pages/cabinet/Balance';
import Investments from './pages/cabinet/Investments';
import Expired from './pages/cabinet/Expired';
import Archive from './pages/cabinet/Archive';

import Main from './pages/cabinet/Main';
import ProjectsTable from './pages/cabinet/ProjectsTable';
import Partners from './pages/cabinet/Partners';
import PaymentsInvestors from './pages/cabinet/PaymentsInvestors';
import Schedule from './pages/cabinet/Schedule';
import Claims from './pages/cabinet/Claims';
import Project from './pages/cabinet/Project';
import Projects from './pages/cabinet/Projects';
import ProjectEdit from './pages/cabinet/Project/components/Edit';
import Requests from './pages/cabinet/Requests';
import Loans from './pages/cabinet/Loans';
import Documents from './pages/cabinet/Documents';
import PageDocuments from './pages/Documents';


class App extends React.Component {
    constructor(props) {
        super(props);
        this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
    }

    handleCloseSnackbar = () => {
        store.dispatch({
            type: "HIDE.SNACKBAR"
        });
    };

    render() {
        document.title = this.props.page.title;

        return (
            <div className={this.props.page.class}>
                <Provider store={store}>
                    <Router history={this.props.history}>
                        {
                            this.props.sidebar.active && <Sidebar />
                        }
                        <Header pageType={this.props.page.type}/>
                        <Switch>
                            <Route path="/about" component={About} exact />
                            <Route path="/documents" component={PageDocuments} exact />
                            <Route path="/" component={Investors} exact />
                            <Route path="/borrowers" component={Borrowers} exact />
                            <Route path="/signup" component={SignUp} exact />
                            <Route path="/signin" component={SignIn} exact />
                            <Route path="/restore" component={Restore} exact />
                            <Route path="/cabinet" component={Cabinet} exact />                            
                            <Route path="/profile/edit/:id" component={Settings} exact />
                            <Route path="/cabinet/balance" component={Balance} exact />
                            <Route path="/projects/" component={Projects} exact />
                            <Route path="/project/:id" component={Project} exact />
                            <Route path="/project/edit/:id" component={ProjectEdit} exact />
                            <Route path="/investments" component={Investments} exact />
                            <Route path="/Expired" component={Expired} exact />
                            <Route path="/cabinet/requests" component={Requests} exact />
                            <Route path="/cabinet/loans" component={Loans} exact />
                            <Route path="/archive" component={Archive} exact />
                            <Route path="/cabinet/documents" component={Documents} exact />
                            <Route path="/partners" component={Partners} exact />
                            <Route path="/paymentsInvestors" component={PaymentsInvestors} exact />
                            <Route path="/schedule" component={Schedule} exact />
                            <Route path="/claims" component={Claims} exact />
                            <Route path="/projectsTable" component={ProjectsTable} exact />
                            <Route path="/main" component={Main} exact />
                            <Redirect to="/" />
                        </Switch>
                        {
                            this.props.footer && <Footer />
                        }
                        <Snackbar
                            open={this.props.snackbar.open}
                            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                            autoHideDuration={5000}
                            onClose={this.handleCloseSnackbar}>
                            <Alert onClose={this.handleCloseSnackbar} severity={this.props.snackbar.status}>
                                {this.props.snackbar.message}
                            </Alert>
                        </Snackbar>
                    </Router>
                </Provider>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(App);
