import React from 'react';
import {connect} from "react-redux";
import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'

UIkit.use(Icons)

class SectionDocuments extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { data, onChange, disableSection } = this.props;

        return (
            <div className="tm-section-profile-documents uk-margin-medium-top">
                <h3 className="uk-h3">Документы</h3>
                <div className="uk-padding">
                    <div className="uk-flex uk-flex-middle" data-uk-grid>
                        <div className="uk-width-auto">
                            <i data-uk-icon="icon: link; ratio: 2;" />
                        </div>
                        <div className="uk-width-expand">
                            <div>Паспорт (все страницы)</div>
                            <div className="uk-text-small uk-text-muted">
                                {
                                    data.inquirerGroup === 0 && (                                        
                                      data.documents.passport.main ? <a href={`https://crowd-money.ru/public/uploads/documents/${data.documents.passport.main}`} target="_blank">Смотреть документ</a> : "Не загружен"
                                    )
                                }
                                {
                                    data.inquirerGroup > 0 && (
                                         data.documents.passport.main ? "Загружен" : "Не загружен"
                                    )
                                }
                            </div>
                        </div>
                        <div className="uk-width-auto" hidden={disableSection}>
                            <div data-uk-form-custom>
                                <input type="file" name="passportMain" onChange={onChange}/>
                                <button
                                    className="uk-button uk-button-default"
                                    type="button"
                                    tabIndex="-1"
                                   >
                                    Выбрать файл
                                </button>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="uk-flex uk-flex-middle" data-uk-grid>
                        <div className="uk-width-auto">
                            <i data-uk-icon="icon: link; ratio: 2;" />
                        </div>
                        <div className="uk-width-expand">
                            <div>Селфи с паспортом</div>
                            <div className="uk-text-small uk-text-muted">
                                {
                                    data.inquirerGroup === 0 && (
                                        data.documents.passport.verify ? <a href={`https://crowd-money.ru/public/uploads/documents/${data.documents.passport.verify}`} target="_blank">Смотреть документ</a> : "Не загружено"
                                    )
                                }
                                {
                                    data.inquirerGroup > 0 && (
                                        data.documents.passport.verify ? "Загружено" : "Не загружено"
                                    )
                                }
                            </div>
                        </div>
                        <div className="uk-width-auto" hidden={disableSection}>
                            <div data-uk-form-custom>
                                <input type="file" name="passportVerify" onChange={onChange}/>
                                <button
                                    className="uk-button uk-button-default"
                                    type="button"
                                    tabIndex="-1"
                                    >
                                    Выбрать файл
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* {
                        data.entrepreneur.type > 1 && (
                            <>
                                <hr />
                                <div className="uk-flex uk-flex-middle" data-uk-grid>
                                    <div className="uk-width-auto">
                                        <i data-uk-icon="icon: link; ratio: 2;" />
                                    </div>
                                    <div className="uk-width-expand">
                                        <div>
                                            {
                                                data.entrepreneur.type === 2 && "Выписка из реестра налоговой"
                                            }
                                            {
                                                data.entrepreneur.type === 3 && "Свидетельство о постановке на учёт"
                                            }
                                        </div>
                                        <div className="uk-text-small uk-text-muted">
                                            {
                                                (data.inquirerGroup === 0 && data.entrepreneur.type === 2) && (
                                                    data.documents.registrationCertificate.main ? <a href={`https://crowd-money.ru/public/uploads/documents/${data.documents.registrationCertificate.main}`} target="_blank">Смотреть документ</a> : "Не загружена"
                                                )
                                            }
                                            {
                                                (data.inquirerGroup > 0 && data.entrepreneur.type === 2) && (
                                                    data.documents.registrationCertificate.main ? "Загружена" : "Не загружена"
                                                )
                                            }
                                            {
                                                (data.inquirerGroup === 0 && data.entrepreneur.type === 3) && (
                                                    data.documents.registrationCertificate.main ? <a href={`https://crowd-money.ru/public/uploads/documents/${data.documents.registrationCertificate.main}`} target="_blank">Смотреть документ</a> : "Не загружено"
                                                )
                                            }
                                            {
                                                (data.inquirerGroup > 0 && data.entrepreneur.type === 3) && (
                                                    data.documents.registrationCertificate.main ? "Загружено" : "Не загружено"
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="uk-width-auto" hidden={disableSection}>
                                        <div data-uk-form-custom>
                                            <input type="file" name="registrationCertificateMain" onChange={onChange}/>
                                            <button
                                                className="uk-button uk-button-default"
                                                type="button"
                                                tabIndex="-1"                                                
                                            >
                                                Выбрать файл
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    } */}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(SectionDocuments);