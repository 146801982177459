import React from 'react';
import store from "../../../store";
import {connect} from "react-redux";
import InputMask from "react-input-mask";

import SectionMain from "./components/Edit/Main";
import SectionPassport from "./components/Edit/Passport";
import SectionEntrepreneur from "./components/Edit/Entrepreneur";
import SectionDocuments from "./components/Edit/Documents";
import SectionBank from "./components/Edit/Bank";
import UIkit from 'uikit'

class Settings extends React.Component {
    constructor(props) {
        super(props);

        this.changeDocumentFile = this.changeDocumentFile.bind(this);
        this.changeAgreementsData = this.changeAgreementsData.bind(this);             
        this.changeMainData = this.changeMainData.bind(this);
        this.changePassportData = this.changePassportData.bind(this);
        this.changeBankData = this.changeBankData.bind(this);
        this.changeEntrepreneurData = this.changeEntrepreneurData.bind(this);
        this.sendBeneficiary = this.sendBeneficiary.bind(this);
        this.updateSettings = this.updateSettings.bind(this);
        this.changeApprove = this.changeApprove.bind(this);
        this.changeQualified = this.changeQualified.bind(this);
        this.editProfileUnlock = this.editProfileUnlock.bind(this); 
        this.getBeneficiaryStatus = this.getBeneficiaryStatus.bind(this);      
       
        this.state = {
            isLoaded: false,
            documents: [],
            profile: this.props.profile,
            editProfileBlock: true,
            saveProfile: false,
            viewedProfile: { 
                access: {
                   group: 1 
                },               
                mainData: {
                    lastName: null,
                    firstName: null,
                    middleName: null,
                    phone: null
                },
                passportData: {
                    series: null,
                    number: null,
                    date: null,
                    issued: null,
                    code: null,
                    address: null
                },
                agreementsData: {},
                entrepreneurData: {
                    category: 1,
                    type: 1
                },
                bankData: {
                    account: "",
                    bik: ""
                },
                documentsData: {
                    passport: {
                        main: "",
                        verify: ""
                    }
                },  
                beneficiaryData: {   
                    orderId: "",
                    orderStatus: "",
                    errCode: "",
                    errMsg: ""                                            
                },
                beneficiaryStatus: "",                
                verification: {
                    id: ""
                }          
            }
        }
    }

    async componentDidMount() {
        const profileHash = localStorage.getItem("profileHash");
        const profileId = parseInt(window.location.pathname.split('/')[3]);

        await fetch("https://api.crowd-money.ru/documents", {
            method: 'POST'
        })
        .then(res => res.json())
        .then(
            (result) => {

                this.setState({
                    isLoaded: true,
                    documents: result
                })
            },
            (error) => {
                console.log(error)
            }
        );

        if (profileHash) {
            await fetch("https://api.crowd-money.ru/profile", {
                method: 'POST',
                body: JSON.stringify({
                    data: {
                        profileHash: profileHash
                    }
                })
            })
            .then(res => res.json())
            .then(
                (result) => {
                    if (!result) {
                        localStorage.removeItem('profileHash');
                        this.props.history.push('/');
                    }

                    this.setState({
                        profile: result,
                        editProfileBlock: result.verification.id ? true : false
                    })
                },
                (error) => {
                    console.log(error)
                }
            );
        }

        if (this.state.profile.access.authorized === false) {
            this.props.history.push('/');
        }
        else {
            store.dispatch({
                type: 'CABINET.SETTINGS',
                payload: {
                    profile: this.state.profile
                }
            });

            if (parseInt(this.state.profile.access.id) !== profileId) {
                if (this.state.profile.access.group === 0 ) {
                    await fetch("https://api.crowd-money.ru/profile", {
                        method: 'POST',
                        body: JSON.stringify({
                            data: {
                                profileId: profileId
                            }
                        })
                    })
                    .then(res => res.json())
                        .then(
                            (result) => {
                                this.setState({
                                    viewedProfile: 
                                        {
                                            ...result,
                                            beneficiaryData: result.beneficiaryData ? {...result.beneficiaryData} : {...this.state.viewedProfile.beneficiaryData} ,
                                            beneficiaryStatus: this.getBeneficiaryStatus(result.beneficiaryData)
                                        },                                                                        
                                    editProfileBlock: result.verification.id ? true : false,                                   
                                })
                            },
                            (error) => {
                                console.log(error)
                            }
                    );

                    if (this.state.viewedProfile.beneficiaryData.orderStatus == "CREATED" || 
                        this.state.viewedProfile.beneficiaryData.orderStatus == "PROCESSING"){
                        await fetch("https://api.crowd-money.ru/profile/beneficiary", {
                            method: 'POST',
                            body: JSON.stringify({
                                action: 'GetBeneficiaryStatus',                               
                                data: {
                                    profileId: this.state.viewedProfile.access.id,
                                    orderId: this.state.viewedProfile.beneficiaryData.orderId
                                }
                            })
                        })
                            .then(res => res.json())
                            .then(
                                (result) => {
                                    if (result.message) {
                                        store.dispatch({
                                            type: "SHOW.SNACKBAR",
                                            payload: {
                                                status: result.status,
                                                message: result.message
                                            }
                                        });
                                    }   
                                },
                                (error) => {
                                    console.log(error)
                                }
                            );
                    }                   
                }
                else {
                    this.props.history.push('/cabinet');
                }
            }
            else {
                this.setState({
                    viewedProfile: this.state.profile
                });
            }
        }    
    }

    getBeneficiaryStatus = (beneficiaryData) => {
        let status = beneficiaryData ? beneficiaryData.orderStatus : "";

        switch(status){
            case "CREATED":
                return "Принято в обработку";
            case "PROCESSING":
                return "В обработке";
            case "DONE":
                return "Обработан";
            case "ERROR":
                return "Ошибка обработки";
            case "NOT_EXIST":
                return "Заявка не найдена";
            case "":           
                return "Не отправлялся";               
        }       
    }

    changeDocumentFile = (e) => {
        if(e.target.name === "avatar"){
            this.setState({
                viewedProfile: {
                    ...this.state.viewedProfile,
                    mainData: {
                        ...this.state.viewedProfile.mainData,
                        newAvatar: e.target.files[0],
                        previewAvatar: URL.createObjectURL(e.target.files[0])
                    }
                },
                saveProfile: true
            })
        }

        if(e.target.name === "passportMain"){
            this.setState({
                viewedProfile: {
                    ...this.state.viewedProfile,
                    documentsData: {
                        ...this.state.viewedProfile.documentsData,
                        passport: {
                            ...this.state.viewedProfile.documentsData.passport,
                            main: e.target.files[0]
                        }
                    }
                },
                saveProfile: true
            })
        }

        if(e.target.name === "passportVerify"){
            this.setState({
                viewedProfile: {
                    ...this.state.viewedProfile,
                    documentsData: {
                        ...this.state.viewedProfile.documentsData,
                        passport: {
                            ...this.state.viewedProfile.documentsData.passport,
                            verify: e.target.files[0]
                        }
                    }
                },
                saveProfile: true
            })
        }

        if(e.target.name === "registrationCertificateMain"){
            this.setState({
                viewedProfile: {
                    ...this.state.viewedProfile,
                    documentsData: {
                        ...this.state.viewedProfile.documentsData,
                        registrationCertificate: {
                            main: e.target.files[0]
                        }
                    }
                },
                saveProfile: true
            })
        }
    };

    changeApprove = (e) => {
        this.setState({
            viewedProfile: {
                ...this.state.viewedProfile,
                approve: parseInt(e.target.value)
            },
            saveProfile: true
        })
    };

    changeQualified = (e) => {
        this.setState({
            viewedProfile: {
                ...this.state.viewedProfile,
                qualified: parseInt(e.target.value)
            },
            saveProfile: true
        })
    };

    changeMainData = (e) => {
        this.setState({
            viewedProfile: {
                ...this.state.viewedProfile,
                mainData: {
                    ...this.state.viewedProfile.mainData,
                    [e.target.name]: e.target.value
                }
            },
            saveProfile: true
        })
    };

    changeBankData = (e) => {
        this.setState({
            viewedProfile: {
                ...this.state.viewedProfile,
                bankData: {
                    ...this.state.viewedProfile.bankData,
                    [e.target.name]: e.target.value
                }
            },
            saveProfile: true          
        })
    };

    changePassportData = (e) => {
        this.setState({
            viewedProfile: {
                ...this.state.viewedProfile,
                passportData: {
                    ...this.state.viewedProfile.passportData,
                    [e.target.name]: e.target.value
                }
            },
            saveProfile: true
        })
    };

    changeEntrepreneurData = (e) => {
        if (e.target.name === "type") {
            if (parseInt(e.target.value) === 1) {
                this.setState({
                    viewedProfile: {
                        ...this.state.viewedProfile,
                        entrepreneurData: {
                            category: 1,
                            type: 1
                        },
                        documentsData: {
                            passport: {
                                ...this.state.viewedProfile.documentsData.passport
                            }
                        }
                    },
                    saveProfile: true
                });

                return false;
            }

            if (parseInt(e.target.value) === 2) {
                this.setState({
                    viewedProfile: {
                        ...this.state.viewedProfile,
                        entrepreneurData: {
                            category: 1,
                            type: 2,
                            inn: this.state.viewedProfile.mainData.inn
                        },
                        documentsData: {
                            passport: {
                                ...this.state.viewedProfile.documentsData.passport
                            },
                            registrationCertificate: {
                                main: ""
                            }
                        }
                    },
                    saveProfile: true
                });

                return false;
            }

            if (parseInt(e.target.value) === 3) {
                this.setState({
                    viewedProfile: {
                        ...this.state.viewedProfile,
                        entrepreneurData: {
                            category: 1,
                            type: 3
                        },
                        documentsData: {
                            passport: {
                                ...this.state.viewedProfile.documentsData.passport
                            },
                            registrationCertificate: {
                                main: ""
                            }
                        }
                    },
                    saveProfile: true
                });

                return false;
            }
        }
        else {
            this.setState({
                viewedProfile: {
                    ...this.state.viewedProfile,
                    entrepreneurData: {
                        ...this.state.viewedProfile.entrepreneurData,
                        [e.target.name]: e.target.value
                    }
                },
                saveProfile: true
            })
        }
    };

    changeAgreementsData = (e) => {
        this.setState({
            viewedProfile: {
                ...this.state.viewedProfile,
                agreementsData: {
                    ...this.state.viewedProfile.agreementsData,
                    [e.target.name]: e.target.checked ? 1 : 0
                }
            },
            saveProfile: true
        })
    };
    
    updateSettings = () => {             
        const { mainData, passportData, entrepreneurData, documentsData, agreementsData, bankData } = this.state.viewedProfile;
        const formData = new FormData();
            
        this.setState({
            ...this.state,
            saveProfile: false
        })

        formData.append('action', 'updateProfile');
        formData.append('data[inquirer][profileHash]', this.state.profile.access.hash);
        formData.append('data[profileId]', this.state.viewedProfile.access.id);
        formData.append('data[profileHash]', this.state.viewedProfile.access.hash);
        formData.append('data[editProfileBlock]', this.state.editProfileBlock);

        if (this.state.viewedProfile.approve) {
            formData.append('data[otherData][approve]', this.state.viewedProfile.approve);
        }

        if (this.state.viewedProfile.qualified) {
            formData.append('data[otherData][qualified]', this.state.viewedProfile.qualified);
        }

        formData.append('data[mainData][avatar]', mainData.avatar);
        formData.append('data[mainData][newAvatar]', mainData.newAvatar);
        formData.append('data[mainData][lastName]', mainData.lastName);
        formData.append('data[mainData][firstName]', mainData.firstName);
        formData.append('data[mainData][middleName]', mainData.middleName);
        formData.append('data[mainData][birthday]', mainData.birthday);
        formData.append('data[mainData][phone]', mainData.phone);
        formData.append('data[mainData][email]', mainData.email);
        formData.append('data[mainData][snils]', mainData.snils);
        formData.append('data[mainData][inn]', mainData.inn);

        formData.append('data[passportData][series]', passportData.series);
        formData.append('data[passportData][number]', passportData.number);
        formData.append('data[passportData][date]', passportData.date);
        formData.append('data[passportData][issued]', passportData.issued);
        formData.append('data[passportData][code]', passportData.code);
        formData.append('data[passportData][address]', passportData.address);
        formData.append('data[passportData][placeBirth]', passportData.placeBirth);

        formData.append('data[bankData][account]', bankData.account);
        formData.append('data[bankData][bik]', bankData.bik);

        formData.append('data[entrepreneurData][category]', entrepreneurData.category);
        formData.append('data[entrepreneurData][type]', entrepreneurData.type);

        if (entrepreneurData.type === 1) {
            formData.append('data[entrepreneurData][inn]', mainData.inn);
        }
        else if (entrepreneurData.type === 2) {
            formData.append('data[entrepreneurData][inn]', mainData.inn);
            formData.append('data[entrepreneurData][ogrnip]', entrepreneurData.ogrnip);
        }
        else if (entrepreneurData.type === 3) {
            if(entrepreneurData.companyName){
                formData.append('data[entrepreneurData][companyName]', entrepreneurData.companyName);
            }

            if(entrepreneurData.inn){
                formData.append('data[entrepreneurData][inn]', entrepreneurData.inn);
            }

            if(entrepreneurData.kpp){
                formData.append('data[entrepreneurData][kpp]', entrepreneurData.kpp);
            }

            if(entrepreneurData.ogrn){
                formData.append('data[entrepreneurData][ogrn]', entrepreneurData.ogrn);
            }

            if(entrepreneurData.address){
                formData.append('data[entrepreneurData][address]', entrepreneurData.address);
            }

            if(entrepreneurData.fullName){
                formData.append('data[entrepreneurData][fullName]', entrepreneurData.fullName);
            }
        }

        formData.append('data[documentsData][passport][main]', documentsData.passport.main);
        formData.append('data[documentsData][passport][verify]', documentsData.passport.verify);

        // if (entrepreneurData.type > 1) {
        //     formData.append('data[documentsData][registrationCertificate][main]', documentsData.registrationCertificate.main);
        // }

        formData.append('data[agreementsData][personalData]', agreementsData.personalData);
        formData.append('data[agreementsData][terms]', agreementsData.terms);

        store.dispatch({
            type: "HIDE.SNACKBAR"
        });

        fetch( "https://api.crowd-money.ru/profile/update", {
            method: 'POST',
            body: formData
        })
        .then(res => res.json())
        .then(
            (result) => {
                store.dispatch({
                    type: "SHOW.SNACKBAR",
                    payload: {
                        status: result.status,
                        message: result.message
                    }
                });

                if (result.status === "error") {
                    if (result.field) {
                        this[result.field].focus();

                        return false;
                    }
                }
                else{
                    if (this.state.viewedProfile.approve == 0 && this.state.profile.access.group != 0){
                        UIkit.modal("#tm-modal-save-notification").show();
                    }  
                }
            },(error) => {
                console.log(error)
            }
        )
    };

    editProfileUnlock = () => {        
        this.setState({
            ...this.state,
            editProfileBlock: false            
        })        
        UIkit.modal("#tm-modal-editProfileUnlock").hide();
    };

    sendBeneficiary = () => {
        fetch("https://api.crowd-money.ru/profile/beneficiary", {
            method: 'POST',
            body: JSON.stringify({
                action: 'SendBeneficiary',
                data: {
                    profileId: this.state.viewedProfile.access.id
                }
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.message) {
                        this.setState({
                            viewedProfile: 
                                {
                                    ...this.state.viewedProfile,
                                    beneficiaryData: result.beneficiaryData ? {...result.beneficiaryData} : {...this.state.viewedProfile.beneficiaryData},
                                    beneficiaryStatus: this.getBeneficiaryStatus(result.beneficiaryData)
                                }                                                                                                     
                        })

                        store.dispatch({
                            type: "SHOW.SNACKBAR",
                            payload: {
                                status: result.status,
                                message: result.message
                            }
                        });
                    }   
                },
                (error) => {
                    console.log(error)
                }
            );
    }

    render() {       
        const {isLoaded, documents, editProfileBlock, saveProfile} = this.state;
        const {access, mainData, passportData, entrepreneurData, documentsData, agreementsData, agreementsDocumentsData, beneficiaryData, bankData, beneficiaryStatus} = this.state.viewedProfile;

        return (
            isLoaded && (
                <div className="tm-body">
                    <div className="uk-container uk-section-small uk-padding-remove-top">
                        <h3 className="uk-h3 tm-font-weight-5 uk-margin-medium-bottom">Профиль пользователя</h3>                        
                        <div data-uk-grid>
                            <div className="uk-width-1-3@s">
                                <div className="tm-profile-avatar" >
                                    {
                                        mainData.previewAvatar ? (
                                            <img src={mainData.previewAvatar} width="100%"></img>) : (
                                            mainData.avatar && (<img src={`/public/uploads/avatars/${mainData.avatar}`} width="100%"></img>)
                                        )
                                    }
                                    <div data-uk-form-custom className="tm-button-upload-avatar">
                                        <input type="file" name="avatar" onChange={this.changeDocumentFile}/>
                                        <div />
                                    </div>
                                </div>
                                <div className="uk-margin-medium-top uk-margin-small-bottom">
                                    <label className="tm-checkbox uk-text-small">
                                        <input
                                            className="uk-checkbox"
                                            type="checkbox"
                                            name="personalData"
                                            checked={agreementsData.personalData && true}
                                            onChange={this.changeAgreementsData}
                                        />
                                        <span>Я даю согласие на обработку персональных данных</span>
                                    </label>
                                </div>
                                <div className="uk-margin-small-bottom">
                                    <label className="tm-checkbox uk-text-small">
                                        <input
                                            className="uk-checkbox"
                                            type="checkbox"
                                            name="terms"
                                            checked={agreementsData.terms && true}
                                            onChange={this.changeAgreementsData}
                                        />
                                        <span>Ознакомлен(а) c <a href={`/documents/${documents[4]['data'][0]}`} download>пользовательским соглашением</a></span>
                                    </label>
                                </div>
                                {
                                    agreementsDocumentsData && (
                                        <>
                                            {
                                                agreementsDocumentsData.investorDocumentfile && (
                                                    <div className="uk-margin-small-bottom">
                                                        <label className="tm-checkbox uk-text-small">                                                       
                                                            <span>Подписанное <a Style="text-decoration: underline" href={`/private/investmentAssistance/` + agreementsDocumentsData.investorDocumentfile} download>Заявление на присоединение к Правилам платформы и договор на оказание услуг по содействию в инвестировании</a></span>
                                                        </label> 
                                                    </div>
                                                )
                                            }
                                            {
                                                agreementsDocumentsData.borrowerDocumentfile && (
                                                    <div className="uk-margin-small-bottom">
                                                        <label className="tm-checkbox uk-text-small">
                                                            <span>Подписанное <a Style="text-decoration: underline" href={`/private/investmentAttracting/` + agreementsDocumentsData.borrowerDocumentfile} download>Заявление на присоединение к Правилам платформы и договор на оказание услуг по привлечению инвестиций</a></span>
                                                        </label>
                                                    </div>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </div>
                            <div className="uk-width-2-3@s">
                                <SectionMain
                                    parentThis={this}                                   
                                    data={mainData}
                                    onChange={this.changeMainData}
                                    disableSection={editProfileBlock}
                                />
                                <SectionPassport
                                    parentThis={this}
                                    data={passportData}
                                    onChange={this.changePassportData}
                                    disableSection={editProfileBlock}
                                />
                                <SectionEntrepreneur
                                    parentThis={this}
                                    data={entrepreneurData}
                                    onChange={this.changeEntrepreneurData}
                                    disableSection={editProfileBlock}
                                    profileGroup={this.state.profile.access.group}
                                />
                                <SectionBank
                                    parentThis={this}
                                    data={bankData}
                                    onChange={this.changeBankData}
                                    disableSection={editProfileBlock}                                  
                                />
                                <SectionDocuments
                                    data={{ documents: documentsData, entrepreneur: entrepreneurData, inquirerGroup: this.state.profile.access.group }}
                                    onChange={this.changeDocumentFile}
                                    disableSection={editProfileBlock}
                                />
                                <div className="uk-margin-medium-top">
                                    <button
                                        className="uk-button uk-button-large uk-button-primary"
                                        disabled={!editProfileBlock}
                                        data-uk-toggle="target: #tm-modal-editProfileUnlock"
                                    >
                                        Редактировать данные профиля
                                    </button>
                                </div>                                                               
                                {
                                    this.state.profile.access.group === 0 && (
                                        <>
                                            <h3 className="uk-h3">Администрирование</h3>
                                            <div className="uk-grid-row-small" data-uk-grid>
                                                <div className="uk-width-1-4@s">
                                                    <label className="uk-form-label">Данные корректны</label>
                                                    <select className="uk-select" onChange={this.changeApprove}>
                                                        <option value={0} selected={this.state.viewedProfile.approve === 0 && true}>Нет</option>
                                                        <option value={1} selected={this.state.viewedProfile.approve === 1 && true}>Да</option>
                                                    </select>
                                                </div>                                               
                                                {
                                                    access.group === 1 && (
                                                        <div className="uk-width-1-4@s">
                                                            <label className="uk-form-label">Квалифицированный</label>
                                                            <select className="uk-select" disabled={entrepreneurData.type != 1} onChange={this.changeQualified}>
                                                                <option value={0} selected={this.state.viewedProfile.qualified === 0 && true}>Нет</option>
                                                                <option value={1} selected={this.state.viewedProfile.qualified === 1 && true}>Да</option>
                                                            </select>
                                                        </div>
                                                    )
                                                }                                                
                                                <div className="uk-width-1-2@s"> 
                                                    <label className="uk-form-label">Отправка бенефициара в банк</label>                                                  
                                                    <div className="uk-flex uk-flex-middle">
                                                        <input type="text" className="uk-input" defaultValue={beneficiaryStatus}                                                        
                                                        disabled={true} />
                                                        <button className="uk-button uk-button-small uk-button-primary uk-margin-left" 
                                                                data-uk-icon="icon: arrow-up;"
                                                                onClick={this.sendBeneficiary}
                                                                disabled={beneficiaryData.errCode == "0"}
                                                        />
                                                    </div>
                                                </div>
                                                {
                                                    beneficiaryStatus == "Ошибка обработки" && (
                                                        <div className="uk-width-1-1@s">
                                                            <textarea type="text" className="uk-textarea" defaultValue={beneficiaryData.errMsg}
                                                                disabled={true} />
                                                        </div>
                                                    )
                                                }                                                
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                        <hr />
                        <div className="uk-margin-medium-top">
                            <button
                                className="uk-button uk-button-large uk-button-primary"
                                onClick={this.updateSettings}  
                                disabled={!saveProfile}                              
                            >
                                Сохранить изменения 
                            </button>
                        </div>
                    </div>
                    <div id="tm-modal-editProfileUnlock" className="uk-flex-top" data-uk-modal>
                        <div className="uk-modal-dialog uk-margin-auto-vertical uk-width-1-3@s">
                            <button className="uk-modal-close-default" type="button" data-uk-close></button>
                            <div class="uk-modal-header">
                                <h3 className="uk-h3 tm-font-weight-5 uk-modal-title">Внимание</h3>
                            </div>
                            <div class="uk-modal-body">
                                <p>При изменении данных профиля потребуется повторное прохождение процедуры «Автоматической скоринг оценки».</p>
                                <p>Продолжить?</p>
                            </div>                            
                            <div className="uk-modal-footer uk-text-left">
                                <button
                                    className="uk-button uk-button-primary uk-modal-close"
                                    onClick={this.editProfileUnlock }>Да
                                </button>                                
                                <button
                                    className="uk-button uk-button-default uk-margin-small-left uk-modal-close"
                                    onClick={() => { UIkit.modal("#tm-modal-editProfileUnlock").hide() }}>Нет
                                </button>
                            </div>
                        </div>
                    </div>
                    <div id="tm-modal-save-notification" className="uk-flex-top" data-uk-modal>
                        <div className="uk-modal-dialog uk-margin-auto-vertical uk-width-1-3@s">
                            <button className="uk-modal-close-default" type="button" data-uk-close></button>
                            <div class="uk-modal-header">
                                <h3 className="uk-h3 tm-font-weight-5 uk-modal-title">Внимание!</h3>
                            </div>
                            <div class="uk-modal-body">
                                <p>Данные профиля проходят модерацию.</p>    
                                <p>Вам поступит уведомление о завершении проверки в ближайшее время.</p>                              
                            </div>
                            <div className="uk-modal-footer">
                                <button className="uk-button uk-button-primary uk-modal-close">Понятно</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        )
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(Settings);