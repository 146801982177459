import React from 'react';
import {connect} from "react-redux";
import InputMask from "react-input-mask";

class SectionPassport extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { parentThis, data, onChange, disableSection} = this.props;

        return (
            <>
                <h3 className="uk-h3">Паспортные данные</h3>
                <div className="uk-grid-row-small" data-uk-grid>
                    <div className="uk-width-1-2@s">
                        <label className="uk-form-label">Паспорт (серия)</label>
                        <InputMask
                            mask="9999"
                            onChange={onChange}
                            value={data.series}
                            disabled={disableSection}
                        >
                            <input
                                type="text"
                                className="uk-input"
                                name="series"
                                ref={(input) => {parentThis.passportSeries = input}}
                            />
                        </InputMask>
                    </div>
                    <div className="uk-width-1-2@s">
                        <label className="uk-form-label">Паспорт (номер)</label>
                        <InputMask
                            mask="999999"
                            onChange={onChange}
                            value={data.number}
                            disabled={disableSection}
                        >
                            <input
                                type="text"
                                className="uk-input"
                                name="number"
                                ref={(input) => {parentThis.passportNumber = input}}
                            />
                        </InputMask>
                    </div>
                    <div className="uk-width-1-2@s">
                        <label className="uk-form-label">Дата выдачи паспорта</label>
                        <InputMask
                            mask="99.99.9999"
                            onChange={onChange}
                            value={data.date}
                            disabled={disableSection}
                        >
                            <input
                                type="text"
                                className="uk-input"
                                name="date"
                                ref={(input) => {parentThis.passportDate = input}}
                                defaultValue={data.date}
                            />
                        </InputMask>
                    </div>
                    <div className="uk-width-1-2@s">
                        <label className="uk-form-label">Кем выдан</label>
                        <input
                            type="text"
                            className="uk-input"
                            name="issued"
                            ref={(input) => {parentThis.passportIssued = input}}
                            defaultValue={data.issued}
                            onChange={onChange}
                            disabled={disableSection}
                        />
                    </div>
                    <div className="uk-width-1-2@s">
                        <label className="uk-form-label">Код подразделения</label>
                        <InputMask
                            mask="999999"
                            onChange={onChange}
                            value={data.code}
                            disabled={disableSection}
                        >
                            <input
                                type="text"
                                className="uk-input"
                                name="code"
                                ref={(input) => {parentThis.passportCode = input}}
                            />
                        </InputMask>
                    </div>
                    <div className="uk-width-1-2@s">
                        <label className="uk-form-label">Адрес регистрации</label>
                        <input
                            type="text"
                            className="uk-input"
                            name="address"
                            ref={(input) => {parentThis.passportAddress = input}}
                            defaultValue={data.address}
                            onChange={onChange}
                            disabled={disableSection}
                        />
                    </div>
                    <div className="uk-width-1-2@s">
                        <label className="uk-form-label">Место рождения</label>
                        <input
                            type="text"
                            className="uk-input"
                            name="placeBirth"
                            ref={(input) => {parentThis.placeBirth = input}}
                            defaultValue={data.placeBirth}
                            onChange={onChange}
                            disabled={disableSection}
                        />
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(SectionPassport);