import React, { useState } from 'react';
import CurrencyValue from '../CurrencyValue';
import { IMaskInput } from 'react-imask';
import { Link } from "react-router-dom";
import store from "../../../store";

function PaymentsInvestorsTable(props) {
    const [data, setData] = useState({balanceCorrectActive: true});

    const balanceCorrect = () => {
        fetch("https://api.crowd-money.ru/profile/balance", {
            method: 'POST',
            body: JSON.stringify({
                action: 'changeBalances',
                data: {                    
                    changeData: props.data.investmentsProject.map(investment => {
                        return {
                            projectId: props.data.id,                                                    
                            amount: investment.amountPayment,
                            profileId: investment.profile.profileId,
                            purposePayment: props.comment                           
                        }
                    }),
                    borrowerProfileId: props.borrowerProfileId                
                }
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.message) {
                        props.clear();
                        setData({...data, balanceCorrectActive: false})                       

                        store.dispatch({
                            type: "SHOW.SNACKBAR",
                            payload: {
                                status: result.status,
                                message: result.message
                            }
                        });
                        window.location.reload();
                    }
                },
                (error) => {
                    console.log(error)
                }
            );
    };

        return (
            <div className="uk-overflow-auto">
                <h3 className="uk-h3 tm-font-weight-5">Платежи по заявке № {props.data.id}</h3>
                <table className="tm-table-balance-operations uk-table uk-text-center uk-table-divider uk-table-hover uk-table-middle">
                    <thead>
                        <tr>
                            <th className="uk-text-center">ID инвестора</th>
                            <th className="uk-text-center">ФИО</th>
                            <th className="uk-text-center">Сумма инвестиций, руб.</th>
                            <th className="uk-text-center">Доля инвестора в займе</th>
                            <th className="uk-text-center">Сумма к зачислению</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.data.investmentsProject.map(investment => (
                                <tr>
                                    <td>
                                        {investment.profile.profileId}
                                    </td>
                                    <td>
                                        <>
                                            <Link to={`/profile/edit/${investment.profile.profileId}`}>{investment.profile.lastName} {investment.profile.firstName} {investment.profile.middleName}</Link>
                                        </>
                                    </td>
                                    <td>
                                        <CurrencyValue value={investment.amount} />&nbsp;₽
                                    </td>
                                    <td>
                                        {parseFloat(((investment.amount / props.data.collected) * 100)).toFixed(2)} %
                                    </td>
                                    <td>
                                        <IMaskInput
                                            mask={Number}
                                            unmask={true}                                           
                                            radix=","
                                            name={`amountPayment_${investment.id}`}                                           
                                            style={{ width: 120 }}
                                            className="uk-input uk-form-small"
                                            value={`${investment.amountPayment}`}
                                            onAccept={
                                                (value) => { investment.amountPayment = value }
                                            }
                                            placeholder='Введите сумму'
                                        />&nbsp;₽
                                    </td>
                                </tr>
                            ))
                        }
                        <tr className="uk-text-bold">
                            <td colSpan={1}>
                            </td>
                            <td >
                                Итого:
                            </td>
                            <td>
                                <CurrencyValue value={props.data.collected} />&nbsp;₽
                            </td>
                            <td>
                                100 %
                            </td>
                            <td>
                                <CurrencyValue value={props.amountProjectCalc} />&nbsp;₽
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="uk-margin-medium-top">
                    <button className="uk-button uk-button-large uk-button-primary" disabled={!data.balanceCorrectActive} onClick={() => balanceCorrect()}>
                        Пополнить баланс
                    </button>
                </div>
            </div>

        )
    
}

export default PaymentsInvestorsTable;