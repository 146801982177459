import React from "react";
import { PieChart } from 'react-minimal-pie-chart';
import store from "../../../store";
import { connect } from "react-redux";
import NumberFormat from 'react-number-format';
import InputMask from "react-input-mask";
import { Link } from "react-router-dom";
import PaymentCode from "../../../partials/others/PaymentCode"
import CurrencyValue from "../../../partials/others/CurrencyValue"
import { IMaskInput } from 'react-imask';
import StatementQualifiedDescription from '../../../partials/others/StatementQualifiedDescription';
import BalanceHistoryGeneration from "../../../pages/cabinet/BalanceHistoryGeneration"
import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'

UIkit.use(Icons);

class Balance extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            profile: this.props.profile,
            payments: [],
            balance: {
                amount: null
            },
            withdraw: [],
            withdrawContinue: true,
            withdrawStep: 0
        };

        this.handleChange = this.handleChange.bind(this);
        this.topUpBalance = this.topUpBalance.bind(this);
        this.withdraw = this.withdraw.bind(this);
        this.openModalRefill = this.openModalRefill.bind(this);
        this.continueActive = this.continueActive.bind(this);
    }

    async componentDidMount() {
        const profileHash = localStorage.getItem("profileHash");

        if (profileHash) {

            await fetch("https://api.crowd-money.ru/profile", {
                method: 'POST',
                body: JSON.stringify({
                    data: {
                        profileHash: profileHash
                    }
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        if (!result) {
                            localStorage.removeItem('profileHash');
                            this.props.history.push('/');
                        }

                        this.setState({
                            profile: result,
                            withdraw: {
                                currentAccount: result.bankData.account,
                                bik: result.bankData.bik
                            }
                        })
                    },
                    (error) => {
                        console.log(error)
                    }
                );

            if (this.state.profile.access.authorized === false) {
                localStorage.removeItem('profileHash');
                this.props.history.push('/');
            }
            else {
                fetch("https://api.crowd-money.ru/payments", {
                    method: 'POST',
                    body: JSON.stringify({
                        action: "getPayments",
                        data: {
                            profileHash: this.state.profile.access.hash
                        }
                    })
                })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            this.setState({
                                payments: result
                            })
                        },
                        (error) => {
                            console.log(error)
                        }
                    );

                store.dispatch({
                    type: 'CABINET.BALANCE',
                    payload: {
                        profile: this.state.profile
                    }
                });
            }
        }
        else {
            this.props.history.push('/');
        }
    }

    handleChange = (e) => {
        this.setState({
            withdraw: {
                ...this.state.withdraw,
                [e.target.name]: e.target.value
            }
        })
    };

    openModalRefill = () => {
        const profile = this.state.profile;

        if (profile.access.group === 1 && profile.verification.length === 0) {
            store.dispatch({
                type: "SHOW.SNACKBAR",
                payload: {
                    status: 'error',
                    message: 'Перед пополнением баланса, пожалуйста, пройдите процедуру «Автоматической скоринг оценки»'
                }
            });
        }
        else {
            UIkit.modal("#tm-modal-refill").show();
        }
    };

    topUpBalance = () => {
        store.dispatch({
            type: "HIDE.SNACKBAR"
        });

        fetch("https://api.crowd-money.ru/payments", {
            method: 'POST',
            body: JSON.stringify({
                action: "createPayment",
                type: 1,
                data: {
                    profileId: this.state.profile.access.id,
                    amount: this.state.balance.amount
                }
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result)

                    if (result.message) {
                        store.dispatch({
                            type: "SHOW.SNACKBAR",
                            payload: {
                                status: result.status,
                                message: result.message
                            }
                        });
                    }

                    if (result.field) {
                        this[result.field].focus();
                    }

                    if (result.status === 1) {
                        window.open(result.url, '_blank')
                    }
                },
                (error) => {
                    console.log(error)
                }
            );
    };

    withdraw = () => {
        this.continueActive();

        store.dispatch({
            type: "HIDE.SNACKBAR"
        });

        fetch("https://api.crowd-money.ru/payments", {
            method: 'POST',
            body: JSON.stringify({
                action: "createWithdraw",
                type: 3,
                data: {
                    profileId: this.state.profile.access.id,
                    ...this.state.withdraw,
                    step: this.state.withdrawStep,
                    verifyCode: this.state.withdraw.verifyCode,
                    sentCode: this.state.sentCode,
                    profilePhone: this.state.profile.mainData.phone
                }
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result)

                    if (result.message) {
                        store.dispatch({
                            type: "SHOW.SNACKBAR",
                            payload: {
                                status: result.status,
                                message: result.message
                            }
                        });
                    }

                    if (result.field) {
                        this[result.field].focus();

                        return false;
                    }

                    if (result.status == 'continue') {
                        this.setState({
                            ...this.state,
                            withdrawStep: 1,
                            sentCode: result.sentCode
                        })
                    }

                    if (result.status == 'success') {
                        this.setState({
                            ...this.state,
                            withdrawStep: 0
                        })

                        UIkit.modal("#tm-modal-withdraw").hide();
                    }
                },
                (error) => {
                    console.log(error)
                }
            );
    };

    continueActive = (balance) => {
        if (balance) {
            if (this.state.profile.balance < this.state.withdraw.amount) {
                this.setState({ withdrawContinue: true })
            }
            else {
                this.setState({ withdrawContinue: false })
            }
        }
        else {
            this.setState({ withdrawContinue: true })
        }
    }

    render() {
        const { profile, payments, withdraw, withdrawStep } = this.state;
        let investmentAmount = 0;
        let withdrawAmount = 0;
        let projectsCollected = 0;
        let projectActive;

        if (profile.projects.length > 0) {
            profile.projects.map((project) => {
                if (project.scheduleData) {
                    projectsCollected += project.scheduleData.repaymentAmountTotal
                }
            });
        }

        const balanceChartPieOne = () => {
            if (profile.access.group === 1) {
                let paymentsTotalAmount = 0;

                if (payments.length > 0) {
                    payments.map((payment) => {
                        // if (payment.type === 1 && payment.status === 3) {
                        //     paymentsTotalAmount += parseFloat(payment.amount);
                        // } 
                        if (payment.type === 2) {
                            paymentsTotalAmount += parseFloat(payment.amount);
                        }
                    });

                    if (paymentsTotalAmount == 0) {
                        return 0;
                    }

                    //(средства на счете / инвестировано ) * 100%
                    return parseFloat(((this.state.profile.balance / paymentsTotalAmount) * 100).toFixed(2));
                }
                else {
                    return 0;
                }
            }

            if (profile.access.group === 3) {
                return 0;
            }

            if (projectActive) {
                if (profile.access.group === 2) {
                    if (projectActive.data.amountType === 1) {
                        return parseFloat(((projectActive.collected / projectActive.data.amountTotal) * 100).toFixed(2));
                    }
                    else if (projectActive.data.amountType === 2) {
                        return parseFloat(((projectActive.collected / projectActive.data.amountEnd) * 100).toFixed(2));
                    }
                    else {
                        return 0;
                    }
                }
            }
            else {
                return 0;
            }
        };

        const balanceChartPieTwo = () => {
            if (profile.access.group === 1) {
                let paymentsTotalAmount = 0;
                let investmentsTotalAmount = 0;

                if (payments.length > 0) {
                    payments.map((payment) => {
                        if (payment.type === 1 && payment.status === 3) {
                            paymentsTotalAmount += parseFloat(payment.amount);
                        }

                        if (payment.type === 2) {
                            investmentsTotalAmount += parseFloat(payment.amount);;
                        }
                    });

                    if (paymentsTotalAmount == 0) {
                        return 0;
                    }

                    return parseFloat(((investmentsTotalAmount / paymentsTotalAmount) * 100).toFixed(2));
                }
                else {
                    return 0;
                }
            };

            if (profile.access.group === 2) {
                if (projectActive) {
                    if (projectActive.collected == 0) {
                        return 0;
                    }
                    else {
                        return parseFloat(((((projectActive.collected * projectActive.rate) / 12) * parseInt(projectActive.data.duration)) / projectActive.collected).toFixed(2));
                    }
                }
                else {
                    return 0;
                }
            }

            if (profile.access.group === 3) {
                return 0;
            }
        };

        const balanceChartPieThree = () => {
            if (profile.access.group === 1) {
                let withdrawAmount = 0;
                let investmentsTotalAmount = 0;

                if (payments.length > 0) {
                    payments.map((payment) => {
                        if (payment.type === 4 && payment.status === 3) {
                            withdrawAmount += parseFloat(payment.amount);
                        }

                        if (payment.type === 2) {
                            investmentsTotalAmount += parseFloat(payment.amount);
                        }
                    });

                    return parseFloat(((withdrawAmount / investmentsTotalAmount) * 100).toFixed(2));
                }
                else {
                    return 0;
                }
            };
        };

        const getProjectPurposePayment = (purposePayment) => {
            let result = purposePayment.match(/[0-9]{1,}/);
            return result[0];
        }

        const recipient = (payment) => {
            let $recipientFrom;
            let $recipientTo;

            if (payment.type === 1 || payment.type === 4) {
                $recipientTo = {
                    mainData: payment.mainData,
                    entrepreneurData: payment.entrepreneurData,
                    fullName: null
                };
            }

            if (payment.type === 2) {
                $recipientFrom = {
                    mainData: payment.mainData,
                    entrepreneurData: payment.entrepreneurData,
                    fullName: null
                };

                $recipientTo = {
                    access: payment.project.owner.access,
                    mainData: payment.project.owner.mainData,
                    entrepreneurData: payment.project.owner.entrepreneurData,
                    fullName: null
                };
            }

            if (payment.type === 1 || payment.type === 4) {
                if ($recipientTo.entrepreneurData.type === 1) {
                    $recipientTo.fullName = $recipientTo.mainData.lastName + ' ' + $recipientTo.mainData.firstName + ' ' + $recipientTo.mainData.middleName;
                }
                else if ($recipientTo.entrepreneurData.type === 2) {
                    $recipientTo.fullName = 'ИП ' + $recipientTo.mainData.lastName + ' ' + $recipientTo.mainData.firstName + ' ' + $recipientTo.mainData.middleName;
                }
                else if ($recipientTo.entrepreneurData.type === 3) {
                    $recipientTo.fullName = $recipientTo.entrepreneurData.companyName
                }

                // <Link to={`/profile/edit/${payment.profileId}`}>{$recipientTo.fullName}</Link>

                return $recipientTo.fullName
            }

            else if (payment.type === 2) {
                if ($recipientTo.entrepreneurData.type === 1) {
                    $recipientTo.fullName = $recipientTo.mainData.lastName + ' ' + $recipientTo.mainData.firstName + ' ' + $recipientTo.mainData.middleName;
                }
                else if ($recipientTo.entrepreneurData.type === 2) {
                    $recipientTo.fullName = 'ИП ' + $recipientTo.mainData.lastName + ' ' + $recipientTo.mainData.firstName + ' ' + $recipientTo.mainData.middleName;
                }
                else if ($recipientTo.entrepreneurData.type === 3) {
                    $recipientTo.fullName = $recipientTo.entrepreneurData.companyName
                }

                if ($recipientFrom.entrepreneurData.type === 1) {
                    $recipientFrom.fullName = $recipientFrom.mainData.lastName + ' ' + $recipientFrom.mainData.firstName + ' ' + $recipientFrom.mainData.middleName;
                }
                else if ($recipientFrom.entrepreneurData.type === 2) {
                    $recipientFrom.fullName = 'ИП ' + $recipientFrom.mainData.lastName + ' ' + $recipientFrom.mainData.firstName + ' ' + $recipientFrom.mainData.middleName;
                }
                else if ($recipientFrom.entrepreneurData.type === 3) {
                    $recipientFrom.fullName = $recipientFrom.entrepreneurData.companyName
                }

                return (
                    <>
                        <div>
                            <span className="uk-text-muted">От: </span>
                            {/* <Link to={`/profile/edit/${payment.profileId}`}> */}
                            {$recipientFrom.fullName}
                            {/* </Link> */}
                        </div>
                        <div>
                            <span className="uk-text-muted">Кому: </span>
                            {/* <Link to={`/profile/edit/${$recipientTo.access.id}`}> */}
                            {$recipientTo.fullName}
                            {/* </Link> */}
                        </div>
                    </>
                );
            }
        };

        return (
            <div className="tm-body">
                <div className="tm-section-documents">
                    <div className="uk-container uk-section-small uk-padding-remove-top">
                        <h3 className="uk-h3 tm-font-weight-5">Лицевой счёт № {profile.accountNumber}</h3>
                        {
                            (profile.access.group === 1 || profile.access.group === 2) &&
                            (
                                <div className="tm-charts">
                                    <div className="uk-position-relative uk-visible-toggle" tabIndex="-1" data-uk-slider>
                                        <ul className="uk-slider-items ">
                                            <li className="uk-text-center uk-width-expand@m">
                                                <div>
                                                    <div>
                                                        <img src={require('../../../assets/images/rub.png')} width="92" height="92" />
                                                    </div>
                                                    <div className="uk-text-center">
                                                        <div className="uk-margin-top uk-text-muted">Средства на счёте</div>
                                                        <span className="uk-h3 uk-margin-small-left">
                                                            <CurrencyValue value={profile.balance} currencyType={"₽"} />
                                                        </span>
                                                    </div>
                                                    <div className="uk-margin-top">
                                                        <button className="uk-button uk-button-primary uk-width-3-4" onClick={this.openModalRefill}>Пополнить счёт</button>
                                                    </div>
                                                </div>
                                            </li>
                                            {
                                                (profile.access.group === 2) &&
                                                (
                                                    <li className="uk-text-center uk-width-expand@m">
                                                        <div>
                                                            <div>
                                                                <img src={require('../../../assets/images/safebox.png')} width="92" height="92" />
                                                            </div>
                                                            <div className="uk-text-center">
                                                                <div className="uk-margin-top uk-text-muted">Привлечено средств</div>
                                                                <span className="uk-h3 uk-margin-small-left">
                                                                    <CurrencyValue value={projectsCollected} currencyType={"₽"} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            }
                                            <li className="uk-text-center uk-width-expand@m">
                                                <div>
                                                    <div>
                                                        <img src={require('../../../assets/images/wallet2.png')} width="100" height="100" />
                                                    </div>
                                                    <div className="uk-text-center">
                                                        <div className="uk-margin-top uk-text-muted">Выведено со счёта</div>
                                                        <div>
                                                            {
                                                                payments.length > 0 && (
                                                                    payments.filter((payment) => {
                                                                        if (payment.type === 4) {
                                                                            withdrawAmount += parseFloat(payment.amount)
                                                                        }
                                                                    })
                                                                )
                                                            }
                                                            <span className="uk-h3 uk-margin-small-left">
                                                                <CurrencyValue value={withdrawAmount} currencyType={"₽"} />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="uk-margin-top">
                                                        <button className="uk-button uk-button-primary uk-width-3-4"
                                                            onClick={() => this.setState({ ...this.state, withdrawStep: 0 })}
                                                            disabled={profile.balance === 0 && (true)}
                                                            data-uk-toggle="target: #tm-modal-withdraw;">
                                                            Вывести деньги
                                                        </button>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <a className="uk-position-center-left uk-position-small" href="#"
                                            data-uk-slidenav-previous data-uk-slider-item="previous"></a>
                                        <a className="uk-position-center-right uk-position-small" href="#"
                                            data-uk-slidenav-next data-uk-slider-item="next"></a>
                                    </div>
                                </div>
                            )
                        }
                        {
                            profile.approve === 1 &&
                            profile.entrepreneurData.category === 1 &&
                            profile.entrepreneurData.type === 1 &&
                            profile.qualified !== 1 && (
                                <div className="uk-margin-medium">
                                    {
                                        <StatementQualifiedDescription profileId={profile.access.id} />
                                    }
                                </div>
                            )
                        }
                        <hr className="uk-margin-medium" />
                        <div data-uk-grid className="uk-child-width-auto uk-margin">
                            <h3 className="uk-h3 tm-font-weight-5 uk-margin-small-top">История операций</h3>
                            <BalanceHistoryGeneration
                                profile={profile}
                                disabled={payments.length > 0 ? false : true} />
                        </div>
                        <div className="uk-overflow-auto">
                            <table className="tm-table-balance-operations uk-table uk-text-center uk-table-divider uk-table-hover">
                                <thead>
                                    <tr>
                                        <th className="uk-text-center uk-table-middle">Наименование</th>
                                        {
                                            (profile.access.group === 1) &&
                                            (
                                                <th className="uk-text-center uk-table-middle">Получатель</th>
                                            )
                                        }
                                        <th className="uk-text-center uk-table-middle">Назначение операции</th>
                                        <th className="uk-text-center uk-table-middle">Статус</th>
                                        <th className="uk-text-center uk-table-middle">Дата операции</th>
                                        <th className="uk-text-center uk-table-middle">Сумма</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        payments.length > 0 ? (
                                            payments.map((payment) => (
                                                <tr>
                                                    <td>
                                                        {
                                                            payment.type === 1 && (
                                                                'Пополнение баланса'
                                                            )
                                                        }
                                                        {
                                                            (payment.type === 2 || payment.type === 4) && (
                                                                'Списание со счёта'
                                                            )
                                                        }
                                                        {
                                                            payment.type === 3 && (
                                                                'Заявка на вывод средств'
                                                            )
                                                        }
                                                    </td>
                                                    {
                                                        (profile.access.group === 1) &&
                                                        (
                                                            <td>
                                                                {
                                                                    payment.type !== 3 && (
                                                                        recipient(payment)
                                                                    )
                                                                }
                                                                {
                                                                    (payment.type === 2 && payment.investmentAccept) && (
                                                                        <div>
                                                                            <a href={`/private/paymentInvestmentAccepts/${payment.investmentAccept}`} download>Скачать акцепт</a>
                                                                        </div>
                                                                    )
                                                                }
                                                                {
                                                                    payment.type === 3 && (
                                                                        <>
                                                                            <div>
                                                                                <span className="uk-text-muted">Р/С: </span>
                                                                                <NumberFormat value={payment.data.currentAccount} displayType={'text'} format="#####-###-#-####-#######" />
                                                                            </div>
                                                                            <div>
                                                                                <span className="uk-text-muted">БИК: </span>
                                                                                <NumberFormat value={payment.data.bik} displayType={'text'} format="###-###-###" />
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }
                                                            </td>
                                                        )
                                                    }
                                                    <td >
                                                        {
                                                            payment.purposePayment.includes("Выплата ежемесячных") == true &&
                                                            (
                                                                <a class="uk-link uk-text-primary" href={`../project/${getProjectPurposePayment(payment.purposePayment)}`}>{payment.purposePayment}</a>
                                                            )
                                                        }
                                                        {
                                                            payment.purposePayment.includes("Выплата ежемесячных") != true &&
                                                            (
                                                                payment.purposePayment
                                                            )
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            (payment.status === 1) && <span className="uk-text-success">Исполнено</span>
                                                        }
                                                        {
                                                            (payment.status === 2) && <span className="uk-text-muted">Платёж отменён</span>
                                                        }
                                                        {
                                                            (payment.status === 3) && <span className="uk-text-warning">В обработке</span>
                                                        }
                                                    </td>
                                                    <td>{payment.date}</td>
                                                    <td>
                                                        {
                                                            <>
                                                                <CurrencyValue value={parseFloat(payment.amount)} currencyType={"₽"} />
                                                            </>
                                                        }
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={6}>Операций нет</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div id="tm-modal-refill" className="uk-flex-top" data-uk-modal>
                            <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-width-2-5@s">
                                <button className="uk-modal-close-default" type="button" data-uk-close></button>
                                <h3 className="uk-h3 tm-font-weight-5">Пополнить счёт можно следующим способом:</h3>
                                {/* {
                                    profile.entrepreneurData.type === 1 && (
                                        <>
                                            <h4 className="uk-h4 uk-text-muted uk-margin-remove-bottom tm-font-weight-5">Через платёжную систему</h4>
                                            <div className="uk-text-muted uk-text-small">Комиссия от 2%</div>
                                            <div className="uk-margin">
                                                <input
                                                    type="text"
                                                    placeholder="Сумма пополнения"
                                                    className="uk-input"
                                                    name="amount"
                                                    ref={(input) => {this.amount = input}}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                            <div className="uk-margin">
                                                <div className="uk-button uk-button-large uk-button-primary uk-width" onClick={this.topUpBalance}>Продолжить</div>
                                            </div>
                                        </>
                                    )
                                } */}
                                <>
                                    <h4 className="uk-h4 uk-margin-remove-bottom tm-font-weight-5">Банковский перевод</h4>
                                    <div className="uk-text-muted uk-text-small">Без комиссии</div>
                                    <PaymentCode
                                        accountNumber={profile.accountNumber}
                                    />
                                    <div className="uk-margin">
                                        <div>
                                            <div className="uk-form-label">Назначение платежа:</div>
                                            <div>
                                                Пополнение лицевого счёта №{profile.accountNumber} на платформе "Crowd Money". Без НДС
                                            </div>
                                        </div>
                                        <div className="uk-margin-small">
                                            <div className="uk-form-label">Наименование организации:</div>
                                            <div>
                                                ООО «КРАУД ИНВЕСТ»
                                            </div>
                                        </div>
                                        <div className="uk-margin-small">
                                            <div className="uk-form-label">Номинальный счёт:</div>
                                            <div>
                                                40702 810 7 2002 0100003
                                            </div>
                                        </div>
                                        <div className="uk-margin-small">
                                            <div className="uk-form-label">ИНН:</div>
                                            <div>
                                                7713475803
                                            </div>
                                        </div>
                                        <div className="uk-margin-small">
                                            <div className="uk-form-label">КПП:</div>
                                            <div>
                                                771501001
                                            </div>
                                        </div>
                                        <div className="uk-margin-small">
                                            <div className="uk-form-label">Банк:</div>
                                            <div>
                                                ТКБ БАНК ПАО, г.Москва
                                            </div>
                                        </div>
                                        <div className="uk-margin-small">
                                            <div className="uk-form-label">БИК:</div>
                                            <div>
                                                044525388
                                            </div>
                                        </div>
                                        <div className="uk-margin-small">
                                            <div className="uk-form-label">К/С:</div>
                                            <div>
                                                30101 810 8 0000 0000388
                                            </div>
                                        </div>
                                        <div className="uk-margin-small">
                                            <div className="uk-form-label">Юридический адрес банка:</div>
                                            <div>
                                                109147, г. Москва, ул Воронцовская, 27/35
                                            </div>
                                        </div>
                                    </div>
                                </>
                            </div>
                        </div>
                        <div id="tm-modal-withdraw" className="uk-flex-top" data-uk-modal>
                            <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-width-1-3@s">
                                <button className="uk-modal-close-default" type="button" data-uk-close></button>
                                {
                                    withdrawStep == 0 && (
                                        <>
                                            <h3 className="uk-h3 tm-font-weight-5">Вывести деньги</h3>
                                            <h4>Средства на счёте: <CurrencyValue value={profile.balance} currencyType={"₽"} /></h4>
                                            <div className="uk-margin">
                                                <InputMask
                                                    mask="99999999999999999999"
                                                    onChange={this.handleChange}
                                                    placeholder="Расчётный счёт"
                                                    value={withdraw.currentAccount}
                                                >
                                                    <input
                                                        type="text"
                                                        className="uk-input"
                                                        name="currentAccount"
                                                        ref={(input) => { this.currentAccount = input }}
                                                    />
                                                </InputMask>
                                            </div>
                                            <div className="uk-margin">
                                                <InputMask
                                                    mask="999999999"
                                                    onChange={this.handleChange}
                                                    placeholder="БИК Банка"
                                                    value={withdraw.bik}
                                                >
                                                    <input
                                                        type="text"
                                                        className="uk-input"
                                                        name="bik"
                                                        ref={(input) => { this.bik = input }}
                                                    />
                                                </InputMask>
                                            </div>
                                            <div className="uk-margin">
                                                <IMaskInput
                                                    mask={Number}
                                                    radix=","
                                                    unmask={true}
                                                    name="amount"
                                                    inputRef={(input) => { this.amount = input }}
                                                    className="uk-input"
                                                    onAccept={
                                                        (value) => {
                                                            this.setState({
                                                                withdraw: {
                                                                    ...this.state.withdraw,
                                                                    ['amount']: value
                                                                }
                                                            })
                                                        }
                                                    }
                                                    placeholder='Сумма'
                                                    onChange={() => this.continueActive(true)}
                                                />
                                            </div>
                                            <div className="uk-margin">
                                                <button
                                                    className="uk-button uk-button-large uk-button-primary uk-width"
                                                    disabled={this.state.withdrawContinue}
                                                    onClick={this.withdraw}>Продолжить
                                                </button>
                                            </div>
                                        </>
                                    )
                                }
                                {
                                    (
                                        withdrawStep == 1 && (
                                            <>
                                                <h3 className="uk-h3 tm-font-weight-5">
                                                    Мы отправили код подтверждения
                                                    на<br /> номер <NumberFormat value={profile.mainData.phone} displayType={'text'} format="+#(###)###-##-##" />
                                                </h3>
                                                <div className="uk-margin">
                                                    <InputMask mask="9999" onChange={this.handleChange}>
                                                        <input
                                                            type="text"
                                                            placeholder="Код подтверждения"
                                                            className="uk-input"
                                                            name="verifyCode"
                                                            ref={(input) => {
                                                                this.verifyCode = input
                                                            }}
                                                        />
                                                    </InputMask>
                                                </div>
                                                <div className="uk-margin-medium-top">
                                                    <div className="uk-button uk-button-primary" onClick={this.withdraw}>Далее</div>
                                                </div>
                                            </>
                                        )
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(Balance);