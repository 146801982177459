import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { Link } from "react-router-dom";
import store from "../../../store";
import { connect } from "react-redux";
import OperationHistory from '../OperationHistory';
import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'

UIkit.use(Icons);

class Archive extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            profile: this.props.profile,
            investments: [],
        }
    }

    async componentDidMount() {
        const profileHash = localStorage.getItem("profileHash");

        if (profileHash) {
            await fetch("https://api.crowd-money.ru/profile", {
                method: 'POST',
                body: JSON.stringify({
                    data: {
                        profileHash: profileHash
                    }
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        if (!result) {
                            localStorage.removeItem('profileHash');
                            this.props.history.push('/');
                        }

                        this.setState({
                            profile: result
                        })
                    },
                    (error) => {
                        console.log(error)
                    }
                );
        }

        if (this.state.profile.access.authorized === false) {
            this.props.history.push('/');
        }
        else {
            fetch("https://api.crowd-money.ru/investments", {
                method: 'POST',
                body: JSON.stringify({
                    action: "getInvestments",
                    data: {
                        profileHash: this.state.profile.access.hash,
                        profileId: this.state.profile.access.id
                    }
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            investments: result.filter(v => [4, 6, 9].includes(v.project.status))
                        })
                    },
                    (error) => {
                        console.log(error)
                    }
                );

            store.dispatch({
                type: 'CABINET.INVESTMENTS',
                payload: {
                    profile: this.state.profile
                }
            });
        }
    }

    render() {
        const { investments } = this.state;

        return (
            <div className="tm-body">
                <div className="tm-section-documents">
                    <div className="uk-container uk-section-small uk-padding-remove-top">
                        <h3 className="uk-h3 tm-font-weight-5">Архив</h3>
                        <div>
                            <ul className="uk-tab">
                                <li><Link to="/projects">Для инвестиций</Link></li>
                                <li><Link to="/investments">Активные</Link></li>
                                <li><Link to="/Expired">Просроченные</Link></li>
                                <li className="uk-active"><Link to="/archive">Архив</Link></li>
                            </ul>
                              {
                                investments.length > 0 ? (
                                    <OperationHistory investments={investments} />
                                ) : (
                                    <div className="uk-alert uk-text-center uk-alert-primary" Style="border-radius: 4px;">
                                        Информация отсутствует
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(Archive);