import React from 'react';
import store from "../../../store";
import { connect } from "react-redux";
import { IMaskInput } from 'react-imask';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

UIkit.use(Icons);

class Claims extends React.Component {

    constructor(props) {
        super(props);

        const defaultData = {
            profile: this.props.profile,
            projects: [],
            projectIds: [],
            projectsProfileIds: [],
            profiles: [],
            currentProfile: null,
            currentProject: null,
            profilesIds: [],
            projectDataTable: '',
            paymentProjectId: '',
            fileBorrowerActive: '',
            fileGuarantorActive: ''
        }

        this.state = {
            ...defaultData,
            defaultData: defaultData
        };

        this.handleChange = this.handleChange.bind(this);
    }

    async componentDidMount() {
        const profileHash = localStorage.getItem("profileHash");

        if (profileHash) {
            await fetch("https://api.crowd-money.ru/profile", {
                method: 'POST',
                body: JSON.stringify({
                    data: {
                        profileHash: profileHash
                    }
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        if (!result) {
                            localStorage.removeItem('profileHash');
                            this.props.history.push('/');
                        }

                        this.setState({
                            profile: result
                        })
                    },
                    (error) => {
                        console.log(error)
                    }
                );
        }

        if (this.state.profile.access.authorized === false) {
            this.props.history.push('/');
        }
        else {
            store.dispatch({
                type: 'CABINET.INDEX',
                payload: {
                    profile: this.state.profile
                }
            });

            await fetch("https://api.crowd-money.ru/loans", {
                method: 'POST',
                body: JSON.stringify({
                    data: {
                        type: "cabinet",
                        profileId: this.state.profile.access.id
                    }
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.projectsUpdate(result)
                    },
                    (error) => {
                        console.log(error)
                    }
                );

            fetch("https://api.crowd-money.ru/profile", {
                method: 'POST',
                body: JSON.stringify({
                    action: 'getProfiles',
                    data: {
                        profileId: this.state.profile.access.id
                    }
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            ...this.state,
                            profiles: this.profilesUpdate(result)
                        })
                    },
                    (error) => {
                        console.log(error)
                    }
                );
        }
    }

    handleChange = (e, newValue) => {
        let name = e.target.name;
        let value = e.target.value;

        this.setState({
            ...this.state,
            [name]: value
        })
    };

    handleChangeProjects = (id, newValue) => {
        if (newValue != null) {
            let projectIds = this.state.projects.filter(project => project.profileId == newValue.id).map(p => p.id);

            this.setState({
                ...this.state,
                projectIds: projectIds,
                currentProfile: newValue,
                paymentProjectId: projectIds[0],
                fileBorrowerActive: '',
                fileGuarantorActive: ''
            })
        }
    };

    projectsUpdate = (projects) => {
        let projectsRep = projects.filter(project => [8, 7, 4, 5].includes(project.status))
        let projectsProfileIds = projectsRep.map(project => project.profileId)

        this.setState({
            ...this.state,
            projects: projectsRep,
            projectsProfileIds: projectsProfileIds
        })
    }

    profilesUpdate = (profiles) => {
        let projectsProfileIds = this.state.projectsProfileIds;
        let profilesRep = profiles.filter(profile => projectsProfileIds.includes(profile.id))
            .map((row) => {
                return {
                    ...row,
                    fullName: (row.entrepreneurData.type === 1 && row.lastName + " " + row.firstName + " " + row.middleName) ||
                        (row.entrepreneurData.type === 2 && "ИП " + row.lastName + " " + row.firstName + " " + row.middleName) ||
                        (row.entrepreneurData.type === 3 && row.entrepreneurData.companyName),
                }
            }
            )
        return profilesRep;
    }

    generateClaim = (type) => {
        fetch("https://api.crowd-money.ru/documents/generateClaim", {
            method: 'POST',
            body: JSON.stringify({
                action: "generateClaim",
                data: {
                    claimType: type,
                    paymentProjectId: this.state.paymentProjectId,
                    daysDelay: this.state.daysDelay,
                    amountCredit: this.state.amountCredit,
                    amountCreditDelay: this.state.amountCreditDelay,
                    rateRub: this.state.rateRub,
                    rateRubDelay: this.state.rateRubDelay,
                    fine: this.state.fine,
                    amountAll: this.state.amountAll,
                    contractNumberGuarantor: this.state.contractNumberGuarantor,
                    dateNumberGuarantor: this.state.dateNumberGuarantor,
                    guarantorName: this.state.guarantorName,
                    guarantorBirthDay: this.state.guarantorBirthDay,
                    guarantorPassportData: this.state.guarantorPassportData,
                    guarantorAddress: this.state.guarantorAddress,        
                    currentProfile: this.state.currentProfile,
                    currentProject: this.state.projects.filter(project => project.profileId == this.state.currentProfile.id)[0]
                }
            })
        })
            .then(res => res.text())
            .then((result) => {
                store.dispatch({
                    type: "SHOW.SNACKBAR",
                    payload: {
                        status: "success",
                        message: "Претензия сформирована"
                    }
                });
                this.setState(
                    {
                        ...this.state,
                        [type == 1 ? 'fileBorrowerActive' : 'fileGuarantorActive']: result
                    }
                )
            },
                (error) => {
                    console.log(error)

                    store.dispatch({
                        type: "SHOW.SNACKBAR",
                        payload: {
                            status: "error",
                            message: "Ошибка формирования претензии"
                        }
                    });
                }
            );
    }

    render() {
        const { profiles, projectIds, paymentProjectId, currentProfile, fileBorrowerActive, fileGuarantorActive } = this.state;

        return (
            <div className="tm-body">
                <div className="tm-section-documents">
                    <div className="uk-container uk-container-expand uk-section-small uk-padding-remove-top">
                        <h3 className="uk-h3 tm-font-weight-5">Досудебная работа</h3>
                        <div className="uk-overflow-auto">
                            {
                                profiles.length != 0 ? (
                                    <>
                                        <div className="uk-grid-row-small" data-uk-grid>
                                            <div className="uk-width-1-3@s">
                                                <label className="uk-form-label">
                                                    Выбрать заёмщика
                                                </label>
                                                <Autocomplete
                                                    key="autocomplete"
                                                    name="projectsList"
                                                    options={profiles}
                                                    disableClearable
                                                    getOptionLabel={(option) => `${option.fullName}`}
                                                    renderOption={(props, option) => (
                                                        <li {...props}>
                                                            {
                                                                `${option.fullName}`
                                                            }
                                                        </li>
                                                    )}
                                                    value={currentProfile}
                                                    onChange={this.handleChangeProjects}
                                                    renderInput={(params) => (
                                                        <TextField {...params} placeholder="Заёмщики" />
                                                    )}
                                                />
                                            </div>
                                            <div className="uk-width-1-4@s">
                                                <label className="uk-form-label">
                                                    Номер договора
                                                </label>
                                                <select
                                                    className="uk-select"
                                                    name="paymentProjectId"
                                                    onChange={this.handleChange}
                                                    disabled={currentProfile == null}
                                                >
                                                    {
                                                        paymentProjectId == '' && (
                                                            <option value={0}>Выберите договор...</option>
                                                        )
                                                    }
                                                    {
                                                        projectIds.map(id => (
                                                            <option value={id}>{id}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="uk-grid-row-small" data-uk-grid>
                                            <div className="uk-width-1-6@s">
                                                <label className="uk-form-label">
                                                    Кол-во дней просрочки
                                                </label>
                                                <IMaskInput
                                                    mask={Number}
                                                    radix=","
                                                    name="daysDelay"
                                                    unmask={true}
                                                    inputRef={(input) => { this.daysDelay = input }}
                                                    className="uk-input"
                                                    onChange={this.handleChange}
                                                    placeholder='Ввод вручную'                                               
                                                />
                                            </div>
                                            <div className="uk-width-1-6@s">
                                                <label className="uk-form-label">
                                                    Основной долг
                                                </label>
                                                <IMaskInput
                                                    mask={Number}
                                                    radix=","
                                                    name="amountCredit"
                                                    unmask={true}
                                                    inputRef={(input) => { this.amountCredit = input }}
                                                    className="uk-input"
                                                    onChange={this.handleChange}
                                                    placeholder='Ввод вручную'                                          
                                                />
                                            </div>
                                            <div className="uk-width-1-4@s">
                                                <label className="uk-form-label">
                                                    Просроченная сумма основного долга
                                                </label>
                                                <IMaskInput
                                                    mask={Number}
                                                    radix=","
                                                    name="amountCreditDelay"
                                                    unmask={true}
                                                    inputRef={(input) => { this.amountCreditDelay = input }}
                                                    className="uk-input"
                                                    onChange={this.handleChange}
                                                    placeholder='Ввод вручную'                                                
                                                />
                                            </div>
                                        </div>
                                        <div className="uk-grid-row-small" data-uk-grid>
                                            <div className="uk-width-1-6@s">
                                                <label className="uk-form-label">
                                                    Проценты
                                                </label>
                                                <IMaskInput
                                                    mask={Number}
                                                    radix=","
                                                    name="rateRub"
                                                    unmask={true}
                                                    inputRef={(input) => { this.rateRub = input }}
                                                    className="uk-input"
                                                    onChange={this.handleChange}
                                                    placeholder='Ввод вручную'                                                
                                                />
                                            </div>
                                            <div className="uk-width-1-6@s">
                                                <label className="uk-form-label">
                                                    Просроченные проценты
                                                </label>
                                                <IMaskInput
                                                    mask={Number}
                                                    radix=","
                                                    name="rateRubDelay"
                                                    unmask={true}
                                                    inputRef={(input) => { this.rateRubDelay = input }}
                                                    className="uk-input"
                                                    onChange={this.handleChange}
                                                    placeholder='Ввод вручную'                                                
                                                />
                                            </div>
                                            <div className="uk-width-1-4@s">
                                                <label className="uk-form-label">
                                                    Пени
                                                </label>
                                                <IMaskInput
                                                    mask={Number}
                                                    radix=","
                                                    name="fine"
                                                    unmask={true}
                                                    inputRef={(input) => { this.fine = input }}
                                                    className="uk-input"
                                                    onChange={this.handleChange}
                                                    placeholder='Ввод вручную'                                                
                                                />
                                            </div>
                                        </div>
                                        <div className="uk-grid-row-small" data-uk-grid>
                                            <div className="uk-width-1-5@s">
                                                <label className="uk-form-label">
                                                    Итого к возврату
                                                </label>
                                                <IMaskInput
                                                    mask={Number}
                                                    radix=","
                                                    name="amountAll"
                                                    unmask={true}
                                                    inputRef={(input) => { this.amountAll = input }}
                                                    className="uk-input"
                                                    onChange={this.handleChange}
                                                    placeholder='Ввод вручную'                                             
                                                />
                                            </div>
                                        </div>
                                        <div className="uk-grid-row-small uk-margin-medium-top" data-uk-grid>
                                            {
                                                fileBorrowerActive == '1' ? (
                                                    <a
                                                        href={'https://api.crowd-money.ru/templates/Претензия для заёмщика.docx'}
                                                        // download={`Претензия для заёмщика по договору.docx`}                                                        
                                                    >
                                                        <button className="uk-button uk-button-large uk-button-primary">
                                                            Скачать
                                                        </button>
                                                    </a>
                                                )
                                                    :
                                                    (
                                                        <div>
                                                            <button
                                                                className="uk-button uk-button-large uk-button-primary" onClick={() => this.generateClaim(1)}
                                                                disabled={currentProfile == null}
                                                            >
                                                                Сформировать претензию для заемщика
                                                            </button>
                                                        </div>
                                                    )
                                            }
                                        </div>
                                        <div className="uk-grid-row-small uk-margin-medium-top" data-uk-grid>
                                            <div className="uk-width-1-5@s">
                                                <label className="uk-form-label">
                                                    Номер договора поручителя
                                                </label>
                                                <IMaskInput
                                                    mask={String}
                                                    radix=","
                                                    name="contractNumberGuarantor"
                                                    unmask={true}
                                                    inputRef={(input) => { this.contractNumberGuarantor = input }}
                                                    className="uk-input"
                                                    onChange={this.handleChange}
                                                    placeholder='Ввод вручную'                                               
                                                />
                                            </div>
                                            <div className="uk-width-1-5@s">
                                                <label className="uk-form-label">
                                                    Дата договора поручителя
                                                </label>
                                                <IMaskInput
                                                    mask={Date}
                                                    radix=","
                                                    name="dateNumberGuarantor"
                                                    unmask={true}
                                                    inputRef={(input) => { this.dateNumberGuarantor = input }}
                                                    className="uk-input"
                                                    onChange={this.handleChange}
                                                    placeholder='Ввод вручную'                                               
                                                />
                                            </div>
                                        </div>
                                        <div className="uk-grid-row-small uk-margin-medium-top" data-uk-grid>
                                            <div className="uk-width-1-5@s">
                                                <label className="uk-form-label">
                                                    ФИО поручителя
                                                </label>
                                                <IMaskInput
                                                    mask={String}
                                                    radix=","
                                                    name="guarantorName"
                                                    unmask={true}
                                                    inputRef={(input) => { this.guarantorName = input }}
                                                    className="uk-input"
                                                    onChange={this.handleChange}
                                                    placeholder='Ввод вручную'                                               
                                                />
                                            </div>
                                            <div className="uk-width-1-5@s">
                                                <label className="uk-form-label">
                                                    Дата рождения поручителя
                                                </label>
                                                <IMaskInput
                                                    mask={Date}
                                                    radix=","
                                                    name="guarantorBirthDay"
                                                    unmask={true}
                                                    inputRef={(input) => { this.guarantorBirthDay = input }}
                                                    className="uk-input"
                                                    onChange={this.handleChange}
                                                    placeholder='Ввод вручную'                                               
                                                />
                                            </div>
                                        </div>
                                        <div className="uk-grid-row-small uk-margin-medium-top" data-uk-grid>
                                            <div className="uk-width-1-5@s">
                                                <label className="uk-form-label">
                                                    Паспортные данные поручителя
                                                </label>
                                                <IMaskInput
                                                    mask={String}
                                                    radix=","
                                                    name="guarantorPassportData"
                                                    unmask={true}
                                                    inputRef={(input) => { this.guarantorPassportData = input }}
                                                    className="uk-input"
                                                    onChange={this.handleChange}
                                                    placeholder='Ввод вручную'                                               
                                                />
                                            </div>
                                            <div className="uk-width-1-5@s">
                                                <label className="uk-form-label">
                                                    Адрес поручителя
                                                </label>
                                                <IMaskInput
                                                    mask={String}
                                                    radix=","
                                                    name="guarantorAddress"
                                                    unmask={true}
                                                    inputRef={(input) => { this.guarantorAddress = input }}
                                                    className="uk-input"
                                                    onChange={this.handleChange}
                                                    placeholder='Ввод вручную'                                               
                                                />
                                            </div>
                                        </div>
                                        <div className="uk-grid-row-small uk-margin-medium-top" data-uk-grid>
                                            {
                                                fileGuarantorActive == '1' ? (
                                                    <a
                                                        href={'https://api.crowd-money.ru/templates/Претензия для поручителя.docx'}
                                                        // download={`Претензия для поручителя по договору ${paymentProjectId}.docx`}
                                                    >
                                                        <button className="uk-button uk-button-large uk-button-primary">
                                                            Скачать
                                                        </button>
                                                    </a>
                                                )
                                                    :
                                                    (
                                                        <div>
                                                            <button className="uk-button uk-button-large uk-button-primary" onClick={() => this.generateClaim(2)}
                                                                disabled={currentProfile == null}
                                                            >
                                                                Сформировать претензию для поручителя
                                                            </button>
                                                        </div>
                                                    )
                                            }
                                        </div>
                                    </>
                                )
                                    :
                                    (
                                        <div className="tm-spinner">
                                            <span data-uk-spinner="ratio: 3" />
                                        </div>
                                    )
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(Claims);