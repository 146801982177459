import React, { useState } from 'react';
import ChangeBalanceModal from '../../others/ChangeBalanceModal';
import { IMaskInput } from 'react-imask';

function ChangeBalancePanel(props) {
 
  const defaultData = {
    action: "",
    newBalance: ""
}

  const [data, setData] = useState(defaultData);

  return (
    <div>
      <IMaskInput
        mask={Number}
        radix=","
        unmask={true}
        name="purposeAmount"
        style={{ width: 100 }}        
        className="uk-input uk-form-small"
        onAccept={
          (value) => {setData({...data, newBalance: value})}
        }
        placeholder='Сумма'
      />
      <button
        className="uk-button uk-button-small uk-button-primary"
        disabled={data.newBalance > 0 ? false : true}
        onClick={(e) => {setData({...data, action: 1})}}
        data-uk-toggle={`target: #changeBalanceModal${props.profile.id};`}
      >
        Пополнить
      </button>
      <button
        className="uk-button uk-button-small uk-button-primary"
        disabled={data.newBalance > 0 &&
          data.newBalance <= props.profile.balance ? false : true}
          onClick={(e) => {setData({...data, action: 2})}}
        data-uk-toggle={`target: #changeBalanceModal${props.profile.id};`}
      >
        Списать
      </button>

      <ChangeBalanceModal
        action={data.action}
        profile={props.profile}
        defaultProfile={props.defaultProfile}
        newBalance={data.newBalance}
        projectsId={props.projectsId}
      />
    </div>
  )

}

export default ChangeBalancePanel;