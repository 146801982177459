import React from 'react';
import store from "../../store";
import DocumentsList from "../cabinet/Documents/components/List";

class About extends React.Component {
    componentDidMount() {
        store.dispatch({ type: 'PAGE.DOCUMENTS' });
    }

    render() {
        return (
            <div className="uk-container uk-margin-large-top">
                <div className="uk-light uk-text-center">
                    <h1 className="uk-h1 uk-margin-large-bottom">
                        <span>
                            Документы
                        </span>
                    </h1>
                </div>
                <DocumentsList />
            </div>
        )
    }
}

export default About;