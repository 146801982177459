import React from 'react';

function DocumentBox(props){
    return (
        <div className={props.className ? props.className : "tm-document-pdf-box"}>
             <div className="pdf-box-text">{props.text}</div>
            <div>
                <span>PDF</span>
                <a className="uk-button-small uk-button-primary uk-button-small" href={props.href} download>
                    Скачать
                </a>
            </div>           
        </div>
    )
}

export default DocumentBox;