import React from 'react';

const VideoModalButton = props => (
    <>
        <div className="tm-play">
            <p>{props.buttonName}</p>
            <div className="tm-play-button" data-uk-toggle="target: #tm-modal-video;">
                <img src={require(`../../../assets/images/play.png`)} />
                <div class="pulse-button-circle"></div>
                <div class="pulse-button-circle"></div>
                <div class="pulse-button-circle"></div>
            </div>
        </div>
        <div id="tm-modal-video" className="uk-flex-top" data-uk-modal>
            <div className="uk-modal-dialog uk-width-auto uk-margin-auto-vertical">
                <button className="uk-modal-close-outside" type="button" data-uk-close />
                <video src={props.url} controls playsinline data-uk-video />
            </div>
        </div>

    </>

)

export default VideoModalButton;