import React from 'react';
import InputMask from "react-input-mask";
import { Link } from "react-router-dom";
import store from "../../../store";
import ShowAndHidePassword from "../../../partials/others/ShowAndHidePassword"

class Restore extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleRestore = this.handleRestore.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);

        this.state = {
            activeStep: 1,
            profile: {}
        };
    }

    componentDidMount() {
        store.dispatch({ type: 'MAIN.RESTORE' });
    }

    handleChange = (e) => {
        this.setState({
            profile: {
                ...this.state.profile,
                [e.target.name]: e.target.value
            }
        })
    };

    onKeyDown = (e) => {
        if (e.which === 13) {
            this.handleRestore();
        }
    };

    handleRestore = (step) => {
        const $this = this;

        store.dispatch({
            type: "HIDE.SNACKBAR"
        });

        fetch( "https://api.crowd-money.ru/auth/signin", {
            method: 'POST',
            body: JSON.stringify({
                action: "restore",
                step: this.state.activeStep,
                data: this.state.profile
            })
        })
        .then(res => res.json())
        .then(
            (result) => {
                if (result.status === "success") {
                    localStorage.setItem("profileHash", result.profileHash);

                    this.props.history.push('/cabinet');
                }
                else {
                    if (result.message) {
                        store.dispatch({
                            type: "SHOW.SNACKBAR",
                            payload: {
                                status: result.status,
                                message: result.message
                            }
                        });
                    }

                    if (result.field) {
                        $this[result.field].focus();

                        return false;
                    }

                    if (step === 1) {
                        this.setState({
                            activeStep: 2
                        });
                    }
                    else if (step === 2) {
                        this.setState({
                            activeStep: 3
                        });
                    }
                    else if (step === 3) {
                        localStorage.setItem("profileHash", result.profileHash);

                        this.props.history.push('/cabinet');
                    }
                }
            },(error) => {
                console.log(error)
            }
        )
    };

    render() {
        const {activeStep} = this.state;

        return (
            <div className="tm-section-signin">
                <div className="uk-container uk-section">
                    <h3 className="uk-h3 uk-margin-small">Вход в аккаунт</h3>
                    <div className="uk-text-small uk-text-muted">Вход в существующий аккаунт</div>
                    <ul className="uk-tab uk-margin-medium-top uk-margin-medium-bottom">
                        <li><Link to="/signin">Вход в аккаунт</Link></li>
                        <li><Link to="/signup">Регистрация</Link></li>
                        <li className="uk-active"><Link to="/restore">Восстановление пароля</Link></li>
                    </ul>
                    <div className="uk-width-2-5@s uk-margin-medium-top">
                        {
                            activeStep === 1 && (
                                <>
                                    <div className="uk-margin">
                                        <InputMask mask="+7(999)999-99-99" onChange={this.handleChange} onKeyDown={this.onKeyDown}>
                                            <input
                                                type="text"
                                                placeholder="Номер мобильного телефона"
                                                className="uk-input"
                                                name="phone"
                                                ref={(input) => {this.phone = input}}
                                            />
                                        </InputMask>
                                    </div>
                                    <div className="uk-margin-medium-top">
                                        <div className="uk-button uk-button-large uk-button-primary uk-width" onClick={() => (this.handleRestore(1))}>Далее</div>
                                    </div>
                                </>
                            )
                        }
                        {
                            activeStep === 2 && (
                                <>
                                    <h4 className="uk-h4 uk-text-light">
                                        Мы отправили код подтверждения на<br /> номер {this.state.profile.phone}
                                    </h4>
                                    <div className="uk-margin">
                                        <InputMask mask="9999" onChange={this.handleChange} onKeyDown={this.onKeyDown}>
                                            <input
                                                type="text"
                                                placeholder="Код подтверждения"
                                                className="uk-input"
                                                name="verifyCode"
                                                ref={(input) => {this.verifyCode = input}}
                                            />
                                        </InputMask>
                                    </div>
                                    <div className="uk-margin-medium-top">
                                        <div className="uk-button uk-button-large uk-button-primary uk-width" onClick={() => (this.handleRestore(2))}>Далее
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        {
                            activeStep === 3 && (
                                <>
                                    <div className="uk-margin">
                                        <ShowAndHidePassword 
                                            onKeyDown={this.onKeyDown}                              
                                            onChange={this.handleChange}
                                            placeholder="Новый пароль"
                                            name="password"
                                            context={this}
                                        />                                       
                                    </div>
                                    <div className="uk-margin">
                                        <ShowAndHidePassword 
                                            onKeyDown={this.onKeyDown}                              
                                            onChange={this.handleChange}
                                            placeholder="Повторите пароль"
                                            name="passwordConfirm"
                                            context={this}
                                        />                                       
                                    </div>
                                    <div className="uk-margin-medium-top">
                                        <div className="uk-button uk-button-large uk-button-primary uk-width" onClick={() => (this.handleRestore(3))}>Изменить пароль</div>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Restore;