import React from 'react';
import { Link } from "react-router-dom";
import InputMask from "react-input-mask";
import store from "../../../store";
import UIkit from "uikit";

import VideoModalButton from '../../../partials/others/VideoModalButton';

const advantages = [
    {
        icon: "contract.png",
        title: "Без залога и в краткий срок",
        description: "На инвестиционной платформе «Crowd money» у вас есть возможность привлечь денежные средства по договору инвестирования без твердого обеспечения и в кратчайшие сроки. Публикуем заявку на привлечение финансирования и занимаемся поиском надежных инвесторов."
    },
    {
        icon: "icon.png",
        title: "Надежность платежей",
        description: "Все денежные средства по сделкам зачисляются на специальный номинальный счёт в банке. Личные средства всегда можно вывести на личный банковский счёт. Банк защищает средства на счете от любых претензий — блокировок и арестов."
    },
    {
        icon: "hourglass.png",
        title: "Какие условия?",
        description: "Ставки по займам от 1,25% в месяц и варьируются в зависимости от двух факторов: индекс благонадежности заемщика и рейтинга проекта (заявки на привлечение финансирования), после детального изучения всех факторов, влияющих на финансируемый проект."
    },
    {
        icon: "workspace.png",
        title: "Почему инвестиционная платформа?",
        description: "В настоящее время существует большое количество заемщиков, представителей малого и среднего бизнеса, которые не могут получить кредит в банке. На инвестиционной платформе более гибкий подход, позволяющий привлечь средства инвестора с защитой вложений."
    }
];

const steps = [
    {
        image: "step_1.png",
        title: "Подача заявки на займ",
        description: "Регистрируетесь на платформе и заполняете заявку на займ с указанием суммы и сроков"
    },
    {
        image: "step_2.png",
        title: "Анализ платформы",
        description: "Оцениваем вашу заявку во избежание мошенничества и присваиваем рейтинг"
    },
    {
        image: "step_3.png",
        title: "Публикация заявки",
        description: "Заявка становится доступной для инвесторов"
    },
    {
        image: "step_4.png",
        title: "Поступление денег",
        description: "Заключается договор инвестирования с инвесторами, и собранные денежные средства поступают на Ваш расчетный счет"
    }
];

const guarantees = [
    {
        icon: "star.png",
        title: "Отбор заемщиков",
        description: "Публикация заявки на привлечение финансирования происходит после прохождения экспертного анализа и скоринг-оценки"
    },
    {
        icon: "shield.png",
        title: "Поручительство",
        description: "Заключение договора поручительства с бенефициарными владельцами заемщика является обязательным условием выдачи займа на инвестиционной платформе"
    },
    {
        icon: "info.png",
        title: "Юридическая защита",
        description: "При выдаче займа заключается договор инвестирования, который имеет положительную судебную практику по взысканию средств инвесторов"
    }
];

const questions = [
    [
        {
            title: "Что такое платформа «Crowd Money»?",
            description: "«Crowd Money» - онлайн-платформа взаимного финансирования, на которой размещаются заявки на выдачу займа для реализации государственных контрактов и для развития бизнеса. Платформа действует по принципам краудлендинга: позволяет инвестировать средства с доходностью выше вклада в банке и привлекать денежные средства для развития бизнеса."
        },
        {
            title: "Как это работает в двух словах?",
            description: "Заключается договор инвестирования, который по смыслу является стандартным договором займа. Все платежи проходят через номинальный счет платформы, которая лишь управляет счетом и не может списать средства на свои нужды. По истечению срока заемщик перечисляет сумму инвестиций плюс доход по процентам на номинальный счет платформы, и пополнение средств отражается в личном кабинете инвестора."
        },
        {
            title: "Сколько можно заработать?",
            description: "Ставки по займам варьируются в зависимости от двух факторов: индекс благонадежности заемщика и рейтинг заявки на займ. Примерные ставки на платформе от 15% до 35% годовых. Для стабильного уровня доходности рекомендуем инвесторам диверсифицировать портфель инвестиций из нескольких проектов (займов) разного рейтинга."
        },
        {
            title: "Как быть с налогами?",
            description: "При заключении договора инвестирования (займа) есть две стороны процесса налогообложения: инвестор и заемщик. Налоговый кодекс возлагает на заемщика обязанность налогового агента. Платформа «Crowd Money» не удерживает с инвестора никаких налогов, эта задача возложена на заемщика. Если заемщик не удержал НДФЛ или не сообщил об этом, то никаких санкций к инвесторам со стороны налоговой не будет."
        }
    ],
    [
        {
            title: "Какие шаги для регистрации на платформе?",
            description: "Пять шагов для успешных инвестиций на платформе «Crowd Money»:<br /> 1. Регистрация пользователя на сайте; <br />2. Выбор статуса участника: инвестор либо заемщик; <br />3. Выбор проекта для инвестирования / подача заявки на займ; <br />4. Заключение договора инвестирования (займа); <br />5. Возврат инвестиций с процентным доходом."
        },
        {
            title: "Зачем заемщикам обращаться к инвестиционной платформе?",
            description: "При получении кредита в банке необходимо иметь твердое обеспечение, а без обеспечения - сроки проверочных мероприятий со стороны банка могут затянуть процесс на длительное время с высокой вероятностью отказа. При привлечении суммы выше 1 млн. рублей возникает «кредитный вакуум». На инвестиционной платформе более гибкий подход, позволяющий привлечь средства инвестора с гарантированной защитой вложений."
        },
        {
            title: "Почему стоит инвестировать на платформе?",
            description: "Ключевое слово - «простые» инвестиции. Все прозрачно и понятно: инвестируете средства заемщику по оговоренной процентной ставке, никаких скрытых платежей, никаких дополнительных налогов, только стандартная ставка по НДФЛ, удерживаемая налоговым агентом."
        },
        {
            title: "Какие гарантии?",
            description: "Мы регистрируем только надежных заемщиков, прошедших отбор скоринг- оценки для минимизации рисков инвестора. Анализ заявок на выдачу займа проводят квалифицированные финансовые эксперты. Платформа следит за соблюдением условий займа. При наступлении просрочки и невыплаты займа мы защищаем интересы инвестора в судебном порядке и проводим взыскание средств. В планах предоставить возможность застраховать договор инвестирования (займа) для компенсации возможных потерь."
        }
    ],
    [
        {
            title: "Как проходят платежи?",
            description: "Все платежи проходят через номинальный счет платформы, которая лишь удерживает свою комиссию, согласно Тарифам. Средства на номинальный счет зачисляют инвесторы, и по их распоряжению платформа перечисляет сумму займа на расчетный счет заемщика. По истечению срока заемщик перечисляет сумму инвестиций плюс доход по процентам на номинальный счет. Инвестор может вывести деньги на банковский счет в любой момент.\n"
        },
        {
            title: "Что такое номинальный счет?",
            description: "С 1 января 2020 года все инвестиционные платформы обязаны использовать номинальный счёт. Это необходимо для защиты средств пользователей. Правила предусмотрены законом No 259-ФЗ «О привлечении инвестиций с помощью инвестиционных платформ и о внесении изменений в отдельные законодательные акты Российской Федерации». Номинальный счёт — это банковский счёт, на котором хранятся деньги инвесторов, и только с их распоряжения перечисляются платформой на счет заемщика. Никто не может присвоить средства инвестора с номинального счёта."
        },
        {
            title: "Что делать если заемщик не возвращает средства займа?",
            description: "В большинстве случаев просрочка до 7 дней носит технический характер и связана с небольшой задержкой. Платформа мониторит сроки по займам и уведомляет заемщика заранее о наступлении даты уплаты платежей. В случае если заемщик не выплатил средства по договору инвестирования (займа) - инвестор может обратиться к платформе для организации судебного взыскания по платежам. Юристы займутся взысканием средств в пользу инвестора."
        },
        {
            title: "Не нашли ответа на Ваш вопрос?",
            description: "Напишите нам на почту info@crowd-money.ru или свяжитесь с нами по телефону +7 (985) 712-34-38, и мы оперативно Вам ответим."
        }
    ]
];

const partners = [
    { logotype: 'partners-image-2.png' },
    { logotype: 'partners-image-3.svg' },
    { logotype: 'partners-image-4.png' },
    { logotype: 'partners-image-6.svg' },
    { logotype: 'partners-image-7.svg' },
    { logotype: 'partners-image-8.svg' },
    { logotype: 'partners-image-9.png' },
    { logotype: 'partners-image-10.svg' },
    { logotype: 'partners-image-11.svg' }
];

class Borrowers extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            documents: [],
        };

        this.handleChange = this.handleChange.bind(this);
        this.signup = this.signup.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    componentDidMount() {
        store.dispatch({ type: 'MAIN.BORROWERS' });

        fetch("https://api.crowd-money.ru/documents", {
            method: 'POST'
        })
            .then(res => res.json())
            .then(
                (result) => {

                    this.setState({
                        isLoaded: true,
                        documents: result
                    })
                },
                (error) => {
                    console.log(error)
                }
            );
    }

    handleChange = (e) => {
        this.setState({
            profile: {
                [e.target.name]: e.target.value
            }
        })
    };

    onKeyDown = (e) => {
        if (e.which === 13) {
            this.signup();
        }
    };

    signup = () => {
        store.dispatch({
            type: "HIDE.SNACKBAR"
        });

        fetch("https://api.crowd-money.ru/auth/signup", {
            method: 'POST',
            body: JSON.stringify({
                action: "signup",
                step: 1,
                data: this.state.profile
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.message) {
                        store.dispatch({
                            type: "SHOW.SNACKBAR",
                            payload: {
                                status: result.status,
                                message: result.message
                            }
                        });
                    }

                    if (result.field === 'phone') {
                        this.phone.focus();

                        return false;
                    }

                    store.dispatch({
                        type: 'MAIN.SIGNUP.STEP_TWO',
                        payload: {
                            signupData: {
                                activeStep: 2,
                                profile: {
                                    ...this.state.profile,
                                    sentCode: result.sentCode
                                }
                            }
                        }
                    });

                    this.props.history.push("signup");
                }, (error) => {
                    console.log(error)
                }
            )
    };

    render() {
        const { isLoaded, documents } = this.state;

        return (
            isLoaded && (
                <div>
                    <div className="uk-container uk-margin-large-bottom">
                        <div className="tm-section-hero">
                            <div className="uk-light uk-text-center">
                                <h1 className="uk-h1">
                                    <span>
                                        Получайте деньги на развитие <br /> бизнеса и исполнение контрактов
                                    </span>
                                </h1>
                                <p>
                                    Опубликуйте на нашей платформе заявку на привлечение<br /> денежных средств и получите займ в кратчайшие сроки<br /> и по выгодным условиям с низкой процентной ставкой
                                </p>
                            </div>
                            <div className="tm-form-phone uk-text-center">
                                <InputMask mask="+7(999)999-99-99" onChange={this.handleChange} onKeyDown={this.onKeyDown}>
                                    <input
                                        type="text"
                                        placeholder="Номер мобильного телефона"
                                        className="uk-input"
                                        name="phone"
                                        ref={(input) => { this.phone = input }}
                                    />
                                </InputMask>
                                <div className="uk-button uk-button-primary" onClick={this.signup}>
                                    НАЧАТЬ
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="uk-container uk-margin-large-bottom">
                        <div className="tm-mineconom-info">
                            {/* <div className="uk-card uk-card-default">
                                    <h3 className="uk-card-header uk-text-bold">Внимание!</h3>
                                    <div className="uk-card-body">
                                        <p className="uk-text-justify uk-text-bold">
                                            В соответствии с утвержденными майскими праздниками Министерством труда и социальной защиты Российской Федерации, уведомляем пользователей платформы о выходных днях в период с 2 мая по 3 мая 2022 года и с 9 мая по 10 мая 2022 года. Функционал платформы будет доступен в указанные дни, кроме проведения платежей и рассмотрения новых заявок. Желаем вам хороших праздников и всех благ!
                                        </p>
                                    </div>
                                </div> */}
                            <img src={require('../../../assets/images/minecomom.png')} alt="" />
                            <div className="text-block">
                                <div>
                                    <div className="uk-text-justify">
                                        Инвестиционная платформа прошла отбор по программе субсидирования доступа субъектов малого и среднего предпринимательства к альтернативным способам финансирования от Министерства экономического развития РФ.
                                    </div>
                                    <div className="uk-margin-small-top">
                                        <a href="https://www.economy.gov.ru/material/news/msp_smogut_poluchit_kompensaciyu_pri_privlechenii_investiciy_na_11_kraudfandingovyh_platformah.html" target="_blank">Перейти на сайт Минэкономразвития</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="uk-container uk-margin-large-bottom">
                        <div className="tm-section-advantages">
                            {
                                advantages.map((advantage) => (
                                    <div className="tm-advantage">
                                        <img src={require(`../../../assets/images/icons/${advantage.icon}`)} />
                                        <h3 className="uk-h3">{advantage.title}</h3>
                                        <p className="uk-text-center">{advantage.description}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="uk-container uk-margin-xlarge-bottom">
                        <div className="tm-section-partners">
                            <div className="uk-light uk-text-center uk-margin-large-bottom">
                                <h1 className="uk-h1">
                                    <span>
                                        Наши партнёры
                                    </span>
                                </h1>
                            </div>
                            <div className="uk-position-relative uk-visible-toggle uk-light" tabIndex="-1" data-uk-slider>
                                <ul className="uk-flex uk-flex-middle uk-slider-items uk-child-width uk-child-width-auto@s">
                                    {
                                        partners.map((partner) => (
                                            <li className="uk-text-center">
                                                <div className="uk-panel uk-margin-left uk-margin-right">
                                                    <img src={require(`../../../assets/images/${partner.logotype}`)} style={{ height: 60 }} />
                                                </div>
                                            </li>
                                        ))
                                    }
                                </ul>
                                <a className="uk-position-center-left uk-position-small" href="#"
                                    data-uk-slidenav-previous data-uk-slider-item="previous"></a>
                                <a className="uk-position-center-right uk-position-small" href="#"
                                    data-uk-slidenav-next data-uk-slider-item="next"></a>
                            </div>
                        </div>
                    </div>
                    <div className="uk-container uk-margin-large-bottom">
                        <div className="tm-section-how-it-works" id="tm-section-how-it-works">
                            <div className="uk-light uk-text-center uk-margin-medium-bottom">
                                <h1 className="uk-h1">
                                    <span>
                                        Как работает инвестиционная<br />платформа
                                    </span>
                                </h1>
                            </div>
                            <div>
                                <div className="works-box">
                                    <div className="works-head">
                                        <div>
                                            <span>01</span>
                                            <h1>Для кого</h1>
                                            <VideoModalButton buttonName="Смотреть видео" url="/public/videos/crowdmoney.mp4" />
                                        </div>
                                    </div>
                                    <div className="works-step-name-img uk-margin-bottom">
                                        <img src={require(`../../../assets/images/icons/case.png`)} />
                                        <h3 className="uk-h3">Для кого подойдет</h3>
                                    </div>
                                    <div className="uk-flex uk-grid uk-child-width-1-2@s">
                                        <div className="uk-margin-medium-bottom">
                                            <span className="uk-text-left">
                                                Наша краудлендинговая платформа позволяет представителям малого и среднего бизнеса привлекать финансирование на следующие цели:<br />
                                                <ul>
                                                    <li>реализация государственных контрактов;</li>
                                                    <li>расширение и развитие бизнеса;</li>
                                                    <li>пополнение оборотных средств.</li>
                                                </ul>
                                            </span>
                                        </div>
                                        <div>
                                            <p className="uk-text-left">
                                                Кроме того, на платформе планируется реализовать направление краудинвестинга, позволяющее искать инвесторов в развивающиеся проекты и стартапы.
                                            </p>
                                            <a href={`/documents/${documents[3]['data'][0]}`} Style="text-decoration: underline;" download>Политика отбора заявок</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="works-box">
                                    <div className="works-head">
                                        <div>
                                            <span>02</span>
                                            <h1>Условия</h1>
                                            <VideoModalButton buttonName="Смотреть видео" url="/public/videos/crowdmoney.mp4" />
                                        </div>
                                    </div>
                                    <div className="uk-flex uk-grid uk-child-width-1-2@s">
                                        <div className="uk-margin-medium-bottom">
                                            <div className="works-step-name-img">
                                                <img src={require(`../../../assets/images/icons/card.png`)} />
                                                <h3 className="uk-h3">Условия привлечения займа</h3>
                                            </div>
                                            <p className="uk-text-left">
                                                Платформа при регистрации проверяет заемщика и оценивает уровень благонадежности. Заявки на займ публикуются на платформе, которая занимается привлечением инвесторов.<br /> В отличие от банка на платформе не требуется твердое обеспечение и поручительство. Проверка клиентов по скорингу автоматизирована и рейтинг присваивается по окончанию регистрации. Платформа берет разовую комиссию после привлечения средств на Ваш счет.
                                            </p>
                                        </div>
                                        <div>
                                            <div className="works-step-name-img">
                                                <img src={require(`../../../assets/images/icons/security_02.png`)} />
                                                <h3 className="uk-h3">Платежи по номинальному счету</h3>
                                            </div>
                                            <p className="uk-text-left">
                                                Согласно <a href="http://www.consultant.ru/document/cons_doc_LAW_330652" target="_blank">Федеральному закону 259-ФЗ</a> все средства по сделкам краудлендинга должны зачисляться на номинальный счёт в банке. Распоряжаться деньгами могут только инвесторы, но при этом владелец счёта — платформа. Личные средства всегда можно вывести на личный банковский счёт. Банк защищает деньги инвесторов от любых претензий — блокировок и арестов.
                                            </p>
                                            <div className="uk-grid">
                                                <a href={`/documents/${documents[5]['data'][0]}`} className="uk-margin-right" Style="text-decoration: underline;" download>Правила работы платформы</a>
                                                <a href={`/documents/${documents[11]['data'][0]}`} Style="text-decoration: underline;" download>Тарифы платформы</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="uk-container uk-margin-large-bottom">
                        <div>
                            <div className="uk-light uk-text-center uk-margin-large-bottom">
                                <img src={require(`../../../assets/images/logotype 1.png`)} style={{ height: 60 }} />
                                <h1 className="uk-h1">
                                    <span>
                                        Как получить займ?
                                    </span>
                                </h1>
                                <p>
                                    У нас вы можете получить займ просто и не выходя из дома.<br />
                                    Вам понадобится всего пару минут :)
                                </p>
                            </div>
                            <div className="tm-section-steps-box">
                                <div className="tm-section-steps-screens">
                                    <img className="screen-1" src={require(`../../../assets/images/screens/screen_1.png`)} />
                                    <img className="screen-2" src={require(`../../../assets/images/screens/screen_2.png`)} />
                                    <img className="screen-3" src={require(`../../../assets/images/screens/screen_3.png`)} />
                                    <img className="screen-4" src={require(`../../../assets/images/screens/screen_4.png`)} />
                                </div>
                                <div className="tm-section-steps-main">
                                    <div className="tm-section-steps">
                                        {
                                            steps.map((step, index) => (
                                                <>
                                                    <div className="tm-step">
                                                        <img src={require(`../../../assets/images/steps/${step.image}`)} />
                                                        <h3 className="uk-h3">{step.title}</h3>
                                                        <p>{step.description}</p>
                                                    </div>
                                                </>
                                            )
                                            )
                                        }
                                    </div>
                                    <div className="uk-text-left">
                                        <Link to="/signup" className="uk-button uk-button-primary">НАЧАТЬ</Link>
                                        <Link to="/documents" className="uk-button uk-button-default">ДОКУМЕНТЫ</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="uk-container uk-margin-large-bottom">
                        <div className="uk-light uk-text-center">
                            <h1 className="uk-h1">
                                <span>
                                    Гарантии
                                </span>
                            </h1>
                        </div>
                        <div className="tm-section-guarantees">
                            {
                                guarantees.map((guarantee) => (
                                    <div className="tm-guarantee">
                                        <img src={require(`../../../assets/images/icons/${guarantee.icon}`)} />
                                        <h3 className="uk-h3">{guarantee.title}</h3>
                                        <p className="uk-text-center">{guarantee.description}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="uk-container uk-margin-large-bottom">
                        <div className="tm-loan-start-box">
                            <div>
                                <div className="uk-light tm-invest-start-head">
                                    <h1 className="uk-h1">
                                        <span>
                                            Хотите получить займ?
                                        </span>
                                    </h1>
                                    <p>
                                        Зарегистрируйтесь на нашей платформе и опубликуйте<br/>заявку на привлечение средств уже сегодня!
                                    </p>
                                </div>
                                <div className="tm-invest-start">
                                    <Link to="/signup" className="uk-button uk-button-primary">НАЧАТЬ</Link>
                                </div>
                            </div>
                            <div className="tm-loan-start-images">
                                <img src={require(`../../../assets/images/Exclude.svg`)} />
                                <img src={require(`../../../assets/images/IpadPro.png`)} />
                            </div>
                        </div>
                    </div>
                    <div className="uk-container uk-margin-large-bottom">
                        <div className="uk-light uk-text-center">
                            <h1 className="uk-h1 uk-margin-medium-bottom">
                                <span>
                                    Частые вопросы
                                </span>
                            </h1>
                        </div>
                        <div className="tm-section-faq" id="tm-section-faq">
                            <div className="uk-width-3-4@s uk-margin-auto">
                                <ul uk-switcher="connect: .uk-switcher;" className="tm-tabs-faq uk-subnav">
                                    <li><a href="#">Платформа</a></li>
                                    <li><a href="#">Регистрация</a></li>
                                    <li><a href="#">Вывод средств</a></li>
                                </ul>
                                <ul className="uk-switcher uk-margin-bottom">
                                    <li>
                                        <ul data-uk-accordion>
                                            {
                                                questions[0].map((question) => (
                                                    <li>
                                                        <a className="uk-accordion-title" href="#">{question.title}</a>
                                                        <div className="uk-accordion-content uk-text-justify">
                                                            <p dangerouslySetInnerHTML={{ __html: question.description }} />
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </li>
                                    <li>
                                        <ul data-uk-accordion>
                                            {
                                                questions[1].map((question) => (
                                                    <li>
                                                        <a className="uk-accordion-title" href="#">{question.title}</a>
                                                        <div className="uk-accordion-content uk-text-justify">
                                                            <p dangerouslySetInnerHTML={{ __html: question.description }} />
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </li>
                                    <li>
                                        <ul data-uk-accordion>
                                            {
                                                questions[2].map((question) => (
                                                    <li>
                                                        <a className="uk-accordion-title" href="#">{question.title}</a>
                                                        <div className="uk-accordion-content uk-text-justify">
                                                            <p dangerouslySetInnerHTML={{ __html: question.description }} />
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>                             
                </div>
            )
        )
    }
}

export default Borrowers;