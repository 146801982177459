const initialState = {
    page: {
        type: null,
        title: null,
        class: null
    },
    signupData: {
        activeStep: 1,
        profile: {
            gender: 1
        }
    },
    profile: {
        access: {
            authorized: false,
            hash: null,
            id: null,
            group: 1
        },
        approve: 0,
        mainData: {
            lastName: null,
            firstName: null,
            middleName: null,
            phone: null
        },
        passportData: {
            series: null,
            number: null,
            date: null,
            issued: null,
            code: null,
            address: null
        },
        bankData: {
            account: "",
            bik: ""
        },
        agreementsData: {},
        entrepreneurData: {
            category: 1,
            type: 1
        },
        documentsData: {
            passport: {
                main: {},
                verify: {}
            }
        },
        settings: {
            type: 1,
            lastName: null,
            firstName: null,
            middleName: null,
            phone: null
        },
        verification: [],
        projects: []
    },
    project: [],
    snackbar: {
        open: false,
        status: null,
        message: null
    },
    sidebar: {
        active: false,
        currentItem: 0
    },
    footer: true
};

function reducers(state = initialState, action) {
    switch (action.type) {
        case 'SET.AUTH':
            return {
                ...state,
                profile: {
                    ...state.profile,
                    access: {
                        ...state.profile.access,
                        ...action.payload.access
                    },
                    mainData: {
                        ...state.profile.mainData,
                        ...action.payload.mainData
                    },
                    settings: {
                        ...action.payload.settings
                    }
                }
            };
        case 'SHOW.SNACKBAR':
            return {
                ...state,
                snackbar: {
                    open: true,
                    ...action.payload
                }
            };
        case 'HIDE.SNACKBAR':
            return {
                ...state,
                snackbar: {
                    ...state.snackbar,
                    open: false
                }
            };
        case 'HIDE.SIDEBAR':
            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    active: false
                }
            };
        case 'CABINET.CHANGE_CABINET_TYPE':
            return {
                ...state,
                profile: {
                    ...state.profile,
                    access: {
                        ...state.profile.access,
                        ...action.payload
                    }
                }
            };

        case 'MAIN.HOME':
            return {
                ...state,
                page: {
                    type: "MAIN",
                    title: "Главная страница",
                    class: "tm-page-main tm-page-main-home"
                },
                sidebar: {
                    ...state.sidebar,
                    active: false
                }
            };
        case 'MAIN.ABOUT':
            return {
                ...state,
                page: {
                    type: "MAIN",
                    title: "О платформе",
                    class: "tm-page-main tm-page-main-about"
                },
                sidebar: {
                    ...state.sidebar,
                    active: false
                }
            };
        case 'PAGE.DOCUMENTS':
            return {
                ...state,
                page: {
                    type: "DOCUMENTS",
                    title: "Документы",
                    class: "tm-page-main tm-page-documents"
                },
                sidebar: {
                    ...state.sidebar,
                    active: false
                }
            };
        case 'MAIN.BORROWERS':
            return {
                ...state,
                page: {
                    type: "MAIN",
                    title: "Заёмщикам",
                    class: "tm-page-main tm-page-main-borrowers"
                },
                sidebar: {
                    ...state.sidebar,
                    active: false
                }
            };
        case 'MAIN.SIGNIN':
            return {
                ...state,
                page: {
                    type: "MAIN",
                    title: "Авторизация",
                    class: "tm-page-main tm-page-main-signin"
                },
                sidebar: {
                    ...state.sidebar,
                    active: false
                }
            };
        case 'MAIN.SIGNOUT':
            return {
                ...state,
                profile: {
                    ...state.profile,
                    access: {
                        authorized: false,
                        hash: null,
                        id: null,
                        group: 1
                    }
                },
                sidebar: {
                    ...state.sidebar,
                    active: false
                }
            };
        case 'MAIN.RESTORE':
            return {
                ...state,
                page: {
                    type: "MAIN",
                    title: "Восстановление пароли",
                    class: "tm-page-main tm-page-main-restore"
                },
                sidebar: {
                    ...state.sidebar,
                    active: false
                }
            };
        case 'MAIN.SIGNUP':
            return {
                ...state,
                page: {
                    type: "MAIN",
                    title: "Регистрация",
                    class: "tm-page-main tm-page-main-signup"
                },
                sidebar: {
                    ...state.sidebar,
                    active: false
                }
            };
        case 'MAIN.SIGNUP.STEP_TWO':
            return {
                ...state,
                page: {
                    type: "MAIN",
                    title: "Регистрация",
                    class: "tm-page-main tm-page-main-signup"
                },
                sidebar: {
                    ...state.sidebar,
                    active: false
                },
                signupData: {
                    ...state.signupData,
                    ...action.payload.signupData
                }
            };
        case 'CABINET.INDEX':
            return {
                ...state,
                page: {
                    type: "CABINET",
                    title: "Личный кабинет",
                    class: "tm-page-cabinet tm-page-cabinet-index"
                },
                sidebar: {
                    active: true,
                    currentItem: null
                },
                footer: false,
                profile: {
                    ...state.profile,
                    ...action.payload.profile
                }
            };
         case 'CABINET.MAIN':
                return {
                    ...state,
                    page: {
                        type: "CABINET",
                        title: "Главная",
                        class: "tm-page-cabinet tm-page-cabinet-index"
                    },
                    sidebar: {
                        active: true,
                        currentItem: null
                    },
                    footer: false,
                    profile: {
                        ...state.profile,
                        ...action.payload.profile
                    }
                };
        case 'CABINET.BALANCE':
            return {
                ...state,
                page: {
                    type: "CABINET",
                    title: "Баланс",
                    class: "tm-page-cabinet tm-page-cabinet-balance"
                },
                sidebar: {
                    active: true,
                    currentItem: 1
                },
                footer: false,
                profile: {
                    ...state.profile,
                    ...action.payload.profile
                }
            };
        case 'CABINET.REQUESTS':
            return {
                ...state,
                page: {
                    type: "CABINET",
                    title: "Заявки",
                    class: "tm-page-cabinet tm-page-cabinet-requests"
                },
                sidebar: {
                    active: true,
                    currentItem: 1
                },
                footer: false,
                profile: {
                    ...state.profile,
                    ...action.payload.profile
                }
            };
        case 'CABINET.PROJECTS_ARCHIVE':
            return {
                ...state,
                page: {
                    type: "CABINET",
                    title: "Архив заявок",
                    class: "tm-page-cabinet tm-page-cabinet-projects-archive"
                },
                sidebar: {
                    active: true,
                    currentItem: 2
                },
                footer: false,
                profile: {
                    ...state.profile,
                    ...action.payload.profile
                }
            };
        case 'CABINET.PROJECTS_TABLE':
            return {
                ...state,
                page: {
                    type: "CABINET",
                    title: "Витрина заявок",
                    class: "tm-page-cabinet tm-page-cabinet-projects-table"
                },
                sidebar: {
                    active: true,
                    currentItem: 2
                },
                footer: false,
                profile: {
                    ...state.profile,
                    ...action.payload.profile
                }
            };
        case 'CABINET.PROJECTS':
            return {
                ...state,
                page: {
                    type: "CABINET",
                    title: "Заявки для финансирования",
                    class: "tm-page-cabinet tm-page-cabinet-projects"
                },
                sidebar: {
                    active: true,
                    currentItem: 2
                },
                footer: false,
                profile: {
                    ...state.profile,
                    ...action.payload.profile
                }
            };
        case 'CABINET.PROJECT':
            return {
                ...state,
                page: {
                    type: "CABINET",
                    title: "Проекты",
                    class: "tm-page-cabinet tm-page-project"
                },
                sidebar: {
                    active: true,
                    currentItem: 2
                },
                footer: false,
                profile: {
                    ...state.profile,
                    ...action.payload.profile
                },
                project: {
                    ...state.project,
                    ...action.payload.project
                }
            };
        case 'PERSONAL_AREA.PROJECT.EDIT':
            return {
                ...state,
                page: {
                    type: "CABINET",
                    title: "Редактирование проекта",
                    class: "tm-page-cabinet tm-page-project"
                },
                sidebar: {
                    active: true,
                    currentItem: 2
                },
                footer: false,
                profile: {
                    ...state.profile,
                    ...action.payload.profile
                },
                project: {
                    ...state.project,
                    ...action.payload.project
                }
            };
        case 'CABINET.INVESTMENTS':
            return {
                ...state,
                page: {
                    type: "CABINET",
                    title: "Инвестиции",
                    class: "tm-page-cabinet tm-page-cabinet-investments"
                },
                sidebar: {
                    active: true,
                    currentItem: 3
                },
                footer: false,
                profile: {
                    ...state.profile,
                    ...action.payload.profile
                }
            };
        case 'CABINET.DOCUMENTS':
            return {
                ...state,
                page: {
                    type: "CABINET",
                    title: "Документы",
                    class: "tm-page-cabinet tm-page-cabinet-documents"
                },
                sidebar: {
                    active: true,
                    currentItem: 4
                },
                footer: false,
                profile: {
                    ...state.profile,
                    ...action.payload.profile
                }
            };
        case 'CABINET.SETTINGS':
            return {
                ...state,
                page: {
                    type: "CABINET",
                    title: "Настройки",
                    class: "tm-page-cabinet tm-page-cabinet-settings"
                },
                sidebar: {
                    active: true,
                    currentItem: 3
                },
                footer: false,
                profile: {
                    ...state.profile,
                    mainData: {
                        ...state.profile.mainData,
                        ...action.payload.mainData
                    },
                    passportData: {
                        ...state.profile.passportData,
                        ...action.payload.passportData
                    },
                    entrepreneurData: {
                        ...state.profile.entrepreneurData,
                        ...action.payload.entrepreneurData
                    }
                }
            };
        default:
            return state
    }
}

export default reducers;