import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import store from "../../store";
import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'

UIkit.use(Icons)

const navigationLinks = [
    {
        title: 'О платформе',
        path: 'about'
    },
    {
        title: 'Документы',
        path: '/documents'
    },
    {
        title: 'Инвесторам',
        path: ''
    },
    {
        title: 'Заёмщикам',
        path: 'borrowers'
    }
];

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.handleChangeCabinetType = this.handleChangeCabinetType.bind(this);
        this.changeNavigationLink = this.changeNavigationLink.bind(this);
        this.exit = this.exit.bind(this);

        this.state = {
            currentPath: window.location.pathname.split('/')[1]
        }
    }

    async componentDidMount() {
        const profileHash = localStorage.getItem("profileHash");

        if (profileHash && !this.props.profile.access.authorized) {
            await fetch("https://api.crowd-money.ru/profile", {
                method: 'POST',
                body: JSON.stringify({
                    data: {
                        profileHash: profileHash
                    }
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        if (!result) {
                            localStorage.removeItem('profileHash');
                        }
                        else {
                            store.dispatch({
                                type: 'SET.AUTH',
                                payload: result
                            });

                            if (this.props.page.type === 'MAIN' && result.approve && !result.verification.id) {
                                UIkit.modal("#tm-modal-scoring").show();
                            }
                        }
                    },
                    (error) => {
                        console.log(error)
                    }
                );
        }
    }

    exit = () => {
        localStorage.removeItem('profileHash');
        store.dispatch({
            type: 'MAIN.SIGNOUT'
        });
        this.changeNavigationLink();
    };

    handleChangeCabinetType = (group) => {
        this.setState({
            group: group
        });

        store.dispatch({
            type: 'CABINET.CHANGE_CABINET_TYPE',
            payload: {
                group: group
            }
        });
    };

    changeNavigationLink = (currentPath) => {
        this.setState(
            {
                ...this.state,
                currentPath: currentPath
            });

        UIkit.offcanvas('#tm-header-sidebar').hide();
    };

    render() {
        const { group, authorized } = this.props.profile.access;
        const { avatar } = this.props.profile.mainData;
        const { qualified, entrepreneurData } = this.props.profile;
        const { pageType } = this.props;
        const { currentPath } = this.state;

        return (
            <>
                <div className="tm-header">
                    <div className="uk-position-relative">
                        {
                            pageType !== 'CABINET' ? (
                                <div className="uk-container">
                                    <nav className="uk-navbar uk-navbar-transparent uk-margin-top">
                                        <div className="uk-navbar-left">
                                            <ul className="uk-navbar-nav">
                                                <li className="uk-navbar-item" Style="padding-left: 0;">
                                                    <Link
                                                        to="/"
                                                        className={"tm-logotype"}
                                                        onClick={() => this.changeNavigationLink('')}
                                                    />
                                                </li>
                                                {
                                                    navigationLinks.map((navigationLink, index) => (
                                                        <li className={`uk-visible@m uk-navbar-item ${navigationLink.path === currentPath ? 'uk-active' : ''}`}>
                                                            <Link
                                                                to={navigationLink.path}
                                                                onClick={() => this.changeNavigationLink(navigationLink.path)}
                                                            >
                                                                {navigationLink.title}
                                                            </Link>
                                                        </li>

                                                    ))
                                                }
                                            </ul>
                                        </div>
                                        <div className="uk-navbar-right">
                                            <ul className="uk-navbar-nav">
                                                <li className="uk-navbar-item uk-visible@m">
                                                    <div className="tm-header-registry">
                                                        <div className="uk-flex uk-flex-middle">
                                                            {/* <img src={require('../../assets/images/cbrf.svg')} alt="" style={{ height: 58, opacity: 0.9 }} /> */}
                                                            <span className="uk-margin-small-left">Платформа включена в реестр <br />Центрального банка РФ</span>
                                                        </div>
                                                    </div>
                                                </li>
                                                {
                                                    authorized && (
                                                        <>
                                                            <div className="tm-navbar-button-box uk-flex uk-flex-middle uk-navbar-item uk-visible@m">
                                                                <img class="uk-border-pill" src={avatar ? `/public/uploads/avatars/${avatar}` : require('../../assets/images/icon-person.svg')} width="50" height="50" alt="Border pill" />
                                                                <span>
                                                                    <li>
                                                                        <Link to={group == 0 ? "/cabinet" : "/main"} className="uk-button uk-button-default">Кабинет</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link to="/" className="uk-button uk-button-default" onClick={() => this.exit()}>Выйти</Link>
                                                                    </li>
                                                                </span>
                                                            </div>
                                                            <li>
                                                                <a className="uk-navbar-toggle uk-hidden@m" data-uk-navbar-toggle-icon data-uk-toggle="target: #tm-header-sidebar;" href="#"></a>
                                                            </li>
                                                        </>
                                                    )
                                                }
                                                {
                                                    !authorized && (
                                                        <>
                                                            <div className="tm-navbar-button-box uk-flex uk-flex-middle uk-navbar-item uk-visible@m">
                                                                <img class="uk-border-pill" src={require('../../assets/images/icon-person.svg')} width="50" height="50" alt="Border pill" />
                                                                <span>
                                                                    <li>
                                                                        <Link to="/signin" className="uk-button uk-button-default" onClick={() => this.changeNavigationLink()}>Вход</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link to="/signup" className="uk-button uk-button-default" onClick={() => this.changeNavigationLink()}>Регистрация</Link>
                                                                    </li>
                                                                </span>
                                                            </div>
                                                            <li>
                                                                <a className="uk-navbar-toggle uk-hidden@m" data-uk-navbar-toggle-icon data-uk-toggle="target: #tm-header-sidebar;" href="#"></a>
                                                            </li>
                                                        </>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </nav>
                                    <div >
                                        <hr />
                                    </div>
                                </div>
                            ) : (
                                <nav className={`uk-navbar uk-container uk-navbar-transparent ${group === 0 ? 'uk-container-expand' : ''} ${this.props.profile.verification.id && entrepreneurData.type === 1 && 'tm-margin-bottom-mobile'}`}>
                                    <div className="uk-navbar-left">
                                        <ul className="uk-navbar-nav tm-header-profile-types">
                                            {
                                                group === 0 && (
                                                    <li className="uk-navbar-item">
                                                        <div className="uk-button uk-button-small uk-button-default uk-active"><span /><span>Администратор</span></div>
                                                    </li>
                                                )
                                            }
                                            {
                                                group === 1 && (
                                                    <li className="uk-navbar-item uk-flex uk-flex-wrap uk-flex-left">
                                                        <div className={`uk-button uk-button-small uk-button-default ${this.props.profile.verification.id && 'uk-active'} uk-margin-right`}><span /><span>Инвестор</span></div>
                                                        {
                                                            this.props.profile.verification.id && entrepreneurData.type === 1 && (
                                                                <>
                                                                    <div className={`uk-button uk-button-small uk-button-default ${qualified === 1 && 'uk-active'}`}><span />
                                                                        <span>{qualified === 1 ? "Квалифицированный" : "Неквалифицированный"}</span>
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                    </li>
                                                )
                                            }
                                            {
                                                group === 2 && (
                                                    <li className="uk-navbar-item">
                                                        <div className={`uk-button uk-button-small uk-button-default ${this.props.profile.verification.id && 'uk-active'}`}><span /><span>Заёмщик</span></div>
                                                    </li>
                                                )
                                            }
                                            {
                                                group === 3 && (
                                                    <li className="uk-navbar-item">
                                                        <div className="uk-button uk-button-small uk-button-default"><span /><span>Новый пользователь</span></div>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                    <div className="uk-navbar-right">
                                        <ul className="uk-navbar-nav">
                                            <li>
                                                <a className="uk-navbar-toggle uk-hidden@m" data-uk-navbar-toggle-icon data-uk-toggle="target: #tm-sidebar;" href="#"></a>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            )
                        }
                    </div>
                    <div id="tm-modal-scoring" className="uk-flex-top" data-uk-modal>
                        <div className="uk-modal-dialog uk-margin-auto-vertical uk-width-1-3@s">
                            <button className="uk-modal-close-default" type="button" data-uk-close></button>
                            <div class="uk-modal-header">
                                <h3 className="uk-h3 tm-font-weight-5 uk-modal-title">Поздравляем!</h3>
                            </div>
                            <div class="uk-modal-body">
                                <p>Вы успешно прошли модерацию данных в личном кабинете!</p>
                                <p>Далее Вам необходимо пройти автоматическую скоринг-оценку нашей системы и выбрать статус участника на платформе: инвестор или заемщик.</p>
                            </div>
                            <div className="uk-modal-footer">
                                <Link to="/cabinet" onClick={() => { UIkit.modal("#tm-modal-scoring").hide() }} className="uk-button uk-button-primary uk-align-center">Перейти в личный кабинет</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="tm-header-sidebar" data-uk-offcanvas="mode: slide; overlay: true;">
                    <div className="uk-offcanvas-bar uk-offcanvas-bar-animation uk-offcanvas-push">
                        <div className="uk-position-relative">
                            <div className="uk-container">
                                <nav className="uk-navbar uk-navbar-transparent uk-margin-top">
                                    <div className="uk-navbar-left">
                                        <ul className="uk-navbar-nav">
                                            <li className="uk-navbar-item" Style="padding-left: 0;">
                                                <Link
                                                    to="/"
                                                    className={"tm-logotype"}
                                                    onClick={() => this.changeNavigationLink('')}
                                                />
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="uk-navbar-right">
                                        <a className="uk-navbar-toggle uk-hidden@m" uk-icon="close" data-uk-toggle="target: #tm-header-sidebar;" href="#"></a>
                                    </div>
                                </nav>
                                <div >
                                    <hr />
                                </div>
                            </div>
                        </div>
                        <div className="uk-padding-small">
                            <ul className="uk-nav tm-navigation">
                                {
                                    navigationLinks.map((navigationLink, index) => (
                                        <li className={navigationLink.path === currentPath ? 'uk-active' : ''}>
                                            <Link
                                                to={navigationLink.path}
                                                onClick={() => this.changeNavigationLink(navigationLink.path)}
                                            >
                                                {navigationLink.title}
                                            </Link>
                                        </li>
                                    ))
                                }
                                {
                                    authorized && (
                                        <>
                                            <li className="uk-margin">
                                                <Link
                                                    to="/cabinet"
                                                    className="uk-button uk-button-default"
                                                    onClick={() => this.changeNavigationLink()}
                                                >
                                                    Личный кабинет
                                                </Link>
                                            </li>
                                            <li className="uk-margin">
                                                <Link
                                                    to="/"
                                                    className="uk-button uk-button-default"
                                                    onClick={() => this.exit()}>
                                                    Выйти
                                                </Link>
                                            </li>
                                            <li className="uk-margin">
                                                <div className="tm-header-registry">
                                                    <div className="uk-flex uk-flex-middle">
                                                        {/* <img src={require('../../assets/images/cbrf.svg')} alt="" style={{ height: 58, opacity: 0.9 }} /> */}
                                                        <span className="uk-width-2-3@m">Платформа включена в реестр <br />Центрального банка РФ</span>
                                                    </div>
                                                </div>
                                            </li>
                                        </>
                                    )
                                }
                                {
                                    !authorized && (
                                        <>
                                            <li className="uk-margin">
                                                <Link to="/signin" className="uk-button uk-button-default" onClick={() => this.changeNavigationLink()}>Вход</Link>
                                            </li>
                                            <li className="uk-margin">
                                                <Link to="/signup" className="uk-button uk-button-default" onClick={() => this.changeNavigationLink()}>Регистрация</Link>
                                            </li>
                                            <li className="uk-margin">
                                                <div className="tm-header-registry">
                                                    <div className="uk-flex uk-flex-middle">
                                                        {/* <img src={require('../../assets/images/cbrf.svg')} alt="" style={{ height: 58, opacity: 0.9 }} /> */}
                                                        <span className="uk-width-2-3@m">Платформа включена в реестр <br />Центрального банка РФ</span>
                                                    </div>
                                                </div>
                                            </li>
                                        </>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(Header);