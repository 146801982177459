import React from 'react';
import {connect} from "react-redux";
import InputMask from "react-input-mask";

class SectionBank extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { parentThis, data, onChange, disableSection} = this.props;

        return (
            <>
                <h3 className="uk-h3">Банковские реквизиты</h3>
                <div className="uk-grid-row-small" data-uk-grid>
                    <div className="uk-width-1-2@s">
                        <label className="uk-form-label">Расчётный счёт</label>
                        <InputMask
                            mask="99999999999999999999"
                            onChange={onChange}
                            value={data.account}                           
                        >
                            <input
                                type="text"
                                className="uk-input"
                                name="account"
                                ref={(input) => {parentThis.account = input}}
                            />
                        </InputMask>
                    </div>
                    <div className="uk-width-1-2@s">
                        <label className="uk-form-label">БИК Банка</label>
                        <InputMask
                             mask="999999999"
                            onChange={onChange}
                            value={data.bik}                         
                        >
                            <input
                                type="text"
                                className="uk-input"
                                name="bik"
                                ref={(input) => {parentThis.bik = input}}
                            />
                        </InputMask>
                    </div>                    
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(SectionBank);