import React from 'react';
import CurrencyValue from '../../../partials/others/CurrencyValue';
import store from "../../../store";
import {connect} from "react-redux";
import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'

UIkit.use(Icons);

class Partners extends React.Component {
    constructor(props) {      
        super(props);

        this.state = {
            profile: this.props.profile,
            partnerName: "",
            partners: [],
            profiles: [],
            investments: []       
        }

        this.addPartner = this.addPartner.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getRefLink = this.getRefLink.bind(this);
        this.onCopy = this.onCopy.bind(this);
    }

    async componentDidMount() {
        const profileHash = localStorage.getItem("profileHash");

        if (profileHash) {
            await fetch("https://api.crowd-money.ru/profile", {
                method: 'POST',
                body: JSON.stringify({
                    data: {
                        profileHash: profileHash
                    }
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        if (!result) {
                            localStorage.removeItem('profileHash');
                            this.props.history.push('/');
                        }

                        this.setState({
                            profile: result
                        })
                    },
                    (error) => {
                        console.log(error)
                    }
                );
        }

        if (this.state.profile.access.authorized === false) {
            this.props.history.push('/');
        }
        else {
            store.dispatch({
                type: 'CABINET.INDEX',
                payload: {
                    profile: this.state.profile
                }
            });

            await fetch("https://api.crowd-money.ru/partners", {
                method: 'POST',
                body: JSON.stringify({
                    action: 'getPartners'
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            partners: result
                        })
                    },
                    (error) => {
                        console.log(error)
                    }
                );

            fetch("https://api.crowd-money.ru/profile", {
                method: 'POST',
                body: JSON.stringify({
                    action: 'getProfiles',
                    data: {
                        profileId: this.state.profile.access.id
                    }
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            ...this.state,
                            profiles: result
                        })
                    },
                    (error) => {
                        console.log(error)
                    }
                );

            fetch("https://api.crowd-money.ru/payments", {
                method: 'POST',
                body: JSON.stringify({
                    action: "getPayments",
                    data: {
                        inquirer: {
                            profileHash: this.state.profile.access.id
                        }
                    }
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            investments: result.filter(p => p.type === 2)
                        })
                    },
                    (error) => {
                        console.log(error)
                    }
                );
        }
    }

    getRefLink = (refCode) => {
        return `https://crowd-money.ru/signup?partnerCode=${refCode}`;             
    }

    onCopy = (refCode) => {
        navigator.clipboard.writeText(this.getRefLink(refCode));

        store.dispatch({
            type: "SHOW.SNACKBAR",
            payload: {
                status: "success",
                message: "Реферальная ссылка скопирована"
            }
        });        
    }

    addPartner = () => {    
        fetch("https://api.crowd-money.ru/partners", {
            method: 'POST',
            body: JSON.stringify({
                action: 'setPartner',
                partnerName: this.state.partnerName
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    store.dispatch({
                        type: "SHOW.SNACKBAR",
                        payload: {
                            status: result.status,
                            message: result.message
                        }
                    });

                    this.setState({            
                        ...this.state.partners.push({
                            partnerName: result.partnerName,
                            createDate: result.createDate,
                            refCode: result.refCode
                        })
                    })
                },
                (error) => {
                    console.log(error)
                }
            );
    }

    handleChange = (e) => {
        this.setState({
            ...this.state,
            partnerName: e.target.value
        })
    };

    render() {  
        const {partners, profiles, investments} = this.state;
        
        return (
            <div className="tm-body">
                <div className="tm-section-documents">
                    <div className="uk-container uk-container-expand uk-section-small uk-padding-remove-top">
                        <h3 className="uk-h3 tm-font-weight-5">Партнеры</h3>                       
                        <div className="uk-overflow-auto">
                            <div className=" uk-flex uk-flex-middle"> 
                                <button className="uk-button uk-button-medium uk-button-primary"
                                        onClick={this.addPartner}>
                                    Добавить
                                </button>                                                               
                                <input
                                    className="uk-input uk-margin-left uk-form-width-large"
                                    type="text"    
                                    name="partnerName"                                
                                    placeholder="Наименование партнера"
                                    ref={(input) => {this.partnerName = input}}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <table className="tm-table-balance-operations uk-table uk-table-divider uk-table-hover">
                                <thead>
                                    <tr>
                                        <th>Наименование партнера</th>
                                        <th>Дата создания</th>
                                        <th>Кол-во привлеченных пользователей</th>
                                        <th>Сумма привлеченных инвестиций</th>
                                        <th>Реф. ссылка</th>                                       
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    partners.length > 0 ? (
                                        partners.map((partner) => (
                                            <tr>
                                                <td>  
                                                    {partner.partnerName}                                                  
                                                </td>
                                                <td>  
                                                    {partner.createDate}                                                   
                                                </td>                                                
                                                <td> 
                                                    {profiles.reduce((sum, profile) => { if (profile.partnerId == partner.partnerId) { return sum + 1 } else { return sum} }, 0)}                                                   
                                                </td>
                                                <td> 
                                                    {<CurrencyValue value={investments.reduce((sum, invest) => { if (invest.mainData.partnerId == partner.partnerId) { return sum + parseInt(invest.amount) } else { return sum} }, 0)} currencyType={"₽"} />}                                                      
                                                </td>
                                                <td>
                                                    <div className=" uk-flex uk-flex-middle">
                                                        {this.getRefLink(partner.refCode)}
                                                        <button className="uk-button uk-button-small uk-button-primary uk-margin-auto-left"
                                                            data-uk-icon="icon: copy;"
                                                            onClick={() => this.onCopy(partner.refCode)}
                                                        />
                                                    </div>
                                                </td>                                                
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={5}>Партнеры отсутствуют</td>
                                        </tr>
                                    )
                                }
                                </tbody>
                            </table>
                        </div>
                        <div>                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(Partners);