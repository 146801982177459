import React, { useState } from 'react';
import UIkit from 'uikit'
import CurrencyValue from '../../../partials/others/CurrencyValue';
import AgreementRequestBKI from '../../../partials/others/AgreementRequestBKI';
import { IMaskInput } from 'react-imask';
import InputMask from "react-input-mask";
import store from "../../../store";
import fileDownload from 'js-file-download';

class CalculateSchedule extends React.Component {
    constructor(props) {

        const inScheduleParams = { ...props.repaymentScheduleParams };

        super(props);
        this.state = {
            profile: props.profile,
            viewDocs: props.viewDocs,
            customVisible: props.customVisible,
            adminCustomVisible: props.adminCustomVisible,
            projects: [],
            documents: [],
            isLoaded: false,
            isLoadedDocs: false,
            repaymentSchedule: {
                projectId: inScheduleParams.projectId ? inScheduleParams.projectId : null,
                amount: inScheduleParams.amount ? inScheduleParams.amount : 2500000,
                duration: inScheduleParams.duration ? inScheduleParams.duration : 12,
                rate: inScheduleParams.rate ? inScheduleParams.rate : 15,
                ratePlatform: inScheduleParams.ratePlatform ? inScheduleParams.ratePlatform : null,
                borrowerName: inScheduleParams.borrowerName ? inScheduleParams.borrowerName : '',
                borrowerInn: inScheduleParams.borrowerInn ? inScheduleParams.borrowerInn : '',
                borrowerOgrn: inScheduleParams.borrowerOgrn ? inScheduleParams.borrowerOgrn : '',
                projectNumber: inScheduleParams.projectNumber ? inScheduleParams.projectNumber : '',
                type: inScheduleParams.repaymentType > 0 ? inScheduleParams.repaymentType - 1 : 0,
                createDate: this.getNowDateFormat()
            },
            repaymentScheduleData: {
                rows: [],
                total: 0
            },
            activeGenerate: false,
            activeDownloadSpinner: false
        }

        this.continueGenerateRepayment = this.continueGenerateRepayment.bind(this);
        this.getRepaymentScheduleData = this.getRepaymentScheduleData.bind(this);
        this.generateRepaymentSchedule = this.generateRepaymentSchedule.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    async componentDidMount() {
        fetch("https://api.crowd-money.ru/documents", {
            method: 'POST'
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoadedDocs: true,
                        documents: result
                    })
                },
                (error) => {
                    console.log(error)
                }
            );
    }

    getRepaymentScheduleData = () => {
        fetch("https://api.crowd-money.ru/loans/repaymentSchedule", {
            method: 'POST',
            body: JSON.stringify({
                action: "getRepaymentScheduleData",
                data: {
                    ...this.state.repaymentSchedule
                }
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    store.dispatch({
                        type: "SHOW.SNACKBAR",
                        payload: {
                            status: "success",
                            message: "График сформирован"
                        }
                    });
                    this.setState({
                        ...this.state,
                        activeDownloadSpinner: false,
                        repaymentScheduleData: {
                            ...result
                        }
                    })

                    UIkit.scroll("#target").scrollTo("#target");
                },
                (error) => {
                    console.log(error)
                }
            );
    }

    publishRepaymentSchedule = () => {
        this.setState({
            ...this.state,
            activeDownloadSpinner: true,
        })

        fetch("https://api.crowd-money.ru/loans/repaymentSchedule", {
            method: 'POST',
            body: JSON.stringify({
                action: "publishRepaymentSchedule",
                data: {
                    params: { ...this.state.repaymentSchedule }
                }
            })
        })
            .then(res => res.json())
            .then(() => {
                store.dispatch({
                    type: "SHOW.SNACKBAR",
                    payload: {
                        status: "success",
                        message: "График опубликован"
                    }
                });
                this.setState({
                    ...this.state,
                    activeDownloadSpinner: false,
                })
            },
                (error) => {
                    console.log(error)
                    this.setState({
                        ...this.state,
                        activeDownload: false,
                    })
                }
            );
    }

    generateRepaymentSchedule = () => {
        this.setState({
            ...this.state,
            activeDownloadSpinner: true,
        })

        fetch("https://api.crowd-money.ru/loans/repaymentSchedule", {
            method: 'POST',
            body: JSON.stringify({
                action: "generateRepaymentSchedule",
                data: {
                    params: { ...this.state.repaymentSchedule }
                }
            })
        })
            .then(res => res.blob())
            .then((blob) => {
                store.dispatch({
                    type: "SHOW.SNACKBAR",
                    payload: {
                        status: "success",
                        message: "Файл подготовлен"
                    }
                });
                this.setState({
                    ...this.state,
                    activeDownloadSpinner: false,
                })
                fileDownload(blob, `График погашения.pdf`)
            },
                (error) => {
                    console.log(error)
                    this.setState({
                        ...this.state,
                        activeDownload: false,
                    })
                }
            );
    }

    continueGenerateRepayment = () => {
        let repaymentSchedule = this.state.repaymentSchedule;

        this.setState({
            ...this.state,
            activeGenerate: (repaymentSchedule.amount > 0 &&
                repaymentSchedule.rate > 0)
                ? false : true
        })
    }

    getNowDateFormat() {
        let date = new Date();
        return String(date.getDate()).padStart(2, '0') + '.' + String(date.getMonth() + 1).padStart(2, '0') + '.' + date.getFullYear();
    }

    handleChange = (e) => {
        this.setState({
            ...this.state,
            repaymentSchedule: {
                ...this.state.repaymentSchedule,
                [e.target.name]: e.target.value
            }
        })
    };

    render() {
        //Лютый костыль. Подмешиваем данные по заявке, чтобы после изменения значений в заявке, на вкладке с графиком они также учитывались
        if(this.props.repaymentScheduleParams){
            this.state = {
                ...this.state,
                repaymentSchedule: {
                    ...this.state.repaymentSchedule,
                    ...this.props.repaymentScheduleParams
                }
            }
        }

        const { isLoadedDocs, viewDocs, customVisible, profile, documents, repaymentScheduleData, repaymentSchedule, activeGenerate, activeDownloadSpinner, adminCustomVisible } = this.state;
       
        return (
            <>
                <div data-uk-grid>
                    <div className={`${viewDocs ? 'uk-width-2-3@m' : 'uk-width'}`}>
                        <div className="tm-form-calculate">
                            {
                                customVisible == true && (
                                    <div className="tm-form-calculate-head uk-text-center">
                                        Расчет графика погашения
                                    </div>
                                )
                            }
                            <div className={`tm-form-calculate-body`}>
                                <div data-uk-grid>
                                    {
                                        customVisible == true && (
                                            <>
                                                <div className="uk-width-1-5@m">
                                                    <label className="uk-form-label">
                                                        Сумма (руб.)
                                                    </label>
                                                    <IMaskInput
                                                        mask={Number}
                                                        unmask={true}
                                                        scale={0}
                                                        min={100000}
                                                        max={999999999}
                                                        className="uk-input"
                                                        value={`${repaymentSchedule.amount}`}
                                                        name="amount"
                                                        ref={(input) => { this.amount = input }}
                                                        onChange={() => this.continueGenerateRepayment(true)}
                                                        onAccept={
                                                            (value) => {
                                                                this.setState({
                                                                    ...this.state,
                                                                    repaymentSchedule: {
                                                                        ...this.state.repaymentSchedule,
                                                                        ['amount']: value
                                                                    }
                                                                })
                                                            }
                                                        }
                                                    />
                                                </div>
                                                <div className="uk-width-1-5@m">
                                                    <label className="uk-form-label">
                                                        Ставка (%)
                                                    </label>
                                                    <IMaskInput
                                                        mask={Number}
                                                        scale={2}
                                                        unmask={true}
                                                        className="uk-input"
                                                        value={`${repaymentSchedule.rate}`}
                                                        name="rate"
                                                        ref={(input) => { this.rate = input }}
                                                        onChange={() => this.continueGenerateRepayment()}
                                                        onAccept={
                                                            (value) => {
                                                                this.setState({
                                                                    ...this.state,
                                                                    repaymentSchedule: {
                                                                        ...this.state.repaymentSchedule,
                                                                        rate: value
                                                                    }
                                                                })
                                                            }
                                                        }
                                                    />
                                                </div>
                                                <div className="uk-width-1-6@m">
                                                    <label className="uk-form-label">
                                                        Срок (мес.)
                                                    </label>
                                                    <select
                                                        className="uk-select"
                                                        name="duration"
                                                        value={repaymentSchedule.duration}
                                                        onChange={this.handleChange}
                                                        ref={(select) => { this.duration = select }}
                                                    >
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                        <option value="7">7</option>
                                                        <option value="8">8</option>
                                                        <option value="9">9</option>
                                                        <option value="10">10</option>
                                                        <option value="11">11</option>
                                                        <option value="12">12</option>
                                                    </select>
                                                </div>
                                            </>
                                        )
                                    }
                                    {
                                        profile.access.group === 0 && (
                                            <div className="uk-width-1-6@m">
                                                <label className="uk-form-label">
                                                    Дата выдачи займа
                                                </label>
                                                <InputMask mask="99.99.9999" onChange={this.handleChange}>
                                                    <input
                                                        type="text"
                                                        className="uk-input"
                                                        name="createDate"
                                                        defaultValue={repaymentSchedule.createDate}
                                                    />
                                                </InputMask>
                                            </div>
                                        )
                                    }
                                    <div className="uk-width-1-4@m">
                                        <label className="uk-form-label">
                                            Тип погашения
                                        </label>
                                        <select
                                            className="uk-select"
                                            name="type"
                                            value={repaymentSchedule.type}
                                            onChange={this.handleChange}
                                            ref={(select) => { this.type = select }}
                                        > 
                                        {/* Примечание. Эти номера типов не совпадают с типами в самой админке, там они +1 к этим */}
                                            <option value="0" selected={0}>В конце срока</option>
                                            <option value="1" selected={1}>Ежеквартально</option>
                                            <option value="2" selected={2}>Ежемесячно</option>
                                            <option value="3" selected={3}>Ежемесячно (аннуитет)</option>
                                        </select>
                                    </div>
                                    {
                                        profile.access.group === 0 && adminCustomVisible == true && (
                                            <>
                                                <div className="uk-width-2-5@m">
                                                    <label className="uk-form-label">
                                                        Наименование заемщика
                                                    </label>
                                                    <InputMask onChange={this.handleChange}>
                                                        <input
                                                            type="text"
                                                            className="uk-input"
                                                            name="borrowerName"
                                                            defaultValue={repaymentSchedule.borrowerName}
                                                        />
                                                    </InputMask>
                                                </div>                                                
                                                <div className="uk-width-1-6@m">
                                                    <label className="uk-form-label">
                                                        Комиссия (%)
                                                    </label>
                                                    <IMaskInput
                                                        mask={Number}
                                                        scale={2}
                                                        unmask={true}
                                                        className="uk-input"
                                                        value={`${repaymentSchedule.ratePlatform}`}
                                                        name="ratePlatform"
                                                        ref={(input) => { this.ratePlatform = input }}
                                                        onChange={() => this.continueGenerateRepayment()}
                                                        onAccept={
                                                            (value) => {
                                                                this.setState({
                                                                    ...this.state,
                                                                    repaymentSchedule: {
                                                                        ...this.state.repaymentSchedule,
                                                                        ratePlatform: value
                                                                    }
                                                                })
                                                            }
                                                        }
                                                    />
                                                </div>
                                                <div className="uk-width-1-6@m">
                                                    <label className="uk-form-label">
                                                        ИНН
                                                    </label>
                                                    <InputMask onChange={this.handleChange}>
                                                        <input
                                                            type="text"
                                                            className="uk-input"
                                                            name="borrowerInn"
                                                            defaultValue={repaymentSchedule.borrowerInn}
                                                        />
                                                    </InputMask>
                                                </div>
                                                <div className="uk-width-1-6@m">
                                                    <label className="uk-form-label">
                                                        ОГРН
                                                    </label>
                                                    <InputMask onChange={this.handleChange}>
                                                        <input
                                                            type="text"
                                                            className="uk-input"
                                                            name="borrowerOgrn"
                                                            defaultValue={repaymentSchedule.borrowerOgrn}
                                                        />
                                                    </InputMask>
                                                </div>
                                                <div className="uk-width-1-6@m">
                                                    <label className="uk-form-label">
                                                        Номер договора
                                                    </label>
                                                    <InputMask onChange={this.handleChange}>
                                                        <input
                                                            type="text"
                                                            className="uk-input"
                                                            name="projectNumber"
                                                            defaultValue={repaymentSchedule.projectNumber}
                                                        />
                                                    </InputMask>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                                <div className="tm-calculate-actions-box">
                                    <div className="uk-button uk-button-primary uk-button-large" href="#target" uk-scroll disabled={activeGenerate} onClick={this.getRepaymentScheduleData}>Сформировать график</div>
                                    {
                                        this.props.repaymentScheduleParams && repaymentScheduleData.rows.length > 0 && (
                                            <div className="uk-button uk-button-primary uk-button-large" disabled={activeGenerate} onClick={this.publishRepaymentSchedule}>
                                                Опубликовать {activeDownloadSpinner == true && (<span className="uk-margin-left" data-uk-spinner="ratio: 1" />)}
                                            </div>
                                        )
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                    {
                        viewDocs == true &&
                        (
                            <div className="uk-inline uk-width-1-3@m">
                                <div className="uk-text-center tm-form-calculate-head">Документы</div>
                                <div data-uk-grid >
                                    <div className="uk-align-center">
                                        <div>
                                            <div className="tm-text-medium uk-margin-top">
                                                Договор инвестирования
                                            </div>
                                            {isLoadedDocs &&
                                                (
                                                    <>
                                                        <a href={`/documents/${documents[1]['data'][0]}`} download className="uk-button uk-button-primary uk-margin-top">Скачать документ</a>
                                                    </>
                                                )
                                                ||
                                                (
                                                    <span className="uk-position-center" data-uk-spinner="ratio: 1" />
                                                )
                                            }
                                        </div>{
                                            profile.access.group == 2 &&
                                            (
                                                <div>
                                                    <div className="tm-text-medium uk-margin-top">
                                                        Согласие на запрос в БКИ
                                                    </div>
                                                    <AgreementRequestBKI
                                                        profileId={profile.access.id}
                                                        element={<a className="uk-button uk-button-default uk-margin-top">Скачать документ</a>}
                                                    />
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <hr />
                {
                    repaymentScheduleData.rows.length > 0 &&
                    (
                        <>
                            <div data-uk-grid className="uk-child-width-auto uk-margin-medium-top uk-margin-medium-bottom">
                                <h3 className="uk-h3 tm-font-weight-5 uk-margin-small-top" id="target">График погашения</h3>
                                <button
                                    className="uk-button uk-button-primary uk-margin-auto-left"
                                    disabled={activeDownloadSpinner}
                                    onClick={this.generateRepaymentSchedule}>
                                    Скачать {activeDownloadSpinner == true && (<span className="uk-margin-left" data-uk-spinner="ratio: 1" />)}
                                </button>
                            </div>
                            <div className="uk-overflow-auto">
                                <table className="tm-table-balance-operations uk-table uk-text-center uk-table-divider uk-table-hover uk-table-middle">
                                    <thead>
                                        <tr>
                                            <th className="uk-text-center uk-table-middle">Номер платежа (месяц)</th>
                                            <th className="uk-text-center uk-table-middle">Задолженность на начало периода</th>
                                            <th className="uk-text-center uk-table-middle">Погашение основного долга по займу</th>
                                            <th className="uk-text-center uk-table-middle">Ежемесячные проценты за пользование займом</th>
                                            <th className="uk-text-center uk-table-middle">Количество дней пользования средствами займа</th>
                                            <th className="uk-text-center uk-table-middle">Выплаты Займодавцу</th>
                                            <th className="uk-text-center uk-table-middle">Дата платежа (не позднее)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            repaymentScheduleData.rows.map((row) => (
                                                <tr>
                                                    <td>
                                                        {row.number}
                                                    </td>
                                                    <td>
                                                        <CurrencyValue value={row.debtAmount} />
                                                    </td>
                                                    <td>
                                                        <CurrencyValue value={row.repaymentAmount} />
                                                    </td>
                                                    <td>
                                                        <CurrencyValue value={row.rateMonthly} />
                                                    </td>
                                                    <td>
                                                        {row.daysCount}
                                                    </td>
                                                    <td>
                                                        <CurrencyValue value={row.paymentAmount} />
                                                    </td>
                                                    <td>
                                                        {row.paymentDate}
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        <tr className="uk-text-bold">
                                            <td colSpan={5}>
                                                Общие выплаты за весь период:
                                            </td>
                                            <td>
                                                <CurrencyValue value={repaymentScheduleData.total} />
                                            </td>
                                            <td />
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )
                }
            </>
        )
    }
}

export default CalculateSchedule;