import React from 'react';

class RequestVerification extends React.Component {
    render() {
        return (
            <>
                <h3 className="uk-h3 uk-margin-medium-bottom tm-font-weight-5">
                    Отправка заявки на проверку
                </h3>
                <div className="uk-card uk-card-default">
                    <div className="uk-card-header">
                        Идёт проверка
                    </div>
                    <div className="uk-card-body">
                        <h4 className="uk-h4">
                            Заявка отправлена на проверку!<br />
                            Уведомление о публикации придет Вам на почту!
                        </h4>
                        <div className="uk-width-4-5@s uk-text-muted">
                            В случае успешного прохождения проверки на инвестиционной платформе «Crowd money» в автоматическом режиме будет сгенерировано инвестиционное предложение с последующей возможностью подписания договора займа инвесторами.
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default RequestVerification;