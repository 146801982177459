import React from 'react';
import store from "../../../../../store";

import ProjectForm from "../../../Requests/components/Form";

class ProjectEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            profile: this.props.profile
        };

        this.update = this.update.bind(this);
    }

    async componentDidMount() {
        const profileHash = localStorage.getItem("profileHash");

        if (profileHash) {
            await fetch("https://api.crowd-money.ru/profile", {
                method: 'POST',
                body: JSON.stringify({
                    data: {
                        profileHash: profileHash
                    }
                })
            })
            .then(res => res.json())
            .then(
                (result) => {
                    if (!result) {
                        localStorage.removeItem('profileHash');
                        this.props.history.push('/');
                    }

                    this.setState({
                        profile: result
                    })
                },
                (error) => {
                    console.log(error)
                }
            );
        }

        if (this.state.profile.access.authorized === false) {
            this.props.history.push('/');
        }
        else {
            await fetch("https://api.crowd-money.ru/loans/get/"+window.location.pathname.split('/')[3], {
                method: 'POST'
            })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.profileId === this.state.profile.access.id && result.status === 2) {
                        this.setState({
                            isLoaded: true,
                            project: result
                        });
                    }
                    else {
                        this.props.history.push('/cabinet');
                    }
                },
                (error) => {
                    console.log(error)
                }
            );

            store.dispatch({
                type: 'PERSONAL_AREA.PROJECT.EDIT',
                payload: {
                    profile: this.state.profile
                }
            });
        }
    }

    update = () => {
        store.dispatch({
            type: "HIDE.SNACKBAR"
        });

        fetch( "https://api.crowd-money.ru/loans/update", {
            method: 'POST',
            body: JSON.stringify({
                action: "projectUpdate",
                data: {
                    inquirer: {
                        profileHash: this.state.profile.access.hash
                    },
                    project: this.state.project
                }
            })
        })
        .then(res => res.json())
        .then(
            (result) => {
                store.dispatch({
                    type: "SHOW.SNACKBAR",
                    payload: {
                        status: result.status,
                        message: result.message
                    }
                });

                if (result.field) {
                    this[result.field].focus();

                    return false;
                }
            },(error) => {
                console.log(error)
            }
        )
    };

    render() {
        const {isLoaded, profile, project} = this.state;

        return (
            <div className="tm-body">
                <div className="uk-container uk-section-small uk-padding-remove-top">
                    {
                        isLoaded && (<ProjectForm profile={profile} project={project} type="edit" />)
                    }
                </div>
            </div>
        )
    }
}

export default ProjectEdit;